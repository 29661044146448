<main>
    <div class="container-fluid">
        <div class="row text-center">
            <div class="col heading-1 pb-3">
                ABOUT US
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-12 pb-3">
                <b>Property Matrix </b> is a nation-wide network of selected town planners, urban
                economists, property valuers, estate agencies and conveyancers. We create
                a platform where the property industry becomes an integrated network … a matrix where users can
                reason and operate
                beyond their own functions,
                limitations, or locations. This holistic approach achieves results far beyond the advertising and
                selling of property.
            </div>
            <div class="col-12 pb-4">
                The <b>Property Matrix</b> database is designed to serve and connect property
                buyers and sellers on a level
                that has never been
                done before!
            </div>
            <div class="center">
                <div class="paragraph-justify pb-3">
                    <span class="numbers">1</span>
                    Firstly, we provide you with <b>property information you can
                        trust</b>. <u>Every</u> property advertised on this website is accompanied by a
                    planning report, signed off by an experienced town and regional planner, registered with the
                    South African Council
                    of Planners (SACPLAN). This report provides information on the potential of the property – ways
                    to develop, extend, or
                    improve
                    it – based on an interpretation of the land use scheme and assessment of the strategic plans and
                    development policies of
                    the
                    relevant council. The report is also substantiated by typical planning data such as
                    Surveyor-General diagrams for
                    boundaries and
                    servitudes.
                </div>
                <div class="paragraph-justify pb-5">
                    <span class="numbers">2</span>
                    Secondly, our <b>extensive range and unique classification of property
                        types</b>(land uses) are exceptional - not equalled by any other property website you
                    may visit. Whether it be properties relating to business, beauty, retail, religion,
                    education,
                    animals, plants, vehicles, sport facilities; the list goes on… Click <a class="here" href="#"
                        routerLink="">here</a> to view the 'menu' of
                    property types/land uses
                    buyers can
                    “order” from. Sharing in this database (subscription) is free of charge.
                </div>
            </div>
            <div class="row text-center">
                <div class="heading-2 pb-3">
                    HOW DO WE SERVE YOU?
                </div>
            </div>
            <div class="row paragraph pb-3">
                <div class="col-12 first-paragraph">
                    <p>
                        The thought leader, Benjamin Franklin, in his essay 'The Way to Wealth' wrote: “<b>An investment
                            in
                            knowledge pays the best
                            interest</b>”.
                    </p>
                    <p>Transferring knowledge – in the form of instant and reliable data – is our game. We treat all our
                        clients with the
                        highest standards of ethics and
                        professionalism. In doing so, everything “about us” is actually ABOUT YOU:</p>
                </div>
            </div>
        </div>

        <div class="row pb-4">
            <div class="col-12 heading-3 text-center pb-4">
                IF YOU WISH TO:
            </div>
            <br>
            <div class="row extend-container">
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            BUY IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Property Matrix will enable you to
                            find the most fitting property for
                            your exact needs or business requirements. We will also assist you
                            in the structuring of appropriate
                            conditions to be added to your offer to purchase contract to ensure a
                            wise and transparent deal.
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            SELL IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Property Matrix will do a proper planning investigation on your property and its
                            potential, advertise your
                            property (including the report) on
                            this website (free of charge), and our
                            database will immediately trace and
                            notify all buyers on our network with
                            an “appetite” for what your property
                            offers.
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="numbers-right-border-coral px-4">
                        <div class="col-12 heading-3 text-center">
                            SPECULATE WITH IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Property Matrix will do a planning investigation on the property and its
                            potential to advise you on ways to
                            renovate/develop the site optimally
                            and to generate the maximum return
                            on your investment. We can also undertake a market gap analysis to establish your
                            target market
                        </div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <div class="px-4">
                        <div class="col-12 heading-3 text-center">
                            DEVELOP IT
                        </div>
                        <br>
                        <div class="col-12 paragraph text-center">
                            Property Matrix will inspect the possible “zoning”, height, density and
                            coverage for the site and council
                            strategic plans to advise you on
                            the expected process, timeframes
                            and figures to achieve the required
                            legal rights. We can also create a
                            massing diagram to determine the
                            most viable design/layout.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-12">
                <p>
                    Property Matrix is unique in the sense that our partnering estate agents are vetted and trained to
                    collect very
                    specialised information of the land uses
                    and structures on the properties we investigate. Our network is also linked to the inputs and
                    services of related
                    disciplines such as engineers, traffic
                    consultants, environmental consultants, and other experts.
                </p>
            </div>
            <div class="col-12">
                As part of this <b>professional and holistic approach</b>, we also provide a comprehensive range of
                additional
                property-related
                services:
            </div>
            <div class="col-12">
                <ul class="montserrat-bullets">
                    <li>
                        Property investigations - Three levels
                    </li>
                    <li>
                        Property valuations - Two levels
                    </li>
                    <li>
                        Feasibility studies - Two levels
                    </li>
                    <li>
                        Planning applications
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="background-image">
            <div class="subscribe-container center-block">
                <div class="heading-2 center">SHARE IN OUR DATABASE</div>
                <div class="heading-5 center py-3 coral-underline">Subscribe to this knowledge advantage FREE OF CHARGE
                </div>
                <div class="paragraph">
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph-padding">
                            <li>
                                We will notify you straight away when a property, which meets your exact needs/niche,
                                enters
                                the
                                market. This tool will
                                radically reduce the time you spend on property investigations and research.
                            </li>
                            <li>
                                Our extensive range and unique classification of property types (land uses) are
                                exceptional
                                -
                                not equalled by any other
                                property website you have visited before. Click <a href="#" class="here"
                                    routerLink="">here</a>
                                to view the 'menu' of property types you
                                may 'order' from.
                            </li>
                            <li>
                                Be assured that you will not be spammed with notifications. You will only be notified of
                                properties which qualify in
                                terms
                                of your selections.
                            </li>
                            <li>
                                Property presented to you will be accompanied by a planning report, signed off by an
                                experienced
                                SACPLAN-registered
                                professional planner, indicating its potential; possible uses (zoning) and, if
                                applicable,
                                'ripeness' (whether a
                                planning application is approved or in process).
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 pb-4">Multiple 'orders' (up to 15) are possible. Click subscribe below to
                        indicate your preferred property type, prize, size and location for each subscription.</div>
                    <div class="col-12 text-center">
                        <button type="button" class="small-button button" routerLink="/shareInOurDatabase"><span
                                class="heading-3">SUBSCRIBE</span></button>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="text-center">
            <div class="col-12 heading-3 pb-3"><span class="coral-underline-2">Partnership</span></div>
            <div class="paragraph"><b>Property Matrix</b> partners with all town planners and estate
                agents/agencies
                across
                South-Africa.
                For more information on how to partner with us, please refer to the registration section at the bottom
                of
                our home page.</div>
        </div>
    </div>
</main>