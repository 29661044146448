import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-in-our-database',
  templateUrl: './share-in-our-database.component.html',
  styleUrls: ['./share-in-our-database.component.scss']
})
export class ShareInOurDatabaseComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;

  page = 1;

  constructor(private route: Router) { }

  openNav() {
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }

  continueToForm() {
    this.route.navigateByUrl('/shareInOurDatabase/capture');
  }
}