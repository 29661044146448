import { Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: User;

  constructor(private userService: UserService, private authService: PropertiProSharedService) { }

  ngOnInit() {
    if(!this.user){
      return;
    }
    if (this.user.userID == null) {
      this.user = this.authService.userValue;
    }
    this.userService.getbyId(this.user.userID).pipe(first()).subscribe(user => {
      this.user = user;
    })
  }
}
