<div class="background-image container-fluid">
    <section class="content">
        <div class="center-page">
            <div class="heading-1 text-center pb-3">REGISTER AS:</div>
            <div class="row justify-center">
                <div class="col-12 col-lg-8">
                    <div class="choice-block">
                        <a routerLink="/register" class="choice-block-content">
                            <div class="row">
                                <a class="col-4 heading-2 vertical-center">
                                    WEBSITE
                                    <br>
                                    USER
                                </a>
                                <div class="col-1">
                                    <div class="website-user-divider"></div>
                                </div>
                                <div class="col-7 text-start vertical-center">
                                    <ul class="pt-1 paragraph-large">
                                        <li>Buying property</li>
                                        <li>Selling your property</li>
                                        <li>Subscribe to our database</li>
                                        <li>Order additional services</li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6 col-lg-4 pt-3 pt-lg-0">
                    <div class="choice-block">
                        <a routerLink="/plannerRegister" class="choice-block-content heading-2 block-text">
                            URBAN
                            <br>
                            PLANNER
                        </a>
                    </div>
                </div>
                <div class="col-6 col-lg-4 pt-3">
                    <div class="choice-block">
                        <a routerLink="/estateAgentRegistration" class="choice-block-content heading-2 block-text">
                            ESTATE
                            <br>
                            AGENT
                        </a>
                    </div>
                </div>
                <div class="col-6 col-lg-4 pt-3">
                    <div class="choice-block">
                        <a routerLink="/valuerRegistration" class="choice-block-content heading-2 block-text">
                            PROPERTY
                            <br>
                            VALUER
                        </a>
                    </div>
                </div>
                <div class="col-6 col-lg-4 pt-3">
                    <div class="choice-block">
                        <a routerLink="/conveyancer-option" class="choice-block-content heading-2 block-text">
                            CONVEYANCER
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>