import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { animate, style, transition, trigger } from '@angular/animations';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-selling-my-property',
  templateUrl: './selling-my-property.component.html',
  styleUrls: ['./selling-my-property.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class SellingMyPropertyComponent implements AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  showButtons: boolean = false;

  constructor(private _focusMonitor: FocusMonitor, public dialog: MatDialog, private route: Router) { }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore1'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore2'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore3'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore4'));
    GlobalMethods.scrollToTop();
  }

  addOverflow = false;

  isMenuOpen = false;

  showLevel1 = false;

  showLevel2 = false;

  showLevel3 = false;

  showLevel4 = false;

  hasReadPages = false;

  open = false;

  tabIndex = -1;

  isShow = true;

  page = 1;

  openSideNav(index: number, showButtons: boolean) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = index == 0;
    this.showLevel2 = index == 1;
    this.showLevel3 = index == 2;
    this.showLevel4 = index == 3;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
    this.showButtons = showButtons;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    this.addOverflow = false
  }

  closeNav() {
    this.close();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CommissionStructureDialog, {
      width: '45vw',
    })
  }

  navigateToNewTab(route) {
    const url = this.route.serializeUrl(
      this.route.createUrlTree([route])
    );

    window.open(url, '_blank');
  }

  continueToForms() {
    GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/sellingMyProperty/capture', this.route)
  }

  continue(page: number) {
    this.page = page;
    GlobalMethods.scrollToTop();
  }
}

export interface CommisionModel {
  priceRange: string;
  percentage: string;
}

@Component({
  selector: 'commision-structure-dialog',
  templateUrl: 'selling-my-property-commision-structure-dialog.html',
  styleUrls: ['./selling-my-property.component.scss']
})
export class CommissionStructureDialog {
  constructor(
    public dialogRef: MatDialogRef<CommissionStructureDialog>
  ) { }

  tableData: CommisionModel[] = GlobalConstants.commisionStructureTableData;

  onNoClick(): void {
    this.dialogRef.close();
  }
}