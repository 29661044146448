import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'src/app/models/file-uploader';
import { ReportFile } from 'src/app/models/sellingMyProperty/ReportFile';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/common/global-constants';
import { PropertyListingService, FileService } from 'src/app/Services/propertypros/services';
import { PropertyListingFilesEnum, UploadDocumentDetailsVm, UploadDocumentsFileReturnVm } from 'src/app/Services/propertypros/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-workflow-ccf',
  templateUrl: './workflow-ccf.component.html',
  styleUrls: ['./workflow-ccf.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class WorkflowCcfComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('pdfViewer') pdfViewer;
  
  fileUploader = new FileUploader();
  fileIds: any[] = [];
  model: any;

  propertyId: number;
  listingAddress: string;
  customListing: boolean;

  ccfs = []
  user = this.sharedService.getUserModel();

  uploadDocument: File

  constructor(private sharedService: PropertiProSharedService,
    private http: HttpClient,
    private propertyListingService: PropertyListingService,
    private route: ActivatedRoute,
    private fileService: FileService,
    private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.checkPageRolePermissions(this.user, 
      ['AgentPortalAdministrator', 'PlannerPortalAdministrator',
      'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner'],
      this.router);
    this.getPropertyDetailsParams();
    this.getCcfFiles();
  }

  selectUpload(files) {
    this.updateShownBlocks(files[0]);
  }

  dropUpload($event) {
    this.updateShownBlocks($event[0]);
  }

  updateShownBlocks(file) {
    var report: ReportFile = { key: 1, value: 'CCF ' + 1, file: file };
    this.uploadDocument = report.file;
    this.fileUploader.specificFileBrowseHandler([report], 1);
  }

  getPropertyDetailsParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;
      }
    );
  }

  getCcfFiles(){
    this.propertyListingService.apiV1PropertyListingGetFilesGet$Response({ custom: this.customListing, propertyId: this.propertyId, type: PropertyListingFilesEnum.$2 })
      .subscribe({
        next: (result: any) => {
          var data = JSON.parse(result.body);
          this.ccfs = data;
        }, 
        error: (error) => {
          console.log(error);
        }
      }
    )
  }

  uploadFile(){
    const username: string = this.sharedService.getUsername();
    let formData = new FormData();
    this.fileUploader.files.forEach(fileObj => {
      formData.append(`${username}|7|0|${fileObj.id.toString()}|`, (fileObj.file as any).file);
    });
    formData.append('username', username);
    
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)

    this.http.post(environment.APIUrl + FileService.ApiV1FileUploadFilesPostPath, formData, {headers: headers})
      .subscribe({
        next: (result) => {
          var body: UploadDocumentDetailsVm = {
            paths: result as Array<UploadDocumentsFileReturnVm>,
            propertyId: this.propertyId,
            troubleUploadingCopies: false
          }
          this.propertyListingService.apiV1PropertyListingSaveFilesPost$Response({ body: body, custom: this.customListing, type: PropertyListingFilesEnum.$2 })
            .subscribe({
              next: (result) => {
                this.getCcfFiles();
                this.uploadDocument = null;
                this.fileUploader.files = null;
              }
              , error: (error) => {
                console.log(error);
              }
            }
          )
        },
        error: (error) => {
          console.log(error);
        }
      }
    );
  }

  closeNav() {
    this.sidenav.close();
  }

  openCcfFile(fileId: any, fileExtention: string){
    this.fileService.apiV1FileDownloadFileGet$Response({ fileId: fileId })
      .subscribe({
        next: async (dataURI: any) => {
          var blob = await (await fetch(dataURI.body)).blob();
          this.pdfViewer.pdfSrc = new File([blob], "Report" + fileExtention);
          this.pdfViewer.refresh();
          this.sidenav.open();
        },
        error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      }
    )
  }
}
