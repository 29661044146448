<main>
    <div class="container-fluid background-image">
        <div class="row">
            <div class="col-6">
                <div class="m-5">
                    <div *appUserRole="['AgentPortalAdministrator', 'NetworkAgent', 'SeniorAgent']">
                        <div class="h5">Welcome to the Property Practitioners Portal</div>
                        <p class="paragraph">This is a platform where the property industry becomes an integrated network. Here you can reason and operate beyond your own functions, limitations, or locations. This holistic approach achieves results far beyond the advertising and selling of property. The Matrix is alive because professionals in the industry choose to partner with us. We welcome you to the team, the network, the Matrix.
                        </p>
                    </div>
                    <div *appUserRole="['NetworkConveyancer', 'SeniorConveyancer']">
                        <div class="h5">Welcome to the Conveyancers Portal</div>
                        <p class="paragraph">This is a platform where the property industry becomes an integrated network. Here you can reason and operate beyond your own functions, limitations, or locations. This holistic approach achieves results far beyond the advertising and selling of property. The Matrix is alive because professionals in the industry choose to partner with us. We welcome you to the team, the network, the Matrix.
                        </p>
                    </div>
                    <div *appUserRole="['HeadAdministrator', 'ConsultantAdministrator']">
                        <div class="h5">Welcome to the Property Matrix Planners Portal</div>
                        <p class="paragraph">This is a platform where the property industry becomes an integrated network. Here you can reason and operate beyond your own functions, limitations, or locations. This holistic approach achieves results far beyond the advertising and selling of property. The Matrix is alive because professionals in the industry choose to partner with us. We welcome you to the team, the network, the Matrix.
                        </p>
                    </div>
                    <div *appUserRole="['NetworkValuer', 'SeniorValuer']">
                        <div class="h5">Welcome to the Property Practitioners Portal</div>
                        <p class="paragraph">GThis is a platform where the property industry becomes an integrated network. Here you can reason and operate beyond your own functions, limitations, or locations. This holistic approach achieves results far beyond the advertising and selling of property. The Matrix is alive because professionals in the industry choose to partner with us. We welcome you to the team, the network, the Matrix.
                        </p>
                    </div>
                    <div *appUserRole="['User']">
                        <div class="h5">Welcome to the User Portal</div>
                        <p class="paragraph">This is a platform where the property industry becomes an integrated network. Here you can reason and operate beyond your own functions, limitations, or locations. This holistic approach achieves results far beyond the advertising and selling of property. The Matrix is alive because professionals in the industry choose to partner with us. We welcome you to the team, the network, the Matrix.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>