/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddFeasibilityStudyVm } from '../models/add-feasibility-study-vm';
import { AddPropertyInvestigationVm } from '../models/add-property-investigation-vm';
import { AddPropertyValuationVm } from '../models/add-property-valuation-vm';
import { AddProperyInvestigationLevel3Vm } from '../models/add-propery-investigation-level-3-vm';
import { AddTownPlanningVm } from '../models/add-town-planning-vm';

@Injectable({
  providedIn: 'root',
})
export class AdditionalServService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AdditionalServCapturePropertyInvestigationPost
   */
  static readonly ApiV1AdditionalServCapturePropertyInvestigationPostPath = '/api/v1/AdditionalServ/CapturePropertyInvestigation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServCapturePropertyInvestigationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyInvestigationPost$Response(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServService.ApiV1AdditionalServCapturePropertyInvestigationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServCapturePropertyInvestigationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyInvestigationPost(params?: {
    context?: HttpContext
    body?: AddPropertyInvestigationVm
  }
): Observable<void> {

    return this.apiV1AdditionalServCapturePropertyInvestigationPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServCapturePropertyInvestigationLevel3Post
   */
  static readonly ApiV1AdditionalServCapturePropertyInvestigationLevel3PostPath = '/api/v1/AdditionalServ/CapturePropertyInvestigationLevel3';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServCapturePropertyInvestigationLevel3Post()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyInvestigationLevel3Post$Response(params?: {
    context?: HttpContext
    body?: AddProperyInvestigationLevel3Vm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServService.ApiV1AdditionalServCapturePropertyInvestigationLevel3PostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServCapturePropertyInvestigationLevel3Post$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyInvestigationLevel3Post(params?: {
    context?: HttpContext
    body?: AddProperyInvestigationLevel3Vm
  }
): Observable<void> {

    return this.apiV1AdditionalServCapturePropertyInvestigationLevel3Post$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServCaptureFeasibilityStudyPost
   */
  static readonly ApiV1AdditionalServCaptureFeasibilityStudyPostPath = '/api/v1/AdditionalServ/CaptureFeasibilityStudy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServCaptureFeasibilityStudyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCaptureFeasibilityStudyPost$Response(params?: {
    context?: HttpContext
    body?: AddFeasibilityStudyVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServService.ApiV1AdditionalServCaptureFeasibilityStudyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServCaptureFeasibilityStudyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCaptureFeasibilityStudyPost(params?: {
    context?: HttpContext
    body?: AddFeasibilityStudyVm
  }
): Observable<void> {

    return this.apiV1AdditionalServCaptureFeasibilityStudyPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServCaptureTownPlanningPost
   */
  static readonly ApiV1AdditionalServCaptureTownPlanningPostPath = '/api/v1/AdditionalServ/CaptureTownPlanning';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServCaptureTownPlanningPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCaptureTownPlanningPost$Response(params?: {
    context?: HttpContext
    body?: AddTownPlanningVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServService.ApiV1AdditionalServCaptureTownPlanningPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServCaptureTownPlanningPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCaptureTownPlanningPost(params?: {
    context?: HttpContext
    body?: AddTownPlanningVm
  }
): Observable<void> {

    return this.apiV1AdditionalServCaptureTownPlanningPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdditionalServCapturePropertyValuationPost
   */
  static readonly ApiV1AdditionalServCapturePropertyValuationPostPath = '/api/v1/AdditionalServ/CapturePropertyValuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdditionalServCapturePropertyValuationPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyValuationPost$Response(params?: {
    context?: HttpContext
    body?: AddPropertyValuationVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdditionalServService.ApiV1AdditionalServCapturePropertyValuationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdditionalServCapturePropertyValuationPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdditionalServCapturePropertyValuationPost(params?: {
    context?: HttpContext
    body?: AddPropertyValuationVm
  }
): Observable<void> {

    return this.apiV1AdditionalServCapturePropertyValuationPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
