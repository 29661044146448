<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="pdf-viewer-container mt-2">
            <ng2-pdfjs-viewer #pdfViewer [download]="false"></ng2-pdfjs-viewer>
        </div>
    </mat-sidenav>
    <main>
        <div class="container-fluid">
            <div class="">
                <div class="heading-2 text-center">REFERRAL CONFIRMATION</div>
                <div class="row mt-4">
                    <div class="heading-2 ">Agents - Commission Claim Form (CCF)</div>
                    <hr>
                </div>
                <div class="row mt-4">
                    <div class="col-4 col-xl-3" *ngFor="let ccf of ccfs; let i = index">
                        <div class="row">
                            <div class="col-6">
                                <mat-icon class="file-icon">description</mat-icon>
                            </div>
                            <div (click)="openCcfFile(ccf.fileId, '.pdf')" class="col-6 paragraph vertical-center view-file">
                                View
                            </div>
                        </div>
                        <div class="row paragraph">
                            <div class="col-5">Name:</div>
                            <div class="col-7">CCF Report {{i + 1}}</div>
                        </div>
                        <hr>
                        <div class="row paragraph">
                            <div class="col-5">Created:</div>
                            <div class="col-7">{{ccf.created | date}}</div>
                        </div>
                        <hr>
                        <div class="row paragraph">
                            <div class="col-5">Property:</div>
                            <div class="col-7">{{ccf.propertyTitle}}</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" *appUserRole="['HeadOfJuniorPlanners', 'JuniorPlanner']">
                    <div class="col-4  col-xl-3">
                        <div class="block-wrapper text-center heading-5">
                            <div *ngIf="!uploadDocument" class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, 0)">
                                <input type="file" #fileDropRef id="fileDropRef" (change)="selectUpload($event.target.files, 0)"/>
                                <div class="row">
                                    <div class="col-12">CCF Report</div>
                                    <div class="col-12 paragraph">(Click or drop a file)</div>
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="uploadDocument" class="grey-block">
                                <div class="row paragraph">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">insert_drive_file</mat-icon>
                                    </div>
                                    <h4 class="col-12 paragraph bold">
                                        {{uploadDocument.name}}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="uploadDocument" class="mt-2">
                            <button class="button small-button btn-width" (click)="uploadFile()">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</mat-sidenav-container>