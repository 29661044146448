import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListingService } from 'src/app/Services/propertypros/services';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-admin-property-listings-workflow-tasks',
  templateUrl: './property-listings-workflow-tasks.component.html',
  styleUrls: ['./property-listings-workflow-tasks.component.scss']
})
export class AdminPropertyListingsWorkflowTasksComponent implements OnInit {

  propertyId: number;
  listingAddress: string;
  customListing: boolean;
  number: number = 0;
  riskScapeData;

  constructor(private router: Router, private route: ActivatedRoute, private listingService: PropertyListingService) { }

  ngOnInit(): void {
    this.getPropertyParams();
    this.getPropertRiskScapeData();
  }

  returnNumber(){
    return ++this.number;
  }

  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;
      }
    );
  }

  getPropertRiskScapeData(){
    this.listingService.apiV1PropertyListingGetPropertyListingRiskScapeDataGet$Response({propertyId: this.propertyId }).subscribe({
      next: async (result: any) => {
        this.riskScapeData = JSON.parse(result.body);
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem getting property details");
      }
    })
  }

  gotoPropertyDetails() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/workflow/property-details'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        }
      )
    );
    window.open(url, '_self');
  }

  gotoAddendums() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/workflow/addendums'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        }
      )
    );
    window.open(url, '_self');
  }

  gotoCcf() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/workflow/ccf'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        }
      )
    );
    window.open(url, '_self');
  }

  gotoReportForm() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/report'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        }
      )
    );
    window.open(url, '_self');
  }

  gotoAllocate(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/workflow/allocate'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        }
      )
    );
    window.open(url, '_self');
  }
}