import { Component, OnInit } from '@angular/core';
import { GlobalConstants, UserRoles } from 'src/app/common/global-constants';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  userRole: string;
  userRoles = UserRoles;

  constructor(private sharedService: PropertiProSharedService) { }

  ngOnInit(): void {
    this.getUserRole()
  }

  getUserRole(){
    let user = this.sharedService.getUserModel();
    this.userRole = user.userRole;
  }
}
