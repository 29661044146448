import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListingService, FileService } from 'src/app/Services/propertypros/services';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { GlobalMethods } from 'src/app/common/global-methods';
import { PropertyListingFilesEnum, UploadDocumentDetailsVm, UploadDocumentsFileReturnVm } from 'src/app/Services/propertypros/models';
import { FileUploader } from 'src/app/models/file-uploader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'src/app/common/global-constants';
import { environment } from 'src/environments/environment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { GRI_DATE_FORMATS, UploadDocumentsReportDialog } from 'src/app/pages/selling-my-property/upload-documents/upload-documents.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportDialogData } from 'src/app/models/sellingMyProperty/ReportDialogData';
import { ReportFile } from 'src/app/models/sellingMyProperty/ReportFile';
import { ComponentType } from '@angular/cdk/portal';

import { EventEmitter, Input, Output } from '@angular/core';
import { MatDateFormats, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatTable } from '@angular/material/table';
import { map } from 'rxjs';
import { CheckboxVm, UploadDocumentsReportDetailsVm } from 'src/app/Services/propertypros/models';


@Component({
  selector: 'app-workflow-addendums',
  templateUrl: './workflow-addendums.component.html',
  styleUrls: ['./workflow-addendums.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class WorkflowAddendumsComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('pdfViewer') pdfViewer;
  fileUploader = new FileUploader();

  propertyId: number;
  listingAddress: string;
  customListing: boolean;
  username: string;

  addendums: any[] = []
  newAddendum: any
  uploadDocument: File

  report: UploadDocumentsReportDetailsVm = {
    dateReleased: null,
    publishedBy: '',
    typeOfReport: '',
  }
  user = this.sharedService.getUserModel();

  constructor(private route: ActivatedRoute,
    private sharedService: PropertiProSharedService,
    private propertyListingService: PropertyListingService,
    private fileService: FileService,
    private http: HttpClient
    , public dialog: MatDialog,
    private router: Router) { }


  public addendumName(adendum: any){
    return adendum[0].propertyTitle;
  }

  ngOnInit(): void {
    GlobalMethods.checkPageRolePermissions(this.user, 
      ['AgentPortalAdministrator', 'PlannerPortalAdministrator',
      'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner'],
      this.router);
    this.getPropertyDetailsParams()
    this.getFiles();
  }

  getPropertyDetailsParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;
      }
    );
  }

  getFiles(){
    this.propertyListingService.apiV1PropertyListingGetFilesGet$Response({ custom: this.customListing, propertyId: this.propertyId, type: PropertyListingFilesEnum.$1 })
      .subscribe({
        next: (result: any) => {
          var data = JSON.parse(result.body);
          this.addendums = Object.values(
            data.reduce((acc,curr)=>(
              (acc[curr.propertyTitle] = acc[curr.propertyTitle] || []).push(curr), acc
            ), {})
          );
        }, 
        error: (error) => {
          console.log(error);
        }
      }
    )
  }

  uploadFile(){
    const username: string = this.sharedService.getUsername();
    let formData = new FormData();
    this.fileUploader.files.forEach(fileObj => {
      formData.append(`${username}|7|0|${fileObj.id.toString()}|`, fileObj.file);
    });
    formData.append('username', username);
    
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)

    this.http.post(environment.APIUrl + FileService.ApiV1FileUploadFilesPostPath, formData, {headers: headers})
      .subscribe({
        next: (result) => {
          var body: UploadDocumentDetailsVm = {
            paths: result as Array<UploadDocumentsFileReturnVm>,
            propertyId: this.propertyId,
            troubleUploadingCopies: false,
            reports: [this.newAddendum]
          }
          this.propertyListingService.apiV1PropertyListingSaveFilesPost$Response({ body: body, custom: this.customListing, type: PropertyListingFilesEnum.$1 })
            .subscribe({
              next: (result) => {
                this.getFiles();
                this.addendums = null;
                this.fileUploader.files = null;
              }
              , error: (error) => {
                console.log(error);
              }
            }
          )
        },
        error: (error) => {
          console.log(error);
        }
      }
    );
  }

  closeNav() {
    this.sidenav.close();
  }

  openAddendum(fileId: any, fileExtention: string){
    this.fileService.apiV1FileDownloadFileGet$Response({ fileId: fileId })
      .subscribe({
        next: async (dataURI: any) => {
          var blob = await (await fetch(dataURI.body)).blob();
          this.pdfViewer.pdfSrc = new File([blob], "Report" + fileExtention);
          this.pdfViewer.refresh();
          this.sidenav.open();
        },
        error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      }
    )
  }

  openDialog<T>(dialogComponent: ComponentType<T>, width: string, height: string, data: object) {
    return this.dialog.open(dialogComponent, {
      width: width,
      height: height,
      data: data
    });
  }
  
  openAddReportDialog() {
    const dialogRef = this.openDialog(UploadDocumentsReportDialog, '75vw', '60vh', 
    { 
      report: this.report, index: 0, showDelete: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.newAddendum = result.report;
        this.fileUploader.specificFileBrowseHandler([result.uploadReports.file], 6);
        this.uploadFile();
        this.clearReport();
      }
    });
  }
  clearReport() {
    this.report = {
      dateReleased: null,
      publishedBy: '',
      typeOfReport: '',
    }
  }
}

@Component({
  selector: 'workflow-addendums-dialog.component',
  templateUrl: './workflow-addendums-dialog.component.html',
  styleUrls: ['./workflow-addendums.component.scss', '../../../../../../../css/2-modules/_forms.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ]
})
export class WorkflowAddendumReportDialog {
  constructor(
    public dialogRef: MatDialogRef<WorkflowAddendumReportDialog>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ReportDialogData
  ) { }

  selectUpload(files) {
    this.updateShownBlocks(files[0]);
  }

  dropUpload($event) {
    this.updateShownBlocks($event[0]);
  }

  updateShownBlocks(file) {
    var report: ReportFile = { key: 1, value: 'Report ' + 1, file: file };
    this.data.uploadReports = report;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitReport() {
    this.dialogRef.close({report: this.data.report, reportFiles: this.data.uploadReports});
  }

  deleteReport() {
    this.data.deleteReport = true;
    this.dialogRef.close(this.data);
  }
}