<main style="background-color: white">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                REGISTRATION
                            </h1>
                        </div>
                    </div>
                    <div class="text-center">
                        <p class="paragraph">Congratulations on the smart decision to join the <strong>Property Matrix</strong> network.
                        </p>
                        <div class="heading-5">It will take about 15-20 minutes to complete this form through 4 easy steps:
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3 text-center numbers-right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    1
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Provide futher
                                    personal and
                                    company
                                    information
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center numbers-right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    2
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Provide
                                    work related
                                    information
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center numbers-right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    3
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Upload and
                                    submit a few
                                    documents
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    4
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Await confirmation
                                    email with
                                    contract
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph m-5">
                        <p>Once your contract is correctly signed, you will receive an email with links to our training
                            courses.</p>
                        <strong>For STEP 3, please have the following ready to submit in PDF format:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li>Copy of your identification document</li>
                            <li>Up to date EAAB Fidelity Fund Certificate</li>
                        </ul>
                        <strong>Optional:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li>Company VAT certificate (where applicable) </li>
                            <li>Certified copy(ies), of all professional qualifications and/or designations awarded to
                                you
                            </li>
                            <li>A photo of yourself to be used by <strong>Property Matrix</strong> for office & admin
                                purposes only</li>
                        </ul>
                        <p><strong>Please note:</strong> You may provide a photo where the name of the franchise you are
                            affiliated with is visible – <strong>Property Matrix</strong>
                            is inclusive in this regard.</p>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="paragraph text-center">
                                <button class="button heading-3" id="proceed"
                                    [routerLink]="['/estateAgentRegisterForm']">
                                    PROCEED
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>