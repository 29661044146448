<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main style="background-color: white" id="main">
    <div class="content content-padding">
        <div class="container">
            <!--Form section block-->
            <div class="row mt-3 no-gutters">
                <div class="col-3 px-1" *ngFor="let header of formHeaders">
                    <button class="form-section form-section-button active-form-section d-flex align-items-center justify-content-center"
                        [ngClass]="{ 'active-form-section': pageNumber >= header.key }"
                        [disabled]="pageNumber <= header.key || pageNumber == 4" (click)="selectPage(header.key)">
                        <div class="heading-3-light">{{header.value}}</div>
                    </button>
                </div>
            </div>
            <section>
                <div [hidden]="pageNumber != 1">
                    <app-estate-agent-personal-information (pageNumberEvent)="changePage($event)"
                        (EstateAgentPersonalInformationEvent)="assignEstateAgentPersonalInformation($event)">
                    </app-estate-agent-personal-information>
                </div>
                <div [hidden]="pageNumber != 2">
                    <app-estate-agent-company-information (pageNumberEvent)="changePage($event)"
                        (EstateAgentCompanyInformationEvent)="assignEstateAgentCompanyInformation($event)">
                    </app-estate-agent-company-information>
                </div>
                <div [hidden]="pageNumber != 3">
                    <app-estate-agent-submit-documents (pageNumberEvent)="changePage($event)"
                        (uploadFilesEvent)="assignFiles($event)">
                    </app-estate-agent-submit-documents>
                </div>
                <div [hidden]="pageNumber != 4">
                    <app-estate-agent-sign-contract></app-estate-agent-sign-contract>
                </div>
            </section>
        </div>
    </div>
</main>