<main>
    <div class="main-container row">
        <div class="col-1">
            <div class="side-menu-card card-shadow">
                <li class="side-menu-item" *ngFor="let heading of headingTabItems">
                    <button class="side-menu-button"
                            [ngClass]="{'selected-menu-item': heading.key == headingTabIndex}"
                            (click)="selectHeadingTab(heading.key)">
                        <mat-icon class="link">
                            {{heading.icon}}
                        </mat-icon>
                    </button>
                </li>
            </div>
        </div>
        <div class="col-11">
            <div class="title-container">
                <div class="title-icon link">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div class="title">
                    {{headingTab()}}
                </div>
            </div>
            <!-- <button class="button generate-button" (click)="generateListings()">Generate Listings</button> -->
            <div class="description-container">
                <div class="description">
                    The task list is all the listed properties currently assigned to you.
                </div>
                <div class="search-container">
                    <mat-icon class="search-icon">search</mat-icon>
                    <input type="text" placeholder="Search" class="search-box" />
                </div>
            </div>
            <div class="tab-headings-container">
                <div class="tab-heading link"
                    *ngFor="let heading of tabItems"
                    (click)="selectTab(heading.key)"
                    [ngClass]="{'selected-tab': heading.key == tabIndex}">
                    <div class="report-heading" *appUserRole="heading.role">
                        <mat-icon>home</mat-icon>
                        <div class="report-text">
                            {{heading.value}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="property-cards-container">
                <div class="row" *ngIf="tabIndex == 1">
                    <mat-card class="property-card" *ngFor="let listing of filteredPropertyListings">
                        <div class="property-card-heading">
                            {{listing.createdDate}}
                        </div>
                        <div class="property-image-container">
                            <img *ngIf="listing.image" [src]="listing.image">
                            <div class="image-text-overlay">
                                <div class="report-reference-number">M6/LIS/CT/2308/001</div>
                                <div class="report-status">On Time</div>
                            </div>
                        </div>
                        <div class="property-address-container row">
                            <div class="property-address-title col-11">
                                {{listing.addressTitle}}
                            </div>
                            <div class="property-address-menu link col-1">
                                <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="downloadPropertyReport(listing.propertyId, false)">
                                        <mat-icon>file_download</mat-icon>
                                        <span>Download</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="property-address">
                            {{listing.completeAddress}}
                        </div>
                        <mat-divider></mat-divider>
                        <div class="card-footer-custom row">
                            <div class="card-circle col-2">DR</div>
                            <div class="card-user-title col-8">
                                Current Professional
                            </div>
                            <div class="card-circle link col-2" (click)="goToPropertyListingTasksTab(listing)">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="property-cards-container">
                <div class="row" *ngIf="tabIndex == 2">
                    <div class="row mt-2" *ngIf="headingTabIndex == 1">
                        <div class="col-12">
                            <button (click)="openDialog()" class="button">Add</button>
                        </div>
                    </div>
                    <div class="row">
                        <mat-card class="property-card" *ngFor="let listing of filteredCustomListings; let i = index">
                            <div class="property-card-heading">
                                {{listing.listingName}}
                            </div>
                            <div class="property-address-container row">
                                <div class="property-address-title col-11">
                                    {{listing.listingName}}
                                </div>
                                <div class="property-address-menu link col-1">
                                    <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="downloadPropertyReport(listing.propertyId, true)">
                                            <mat-icon>file_download</mat-icon>
                                            <span>Download</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteCustomListing(listing.id, i)">
                                            <mat-icon color="warn">delete</mat-icon>
                                            <span>Delete</span>
                                        </button>
                                        <button mat-menu-item (click)="openDialog(listing)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="card-footer-custom row">
                                <div class="card-circle col-2">DR</div>
                                <div class="card-user-title col-8">
                                    Current Professional
                                </div>
                                <div class="card-circle link col-2" (click)="goToPropertyListingTasksTab(listing)">
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>