<main>
    <div class="container-fluid">
        <div class="submission-form" *ngFor="let detail of propertyDetails; let i = index">
                <div class="heading-2 text-center mt-4">PROPERTY DETAILS ({{i + 1}})</div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Building/
                            unit
                            no:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="buildingUnitNumber" class="paragraph not-required"
                            [(ngModel)]="detail.unitNo">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Estate/complex
                            name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="estateComplexName" class="paragraph not-required"
                            [(ngModel)]="detail.complexName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Street
                            no:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" name="streetNumber" class="paragraph"
                            [(ngModel)]="detail.streetNo" required #streetNumber="ngModel"
                            [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Street
                            Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" name="streetName" class="paragraph"
                            [(ngModel)]="detail.streetName" required #streetName="ngModel"
                            [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Suburb:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="suburb" class="paragraph" [(ngModel)]="detail.suburb" required
                            #suburb="ngModel" [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">City:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" name="city" class="paragraph"
                            [(ngModel)]="detail.city" #city="ngModel" required
                            [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Postal code:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" name="postalCode" class="paragraph"
                            [(ngModel)]="detail.postalCode" #postalCode="ngModel" required
                            [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>Property Description:
                </div>
                <div class="row">
                    <div class="col-10">
                        <textarea name="propertyDescription" class="paragraph" rows="3"
                            [(ngModel)]="detail.propertyDescription" #propertyDescription="ngModel"></textarea>
                    </div>
                </div>
        </div>
        <div class="row mt-4">
            <div class="col-3">
                <button class="button small-button" [disabled]="loadingAddress" (click)="getAddress()">Get Address Details</button>
            </div>
        </div>
        <div class="row" *ngIf="loadingCadaster">
            <div class="row mt-4" *ngFor="let detail of addressDetails; let i = index">
                <div class="col-6 mt-4">
                    <div class="heading-4">Property Boundary</div>
                    <img width="100%" height="100%" [src]="cadasterPicture[i]">
                </div>
                <div class="col-6 mt-4">
                    <div class="heading-4">Property Details</div>
                    <div class="paragraph mt-2">
                        <div class="row  property-details-info">
                            <div class="col-6"><b>Property Key</b></div>
                            <div class="col-6">{{ detail.key }}</div>
                        </div>
                        <div class="row property-details-info">
                            <div class="col-6"><b>Erf / Portion Number</b></div>
                            <div class="col-6">{{ detail.data.erfNumber }}</div>
                        </div>
                        <div class="row property-details-info">
                            <div class="col-6"><b>Short Address</b></div>
                            <div class="col-6">{{ detail.data.short_Address }}</div>
                        </div>
                        <div class="row property-details-info">
                            <div class="col-6"><b>SubPlace</b></div>
                            <div class="col-6">{{ detail.data.subplace }}</div>
                        </div>
                        <div class="row property-details-info">
                            <div class="col-6"><b>Municiplaity</b></div>
                            <div class="col-6">{{ detail.data.municipality }}</div>
                        </div>
                        <div class="row property-details-info">
                            <div class="col-6"><b>Province</b></div>
                            <div class="col-6">{{ detail.data.province }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="loadingCadaster">
            <div class="mt-5 d-flex justify-content-center" *appUserRole="['HeadOfJuniorPlanners', 'JuniorPlanner']">
                <button class="button small-button mx-4" (click)="confirm()">Confirm</button>
            </div>
        </div>
    </div>
</main>