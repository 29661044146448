import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { SellingMyPropertyComponent } from './pages/selling-my-property/selling-my-property.component';
import { PropertyInvestigationsComponent } from './pages/property-investigations/property-investigations.component';
import { PropertyValuationRequestComponent } from './pages/property-valuations/property-valuation-request/property-valuation-request/property-valuation-request.component';
import { FeasibilityStudiesComponent } from './pages/feasibility-studies/feasibility-studies.component';
import { FeasibilityStudiesRequestComponent } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { MarketGapAnalysisComponent } from './pages/market-gap-analysis/market-gap-analysis.component';
import { TownPlanningApplicationsComponent } from './pages/town-planning-applications/town-planning-applications.component';
import { TownPlanningApplicationRequestComponent } from './pages/town-planning-applications/town-planning-applications-request/town-planning-application-request/town-planning-application-request.component';
import { Level1InvestigationsComponent } from './pages/property-investigations/level1-investigations/level1-investigations.component';
import { Level2InvestigationsComponent } from './pages/property-investigations/level2-investigations/level2-investigations.component';
import { Level3InvestigationsComponent } from './pages/property-investigations/level3-investigations/level3-investigations.component';
import { ShareInOurDatabaseComponent } from './pages/share-in-our-database/share-in-our-database.component';
import { PropertyValuationsComponent } from './pages/property-valuations/property-valuations.component';
import { PlannerComponent } from './pages/register/planner/planner.component';
import { EstateAgentComponent } from './pages/register/estate-agent/estate-agent.component';
import { ConveyancerOptionComponent } from './pages/register/conveyancer/option/conveyancer-option/conveyancer-option.component';
import { ConveyancerPersonalCompanyDetailsComponent } from './pages/register/conveyancer/option/option_B/conveyancer-personal-company-details/conveyancer-personal-company-details.component';
import { ConveyancerRegistrationComponent } from './pages/register/conveyancer/registration/conveyancer-registration/conveyancer-registration.component';
import { ConveyancerPersonalInfoComponent } from './pages/register/conveyancer/option/option_B/steps/conveyancer-personal-info/conveyancer-personal-info.component';
import { ValuerComponent } from './pages/register/valuer/valuer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helper/jwtInterceptor';
import { PropertiProSharedService } from './properti-pro-shared.service';
import { PlannerRegistrationComponent } from './pages/register/planner/registration/planner-registration/planner-registration.component';
import { CollaborationAgreementComponent } from './pages/collaboration-agreement/collaboration-agreement.component';
import { LoginValuersComponent } from './pages/login/login-valuers/login-valuers.component';
import { EstateAgentAgreementComponent } from './pages/register/estate-agent/agreement/estate-agent-agreement/estate-agent-agreement.component';
import { EstateAgentRegistrationComponent } from './pages/register/estate-agent/registration/estate-agent-registration/estate-agent-registration.component';
import { ContactUSComponent } from './pages/contact/contact-us/contact-us.component';
import { RegisterValuerComponent } from './pages/register/valuer/register-valuer/register-valuer.component';
import { OptionAComponent } from './pages/register/conveyancer/option/option-a/option-a.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PropertyFormComponent } from './pages/selling-my-property/property-form/property-form.component';
import { SubmissionCompletedComponent } from './pages/selling-my-property/submission-completed/submission-completed.component';
import { PlannerRegisterFormComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form.component';
import { EstateAgentRegisterFormComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-register-form.component';
import { ConveyancerRegisterFormComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-register-form.component';
import { ConveyancerRegisterDetailsComponent } from './pages/register/conveyancer/conveyancer-register-details/conveyancer-register-details.component';
import { ValuerStepsComponent } from './pages/register/valuer/valuer-steps/valuer-steps.component';
import { ValuerRegisterFormComponent } from './pages/register/valuer/valuer-register-form/valuer-register-form.component';
import { SiodFormComponent } from './pages/share-in-our-database/siod-form/siod-form.component';
import { MapComponent } from './map/map.component';
import { PropertyForSaleComponent } from './pages/property-for-sale/property-for-sale.component';
import { ContactEstateAgentComponent } from './pages/contact/contact-estate-agent/contact-estate-agent.component';
import { ContactTownPlannerComponent } from './pages/contact/contact-town-planner/contact-town-planner.component';
import { PropertyForSaleOfferToPurchaseComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase/property-for-sale-offer-to-purchase.component';
import { PropertyForSaleOfferToPurchaseWithConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-with-conditions/property-for-sale-offer-to-purchase-with-conditions.component';
import { PropertyForSaleOfferToPurchaseFormComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-offer-to-purchase-form.component';
import { SiodConfirmComponent } from './pages/share-in-our-database/siod-confirm/siod-confirm.component';
import { SiodAddComponent } from './pages/share-in-our-database/siod-add/siod-add.component';
import { PropertyForSaleOfferToPurchaseFormEndComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form-end/property-for-sale-offer-to-purchase-form-end.component';
import { AdminHomeComponent } from './pages/admin-portal/admin-home/admin-home.component';
import { AdminMyProfileComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile.component';
import { AdminPropertyListingsComponent } from './pages/admin-portal/admin-property-listings/admin-property-listings.component';
import { AdminPropertyListingsWorkflowComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow.component';
import { AdminPropertyListingsWorkflowTasksPropertyDetailsComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-property-details/workflow-property-details.component';
import { WorkflowAddendumsComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-addendums/workflow-addendums.component';
import { WorkflowCcfComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-ccf/workflow-ccf.component';
import { PropertyListingsReportFormComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/property-listings-report-form.component';
import { ReportPdfComponent } from './pages/tests/report-pdf/report-pdf.component';
import { RegisterChoicesComponent } from './pages/register/register-choices/register-choices.component';
import { AdminManageUsersComponent } from './pages/admin-portal/admin-manage-users/admin-manage-users.component';
import { WorkflowAllocateComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-allocate/workflow-allocate.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'loginValuers', component: LoginValuersComponent },
  { path: 'loginconveyancer', component: OptionAComponent },
  { path: 'registerValuer', component: RegisterValuerComponent },
  { path: 'valuerSteps', component: ValuerStepsComponent },
  { path: 'valuerRegisterForm', component: ValuerRegisterFormComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register/choice', component: RegisterChoicesComponent },
  { path: 'home', component: HomeComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'shareInOurDatabase', component: ShareInOurDatabaseComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'aboutUS', component: AboutUsComponent },
  { path: 'sellingMyProperty', component: SellingMyPropertyComponent },
  { path: 'propertyInvestigation', component: PropertyInvestigationsComponent },
  { path: 'propertyValuation', component: PropertyValuationsComponent },
  { path: 'propertyValuation/propertyValuationRequest', component: PropertyValuationRequestComponent },
  { path: 'feasibilityStudies', component: FeasibilityStudiesComponent },
  { path: 'feasibilityStudies/feasibilityStudiesRequest', component: FeasibilityStudiesRequestComponent },
  { path: 'marketGapAnalysis', component: MarketGapAnalysisComponent },
  { path: 'townPlanningApplication', component: TownPlanningApplicationsComponent },
  { path: 'townPlanningApplication/townPlanningApplicationRequest', component: TownPlanningApplicationRequestComponent },
  { path: 'propertyInvestigation/level-1-investigation', component: Level1InvestigationsComponent },
  { path: 'propertyInvestigation/level-2-investigation', component: Level2InvestigationsComponent },
  { path: 'propertyInvestigation/level-3-investigation', component: Level3InvestigationsComponent },
  { path: 'plannerRegister', component: PlannerComponent },
  { path: 'estateAgentRegistration', component: EstateAgentComponent },
  { path: 'conveyancer-option', component: ConveyancerOptionComponent },
  { path: 'conveyancer-registration', component: ConveyancerRegistrationComponent },
  { path: 'conveyancer-personal-info', component: ConveyancerPersonalInfoComponent },
  { path: 'conveyancer-personal-company-detail', component: ConveyancerPersonalCompanyDetailsComponent },
  { path: 'conveyancerRegisterDetails', component: ConveyancerRegisterDetailsComponent },
  { path: 'conveyancerRegisterForm', component: ConveyancerRegisterFormComponent },
  { path: 'valuerRegistration', component: ValuerComponent },
  { path: 'planner-registration', component: PlannerRegistrationComponent },
  { path: 'collaboration-agreement', component: CollaborationAgreementComponent },
  { path: 'contactUS', component: ContactUSComponent },
  { path: 'plannerRegisterForm', component: PlannerRegisterFormComponent },
  { path: 'estate-agent-agreement', component: EstateAgentAgreementComponent },
  { path: 'estate-agent-registration', component: EstateAgentRegistrationComponent },
  { path: 'estateAgentRegisterForm', component: EstateAgentRegisterFormComponent },
  { path: 'contactUS', component: ContactUSComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'sellingMyProperty/capture', component: PropertyFormComponent },
  { path: 'sellingMyProperty/capture/finished', component: SubmissionCompletedComponent },
  { path: 'mapTest', component: MapComponent },
  { path: 'shareInOurDatabase/capture', component: SiodFormComponent },
  { path: 'shareInOurDatabase/confirm', component: SiodConfirmComponent },
  { path: 'shareInOurDatabase/add', component: SiodAddComponent },
  { path: 'propertyForSale', component: PropertyForSaleComponent },
  { path: 'contactEstateAgent', component: ContactEstateAgentComponent },
  { path: 'contactTownPlanner', component: ContactTownPlannerComponent },
  { path: 'propertyForSale/offerToPurchase', component: PropertyForSaleOfferToPurchaseComponent },
  { path: 'propertyForSale/offerToPurchaseWithConditions', component: PropertyForSaleOfferToPurchaseWithConditionsComponent },
  { path: 'propertyForSale/offerToPurchase/capture', component: PropertyForSaleOfferToPurchaseFormComponent },
  { path: 'propertyForSale/offerToPurchase/capture/finish', component: PropertyForSaleOfferToPurchaseFormEndComponent },
  { path: 'admin/home', component: AdminHomeComponent },
  { path: 'admin/myProfile', component: AdminMyProfileComponent },
  { path: 'admin/property-listings', component: AdminPropertyListingsComponent },
  { path: 'admin/property-listings/workflow', component: AdminPropertyListingsWorkflowComponent },
  { path: 'admin/user-management', component: AdminManageUsersComponent},
  { path: 'admin/property-listings/workflow/property-details', component: AdminPropertyListingsWorkflowTasksPropertyDetailsComponent },
  { path: 'admin/property-listings/workflow/addendums', component: WorkflowAddendumsComponent },
  { path: 'admin/property-listings/workflow/ccf', component: WorkflowCcfComponent },
  { path: 'admin/property-listings/workflow/allocate', component: WorkflowAllocateComponent },
  { path: 'admin/property-listings/report', component: PropertyListingsReportFormComponent },
  { path: 'test/report-pdf', component: ReportPdfComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    PropertiProSharedService
  ]
})
export class AppRoutingModule { }
