<div class="container-fluid main-container-height background-img">
    <div class="container-fluid container-style">
        <div class="content-border">
            <div class="row">
                <div class="col"></div>
                <div class="col-6">
                    <div class="heading-1 text-center collaboration-heading">
                        COLLABORATION AGREEMENT
                    </div>
                </div>
                <div class="col"></div>
            </div>
            <div class="inner-content paragraph mt-3">
                <div class="row">
                    <div class="col-12">
                        <strong>We are ready to answer questions and to equip you with all the information, knowledge
                            and support you need. It is
                            essential, however, that you have a good idea of what you are signing up for before you jump
                            into our registration
                            process. Hence the following summary of the <strong>Property Matrix</strong> model in a
                            nutshell:</strong>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <ul class="montserrat-bullets">
                            <li>
                                Once registered, you will join our national network of town planners, estate agents,
                                property valuers, urban economists, and
                                conveyancers, all linked to serve you as well as our clients (buyers and sellers) with a
                                holistic approach.
                            </li>
                            <li>
                                Unfortunately, only principals and full estate agents can register. If you are an intern
                                and have any related/professional qualifications,
                                and feel that you should be an exception, feel free to register and we will evaluate
                                your qualification(s) and experience
                            </li>
                            <li>
                                <strong>Property Matrix</strong> currently only operates in the following metropolitan
                                areas: Tshwane (Pretoria), Johannesburg, Ekurhuleni, Cape
                                Town, and eThekwini (Durban). Only agents operating in these areas can register at this
                                stage
                            </li>
                            <li>
                                Our commission structure is set out in the table below. If you list a property with
                                Property
                                Matrix, you will receive 80% of the
                                commission as indicated:
                                <table class="mt-2 mb-2 table-border">
                                    <tr style="padding-left: 10px">
                                        <th><span class="table-left-padding">Selling price</span></th>
                                        <th>Commission</th>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R0 - R4 000 000</span></td>
                                        <td>7%</td>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R4 000 001 - R10m</span></td>
                                        <td>6.5%</td>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R10 000 001 - R20m</span></td>
                                        <td>6.5%</td>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R20 000 001 - R40m</span></td>
                                        <td>5.5%</td>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R40 000 001 - R70m</span></td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td><span class="table-left-padding">R70 000 001 plus</span></td>
                                        <td>4%</td>
                                    </tr>
                                </table>
                            </li>
                            <li>We know that all franchises have different ways in dealing with commission. We totally
                                respect that and trust that you will honour
                                that too. If your agency requires of you to split your part of the commission and they
                                prefer it to be paid into their account, please
                                indicate such when your register with us. We will, however, always keep you posted - we
                                have sufficient administrative systems in
                                place</li>
                            <li>We consider the 20% commission we keep as the remuneration for our professional inputs
                                and marketing. The commission payable
                                is therefore not negotiable – not between us, nor between yourself and a client. We
                                regard it as unprofessional to engage in any
                                disputes regarding commission and will not tolerate such.</li>
                            <li>When listing a property with us, we will forward a questionnaire to the property
                                owner. It will be required of you to verify the
                                information regarding built improvements and fixtures provided by the owner. Once
                                verified, the value assessment and drafting of
                                the town planning report will kick off.</li>
                            <li>The owner will also be required to sign an exclusive mandate as <strong>Property
                                    Matrix</strong> will invest significant time, experience, and
                                professional knowledge in the investigation of the property regarding the land use
                                rights, context, open market value and potential
                                of the property - without charging a cent. Further information and details will be
                                provided to you as agent during training.</li>
                            <li>The property will then be advertised on our website with your name, surname, and a photo
                                of yourself linked to it. Queries can be
                                lodged electronically, and all agent-related queries received will be forwarded to you
                                directly. You will also receive notifications of
                                such via SMS and via email.</li>
                            <li>To register will take about 15-20 minutes. We believe, however, that this once-off
                                exercise will give you access to a whole new world
                                within the property industry.</li>
                            <li>Once registered, a link to the <strong>Property Matrix</strong> contract will be
                                provided for your signature, followed by a link to our training courses
                                - free of charge, where more details will be explained.</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col  paragraph bold registration-process">
                        If you would like to continue to the registration process, click <a class="privacy-policy-link"
                            routerLink="/estate-agent-registration">here</a>.
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>