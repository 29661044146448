<div class="row mt-3" *ngFor="let report of propertySections; let i = index">
    <div class="cols-12 heading-2 mt-3 section-title" *ngIf="customListing">
        <div class="row">
            <div class="col-11">Property {{counter[i]}} - {{report.value}}</div>
            <div class="col-1">
                <button (click)="collapseOrExpandSection(report)" mat-icon-button>
                    <mat-icon *ngIf="!report.checked">expand_more</mat-icon>
                    <mat-icon *ngIf="report.checked">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="!report.checked">
        <div class="cols-12" *ngFor="let section of sections; let j = index">
            <div class="row mt-4" [ngClass]="{'section-title': !customListing}">
                <div class="col-11 heading-4">{{j + 1}}. {{section.value}}</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12"><span class="heading-5">SERVITUDES</span></div>
                </div>
                <div *ngFor="let servitude of reportDetails.addendumTwo.propertyListingServitudes[i]; let k = index"
                    class="row paragraph mt-2 cloud-bottom-border">
                    <div class="col-5"><b>Servitude {{k + 1}}</b></div>
                    <div class="col-5"><input [id]="'servitude-'+i+'-'+k" [(ngModel)]="servitude.servitude" type="text"
                            class="paragraph w-100 my-1">
                    </div>
                    <div class="col-1">
                        <button *ngIf="reportDetails.addendumTwo.propertyListingServitudes[i].length > 1" (click)="deleteServitude(k, i)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                    </div>
                    <div class="col-1" *ngIf="k >= reportDetails.addendumTwo.propertyListingServitudes[i].length - 1">
                        <button (click)="addServitude(k, i)" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                    </div>
                </div>
                <div class="row paragraph mt-2 cloud-bottom-border">
                    <div class="col-5"><b>Extent: Servitudes</b></div>
                    <div class="col-7"><input [(ngModel)]="reportDetails.addendumTwo.servitudesExtent[i]" type="text"
                            class="paragraph w-100 my-1"></div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row">
                    <div *ngFor="let block of uploadBlocks[i]; let k = index" class="col-3 mt-2">
                        <div class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, block.key, i)"
                            *ngIf="block.file == null" [ngClass]="{'grey-block-required': block.required}">
                            <input type="file" #fileDropRef id="fileDropRef" multiple
                                (change)="selectUpload($event.target.files, block.key, i)" />
                            <div class="row file-drop-text text-center">
                                <div class="col-12">
                                    <mat-icon class="file-icon">cloud_upload</mat-icon>
                                </div>
                                <div class="col-12 heading-3">DRAG & DROP</div>
                                <div class="col-12 heading-3">FILE HERE</div>
                                <div class="col-12 paragraph">or</div>
                                <div class="col-12"><button class="button smal-button">CHOOSE FILE</button></div>
                            </div>
                        </div>
                        <div *ngIf="block.file != null" class="grey-block">
                            <div class="row file-drop-text text-center paragraph">
                                <div class="col-12">
                                    <mat-icon class="file-icon"> insert_drive_file</mat-icon>
                                </div>
                                <h4 class="col-12 paragraph bold">
                                    {{block.file.name}}
                                </h4>
                                <p class="col-12 heading-5">
                                    <span *ngIf="!block.file.existingFile">{{ fileUploader.formatBytes(block.file.size) }}</span>
                                    <span *ngIf="block.file.existingFile">Uploaded</span>
                                </p>
                                <div class="col-12">
                                    <app-progress [progress]="block.file?.progress"></app-progress>
                                </div>
                                <div *ngIf="!block.file.existingFile" class="delete col-12" (click)="deleteFile(block, k, i)">
                                    <mat-icon class="delete delete-icon">delete</mat-icon>
                                </div>
                                <div *ngIf="block.file.existingFile" class="delete col-12" (click)="deleteFileFromDb(k, i)">
                                    <mat-icon class="delete delete-icon">delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="cols-12 mt-4">
        <div class="cols-12 heading-2 mt-3 section-title">
            <div class="row">
                <div class="col-11">{{key}}. REFERENCES</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandReferences()" mat-icon-button>
                        <mat-icon *ngIf="!referencesChecked">expand_more</mat-icon>
                        <mat-icon *ngIf="referencesChecked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!referencesChecked">
            <div class="paragraph mt-2" *ngFor="let reference of reportDetails.addendumTwo.references; let j = index">
                <div class="row">
                    <div class="col-5 vertical-center"> Name of the document, report, plan, diagram or map </div>
                    <div class="col-7"><input type="text" [id]="'reference-' + j" [(ngModel)]="reference.name" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Company</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.company" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Professional</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.professional" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Mobile Number/Email:</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.contact" class="paragraph w-100 my-1"></div>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Website</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.website" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-10"></div>
                    <div class="col-1" *ngIf="j >= reportDetails.addendumTwo.references.length - 1 && j < 8">
                        <button (click)="addRow(j, reportDetails.addendumTwo.references, { name: '', company: '', professional: '', contact: '', website: '' })" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                    </div>
                    <div class="col-1" *ngIf="reportDetails.addendumTwo.references.length > 1">
                        <button (click)="deleteRow(j, reportDetails.addendumTwo.references)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-5">
    <button (click)="goToAddendumThree()" class="button">Next</button>
</div>