<div [attr.class]="mainContainer">
    <div class="row">
        <div class="col">
            <div class="accordion content-position">
                <div class="row">
                    <div class="col">
                        <div class="steps">
                            <progress id="progress" value=0 max=100 style="display: none;"></progress>
                            <div class="step-item">
                                <button id="step1" class="step-button text-center" type="button" (click)='step1()'
                                    data-toggle="collapse" data-target="#collapseOne" [attr.value]="progress"
                                    [attr.aria-expanded]="stepOneExpand" aria-controls="collapseOne">
                                    PERSONAL INFORMATION
                                </button>
                            </div>
                            <div class="step-item">
                                <button id="step2" class="step-button text-center collapsed" type="button"
                                    (click)='step2()' data-target="#collapseTwo" [attr.value]="progress"
                                    [attr.aria-expanded]="stepTwoExpand" aria-controls="collapseTwo">
                                    FIRM INFORMATION
                                </button>
                            </div>
                            <div class="step-item">
                                <button id="step3" class="step-button text-center collapsed" type="button"
                                    (click)='step3()' data-target="#collapseThree" [attr.value]="progress"
                                    [attr.aria-expanded]="stepThreeExpand" aria-controls="collapseThree">
                                    SUBMIT DOCUMENTS
                                </button>
                            </div>
                            <div class="step-item">
                                <button id="step4" class="step-button text-center collapsed" type="button"
                                    (click)='step4()' data-target="#collapseFour" [attr.value]="progress"
                                    [attr.aria-expanded]="stepFourExpand" aria-controls="collapseFour">
                                    SIGN CONTRACT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row heading-style">
                    <div class="col-12">
                        <div id="collapseOne" [attr.class]="collapseStep1" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <form class="form-styling" #personalInfo=ngForm>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col heading-2 bold">
                                            STEP 1: PERSONAL AND COMPANY INFORMATION
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group padding-top">
                                    <div class="row">
                                        <div class="col  heading-2 bold ">
                                            Contact details
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Surname:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Landline:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Mobile:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Aletrnative Mobile:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Email:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Confirm Email:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Identity/Passport
                                                number:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Legal Practice Council
                                                (LPC) number:</label>
                                        </div>
                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group padding-top">
                                    <div class="row">
                                        <div class="col  heading-2 bold ">
                                            What is your physical/street address (domicilium citandi et executandi)?
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Building/unit no:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Estate/complex name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Street no:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Street name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Suburb:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> City:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group pt-5">
                                    <div class="row">
                                        <div class="col">
                                            <label for="chkMunicipalCouncilOther" class="form-check-label label bold">
                                                How many years have you been working as a conveyancer?
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-1 bold">
                                            Years:
                                        </div>
                                        <div class="col-6">
                                            <input id="txtmunicipalCouncilResaon" name="municipalCouncilResaon"
                                                class="custom-input" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.municipalCouncilResaon"
                                                #municipalCouncilResaon="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group pt-5">
                                    <div class="row">
                                        <div class="col">
                                            <label class="form-check-label label bold">
                                                If you/your company serves on the panel of any financial institution,
                                                please indicate below
                                                which institutions are applicable:
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">None</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">ABSA Bank</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">Capitec Bank</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">First Rand Bank
                                            (FNB)</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">Investec</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">Mercantile
                                            Bank</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">Nedbank</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="option1" id="option1">
                                        <label class="form-check-label label" for="privateCapacity">Standard
                                            Bank</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="other" id="other">
                                        <label class="form-check-label label" for="other">Other, please specify</label>
                                        <div class="col-6">
                                            <input class="custom-input" type="text" id="otherTextBox">

                                        </div>
                                    </div>

                                </div>
                                <div class="form-group padding-top">
                                    <div class="row">
                                        <div class="col text-center">
                                            <button mat-raised-button class="button-styling button-style"
                                                (click)='step2()'>SUBMIT</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div id="collapseTwo" [attr.class]="collapseStep2" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <form class="form-styling" #firmInformation=ngForm>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col heading-2 bold">
                                            STEP 2: FIRM INFORMATION
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Firm Name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Firm's Registration 
                                                Number:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Telephone Number:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Alternative Telephone 
                                                Number:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Firm Email:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Confirm Email:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Alternative Email</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col heading-2 bold">
                                            Firm's physical address:
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Building/ unit no:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Estate/complex name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Street no:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Street name:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> Suburb:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-3">
                                            <label for="txtBuildingUnitNo"> City:</label>
                                        </div>

                                        <div class="col-4">
                                            <input id="txtBuildingUnitNo" name="buildingUnitNo"
                                                class="form-control not-required" type="text"
                                                [(ngModel)]="addressOfCompanyOfficeModel.buildingUnitNo"
                                                #buildingUnitNo="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group text-center">

                                    <button mat-raised-button class="button-styling button-style"
                                        (click)='step3()'>SUBMIT</button>

                                </div>

                            </form>
                        </div>
                        <div id="collapseThree" [attr.class]="collapseStep3" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <form class="form-styling" [formGroup]="submitDocument">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col heading-4 bold">
                                            STEP 3: SUBMIT DOCUMENTS:
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="container" appDragAndDrop
                                                (fileDropped)="this.fileUploader.onFileDropped($event)">
                                                <input type="file" #fileDropRef id="fileDropRef" multiple
                                                    (change)="this.fileUploader.fileBrowseHandler($event.target.files)" />
                                                <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                                <h3>Drag and drop file here</h3>
                                                <h3>or</h3>
                                                <label for="fileDropRef">Browse for file</label>
                                            </div>
                                            <div class="files-list">
                                                <div class="single-file"
                                                    *ngFor="let file of this.fileUploader.files; let i = index">
                                                    <div class="file-icon-size">
                                                        <mat-icon class="file-icon"> insert_drive_file</mat-icon>
                                                    </div>
                                                    <div class="info">
                                                        <p class="name paragraph bold">
                                                            {{ file?.name }}
                                                        </p>
                                                        <p class="size">
                                                            {{ this.fileUploader.formatBytes(file?.size) }}
                                                        </p>
                                                        <app-progress [progress]="file?.progress"></app-progress>
                                                    </div>
                                                    <div class="delete" (click)="this.fileUploader.deleteFile(i)">
                                                        <mat-icon class="delete delete-icon">delete</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="information-documents">
                                                <div class="row">
                                                    <div class="col text-left bold">
                                                        Documents to be uploaded
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col text-left">
                                                        <ul class="montserrat-bullets">
                                                            <li>
                                                                Copy of
                                                                your Identity
                                                                Document
                                                            </li>
                                                            <li>
                                                                Proof of 
                                                                admission as 
                                                                conveyancer

                                                            </li>
                                                            <li>
                                                       Fidelity fund 
                                                       certificate of 
                                                       firm 
                                                            </li>
                                                            <li>
                                                                A photo 
                                                                of 
                                                                yourself
                                                            </li>
                                                            <li>
                                                                A logo 
                                                                of your 
                                                                company
                                                            </li>
                                                           
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group button-placement">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-raised-button class="button-styling button-style"
                                                (click)='step4()'>SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="collapseFour" [attr.class]="collapseStep4" aria-labelledby="headingFour"
                            data-parent="#accordionExample">
                            <div class="row">
                                <form class="form-styling">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col heading-2 bold">
                                                STEP 4: SIGN CONTRACT
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col">
                                                <p class="paragraph text-center">
                                                    Thank you for your time.
                                                </p>
                                                <p class="paragraph text-center">
                                                    Please keep an eye on your inbox regarding the way forward. A link
                                                    to the <b>Property Matrix</b> contract
                                                    will be provided for your signature on completion of a successful
                                                    application.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col text-center">
                                                <button mat-raised-button class="button-styling button-style"
                                                    (click)='step4()'>NOTED</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>