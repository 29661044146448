import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MarkerClusterGroup } from "leaflet.markercluster";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  private map;
  mapSearch: string = ''
  suburbs = [];
  polygons = [];
  activeSuburbId = '';
  markerCluster = new MarkerClusterGroup({
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: true,
    animate: true,
    maxClusterRadius: 80,
  });
  activePolygon: any = {};

  constructor(private http: HttpClient) { }

  initMap() {
    this.map = L.map('map').setView([-29, 25], 6);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 50,
      minZoom: 1,
      transparent: false,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    const suburbsLayer = L.tileLayer.wms('https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?', {
      maxZoom: 30,
      minZoom: 13,
      layers: 'Terisha:Suburbs',
      transparent: true,
      format: 'image/png',
      crs: L.CRS.EPSG4326,
      opacity: 0,
    });
    suburbsLayer.addTo(this.map);

    const regionsLayer = L.tileLayer.wms('https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?', {
      maxZoom: 12,
      minZoom: 9,
      layers: 'Terisha:Metro_Regions_test',
      transparent: true,
      format: 'image/png',
      crs: L.CRS.EPSG4326,
      opacity: 0.4,
    });
    regionsLayer.addTo(this.map);

    const genericIcon = L.icon({
      iconUrl: '../../assets/images/maps/icons/map-marker.png',
      iconSize: [55, 60],
    });

    const bigIcon = L.icon({
      iconUrl: '../../assets/images/maps/icons/map-marker.png',
      iconSize: [110, 120],
    });

    let markers = [
      { id: 1, coordinates: [-26, 28] },
      { id: 2, coordinates: [-26.001, 28.001] },
      { id: 3, coordinates: [-26.005, 28.005] },
    ]
    let loadedMarkers = []
    const loaderHtml: string = `<div class="loader"></div><style>.loader {
      border: 22px solid #f3f3f3;
      border-top: 22px solid #f2825e;
      border-radius: 50%;
      width: 5vh;
      height: 5vh;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }</style>`;

    const httpClient = this.http;
    this.map.on('click', function (e) {
      console.log(e)
      let showSuburbs = this._zoom >= 13 && this._zoom <= 20;
      let showRegions = this._zoom >= 9 && this._zoom <= 12;
      let showProvinces = this._zoom >= 1 && this._zoom <= 8;
      const bounds = this.getBounds();
      const bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
      //const bBox = `28.3246193697085,${bounds._southWest.lat},${-25.79916663029151},${bounds._northEast.lat}`;
      const point = this.latLngToContainerPoint(e.latlng, this.getZoom());
      const size = this.getSize()
      const height = size.y;
      const width = size.x;
      let layer = showProvinces ? "Terisha:Provinces" : showRegions ? "Terisha:Metro_Regions_test" : "Terisha:Suburbs";
      let search = 'burg'
    });

  }
  searchSuburbs() {
    if (this.mapSearch.length <= 2) {
      return;
    }
    let showSuburbs = this.map._zoom >= 13 && this.map._zoom <= 20;
    let showRegions = this.map._zoom >= 9 && this.map._zoom <= 12;
    let showProvinces = this.map._zoom >= 1 && this.map._zoom <= 8;
    let bounds = this.map.getBounds();
    let bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
    let size = this.map.getSize()
    let height = size.y;
    let width = size.x;
    let layer = showProvinces ? "Terisha:Provinces" : showRegions ? "Terisha:Metro_Regions_test" : "Terisha:Suburbs";

    searchSuburbLayer(this.http, this.mapSearch).subscribe({
      next: (result: any) => {
        this.suburbs = []
        if (result) {
          this.suburbs = Object.values(result.data);
        }
      },
      error: (error: any) => {
        console.log(error, 6)
      }
    })
  }

  selectedPlacesOfLoading($event: MatAutocompleteSelectedEvent) {
    const id = $event.option.id
    const suburb = this.suburbs.find(f => f.id == id);
    this.selectSuburb(suburb)
  }

  selectSuburb(suburb: any) {
    if (!suburb) {
      return;
    }
    if (this.activeSuburbId == suburb.id) {
      return;
    }
    this.activeSuburbId = suburb.id;
    this.map.closePopup();
    this.map.setZoom(15)
    this.map.panTo([suburb.CENTROIDY, suburb.CENTROIDX]);
    this.suburbs = [];
    let showSuburbs = this.map._zoom >= 13 && this.map._zoom <= 20;
    let showRegions = this.map._zoom >= 9 && this.map._zoom <= 12;
    let showProvinces = this.map._zoom >= 1 && this.map._zoom <= 8;
    let bounds = this.map.getBounds();
    let bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
    const point = this.map.latLngToContainerPoint([suburb.CENTROIDY, suburb.CENTROIDX], this.map.getZoom());
    let size = this.map.getSize()
    let height = size.y;
    let width = size.x;
    let layer = showProvinces ? "Terisha:Provinces" : showRegions ? "Terisha:Metro_Regions_test" : "Terisha:Suburbs";

    getLayerDataFromSearch(this.http, bBox, width, height, layer, layer, point.x, point.y).subscribe({
      next: (result: any) => {
        let latlngs = result.features[0].geometry.coordinates;
        let reversedLatlngs = latlngs.map(function reverse(item) {
          return Array.isArray(item) && Array.isArray(item[0])
            ? item.map(reverse)
            : item.reverse();
        });
        let objectId = result.features[0].id;
        let properties = result.features[0].properties;
        this.map.removeLayer(this.activePolygon)
        this.activePolygon = L.polygon(reversedLatlngs, { fillColor: 'transparent', color: '#f2825e', objectId: objectId, properties: properties });
        this.activePolygon.addTo(this.map);
        //let existingPolygon = this.polygons.find(f => f.options.objectId == objectId);
        // if (!existingPolygon) {
        //   polygon.addTo(this.map);
        //   this.polygons.push(polygon)
        // } else {
        //   this.map.removeLayer(existingPolygon)
        //   let idx = this.polygons.findIndex(f => f.options.objectId == existingPolygon.options.objectId);
        //   this.polygons.splice(idx, 1)
        // }

        const bigIcon = L.icon({
          iconUrl: '../../assets/images/maps/icons/map-marker.png',
          iconSize: [60, 75],
        });

        const genericIcon = L.icon({
          iconUrl: '../../assets/images/maps/icons/map-marker.png',
          iconSize: [40, 50],
        });
        let markers = [
          { id: 1, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX] },
          { id: 2, coordinates: [suburb.CENTROIDY + 0.001, suburb.CENTROIDX] },
          { id: 3, coordinates: [suburb.CENTROIDY - 0.001, suburb.CENTROIDX] },
          { id: 3, coordinates: [suburb.CENTROIDY - 0.0015, suburb.CENTROIDX] },
          { id: 3, coordinates: [suburb.CENTROIDY + 0.0015, suburb.CENTROIDX] },
          { id: 3, coordinates: [suburb.CENTROIDY + 0.0025, suburb.CENTROIDX] },
          { id: 3, coordinates: [suburb.CENTROIDY - 0.0025, suburb.CENTROIDX] },
          { id: 4, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.001] },
          { id: 5, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.001] },
          { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.002] },
          { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.002] },
          { id: 7, coordinates: [suburb.CENTROIDY + 0.002, suburb.CENTROIDX] },
          { id: 8, coordinates: [suburb.CENTROIDY - 0.002, suburb.CENTROIDX] },
          { id: 9, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.005] },
          { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.005] },
          { id: 7, coordinates: [suburb.CENTROIDY + 0.005, suburb.CENTROIDX] },
          { id: 8, coordinates: [suburb.CENTROIDY + 0.0045, suburb.CENTROIDX] },
        ]
        let loadedMarkers = []
        let markerInfoHtml = `<div id="examplePopup">
    <div style="background-color: white; width: 40vw; min-height: 38vh;">
      <div class="row">
        <div class="col-4">
          <div class="m-3" style="border: solid black 1px; width: 100%; height: 25vh;"></div>
        </div>
        <div class="col-8 mt-3 paragraph">
          <div class="row">
            <div class="col-4">
              <strong>Price:</strong>
            </div>
            <div class="col-8">
              <span>R4.5 m</span>
            </div>
            <div class="col-4">
              <strong>Parcel extent: </strong>
            </div>
            <div class="col-8">
              <span>4.3ha</span>
            </div>
            <div class="col-4">
              <strong>Current bulk (±):</strong>
            </div>
            <div class="col-8">
              <span>960m2</span>
            </div>
            <div class="col-4">
              <strong>Current zoning:</strong>
            </div>
            <div class="col-8">
              <span>Residential 2</span>
            </div>
            <div class="col-4">
              <strong>Current use:</strong>
            </div>
            <div class="col-8">
              <span>Multiple residential; Block of flats. Dae ma
                porporro dolo quam secat laborum orpostis.</span>
            </div>
            <div class="col-12">
              <strong>Current use:</strong>
            </div>
            <div class="col-12">
              <span>Rezone to Residential 3 and add 10 more units. Neque min
                nobisciam dolore evellauda net Ollupta volo.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-7">
          <strong class="paragraph coral-text">See planning report for more uses & options</strong>
        </div>
        <div class="col-4 mb-2">
          <button class="button paragraph" style="width:100%; margin-top: -20px;"><strong style="vertical-align: -webkit-baseline-middle;">MORE OPTIONS</strong></button>
        </div>
      </div>
    </div>
  </div>`;
        let loaderHtml: string = `<div class="loader"></div><style>.loader {
          border: 22px solid #f3f3f3;
          border-top: 22px solid #f2825e;
          border-radius: 50%;
          width: 5vh;
          height: 5vh;
          animation: spin 2s linear infinite;
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }</style>`;
        let map = this.map

        for (let index = 0; index < markers.length; index++) {
          let markerDetails = markers[index];
          let marker = L.marker(markerDetails.coordinates, { icon: genericIcon })
            .on('click', function (e) {
              console.log(e, 'Do Api call')
              let lat = e.latlng.lat + 0.001;
              //map.flyTo([lat, e.latlng.lng], 18);
              setTimeout(() => {
                this.bindPopup(loaderHtml).openPopup();
              }, 1);
              setTimeout(() => {
                this.bindPopup(markerInfoHtml, {
                  maxWidth: 100000
                }).openPopup()
              }, 1000);
            });
          marker.on('mouseover', function (e) {
            e.target.setIcon(bigIcon)
          });
          marker.on('mouseout', function (e) {
            e.target.setIcon(genericIcon)
          });

          this.markerCluster.addLayer(marker)
          loadedMarkers.push(marker);
        }
        if (this.map._zoom >= 15) {
          this.map.addLayer(this.markerCluster)
        }
        this.renderMarkerClusters(this.markerCluster)
        //this.map.addLayer(markerCluster)
        //renderMarkers(loadedMarkers, this.map)
      }
      , error: (error: any) => {
        console.log(error, 5)
      }
    });
  }

  renderMarkerClusters(markerCluster: any) {
    this.map.on('zoom', function (e) {
      if (this._zoom >= 10) {
        this.addLayer(markerCluster)
      } else {
        this.removeLayer(markerCluster)
      }
    });
  }

  ngOnInit(): void {
    this.initMap();
  }

  ngAfterViewInit(): void {

  }
}

function renderMarkers(loadedMarkers, map) {
  if (map._zoom >= 11) {
    loadedMarkers.forEach(marker => {
      marker.addTo(map)
    });
  } else {
    loadedMarkers.forEach(marker => {
      map.removeLayer(marker)
    });
  }
}

function getLayerData(http: HttpClient, bbox: string, width: string, height: string, queryLayers: string, layers: string, x: string, y: string): Observable<any> {
  let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${queryLayers}&STYLES&LAYERS=${layers}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
    .pipe(map(m => layerData = m))
  return layerData;
}

function searchSuburbLayer(http: HttpClient, search: string): Observable<any> {
  if (search) {
    let searchString = capitalizeWords(search)
    let bbox = '4.570312500000001,-38.06539235133248,51.4599609375,-12.554563528593656'
    let width = '650'
    let height = '1067'
    let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=UTFGrid&TRANSPARENT=true&STYLES&LAYERS=Terisha%3ASuburbs&exceptions=application%2Fvnd.ogc.se_inimage&CQL_FILTER=SUBURB%20LIKE%20%27%25${searchString}%25%27&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
      .pipe(map(m => layerData = m))
    return layerData;
  }
  return null
}

function getLayerDataFromSearch(http: HttpClient, bbox: string, width: string, height: string, queryLayers: string, layers: string, x: string, y: string): Observable<any> {
  let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${queryLayers}&STYLES&LAYERS=${layers}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
    .pipe(map(m => layerData = m))
  return layerData;
}

function capitalizeWords(value) {
  if (value.includes(" ")) {
    let charArr = value.split(" ");
    for (let i = 0; i < charArr.length; i++) {
      if (charArr[i][0]) {
        charArr[i] = charArr[i][0].toUpperCase() + charArr[i].substr(1);
      }
    }
    return charArr.join(" ");
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}