<div class="row mt-3" *ngFor="let report of propertySections; let i = index">
    <div class="cols-12 heading-2 mt-3 section-title" *ngIf="customListing">
        <div class="row">
            <div class="col-11">Property {{counter[i]}} - {{report.value}}</div>
            <div class="col-1">
                <button (click)="collapseOrExpandSection(report)" mat-icon-button>
                    <mat-icon *ngIf="!report.checked">expand_more</mat-icon>
                    <mat-icon *ngIf="report.checked">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="!report.checked">
        <div class="cols-12" *ngFor="let section of sections; let j = index">
            <div class="row mt-4" [ngClass]="{'section-title': !customListing}">
                <div class="col-11 heading-4">{{j + 1}}. {{section.value}}</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Night Time</td>
                                    <td>Day Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>Population</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].dayPopulation}}</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].nightPopulation}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Population Density</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].dayPopulationDensity}} per km2</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].nightPopulationDensity}} per km2</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Population Density Category</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].dayPopulationDensityCategory}}</td>
                                    <td>{{reportDetails.addendumOne.areaInformation[i].nightPopulationDensityCategory}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>Crow</td>
                                    <td>Route</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cloud-bottom-border" *ngFor="let service of reportDetails.addendumOne.emergencyServices[i]">
                                    <td>{{service.typeName}}</td>
                                    <td>{{service.area}}</td>
                                    <td>{{service.distanceCrow}} km</td>
                                    <td>{{service.distanceRoute}} km</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 3" [hidden]="!section.checked">
                <div class="row mt-2">
                    <div class="col-12 paragraph">
                        <div class="paragraph bold">
                            <div class="row">
                                <div class="col-12">
                                    <div class="heading-5">
                                        Current planning particulars (legal rights) in terms of the
                                    </div>
                                    <div class="row bold">
                                        <div class="col-5 vertical-center">Street front</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].streetFront" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                    <div class="row bold">
                                        <div class="col-5 vertical-center">Other building lines</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].otherBuildingLines" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                    <div class="row">
                                        <div class="col-5 vertical-center">Building lines restriction area</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].buildingLinesRestrictionArea" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="heading-5">
                                        Servitudes
                                    </div>
                                    <div class="row bold">
                                        <div class="col-5 vertical-center">Servitute Restriction area</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].servitudeRestrictionArea" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="heading-5">
                                        Other unusable space:
                                    </div>
                                    <div class="row bold">
                                        <div class="col-5 vertical-center">Environmental Sensitive Area</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].environmentalSensitiveArea" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                    <div class="row bold">
                                        <div class="col-5 vertical-center">Flood line</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.restrictedArea[i].floodLine" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 4" [hidden]="!section.checked">
                <div class="row mt-2">
                    <div class="col-12 paragraph">
                        <div class="paragraph bold">
                            <div class="row">
                                <div class="col-12">
                                    <div class="heading-5">
                                        Dolomite risk: Provide risk classification based on the proximity or presence of dolomite in the sub place. Presence of dolomite is not a direct indication of sink holes or other risks but merely indicate that these issues may be present.
                                    </div>
                                    <div class="row bold mt-4">
                                        <div class="col-5 vertical-center">Risk Category</div>
                                        <div class="col-7"><input [(ngModel)]="reportDetails.addendumOne.dolomiteRiskCategory[i]" type="text" class="paragraph w-100 my-1"></div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 5" [hidden]="!section.checked">
                <div class="row mt-2" *ngFor="let uploadBlock of uploadBlocks[i]; let uploadBlockIndex = index">
                    <div class="col-12 mt-2 heading-3">{{uploadBlock.value}} <button mat-icon-button color="warn" *ngIf="uploadBlocks[i][uploadBlockIndex].picture"
                        (click)="deleteFileFromDb(uploadBlockIndex, i)">
                        <mat-icon>delete</mat-icon>
                    </button></div>
                    <div class="col-12 mt-2">
                        <div class="upload-block" appDragAndDrop (fileDropped)="dropUpload($event, uploadBlock.key, i)"
                            *ngIf="uploadBlock.file == null">
                            <input type="file" #fileDropRef id="fileDropRef" multiple
                                (change)="selectUpload($event.target.files, uploadBlock.key, i)" />
                                <div class="row" *ngIf="uploadBlocks[i][uploadBlockIndex].picture">
                                    <img class="upload-block image" [src]='uploadBlocks[i][uploadBlockIndex].picture'>
                                </div>
                            <div class="row" *ngIf="!uploadBlocks[i][uploadBlockIndex].picture && uploadBlocks[i][uploadBlockIndex].file == null">
                                <div class="col-12">
                                    <mat-icon class="file-icon">cloud_upload</mat-icon>
                                </div>
                                <div class="col-12 heading-3">DRAG & DROP</div>
                                <div class="col-12 heading-3">FILE HERE</div>
                                <div class="col-12 paragraph">or</div>
                                <div class="col-12"><button class="button small-button">CHOOSE FILE</button></div>
                            </div>
                        </div>
                        <div *ngIf="uploadBlock.file != null" class="upload-block">
                            <div class="row paragraph">
                                <div class="col-12">
                                    <mat-icon class="file-icon">insert_drive_file</mat-icon>
                                </div>
                                <p class="col-12 paragraph bold">
                                    {{uploadBlock.file.name}}
                                </p>
                                <p class="col-12 heading-5">
                                    {{ fileUploader.formatBytes(uploadBlock.file.size) }}
                                </p>
                                <div class="col-12">
                                    <app-progress [progress]="uploadBlock.file?.progress"></app-progress>
                                </div>
                                <div class="delete col-12" (click)="deleteFile(uploadBlock, uploadBlockIndex, i)">
                                    <mat-icon class="delete delete-icon">delete</mat-icon>
                                </div>
                            </div>
                        </div>
                        <div class="paragraph text-end">
                            Figure {{uploadBlockIndex + 1}} {{uploadBlock.value}}
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="row">
                            <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.addendumOne.imageDescriptions[i][uploadBlockIndex].imageDescriptions"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="cols-12 mt-4">
        <div class="cols-12 heading-2 mt-3 section-title">
            <div class="row">
                <div class="col-11">{{key}}. REFERENCES</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandReferences()" mat-icon-button>
                        <mat-icon *ngIf="!referencesChecked">expand_more</mat-icon>
                        <mat-icon *ngIf="referencesChecked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!referencesChecked">
            <div class="paragraph mt-2" *ngFor="let reference of reportDetails.addendumOne.references; let i = index">
                <div class="row">
                    <div class="col-5 vertical-center"> Name of the document, report, plan, diagram or map </div>
                    <div class="col-7"><input type="text" [id]="'reference-' + i" [(ngModel)]="reference.name" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Company</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.company" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Professional</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.professional" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Mobile Number/Email:</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.contact" class="paragraph w-100 my-1"></div>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Website</div>
                    <div class="col-7"><input type="text" [(ngModel)]="reference.website" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-10"></div>
                    <div class="col-1" *ngIf="i >= reportDetails.addendumOne.references.length - 1 && i < 8">
                        <button (click)="addRow(i, reportDetails.addendumOne.references, { nameOfData: '', company: '', professional: '', contact: '', website: '' })" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                    </div>
                    <div class="col-1" *ngIf="reportDetails.addendumOne.references.length > 1">
                        <button (click)="deleteRow(i, reportDetails.addendumOne.references)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-5">
    <button (click)="goToAddendumTwo()" class="button">Next</button>
</div>