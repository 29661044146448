import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialog,
    public authenticateUserService: AuthenticateService,
  ) { }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  verifyUser() {
    this.authenticateUserService.apiV1AuthenticateVerifyAccountPut$Json({
      userId: this.dialogData.userProfile.id,
      activeState: this.dialogData.userProfile.accountVerified
    })
      .subscribe(res => {
        this.dialog.closeAll();
      });
  }

}
