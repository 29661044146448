// Angular Imports
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

// Application Internal Imports
import { PropertiProSharedService } from '../properti-pro-shared.service';
import { UserRoles } from '../common/global-constants';

@Directive({
  selector: '[appUserRole]'
})
export class UserRoleDirective {
  @Input() appUserRole: string[];
  userRole: string;
  userRoles = UserRoles;
  user = this.sharedService.getUserModel();

  constructor(private viewContainerRef: ViewContainerRef, private templatesRef: TemplateRef<any>, private sharedService: PropertiProSharedService) {
    let user = this.sharedService.getUserModel();
    this.userRole = user.userRole;
  }

  ngOnInit() {
    // Clear The View If No Roles Are Specified
    if (!this.user.userRole || this.user.userRole == null) {
      this.viewContainerRef.clear();
      return;
    }

    if (!this.appUserRole || this.appUserRole == null || this.appUserRole.length == 0) {
      this.viewContainerRef.createEmbeddedView(this.templatesRef);
    }
    else {
      this.appUserRole = this.appUserRole.map((role) => role.toLowerCase());
      if (this.appUserRole.includes(this.user.userRole.toLowerCase())) {
        this.viewContainerRef.createEmbeddedView(this.templatesRef);
      } else {
        this.viewContainerRef.clear();
      }
    }
  }
}
