<div class="inner-block paragraph">
    <h1 class="heading-1 text-center mt-1">
        COMMISSION STRUCTURE
    </h1>
    <div class="text-center commision-table">
        <table>
            <thead>
                <tr>
                    <th>SELLING PRICE OF YOUR PROPERTY</th>
                    <th>% COMMISSION PAYABLE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of tableData">
                    <td>{{item.priceRange}}</td>
                    <td>{{item.percentage}}</td>
                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button class="button heading-3 mt-4" (click)="onNoClick()">AGREE</button>
        </div>
    </div>
</div>