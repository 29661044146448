<form id="extendedNetworkForm" novalidate #extendedNetworkForm="ngForm" class="submission-form">
    <div class="heading-2 mt-4">STEP 2: EXTENDED NETWORK</div>
    <div class="paragraph mt-2">
        <p>We market ourselves to provide comprehensive assessments and due diligence. Property reports, signed off by
            yourself, will often be supported by
            specialist studies. Information from extended professionals will, in addition, be required when we receive
            technical queries on a specific specialist aspect.
            The professionals you nominate can generate additional work from this exposure. </p>
        <p>The Property Matrix office will contact them individually to notify them of your nomination and to request
            their CVs, logos, etc. We, however, respect your
            role as the coordinator and will always communicate via yourself or cc you in any correspondence to your
            specialist team in ALL instances/properties
            where you are involved.</p>
        <p>Having the contact details of your team on our system will greatly benefit our admin team and will secure
            clear communication via our workflow systems.
            However, we will respect it if you regard this information as confidential - all fields below are therefore
            optional.</p>
    </div>
    <div class="heading-5 mt-5">
        Please provide details of the following professionals you prefer to work with:
    </div>
    <div [hidden]="urbanPlannerExtendedNetwork.professionals.length == 0" class="submission-form">
        <table mat-table [dataSource]="urbanPlannerExtendedNetwork.professionals" class="mat-elevation-z1"
            style="width: 45vw;">
            <ng-container matColumnDef="professional">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let professional; let i = index"> {{professional.profession}} -
                    {{professional.personalDetails.name}}</td>
            </ng-container>
            <ng-container matColumnDef="action1">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let professional; let i = index">
                    <button mat-icon-button style="color: #fb8c00" (click)="openEditProfessionalDialog(professional)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="action2">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let professional; let i = index">
                    <button mat-icon-button color="warn" (click)="deleteProfessional(professional, i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <button class="button" (click)="openProfessionalsDialog()" id="addReportButton">
                Add
            </button>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-10">
            <button id="btnSaveFinal" class="button heading-4" [disabled]="extendedNetworkForm.invalid" (click)="submitForm()">SUBMIT</button>
        </div>
    </div>
</form>