import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-submission-completed',
  templateUrl: './submission-completed.component.html',
  styleUrls: ['./submission-completed.component.scss']
})
export class SubmissionCompletedComponent {

  constructor(private route: Router) { }

  gotoHome(){
    this.route.navigate(['/home'])
  }
}
