<table class="workflow-table">
    <thead>
        <tr>
            <th class="pl-2">NUMBER</th>
            <th>TASK</th>
            <th>PROFESSIONALS</th>
            <th>NOTES</th>
            <th>STATUS</th>
        </tr>
    </thead>
    <tbody class="paragraph">
        <tr>
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Reference and group properties ({{riskScapeData.key}})</b></td>
            <td>Melissa Taleni</td>
            <td>A note description</td>
            <td class="status-1">Done</td>
        </tr>
        <tr class="link" (click)="gotoAllocate()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Allocate SP, HJP, JP and NP</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-2">In Progress</td>
        </tr>
        <tr class="link" (click)="gotoPropertyDetails()"
            *appUserRole="['SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner']">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Confirm Property and Seller details</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()" *appUserRole="['AgentPortalAdministrator', 'PlannerPortalAdministrator',
                'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner']">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Planning Report (JP)</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Confirm Asking Price</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Confirm value assessment</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Price evaluation 1</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoCcf()" *appUserRole="['AgentPortalAdministrator', 'PlannerPortalAdministrator',
                'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner']">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Confirm CCF and referral form</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoAddendums()" *appUserRole="['AgentPortalAdministrator', 'PlannerPortalAdministrator',
                'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner']">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Sort additional addendums</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Planning Report (JP)</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
        <tr class="link" (click)="gotoReportForm()">
            <td class="pl-2"><b>{{returnNumber()}}.</b></td>
            <td><b>Check Planning Report</b></td>
            <td>Melissa Taleni</td>
            <td></td>
            <td class="status-3">Awaiting</td>
        </tr>
    </tbody>
</table>