<form id="ownershipDetailsForm" class="submission-form" #ownershipDetailsForm="ngForm" novalidate>
    <!-- Ownership Details Section -->
    <section>
        <div class="heading-2">STEP 1: OWNERSHIP DETAILS</div>
        <div class="paragraph">
            <b class="coral">IMPORTANT:</b>
            <li>To fill in this form you have to be logged in as the owner (or an owner) of the property.</li>
            <li>Separate properties (land parcels) will each require an individual, separate form.</li>
        </div>
        <div class="heading-5">
            <span class="burnt-red pr-1">|</span>
            <span>The property to be sold is registered in the name of:</span>
        </div>
        <mat-radio-group name="radioRegisteredInNameOf" [(ngModel)]="ownerDetails.propertyOwnershipType">
            <mat-radio-button
                class="paragraph"
                *ngFor="let selectionValue of propertyOwnershipType"
                [value]="selectionValue.key"
                (change)="resetLegalEntityFields($event)">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
        <div class="submission-form" *ngIf="ownerDetails.propertyOwnershipType > 0">
            <div class="submission-form" *ngIf="ownerDetails.propertyOwnershipType == 1">
                <div class="heading-5">
                    <span class="burnt-red pr-1">|</span>
                    <span>Are you, as owner, a resident of the Republic of South Africa?</span>
                </div>
                <div>
                    <mat-radio-group name="radioSouthAfricanResident" [(ngModel)]="ownerIsSouthAfricanResident">
                        <mat-radio-button
                            class="paragraph"
                            *ngFor="let selectionValue of basicYesNo"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="submission-form" *ngIf="ownerDetails.propertyOwnershipType == 2">
                <div class="row">
                    <div class="col-3">
                        <label id="lblLegalEntityName" for="txtLegalEntityName" class="paragraph">
                            Full name of the legal entity:
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtLegalEntityName" class="paragraph"
                            name="legalEntityName" [(ngModel)]="ownerDetails.legalEntityName" required
                            #legalEntityName="ngModel"
                            [ngClass]="{'invalid-input': legalEntityName.invalid, 'valid-input': legalEntityName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblLegalEntityRegistrationNumber" for="txtLegalEntityRegistrationNumber"
                            class="paragraph">Registration number
                            of the entity:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtLegalEntityRegistrationNumber"
                            class="paragraph" name="legalEntityRegistrationNumber"
                            [(ngModel)]="ownerDetails.legalEntityRegistrationNumber" required
                            #legalEntityRegistrationNumber="ngModel"
                            [ngClass]="{'invalid-input': legalEntityRegistrationNumber.invalid, 'valid-input': legalEntityRegistrationNumber.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblLegalEntityVATNumber" for="txtLegalEntityVATNumber" class="paragraph">If
                            applicable, VAT
                            number of the entity:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtLegalEntityVATNumber" name="legalEntityVATNumber"
                            class="paragraph not-required" [(ngModel)]="ownerDetails.legalEntityVATNumber">
                    </div>
                </div>
                <div class="heading-5">
                    Details of the <span class="burnt-red">legal representative</span> for contract purposes
                    (please
                    check spelling and digits carefully):
                </div>
            </div>
            <div *ngIf="(ownerIsSouthAfricanResident > 0 && ownerDetails.propertyOwnershipType == 1) || ownerDetails.propertyOwnershipType == 2"
                class="submission-form">
                <div class="heading-5" *ngIf="ownerDetails.propertyOwnershipType == 1">
                    Details of the <span class="burnt-red">legal owner</span> for contract purposes (please
                    check spelling and digits carefully):
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                            [(ngModel)]="ownerDetails.personalDetails.name" required #name="ngModel"
                            [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph"
                            name="surname" [(ngModel)]="ownerDetails.personalDetails.surname" required
                            #surname="ngModel"
                            [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblWorkPhone" for="txtWorkPhone" class="paragraph">Work Phone:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtWorkPhone" name="workPhone" class="paragraph not-required"
                            [(ngModel)]="ownerDetails.personalDetails.alternativeMobile">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                            class="paragraph" [(ngModel)]="ownerDetails.personalDetails.phoneNumber" required
                            #phoneNumber="ngModel" minlength="10"
                            [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                            Address:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                            class="paragraph" [(ngModel)]="ownerDetails.personalDetails.emailAddress" required
                            #emailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                        'valid-input': emailAddress.valid}">
                        <small class="form-control-feedback"
                            *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                            <span *ngIf="ownerIsSouthAfricanResident == 1 || ownerIsSouthAfricanResident == 0">Identity
                                Number:</span>
                            <span *ngIf="ownerIsSouthAfricanResident == 2">Passport Number:</span>
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                            class="paragraph" [(ngModel)]="ownerDetails.personalDetails.id_Number" required
                            #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                        'valid-input': id_Number.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                            *Must must contain at least 9 characters
                        </small>
                    </div>
                </div>
            </div>
            <section *ngIf="ownerDetails.propertyOwnershipType == 1 && ownerIsSouthAfricanResident > 0">
                <!--Spouse Info section-->
                <section class="submission-form">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>What is your marital status?
                    </div>
                    <div>
                        <mat-radio-group name="radioMaritialStatus" [(ngModel)]="ownerDetails.maritialStatus">
                            <mat-radio-button class="paragraph" *ngFor="let selectionValue of maritialStatuses"
                                [value]="selectionValue.key">
                                {{selectionValue.value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="ownerDetails.maritialStatus == 2 || ownerDetails.maritialStatus == 3">
                        <section class="submission-form">
                            <div class="heading-5">
                                <span class="burnt-red">| </span>Is your spouse a resident of the Republic
                                of South
                                Africa?
                            </div>
                            <mat-radio-group name="radioSpouseSouthAfricanResident"
                                [(ngModel)]="spouseIsSouthAfricanResident">
                                <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                                    [value]="selectionValue.key">
                                    {{selectionValue.value}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf="spouseIsSouthAfricanResident > 0" class="submission-form">
                                <div class="heading-5">
                                    Details of <span class="coral">your spouse</span>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpouseTitle" for="txtSpouseTitle" class="paragraph">Title:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpouseTitle" class="paragraph"
                                            name="spouseTitle" [(ngModel)]="ownerDetails.spouseDetails.title" required
                                            #spouseTitle="ngModel"
                                            [ngClass]="{'invalid-input': spouseTitle.invalid, 'valid-input': spouseTitle.valid}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpousePersonalName" for="txtSpousePersonalName"
                                            class="paragraph">Name:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalName"
                                            class="paragraph" name="spouseName"
                                            [(ngModel)]="ownerDetails.spouseDetails.name" required #spouseName="ngModel"
                                            [ngClass]="{'invalid-input': spouseName.invalid, 'valid-input': spouseName.valid}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpousePersonalSurname" for="txtSpousePersonalSurname"
                                            class="paragraph">Surname:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalSurname"
                                            class="paragraph" name="spouseSurname"
                                            [(ngModel)]="ownerDetails.spouseDetails.surname" required
                                            #spouseSurname="ngModel"
                                            [ngClass]="{'invalid-input': spouseSurname.invalid, 'valid-input': spouseSurname.valid}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpouseWorkPhone" for="txtSpouseWorkPhone" class="paragraph">Work
                                            Phone:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" id="txtSpouseWorkPhone" name="spouseWorkPhone"
                                            class="paragraph not-required"
                                            [(ngModel)]="ownerDetails.spouseDetails.alternativeMobile">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpousePersonalMobile" for="txtSpousePersonalMobile"
                                            class="paragraph">Mobile:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalMobile"
                                            name="spousePhoneNumber" class="paragraph"
                                            [(ngModel)]="ownerDetails.spouseDetails.phoneNumber" required
                                            #spousePhoneNumber="ngModel" minlength="10"
                                            [ngClass]="{'invalid-input': spousePhoneNumber.invalid, 'valid-input': spousePhoneNumber.valid}">
                                        <small class="form-control-feedback"
                                            *ngIf="(spousePhoneNumber.errors && (spousePhoneNumber.dirty || spousePhoneNumber.touched)) && spousePhoneNumber.errors.minlength">
                                            *Mobile number must contain at least the 10 numbers
                                        </small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpousePersonalEmailAddress" for="txtSpousePersonalEmailaddress"
                                            class="paragraph">Email
                                            Address:</label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalEmailaddress"
                                            name="spouseEmailAddress" class="paragraph"
                                            [(ngModel)]="ownerDetails.spouseDetails.emailAddress" required
                                            #spouseEmailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': spouseEmailAddress.invalid, 
                                                                            'valid-input': spouseEmailAddress.valid}">
                                        <small class="form-control-feedback"
                                            *ngIf="spouseEmailAddress.errors && (spouseEmailAddress.dirty || spouseEmailAddress.touched)">
                                            *Must be a valid email address
                                        </small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <label id="lblSpousePersonalIdNumber" for="txtSpousePersonalIdNumber"
                                            class="paragraph">
                                            <span *ngIf="spouseIsSouthAfricanResident == 1">Identity Number:</span>
                                            <span *ngIf="spouseIsSouthAfricanResident == 2">Passport Number:</span>
                                        </label>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalIdNumber"
                                            name="spouseid_Number" class="paragraph"
                                            [(ngModel)]="ownerDetails.spouseDetails.id_Number" required
                                            #spouseid_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': spouseid_Number.invalid, 
                                                                            'valid-input': spouseid_Number.valid}">
                                        <small class="form-control-feedback"
                                            *ngIf="(spouseid_Number.errors && (spouseid_Number.dirty || spouseid_Number.touched)) && spouseid_Number.errors.minlength">
                                            *Must must contain at least 9 characters
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <!--More than 1 owner section-->
                <section
                    *ngIf="ownerDetails.maritialStatus == 1 || (ownerDetails.maritialStatus > 1 && spouseIsSouthAfricanResident > 0)">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Is the entity owned by only one person/shareholder?
                    </div>
                    <div>
                        <mat-radio-group name="radioOnlyPropertyOwner" [(ngModel)]="moreThanOneOwner">
                            <mat-radio-button class="paragraph" *ngFor="let selectionValue of multipleOwners"
                                [value]="selectionValue.key">
                                {{selectionValue.value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <section *ngIf="moreThanOneOwner == 2">
                        <div class="heading-5">
                            <span class="burnt-red">| </span>How many natural persons co-own this property?
                        </div>
                        <div class="heading-5">
                            <strong>IMPORTANT</strong>: The number indicated should <strong>exclude</strong>
                            yourself
                            and
                            your spouse. It must also <strong>exclude</strong> the spouse of the co-owner.
                        </div>
                    </section>
                    <section *ngIf="moreThanOneOwner == 2">
                        <div class="heading-2">Details of co-owners</div>
                        <div [hidden]="coOwners.length == 0">
                            <table mat-table [dataSource]="coOwners" class="mat-elevation-z1" style="width: 45vw;">
                                <ng-container matColumnDef="co-owner">
                                    <th mat-header-cell *matHeaderCellDef> No. </th>
                                    <td mat-cell *matCellDef="let owner; let i = index"> Co-owner {{i + 1}}
                                        ({{owner.personalDetails.name}})</td>
                                </ng-container>

                                <ng-container matColumnDef="action1">
                                    <th mat-header-cell *matHeaderCellDef> Edit </th>
                                    <td mat-cell *matCellDef="let owner">
                                        <button mat-icon-button style="color: #fb8c00"
                                            (click)="openEditCoOwnerDialog(owner)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action2">
                                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                                    <td mat-cell *matCellDef="let owner; let i = index">
                                        <button mat-icon-button color="warn" (click)="deleteCoOwner(owner, i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button class="button" (click)="openAddCoOwnerDialog()">
                                    Add Co-owner
                                </button>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
            <section *ngIf="ownerDetails.propertyOwnershipType == 2">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Is the entity owned by only one person/shareholder?
                </div>
                <div>
                    <mat-radio-group name="radioOnlyPropertyOwner" [(ngModel)]="moreThanOneOwner">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of multipleOwners"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div *ngIf="moreThanOneOwner == 2" class="submission-form">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Are you the <span class="text-danager">legal</span>
                        representative of the entity?
                    </div>
                    <mat-radio-group name="radioAreYouLegalRep" [(ngModel)]="personIsLegalRepOfEntity">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="personIsLegalRepOfEntity == 1" class="submission-form">
                        <p class="paragraph"><strong>Please note:</strong> A resolution, mandating you to
                            act on behalf of all members, as well as a company/entity registration document,
                            will be required.</p>
                    </div>
                    <div *ngIf="personIsLegalRepOfEntity == 2" class="submission-form">
                        <label class="checkbox-container">
                            <span class="paragraph">I have consent from the legal representative of the
                                entity to fill in this form and to provide Property Matrix SA with his/her
                                details</span>
                            <input type="checkbox" [(ngModel)]="ownerDetails.confirmConsent" name="confirmConsent"
                                #confirmConsent="ngModel" id="confirmConsent" data-toggle="toggle" />
                            <span class="checkmark"></span>
                        </label>
                        <p class="paragraph"><strong>Important note:</strong> The person who is nominated as
                            the representative of the entity will need to sign the offer to purchase
                            contract (to be generated). A
                            resolution, mandating him/her to act on behalf of all members, as well as a
                            company/entity registration document, will be required. </p>


                    </div>
                </div>
            </section>
            <!--Physical Address Info section-->
            <div *ngIf="(ownerDetails.propertyOwnershipType == 2 && 
                        ((ownerDetails.confirmConsent == true && personIsLegalRepOfEntity == 2) || personIsLegalRepOfEntity == 1)
                             || moreThanOneOwner == 1) 
                             || (ownerDetails.propertyOwnershipType == 1 && coOwners.length > 0)">
                <section>
                    <span class="paragraph"><strong>Nominated physical address for the purpose of receiving
                            legal
                            documents and notices (domicilium citandi et executandi):</strong></span>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo"
                                class="paragraph">Building/
                                unit
                                no:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                                class="paragraph not-required" [(ngModel)]="physicalAddress.unitNo">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                                class="paragraph">Estate/complex
                                name:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                                class="paragraph not-required" [(ngModel)]="physicalAddress.complexName">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                                no:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                                class="paragraph" [(ngModel)]="physicalAddress.streetNo" required
                                #streetNumber="ngModel"
                                [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                                Name:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                                class="paragraph" [(ngModel)]="physicalAddress.streetName" required
                                #streetName="ngModel"
                                [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb"
                                class="paragraph" [(ngModel)]="physicalAddress.suburb" required #suburb="ngModel"
                                [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
                        </div>
                        <div class="col-7">
                            <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city"
                                class="paragraph" [(ngModel)]="physicalAddress.city" #city="ngModel" required
                                [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                        </div>
                    </div>
                </section>
                <div class="row text-center mt-3">
                    <div class="col-10">
                        <button id="btnSaveFinal" class="button heading-4" [disabled]="ownershipDetailsForm.invalid"
                            (click)="submitForm()">SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>