<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main class="container-fluid">
    <div class="heading-1 py-3">
        LEVEL 2 PROPERTY INVESTIGATION
    </div>
    <div>
        <div class="paragraph">
            <p>
                Thank you for your enquiry. We look forward to serving you the best we can.
            </p>
            <p>
                Please provide your details below and we will email you an invoice once we have located the property.
                Your investigation will kick off as soon as the payment is received.
            </p>
            <p>
                Be assured that all information provided will be protected in terms of our <a
                    class="privacy-policy-link" href="#">Privacy Policy</a>
            </p>
        </div>
    </div>
    <form id="level2form" (ngSubmit)="submit(form)" novalidate #form="ngForm">
        <section class="pb-3">
            <div class="heading-2">
                Personal details
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                        [(ngModel)]="personalDetails.name" required #name="ngModel"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                        [(ngModel)]="personalDetails.surname" required #surname="ngModel"
                        [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalLandline" for="txtPersonalLandline" class="paragraph">Landline:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPersonalLandline" name="landLine" class="paragraph not-required"
                        [(ngModel)]="personalDetails.landline">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph"
                        class="paragraph">Mobile:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                        class="paragraph" [(ngModel)]="personalDetails.phoneNumber" required #phoneNumber="ngModel"
                        minlength="10"
                        [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                        *Mobile must contain at least the 10 numbers
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonAlternativemobile" for="txtPersonalAlternativeMobile"
                        class="paragraph">Alternative
                        Mobile:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPersonalAlternativeMobile" name="alternativeNumber"
                        class="paragraph not-required" [(ngModel)]="personalDetails.alternativeNumber">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                        Address:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="email"
                        class="paragraph" [(ngModel)]="personalDetails.emailAddress" required #email="ngModel"
                        [pattern]="emailPattern" [ngClass]="{'invalid-input': email.invalid, 
                                                                        'valid-input': email.valid}">
                    <small class="form-control-feedback" *ngIf="email.errors && (email.dirty || email.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                        Email:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                        [(ngModel)]="personalDetails.confirmEmail" class="paragraph" required #confirmEmail="ngModel"
                        [pattern]="emailPattern" (change)="checkValidation(email, confirmEmail)"
                        [ngClass]="{'invalid-input': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                                            'valid-input': (email.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                    <small class="form-control-feedback" [ngClass]="{'confirmEmail-invalid': (email.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                                            'confirmEmail-valid': email.value == confirmEmail.value}">
                        *Confirm Email does not match Email Address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">Identity/Passport
                        Number:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                        class="paragraph" [(ngModel)]="personalDetails.id_Number" required #id_Number="ngModel"
                        minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                        'valid-input': id_Number.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                        *Must must contain at least 9 characters
                    </small>
                </div>
            </div>
        </section>
        <section class="pb-3">
            <div class="heading-2 remove-row-gap">
                Property details
            </div>
            <div class="heading-5">
                Physical address of the property <span class="burnt-red">to be investigated:</span>
            </div>
            <div class="row">
                <div class="col-2">
                    <label class="paragraph" for="buildingUnitNo">Building/ unit no:</label>
                </div>
                <div class="col-6">
                    <input type="text" class="paragraph not-required" id="buildingUnitNo"
                        [(ngModel)]="propertyDetails.unitNo" name="unitNo">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label class="paragraph" for="estateComplexName">Estate/complex name:</label>
                </div>
                <div class="col-6">
                    <input type="text" class="paragraph not-required" id="estateComplexName"
                        [(ngModel)]="propertyDetails.complexName" name="complexName">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="txtPropertyStreetNo" class="paragraph">Street
                        no:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNo"
                        class="paragraph" [(ngModel)]="propertyDetails.streetNo" required #streetNo="ngModel"
                        [ngClass]="{'invalid-input': streetNo.invalid, 'valid-input': streetNo.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="txtPropertyStreetName" class="paragraph">Street
                        Name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                        class="paragraph" [(ngModel)]="propertyDetails.streetName" required #streetName="ngModel"
                        [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="txtPropertySuburb" class="paragraph">Suburb:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                        [(ngModel)]="propertyDetails.suburb" required #suburb="ngModel"
                        [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="txtPropertyCity" class="paragraph">City:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                        [(ngModel)]="propertyDetails.city" #city="ngModel" required
                        [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label for="txtPropertyDescription" class="paragraph">Property
                        description</label>
                </div>
                <div class="col-6">
                    <textarea id="txtPropertyDescription" name="propertyDescription"
                        class="paragraph not-required" rows="3"
                        placeholder="Erf 386 Meyerton | Holding 23 of Raslouw Agricultural Holdings | Portion 77 of the Farm Stoneybrooke 365-JR"
                        [(ngModel)]="propertyDetails.propertyDescription" #propertyDescription="ngModel"></textarea>
                    <div class="charcoal paragraph">
                        *indicated on your municipal rates account or your title deed
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label class="paragraph" for="txtPropertLandUse">Current land use:</label>
                </div>
                <div class="col-6">
                    <textarea id="txtPropertyLandUse" name="currentLandUse" class="paragraph not-required" rows="3"
                        placeholder="Guest house | Lodge | Shop selling.... | Warehouse storing.... | Factory manufacturing...."
                        [(ngModel)]="propertyDetails.currentLandUse" #currentLandUse="ngModel"></textarea>
                </div>
            </div>
        </section>
        <div class="heading-2 remove-row-gap pb-2">
            Details for invoice purposes
        </div>
        <section>
            <div class="heading-5 remove-row-gap shift-left">
                <span class="burnt-red">| </span>The invoice should be issued to:
            </div>
            <div>
                <mat-radio-group name="radioEnquirerType" [(ngModel)]="queryBody.enquirerType">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of enquirerTypeSelection"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </section>
        <div *ngIf="queryBody.enquirerType == 1">
            <div class="heading-5 shift-left">
                <span class="burnt-red">| </span>Is the entity/organization registered for VAT?
            </div>
            <div>
                <mat-radio-group name="isVatRegistered" [(ngModel)]="isVatRegistered">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of vatRegistrationSelection"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <section>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityNameOfOrganization" for="txtEntityNameOfOrganization">Name
                            of
                            organization:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityNameOfOrganization" name="orgName"
                            class="paragraph" [(ngModel)]="queryBody.orgName" #orgName="ngModel" required
                            [ngClass]="{'invalid-input': orgName.invalid, 'valid-input': orgName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityRegistrationNumber"
                            for="txtEntityRegistrationNumber">Company
                            registration number:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityRegistrationNumber"
                            name="orgRegistrationNumber" class="paragraph" [(ngModel)]="queryBody.orgRegistrationNumber"
                            #orgRegistrationNumber="ngModel" required
                            [ngClass]="{'invalid-input': orgRegistrationNumber.invalid, 'valid-input': orgRegistrationNumber.valid}">
                    </div>
                </div>
                <div class="row" *ngIf="isVatRegistered == 1">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityVatNumber" for="txtEntityVatNumber">VAT
                            number:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityVatNumber" name="orgVatNumber"
                            class="paragraph" [(ngModel)]="queryBody.orgVatNumber" #orgVatNumber="ngModel" required
                            [ngClass]="{'invalid-input': orgVatNumber.invalid, 'valid-input': orgVatNumber.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityLandline" for="txtEntityLandline">Land line:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" id="txtEntityLandline" name="orgLandline" class="paragraph not-required"
                            [(ngModel)]="queryBody.orgLandline" #orgLandline="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityMobile" for="txtEntityMobile">Mobile:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityMobile" name="orgMobile"
                            class="paragraph" [(ngModel)]="queryBody.orgMobile" #orgMobile="ngModel" required
                            minlength="10"
                            [ngClass]="{'invalid-input': orgMobile.invalid, 'valid-input': orgMobile.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(orgMobile.errors && (orgMobile.dirty || orgMobile.touched)) && orgMobile.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityEmailAddress" for="txtEntityEmailaddress">Email
                            address:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityEmailaddress" name="orgEmail" orgEmail
                            class="paragraph" [(ngModel)]="queryBody.orgEmail" #orgEmail="ngModel" required
                            [pattern]="emailPattern" [ngClass]="{'invalid-input': orgEmail.invalid, 
                                                                    'valid-input': orgEmail.valid}">
                        <small class="form-control-feedback"
                            *ngIf="orgEmail.errors && (orgEmail.dirty || orgEmail.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" id="lblEntityConfirmEmail" for="txtEntityConfirmEmail">Confirm
                            email
                            address:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEntityConfirmEmail" name="orgConfirmEmail"
                            class="paragraph" [(ngModel)]="queryBody.orgConfirmEmail" required
                            #orgConfirmEmail="ngModel" [pattern]="emailPattern"
                            [ngClass]="{'invalid-input': (orgEmail.value != orgConfirmEmail.value) && (orgConfirmEmail.dirty || orgConfirmEmail.touched), 
                                                'valid-input': (orgEmail.value == orgConfirmEmail.value) && (orgConfirmEmail.dirty || orgConfirmEmail.touched)}">
                        <small class="form-control-feedback" [ngClass]="{'confirmEmail-invalid': (orgEmail.value != orgConfirmEmail.value) && (orgConfirmEmail.dirty || orgConfirmEmail.touched), 
                                                'confirmEmail-valid': orgEmail.value == orgConfirmEmail.value}">
                            *Confirm Email does not match Email Address
                        </small>
                    </div>
                </div>
            </section>
            <section>
                <div class="heading-5 remove-row-gap shift-left">
                    <span class="burnt-red">| </span>Do you operate your business from the property to be
                    investigated?
                </div>
                <div>
                    <mat-radio-group name="sameAddress" [(ngModel)]="sameAddress">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of sameAddressOrgSelection"
                            [value]="selectionValue.key">{{selectionValue.value}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </section>
            <section *ngIf="sameAddress == 2">
                <div class="heading-5">
                    Physical address of organisation:
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqUnitNo">Building/unit
                            no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" id="txtEnqUnitNo" class="paragraph not-required"
                            [(ngModel)]="enquirerAddress.unitNo" name="enqUnitNo">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqComplexName">Estate/complex
                            name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" id="txtEnqComplexName" class="paragraph not-required"
                            [(ngModel)]="enquirerAddress.complexName" name="enqComplexName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqStreetNo">Street no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEnqStreetNo" class="paragraph"
                            [(ngModel)]="enquirerAddress.streetNo" #orgStreetNo="ngModel" required name="enqStreetNo"
                            [ngClass]="{'invalid-input': orgStreetNo.invalid, 'valid-input': orgStreetNo.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqStreetName">Street name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEnqStreetName" class="paragraph"
                            [(ngModel)]="enquirerAddress.streetName" #orgStreetName="ngModel" required
                            name="enqStreetName"
                            [ngClass]="{'invalid-input': orgStreetName.invalid, 'valid-input': orgStreetName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqSuburb">Suburb:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEnqSuburb" class="paragraph"
                            [(ngModel)]="enquirerAddress.Suburb" #orgSuburb="ngModel" required name="enqSuburb"
                            [ngClass]="{'invalid-input': orgSuburb.invalid, 'valid-input': orgSuburb.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="txtEnqCity">City:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtEnqCity" class="paragraph"
                            [(ngModel)]="enquirerAddress.City" #orgCity="ngModel" required name="enqCity"
                            [ngClass]="{'invalid-input': orgCity.invalid, 'valid-input': orgCity.valid}">
                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="queryBody.enquirerType == 2">
            <section>
                <div class="heading-5 remove-row-gap shift-left">
                    <span class="burnt-red">| </span>Do you reside at the property to be investigated?
                </div>
                <div>
                    <mat-radio-group name="radioSameIndAddress" [(ngModel)]="queryBody.sameAddress">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of sameAddressIndSelection"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </section>
            <section *ngIf="queryBody.sameAddress == 2">
                <div class="heading-5">
                    Physical address: residing property
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="enqBuildingUnitNo2">Building/ unit no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" class="paragraph not-required" id="enqBuildingUnitNo2"
                            [(ngModel)]="enquirerAddress.unitNo" name="enqUnitNo">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label class="paragraph" for="enqEstateComplexName2">Estate/complex name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" class="paragraph not-required" id="enqEstateComplexName2"
                            [(ngModel)]="enquirerAddress.complexName" name="enqComplexName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="enqPropertyStreetNo2" class="paragraph">Street
                            no:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="enqPropertyStreetNo2" name="enqStreetNo"
                            class="paragraph" [(ngModel)]="enquirerAddress.streetNo" required #enqStreetNo="ngModel"
                            [ngClass]="{'invalid-input': enqStreetNo.invalid, 'valid-input': enqStreetNo.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="enqPropertyStreetName2" class="paragraph">Street
                            Name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="enqPropertyStreetName2" name="enqStreetName"
                            class="paragraph" [(ngModel)]="enquirerAddress.streetName" required #enqStreetName="ngModel"
                            [ngClass]="{'invalid-input': enqStreetName.invalid, 'valid-input':enqStreetName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="enqPropertySuburb2" class="paragraph">Suburb:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="enqPropertySuburb2" name="enqSuburb"
                            class="paragraph" [(ngModel)]="enquirerAddress.suburb" required #enqSuburb="ngModel"
                            [ngClass]="{'invalid-input': enqSuburb.invalid, 'valid-input':enqSuburb.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label for="enqPropertyCity2" class="paragraph">City:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="enqPropertyCity2" name="enqCity"
                            class="paragraph" [(ngModel)]="enquirerAddress.city" #enqCity="ngModel" required
                            [ngClass]="{'invalid-input': enqCity.invalid, 'valid-input':enqCity.valid}">
                    </div>
                </div>
            </section>
            <section>
                <div class="heading-2 remove-row-gap shift-left">
                    <span class="burnt-red">| </span>Please select:
                </div>
                <div>
                    <mat-radio-group name="radioOwnerType" [(ngModel)]="queryBody.ownerType">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of ownerTypes"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </section>
            <div class="row">
                <div class="heading-2 pb-1 shift-left">
                    <span class="cloud">| </span>Purpose of the investigation
                </div>
                <div class="heading-5 pb-1">
                    It will be extremely beneficial if you can explain what you would like to achieve with this
                    property?
                </div>
                <div class="col-9 pb-4">
                    <textarea id="txtInvestigationPurpose" name="investigationPurpose"
                        [(ngModel)]="queryBody.investigationPurpose" class="paragraph not-required" rows="3"
                        placeholder="Enter primary reason" appearance="fill"></textarea>
                </div>
            </div>
        </div>
        <div class="text-center">
            <div class="col-9">
                <button id="btnSaveFinal" class="button heading-4" type="submit"
                    [disabled]="form.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>