import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-valuer',
  templateUrl: './valuer.component.html',
  styleUrls: ['./valuer.component.scss']
})
export class ValuerComponent {
  

  constructor() { }

}