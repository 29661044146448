import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticateService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-approve-user',
  templateUrl: './approve-user.component.html',
  styleUrls: ['./approve-user.component.scss']
})
export class ApproveUserComponent implements OnInit {

  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialog,
    public authenticateUserService: AuthenticateService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      sacplanCertificate: [this.dialogData.userProfile.sacplanCertificate],
      townPlanningDegree: [this.dialogData.userProfile.townPlanningDegree],
      relevantExperience: [this.dialogData.userProfile.relevantExperience],
      idOrPassportProvided: [this.dialogData.userProfile.idOrPassportProvided],
    });
  }

  confirmApplication() {
    this.authenticateUserService.apiV1AuthenticateNetworkApprovalPut$Json({
      userId: this.dialogData.userProfile.id,
      Sacplan: this.form.value.sacplanCertificate,
      townPlanningDegree: this.form.value.townPlanningDegree,
      relevantExperience: this.form.value.relevantExperience,
      idOrPassport: this.form.value.idOrPassportProvided,
      approvalState: true
    }).subscribe(res => {
      this.dialog.closeAll();
    });
  }

  denyApplication() {
    this.authenticateUserService.apiV1AuthenticateNetworkApprovalPut$Json({
      userId: this.dialogData.userProfile.id,
      approvalState: false
    }).subscribe(res => {
      this.dialog.closeAll();
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
