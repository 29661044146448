<div class="page-container" *appUserRole="['HeadAdministrator', 'HeadOfFinances', 'AgentPortalAdministrator', 'PlannerPortalAdministrator', 
    'ConsultantAdministrator', 'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner', 'NetworkEcon', 'NetworkPlanner']">
    <div class="table-container">
        <form [formGroup]="form">
            <app-busy-spinner [isLoading]="loading$ | async">
                <div class="row">
                    <div class="header-content col-9">
                        <div>
                            <h3>USERS</h3>
                            <h5>All Users and their roles</h5>
                        </div>
                        <div class="user-filters">
                            <mat-card-content>                                
                                <mat-button class="filter-buttons" (click)="toggleFilter('')" [class.active]="activeFilter == ''">
                                    <mat-icon>people_outline</mat-icon>
                                    All
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('admin')" [class.active]="activeFilter == 'admin'">
                                    <mat-icon>person_outline</mat-icon>
                                    Admin
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('planner')" [class.active]="activeFilter == 'planner'">
                                    <mat-icon>person_outline</mat-icon>
                                    Planners
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('agent')" [class.active]="activeFilter == 'agent'">
                                    <mat-icon>person_outline</mat-icon>
                                    Agents
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('conveyancers')" [class.active]="activeFilter == 'conveyancers'">
                                    <mat-icon>person_outline</mat-icon>
                                    Conveyancers
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('valuers')" [class.active]="activeFilter == 'valuers'">
                                    <mat-icon>person_outline</mat-icon>
                                    Valuers
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('user')" [class.active]="activeFilter == 'user'">
                                    <mat-icon>person_outline</mat-icon>
                                    General
                                </mat-button>
                                <mat-button class="filter-buttons" (click)="toggleFilter('professional')" [class.active]="activeFilter == 'professional'">
                                    <mat-icon>person_outline</mat-icon>
                                    Extended Professionals
                                </mat-button>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-3 searching-header">
                        <div class="add-user-pos col-12">
                            <mat-button class="add-user-button" (click)="addUser()">
                                <mat-icon class="add-user-icon">add</mat-icon>
                                <span>Add user</span>
                            </mat-button>
                        </div>
                        <div class="col-12">
                            <mat-input class="search-container">
                                <mat-icon matPrefix class="search-icon">search</mat-icon>
                                <input matInput placeholder="Search" class="search-input"
                                    [formControl]="form.controls['searchUser']" (keyup)="searchUsers()">
                            </mat-input>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-card-content>
                        <mat-table mat-table class="table-style" [dataSource]="dataSource">
                            <ng-container matColumnDef="profilePicture">
                                <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                                    *matHeaderCellDef> PROFILE
                                </mat-header-cell>
                                <mat-cell class="center-table-controllers" *matCellDef="let element"> <img
                                        [src]='element.profilePicture ?? defaultPp' id="profile-image">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef>
                                    FULLNAME
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"><strong> {{element.nameAndSurname}}
                                    </strong></mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="email">
                                <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef> EMAIL
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="metro">
                                <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef> METRO
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.metro.name}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="role">
                                <mat-header-cell class="table-header-styling" mat-header-cell *matHeaderCellDef> ROLE
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.role?.name}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="accountVerified">
                                <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                                    *matHeaderCellDef>
                                    ACCOUNT VERIFIED </mat-header-cell>
                                <mat-cell class="center-table-controllers" *matCellDef="let element">
                                    <mat-slide-toggle [checked]="element.accountVerified"
                                        (change)="accountStateChange(element)"></mat-slide-toggle>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="approved">
                                <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                                    *matHeaderCellDef> APPROVED
                                </mat-header-cell>
                                <mat-cell class="center-table-controllers" *matCellDef="let element">
                                    <mat-slide-toggle [checked]="element.approved"
                                        (change)="approveUser(element)"></mat-slide-toggle>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="endorsement">
                                <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                                    *matHeaderCellDef> ENDORSEMENT
                                </mat-header-cell>
                                <mat-cell class="center-table-controllers" *matCellDef="let element">
                                    <mat-slide-toggle [checked]="element.endorsement"
                                        (change)="endorseUser(element)"></mat-slide-toggle>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell class="table-header-styling center-table-controllers" mat-header-cell
                                    *matHeaderCellDef> ACTION
                                </mat-header-cell>
                                <mat-cell class="center-table-controllers" *matCellDef="let element">
                                    <mat-button mat-icon-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </mat-button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="editUser(element)">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit Account</span>
                                        </button>
                                        <button mat-menu-item (click)="removeUser(element)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Remove User</span>
                                        </button>
                                    </mat-menu>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <mat-paginator class="table-pager" [length]="dataSource.recordCount$ | async"
                            [pageSizeOptions]="[ 5, 10, 25, 50, 100]" showFirstLastButtons #bottomPaginator>
                        </mat-paginator>
                    </mat-card-content>
                </div>
            </app-busy-spinner>
        </form>
    </div>
</div>