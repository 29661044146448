import { Component, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'src/app/models/file-uploader';
import { ContactUsVm, PersonalDetailsVm, QueryToEnum, UploadDocumentsFileReturnVm } from 'src/app/Services/propertypros/models';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ContactUsService, FileService } from 'src/app/Services/propertypros/services';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['../../../../css/2-modules/_forms.scss', './contact-us.component.scss'],
})
export class ContactUSComponent {

  loading: boolean = false;
  selectedValue: number;

  showDiv = {
    upload: false
  }

  querys: KeyValuePair[] = [
    { key: 1, value: 'Town Planner' },
    { key: 2, value: 'Estate agent' },
    { key: 3, value: 'Urban economist' },
    { key: 4, value: 'Property valuer' },
    { key: 5, value: 'Conveyancer/legal team' },
    { key: 6, value: 'Technical team (website queries)' }
  ];

  personalDetails: PersonalDetailsVm = {
    name: '',
    phoneNumber: '',
    confirmPhoneNumber: '',
    emailAddress: '',
    confirmEmail: ''
  }

  queryBody: ContactUsVm = {
    personalDetails: this.personalDetails,
    queryTo: 0,
    message: '',
    paths: []
  }

  files: any[] = [];
  fileUploader = new FileUploader;

  constructor(private http: HttpClient, private contactUsService: ContactUsService, private router: Router) { }

  submitPersonalDetails() {
    this.loading = true;
    if (this.fileUploader.files.length == 0) {
      this.sendFinalForm(null)
      return;
    }
    const formData = new FormData();
    for (let i = 0; i < this.fileUploader.files.length; i++) {
      const file = this.fileUploader.files[i];
      const fileIndex = i + 1;
      const username = 'GUEST'
      formData.append(`${username}|${fileIndex.toString()}`, file.file);
    }
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey);

    this.http.post(environment.APIUrl + FileService.ApiV1FileAnonymousUploadFilesPostPath, formData, { headers: headers }).pipe(
      map((result) => {
        return result;
      })).subscribe({
        next: (result) => {
          this.sendFinalForm(result);
        },
        error: (error) => {
          this.loading = false;
          GlobalMethods.tinyErrorAlert("Problem uploading documents", "Please contact support.");
        }
      });
  }

  sendFinalForm(result: any) {
    this.queryBody.queryTo = this.selectedValue;
    if (result) {
      this.queryBody.paths = result as Array<UploadDocumentsFileReturnVm>;
    }
    this.contactUsService.apiV1ContactUsCaptureContactUsQueryPost$Response({ body: this.queryBody })
    .subscribe({
      next: async (result) => {
        this.loading = false
        let successResult = await GlobalMethods.tinySuccessAlert("Success", "Your details have been sent!");
        if(successResult.isConfirmed){
          this.router.navigateByUrl('/home');
        }
      },
      error: (error) => {
        this.loading = false
        GlobalMethods.tinyErrorAlert("Details not sent", error);
      }
    }) 
  }

  onFileDropped($event) {
    this.fileUploader.onFileDropped($event)
    this.files.push($event[0])
  }

  fileBrowseHandler(files) {
    if(!files.length){
      return;
    }
    this.fileUploader.fileBrowseHandler(files)
    this.files.push(files[0])
  }

  deleteFile(index: number) {
    this.fileUploader.deleteFile(index)
    this.files.splice(index, 1)
  }

}