import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { AddressVm, CheckboxVm, PersonalDetailsVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-property-for-sale-checkout',
  templateUrl: './property-for-sale-checkout.component.html',
  styleUrls: ['./property-for-sale-checkout.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PropertyForSaleCheckoutComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();

  cart: CartVM = {
    cartItems: [
      {
        documents: [
          { archived: false, name: 'Planning Report', price: 90 },
          { archived: true, name: 'Civil and electrical demand study', price: 20 },
          { archived: false, name: 'Flood line report', price: 50 },
        ],
        name: 'Portion 436 of the Farm Blueberry Hill 369 JR',
        image: ''
      },
      {
        documents: [
          { archived: false, name: 'Planning Report', price: 90 },
          { archived: true, name: 'Civil and electrical demand study', price: 20 },
          { archived: false, name: 'Flood line report', price: 50 },
        ],
        name: 'Portion 436 of the Farm Blueberry Hill 369 JR',
        image: ''
      }
    ],
    total: 500
  }

  cartSteps: StepperVM[] = [
    { key: 1, value: 'ORDER', active: true },
    { key: 2, value: 'LOG-IN', active: false },
    { key: 3, value: 'BILLING', active: false },
  ]

  billingAddress: AddressVm = {
    city: '',
    complexName: '',
    postalCode: '',
    streetName: '',
    streetNo: '',
    suburb: '',
    unitNo: '',
  }

  companyInvoiceDetails: PersonalDetailsVm = {
    companyName: '',
    emailAddress: '',
    phoneNumber: '',
    vat_Number: '',
  }

  conditions: CheckboxVm[] = [
    {id: 1, value: 'I will not remove any trademark, trade name, website link, contact information or disclaimer from any Property Matrix document if I reproduce it in any form and/or redistribute it.', checked: false},
    {id: 2, value: 'I will always distribute any Property Matrix document, report, or addendum in its entirety, not only parts or sections thereof', checked: false},
    {id: 3, value: 'I realise that the cover page provides context in terms of all the related information available on the subject property and agree to always attach such if I redistribute a Property Matrix document, report, or addendum.', checked: false},
    {id: 4, value: 'I will not, except with written permission from Property Matrix, redistribute any of the information on a large scale, or commercially exploit the content.', checked: false},
    {id: 5, value: 'I understand that failure to comply with any of the above will be investigated by the Property Matrix legal team and may lead to legal action being taken.', checked: false},
  ]

  activePageNumber = 1;

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.gotoNextStep(1)
    GlobalMethods.scrollToTop();
  }

  backToOptions() {
    this.pageNumberEvent.emit(2)
    GlobalMethods.scrollToTop();
  }

  checkIfLoggedIn(){
    sessionStorage.removeItem('originalUrl');
    let user = sessionStorage.getItem('user');
    if (!user) {
      GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/collaboration-agreement', this.route)
    }
  }

  gotoNextStep(key: number) {
    for (let index = 0; index < this.cartSteps.length; index++) {
      let element = this.cartSteps[index];
      element.active = element.key === key
    }
    this.activePageNumber = key
    GlobalMethods.scrollToTop();
  }

  canPay(){
    return this.conditions.filter(f => f.checked === false).length > 0;
  }
}

export interface CartDocument {
  name: string,
  price: number,
  archived: boolean,
}

export interface CartItem {
  name: string,
  image: string,
  documents: CartDocument[],
}

export interface CartVM {
  cartItems: CartItem[],
  total: number
}

export interface StepperVM {
  key: number,
  value: string,
  active: boolean
}