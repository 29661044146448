import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { UploadDocumentsFileReturnVm } from 'src/app/Services/propertypros/models';
import { FileService } from 'src/app/Services/propertypros/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-planner-register-form-documents',
  templateUrl: './planner-register-form-documents.component.html',
  styleUrls: ['./planner-register-form-documents.component.scss']
})
export class PlannerRegisterFormDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() uploadFilesEvent = new EventEmitter<Array<UploadDocumentsFileReturnVm>>();
  fileUploader = new FileUploader();

  loading: boolean = false;

  uploadBlocks: any[] = [
    { key: 1, value: 'Copy of your Identity Document', file: null, required: true },
    { key: 2, value: 'Up to date registration certificate with SACPLAN', file: null, required: false },
    { key: 3, value: 'Certified copy: Town and regional planning degree certificate', file: null, required: false },
    { key: 4, value: 'A logo of your company to be used for marketing purposes', file: null, required: false },
    { key: 5, value: 'Certified copy 1: additional professional qualification and/or designation', file: null, required: false },
    { key: 6, value: 'Certified copy 2: additional professional qualification and/or designation', file: null, required: false },
    { key: 7, value: 'Certified copy 3: additional professional qualification and/or designation', file: null, required: false },
    { key: 8, value: 'Photo of yourself to be used by Property Matrix for office & admin purposes only', file: null, required: false },
  ]

  constructor(private sharedService: PropertiProSharedService, private http: HttpClient) { }

  ngOnInit(): void {
  }

  submitForm() {
    this.loading = true;
    if (this.fileUploader.files.length == 0) {
      this.emitFinalForm(null)
      return;
    }
    let formData = new FormData();
    const username: string = this.sharedService.getUsername();
    this.fileUploader.files.forEach(fileObj => {
      formData.append(`${username}|${fileObj.id.toString()}`, fileObj.file);
    });

    formData.append('username', username);
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)

    this.http.post(environment.APIUrl + FileService.ApiV1FileUploadFilesPostPath, formData, { headers: headers }).pipe(
      map((result) => {
        return result;
      })).subscribe({
        next: (result) => {
          this.emitFinalForm(result);
        }
        , error: (error) => {
          this.loading = false;
          GlobalMethods.tinyErrorAlert("Error", "Please ensure all required fields are filled.");
        }
      });
  }

  emitFinalForm(result: any) {
    this.loading = false;
    if (result) {
      let paths = result as Array<UploadDocumentsFileReturnVm>;
      this.uploadFilesEvent.emit(paths);
      this.pageNumberEvent.emit(4);
    }
  }

  selectUpload(files, id) {
    this.fileUploader.specificFileBrowseHandler(files, id);
    this.updateShownBlocks(files[0], id);
  }

  dropUpload($event, id) {
    this.fileUploader.specificOnFileDropped($event, id);
    this.updateShownBlocks($event[0], id);
  }

  deleteFile(block, blockIndex) {
    this.uploadBlocks[blockIndex].file = null;
    let item = this.fileUploader.files.find(f => f.id == block.key);
    let fileIndex = this.fileUploader.files.indexOf(item);
    this.fileUploader.deleteFile(fileIndex);
  }

  updateShownBlocks(file, id) {
    let uploadBlock = this.uploadBlocks[id - 1]
    uploadBlock.show = this.fileUploader.files.filter(f => f.id == id).length > 0;
    uploadBlock.file = file;
  }

  checkIdUploaded() {
    return this.uploadBlocks[0].file == null;
  }
}
