import { NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { ScrollingMethods } from "./Scrolling/scrolling-methods";
import { User } from "../models/User";

export class GlobalMethods {

  private static scrolling = new ScrollingMethods();
  public static scrollToId(elementId: string) {
    let element: HTMLElement = document.getElementById(elementId);
    this.scrolling.scrollToHTMLElement(element);
  }
  public static scrollToFirstClass(elementClass: string) {
    let element: Element = document.getElementsByClassName(elementClass)[0];
    this.scrolling.scrollToElement(element);
  }
  public static scrollToFirstName(elementName: string) {
    let element: Element = document.getElementsByClassName(elementName)[0];
    this.scrolling.scrollToElement(element);
  }
  public static scrollToTop() {
    this.scrolling.scrollToTop();
  }

  public static scrollToBottom() {
    this.scrolling.scrollToBottom();
  }

  public static tinyErrorAlert(title: string, message: string): Promise<any> {
    return Swal.fire({
      icon: 'error',
      title: title,
      text: message
    });
  }
  public static tinySuccessAlert(title: string, message: string): Promise<any> {
    return Swal.fire({
      icon: 'success',
      title: title,
      text: message
    });
  }

  public static navigateToLoginWithRouteStored(key: string, url: string, route: Router) {
    let user = sessionStorage.getItem('user');
    if (user) {
      route.navigateByUrl(url);
      return;
    }
    if (sessionStorage.getItem(key)) {
      sessionStorage.removeItem(key);
    }
    sessionStorage.setItem(key, url);
    route.navigateByUrl('/login');
  }

  public static checkValidation(email: NgModel, confirmEmail: NgModel): boolean {
    return (email.model == confirmEmail.model) ? true : false;
  }

  public static gotoNewTabPage(page: string) {
    window.open(page, "_blank");
  }

  public static checkPageRolePermissions(user: User, pagePermissions: string[], route: Router){
    if(!pagePermissions || pagePermissions == null || pagePermissions.length == 0){
      return;
    }
    else {
      pagePermissions = pagePermissions.map((role) => role.toLowerCase());
      if (pagePermissions.includes(user.userRole.toLowerCase())) {
        return;
      } else {
        route.navigateByUrl('/home');
      }
    }
  }
}