import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ChangePasswordVm } from 'src/app/Services/propertypros/models';
import { AuthenticateService } from 'src/app/Services/propertypros/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePassForm: FormGroup;
  token: string;
  email: string;

  constructor(private authService: AuthenticateService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.changePassForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('')
    });
    this.token = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];
  }


  ChangePassword = (changePassFormValue) => {
    const changePass = { ...changePassFormValue };

    const changePassBody: ChangePasswordVm = {
      password: changePass.password,
      confirmPassword: changePass.confirmPassword,
      token: this.token,
      email: this.email
    }

    this.authService.apiV1AuthenticateChangePasswordPost$Json({ body: changePassBody }).pipe()
      .subscribe(next => {
        GlobalMethods.tinySuccessAlert("Email sent!","Password changed");
      },
        error => {
          GlobalMethods.tinyErrorAlert("Error", "Failed to save! " + error);
        })
  }
}
