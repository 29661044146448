import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { CheckboxVm, ConveyancerPersonalInformationVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-conveyancer-personal-information',
  templateUrl: './conveyancer-personal-information.component.html',
  styleUrls: ['./conveyancer-personal-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class ConveyancerPersonalInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() ConveyancerPersonalInformationEvent = new EventEmitter<ConveyancerPersonalInformationVm>();

  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern : string = GlobalConstants.passwordPattern;

  form: ConveyancerPersonalInformationVm = {
    addressDetails: {
      city: '',
      suburb: '',
      complexName: '',
      streetName: '',
      streetNo: '',
      unitNo: '',
    },
    conveyancerYears: null,
    financialInstituteOther: '',
    financialInstitutionsPanel: null,
    lpc_Number: ''
  }

  financialInstitutionsPanelCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'ABSA Bank', checked: false },
    { id: 2, value: 'Capitec Bank', checked: false },
    { id: 3, value: 'First Rand Bank (FNB)', checked: false },
    { id: 4, value: 'Investec', checked: false },
    { id: 5, value: 'Mercantile Bank', checked: false },
    { id: 6, value: 'Nedbank', checked: false },
    { id: 7, value: 'Standard Bank', checked: false },
    { id: 8, value: 'Other', checked: false },
  ]

  constructor() { }

  submitForm(){
    this.form.financialInstitutionsPanel = this.financialInstitutionsPanelCheckBoxOptions.filter(f => f.checked == true);
    this.pageNumberEvent.emit(2);
    this.ConveyancerPersonalInformationEvent.emit(this.form);
  }

  financialInstitutionsPanelOtherCheck(option: CheckboxVm) : boolean{
    return option.id == 8 && option.checked;
  }

  ngOnInit(): void {
  }

}
