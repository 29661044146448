import { CommisionModel } from "../pages/selling-my-property/selling-my-property.component";

export class GlobalConstants {
  public static emailPattern: string = "^[\\w-\.]+@([\\w-]+\.)+[\\w-]{2,4}$";
  public static passwordPattern: string = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}';
  public static commisionStructureTableData: CommisionModel[] = [
    { priceRange: 'R0 - R4 000 000', percentage: '6.5%' },
    { priceRange: 'R4 000 001 - R10m', percentage: '6%' },
    { priceRange: 'R10 000 001 - R20m', percentage: '5.5%' },
    { priceRange: 'R20 000 001 - R40m', percentage: '5%' },
    { priceRange: 'R40 000 001 - R70m', percentage: '4.5' },
    { priceRange: 'R70 000 001 plus', percentage: '4%' },
  ]

  public static fileUploadKey: string = "5YrQMX*E*D5#BsPZ&4hEVZZNN";
}

export class UserRoles {
  public static admin: string = "Admin";
  public static user: string = "User";
  public static enquirer: string = "Enquirer";
  public static urbanPlanner: string = "UrbanPlanner";
  public static estateAgent: string = "EstateAgent";
  public static conveyancer: string = "Conveyancer";
  public static valuer: string = "Valuer";
  public static HeadPlanner: string = "HeadPlanner";
  public static SuperAdmin: string = "SuperAdmin";
}