import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-property-valuations',
  templateUrl: './property-valuations.component.html',
  styleUrls: ['./property-valuations.component.scss']
})
export class PropertyValuationsComponent implements AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(private _focusMonitor: FocusMonitor) { }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore1'))
    this._focusMonitor.stopMonitoring(document.getElementById('btn'));
  }

  isMenuOpen = false;
  addOverflow = false;

  showLevel1 = false;
  showLevel2 = false;

  isShow = true;
  open = false;

  tabIndex = -1;

  toggleColorbtn1(index) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorbtn2(index) {
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
  }
}