import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ContractDetailsVm } from 'src/app/Services/propertypros/models';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { CommisionModel } from '../selling-my-property.component';
import Swal from 'sweetalert2';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class ContractDetailsComponent {
  constructor(public dialog: MatDialog) { }

  emailPattern: string = GlobalConstants.emailPattern;
  tableData: CommisionModel[] = GlobalConstants.commisionStructureTableData;
  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() contractDetailsEvent = new EventEmitter<ContractDetailsVm>();
  @Input() ownershipDetailsId: number = 0;

  propertyGoverenedByAssociation: number = 0;
  leviesUpToDate: number = 0;
  useInHouseLegalTeam: number = 0;
  recommendedByPartnerAgent: number = 0;
  connectedToUrbanPlanner: number = 0;

  form: ContractDetailsVm = {
    propertyGoverenedByAssociation: false,
    leviesUpToDate: false,
    useInHouseLegalTeam: false,
    conveyancer: {
      companyName: '',
      personalDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        phoneNumberConfirmed: false,
        landline: '',
        title: '',
      }
    },
    agent: {
      estateAgency: '',
      personalDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        phoneNumberConfirmed: false,
        landline: '',
        title: '',
      }
    },
    urbanPlanner: {
      plannerLocation: '',
      personalDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        phoneNumberConfirmed: false,
        landline: '',
        title: '',
      }
    },
    acceptCommisionStructure: false,
    commitCommisionStructure: false,
    connectedToUrbanPlanner: false,
    recommendedByPartnerAgent: false,
  };

  basicYesNoOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];
  useInHouseLegalTeamOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I wish to appoint my own conveyancer' }
  ];
  recommendedByPartnerAgentOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, I discovered Property Matrix myself' }
  ];
  connectedToUrbanPlannerOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, Property Matrix can assign a planner to investigate my property' }
  ];

  submitForm() {
    this.form.connectedToUrbanPlanner = this.connectedToUrbanPlanner == 1;
    this.form.recommendedByPartnerAgent = this.recommendedByPartnerAgent == 1;
    this.form.propertyGoverenedByAssociation = this.propertyGoverenedByAssociation == 1;
    this.form.leviesUpToDate = this.leviesUpToDate == 1;
    this.form.useInHouseLegalTeam = this.useInHouseLegalTeam == 1;
    this.gotoUploadDocuments();
  }

  gotoUploadDocuments() {
    this.pageNumberEvent.emit(4);
    this.contractDetailsEvent.emit(this.form);
    GlobalMethods.scrollToTop();
  }

  openCollaborationDialog() {
    const dialogRef = this.dialog.open(CollaborationDialog, {
      width: '75vw',
      height: '55vh',
      data: { agree: false }
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitForm();
      }
    });
  }
}

@Component({
  selector: 'contract-details-collaboration-dialog',
  templateUrl: './contract-details-collaboration-dialog.html',
  styleUrls: ['./contract-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class CollaborationDialog {
  constructor(
    public dialogRef: MatDialogRef<CollaborationDialog>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agree() {
    this.dialogRef.close({agree: true});
  }
}