import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conveyancer-option',
  templateUrl: './conveyancer-option.component.html',
  styleUrls: ['./conveyancer-option.component.scss']
})
export class ConveyancerOptionComponent {

  constructor() { }

}
