import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PropertiProSharedService } from '../properti-pro-shared.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: PropertiProSharedService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError( err => {
      if([401, 403].indexOf(err.status) !== -1) {
      
        this.authService.logout();
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}
