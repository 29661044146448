import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { CheckboxVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-addendum-one',
  templateUrl: './addendum-one.component.html',
  styleUrls: ['./addendum-one.component.scss']
})
export class PropertyListingsAddendumOneComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Input() reportDetails = null;
  customListing: boolean;

  fileUploader = new FileUploader();

  key: number = 1;
  counter: string[] = [
    "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
  ]

  propertySections: CheckboxVm[] = []

  sections: CheckboxVm[] = []
  demoSections: CheckboxVm[] = [
    { id: 1, value: 'AREA DEMOGRAPHIC', checked: true },
    { id: 2, value: 'DISTANCES TO EMERGENCY SERVICES', checked: true },
    { id: 3, value: 'BUILDING RESTRICTION AREAS', checked: true },
    { id: 4, value: 'DOLOMITE RISK', checked: true },
    { id: 5, value: 'SITE ACCESS', checked: true }
  ]

  uploadBlocks: any[] = []

  constructor(private sharedService: PropertiProSharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPropertyParams();
    for(let i = 0; i < this.reportDetails.planningReport.propertyInformation.length; i++){
      this.propertySections.push({ id: i + 1, value: this.reportDetails.propertyTitle[i], checked: true });
      this.uploadBlocks.push([
        { key: 1, value: 'EXISTING (AND FUTURE) ROADS', file: null, picture: null, fileId: '' },
        { key: 2, value: 'EXISTING (AND FUTURE) BUS ROUTES', file: null, picture: null, fileId: '' },
        { key: 3, value: 'EXISTING (AND FUTURE) RAILWAY LINES', file: null, picture: null, fileId: '' }
      ]);
      if(this.reportDetails.addendumOne.imageDescriptions[i] == null){
        this.reportDetails.addendumOne.imageDescriptions[i] = [];
        this.reportDetails.addendumOne.imageDescriptions[i].push({imageDescription: ""},{imageDescription: ""},{imageDescription: ""});
      }
    }
    this.assignPictures();
    for(let j = 0; j < this.demoSections.length; j++){
      let newSection: CheckboxVm = { id: this.key, value: this.demoSections[j].value, checked: true }
      this.key++;
      this.sections.push(newSection);
    }
  }
  
  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.customListing = params.customListing === 'true';
      }
    );
  }

  assignPictures() {
    for (let i = 0; i < this.uploadBlocks.length; i++){
      for (let index = 0; index < this.uploadBlocks[i].length; index++) {
        let block = this.uploadBlocks[i][index];
        let file = this.reportDetails.addendumOne?.showFiles[i]?.find(f => f.uploadTypeId == index + 1);
        if(file != undefined){
          block.picture = file?.picture ?? null
          block.fileId = file?.fileId ?? null
        }
      }
    }
  }

  async deleteFileFromDb(index: any, sectionIndex: any) {
    let result = await this.sharedService.deletePropertyListingFile(this.uploadBlocks[sectionIndex][index].fileId, 2, this.customListing);
    if(result){
      this.uploadBlocks[sectionIndex][index].file = null;
      this.uploadBlocks[sectionIndex][index].picture = null;
    }
  }

  collapseOrExpandSection(section: CheckboxVm) {
    section.checked = !section.checked
  }

  referencesChecked = true;
  collapseOrExpandReferences() {
    this.referencesChecked = !this.referencesChecked
  }

  selectUpload(files, id, i) {
    this.fileUploader.specificFileBrowseHandler(files, id, i);
    this.updateShownBlocks(files[0], id, i);
  }

  dropUpload($event, id, i) {
    this.fileUploader.specificOnFileDropped($event, id, i);
    this.updateShownBlocks($event[0], id, i);
  }

  deleteFile(block, blockIndex, i) {
    this.uploadBlocks[i][blockIndex].file = null;
    let item = this.fileUploader.files.find(f => f.id == block.key);
    let fileIndex = this.fileUploader.files.indexOf(item);
    this.fileUploader.deleteFile(fileIndex);
  }

  updateShownBlocks(file, id, i) {
    let uploadBlock = this.uploadBlocks[i][id - 1]
    uploadBlock.show = this.fileUploader.files.filter(f => f.id == id).length > 0;
    uploadBlock.file = file;
  }

  goToAddendumTwo() {
    this.reportDetails.addendumOne.files = this.fileUploader.files;
    this.pageNumberEvent.emit(3);
  }

  addRow(index: number, type: any, data: any): void {
    type.push(data);
    setTimeout(() => {
      document.getElementById(`${typeof(type)}-${index + 1}`)?.focus();
    }, 5);
  }

  deleteRow(index: number, type: any): void {
    type = type.splice(index, 1);
  }
}
