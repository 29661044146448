<main class="background-image">
<form id="finishForm" novalidate #finishForm="ngForm" class="submission-form">
    <div class="text-center paragraph pt-2 container">
        <div class="row">
            <div class="col-12">
                <p>Thank you for your time</p>
                <p><b>Property Matrix</b> will prepare a draft offer to purchase contract for your perusal, based on
                    the information provided. Please keep an eye on your inbox. You will also be notified via sms</p>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-12 pt-3">
                <button id="btnSaveFinal" type="submit" class="button heading-4" (click)="finish()">NOTED</button>
            </div>
        </div>
    </div>
</form>
</main>