import { Component } from '@angular/core';
import { Validators } from 'src/app/models/validation/validators';
import { Router } from '@angular/router';
import { RegisterUserVm } from 'src/app/Services/propertypros/models';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AuthenticateService } from 'src/app/Services/propertypros/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { HttpStatusCode } from '@angular/common/http';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss', '../../../css/2-modules/_forms.scss']
})
export class RegisterComponent {

  constructor(private router: Router, private authService: AuthenticateService) {

  }

  validators = new Validators();
  passwordPattern: string = GlobalConstants.passwordPattern;
  emailPattern: string = GlobalConstants.emailPattern;
  confirmConsent: boolean = false;
  loading: boolean = false;
  isRsaResident: number = 1;

  form: RegisterUserVm = {
    personalDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false,
      phoneNumberConfirmed: false,
      landline: '',
      title: '',
      confirmEmail: '',
      confirmPhoneNumber: ''
    },
    loginDetails: {
      email: '',
      password: '',
      passwordConfirm: '',
    }
  }

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  register() {
    this.loading = true;
    this.form.loginDetails.email = this.form.personalDetails.emailAddress;
    this.form.personalDetails.rsa_Resident = this.isRsaResident == 1;
    this.authService.apiV1AuthenticateRegisterUserPost$Response({ body: this.form })
      .subscribe({
        next: (result: any) => {
          const resultBody = JSON.parse(result.body);
          if(resultBody.success){
            GlobalMethods.tinySuccessAlert('Success', 'You have successfully registered with Property Matrix. Keep an eye on your email inbox.')
            .then((reason) => {
              this.router.navigateByUrl('/login');
            })
            if(resultBody.userExists){
              GlobalMethods.tinyErrorAlert('Failed Registration', 'Account already exist.');
            }
          }else{
            GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.");
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "An unknown error occurred");
          this.loading = false;
        }
      })
  }

}

