<mat-sidenav-container (backdropClick)="close()">
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 0}"
                (click)="toggleColorbtn1(0)">LEVEL 1</button>
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 1}"
                (click)="toggleColorbtn2(1)">LEVEL 2</button>
        </section>
        <div class="container-side sidenav-container container" fxLayout="column" fxLayoutAlign="space-between start"
            *ngIf="showLevel1">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Our LEVEL 1 feasibility study generally incorporates the following
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" id="btnClose" class="btn-close btn-close-white " (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Note: Every site and every development opportunity are unique and will be treated on its own
                    merits. The Level 1 study excludes a visit to the site.
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 heading-3-light">
                    PHASE 1: DATA COLLECTION
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Land-use rights investigation
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Determining current and prospected zoning, acceptable future densities, exact
                            boundaries, servitudes,
                            building restrictions etc. to determine the potential as well as the possible
                            constraints of your property
                            and/or proposed development
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Location Analysis
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Desktop analysis: site and access to site
                        </li>
                        <li>
                            Driven time analysis
                        </li>
                        <li>
                            Focus area delineation
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Demographic Overview
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Socio-economic overview of residents within the market focus area
                        </li>
                        <li>
                            Official Census and Community Survey data from StatsSA
                        </li>
                        <li>
                            Includes age, race, population, household income, level of education and
                            employment.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-3-light ">
                    PHASE 2: DATA ANALYSIS
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Market trends
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Number of households
                        </li>
                        <li>
                            Household income distribution
                        </li>
                        <li>
                            Residential real estate market trends
                        </li>
                        <li>
                            Micro economic trends (industrial projects)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Detailed overview of comparative supply
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Location and overview of existing and planned comparative supply within the
                            catchment area.
                        </li>
                        <li>
                            Location and overview of new and proposed residential and commercial
                            developments within the
                            catchment area.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-3-light">
                    PHASE 3: FULL FEASIBILITY ANALYSIS
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Various elements of the proposed development are compared against the
                            information collected.
                        </li>
                        <li>
                            The results will allow for a professional assessment of the potential
                            feasibility of the proposed project.
                        </li>
                        <li>
                            Comments and recommendations will be included in the report.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-3-light">
                    DELIVERABLES AND TIMEFRAMES
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            15 to 20 page report
                        </li>
                        <li>
                            3 to 4 week delivery
                        </li>
                        <li>
                            Fees are normally between R15k and R20k, depending on specific requirements
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <button class="heading-3 button-light"
                        [routerLink]="['/feasibilityStudies/feasibilityStudiesRequest/']" type="button">REQUEST</button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" fxLayout="column" fxLayoutAlign="space-between start"
            *ngIf="showLevel2">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Our LEVEL 2 feasibility study generally incorporates the following:
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white " (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Note: Every site and every development opportunity are unique and will be treated on its own
                    merits. The Level 2 study includes a visit to the site and surrounding area
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 heading-3-light">
                    PHASE 1: DATA COLLECTION
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Land-use rights investigation
                </div>
                <div class="col-12">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Determining current and prospected zoning, acceptable future densities, exact
                            boundaries, servitudes,
                            building restrictions etc. to determine the potential as well as the possible
                            constraints of your property
                            and/or proposed development
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    Location Analysis
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Site visit and ease-of-access analysis
                        </li>
                        <li>
                            Visit to surrounding areas
                        </li>
                        <li>
                            Drive time analysis
                        </li>
                        <li>
                            Focus area delineation.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    Demographic Overview
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Socio-economic overview of residents within the market focus area
                        </li>
                        <li>
                            Official Census and Community Survey data from StatsSA
                        </li>
                        <li>
                            Includes age, race, population, household income, level of education and
                            employment.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-3-light">
                    PHASE 2: DATA ANALYSIS
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Market trends
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Number of households
                        </li>
                        <li>
                            Household income distribution
                        </li>
                        <li>
                            Residential real estate market trends
                        </li>
                        <li>
                            Micro economic trends (industrial projects)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    Detailed overview of comparative supply
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Location and overview of existing and planned comparative supply within the
                            catchment area.
                        </li>
                        <li>
                            Location and overview of new and proposed residential and commercial
                            developments within the
                            catchment area.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-3-light">
                    PHASE 3: FULL FEASIBILITY ANALYSIS
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Various elements of the proposed development are compared against the
                            information collected.
                        </li>
                        <li>
                            The results will allow for a professional assessment of the potential
                            feasibility of the proposed project.
                        </li>
                        <li>
                            Comments and recommendations will be included in the report.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    DELIVERABLES AND TIMEFRAMES
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>
                            Visit the property: analysis of the site and surrounding areas
                        </li>
                        <li>
                            20 to 25-page report in PDF format
                        </li>
                        <li>
                            4 to 6 weeks for delivery
                        </li>
                        <li>
                            Fees are normally between R35k and R60k, depending on specific requirements
                            (request below for a
                            project-specific quote).
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph-light">
                        Request below and one of our development economists will give you a call to discuss your
                        specific site and
                        project scope to provide you with a project-specific quote.
                    </p>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <button class="heading-3 button-light"
                        [routerLink]="['/feasibilityStudies/feasibilityStudiesRequest/']" type="button">REQUEST</button>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <main class="background-image container-fluid">
        <section class="content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading-1 text-center">
                            FEASIBILITY STUDIES
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="paragraph">
                            Feasibility studies conducted by <span class="heading-5">Property Matrix</span> start
                            with determining the desirability of the
                            project. Then the feasibility is investigated, providing insight into the potential
                            viability of
                            each project.
                            'Feasibility' refers to the degree or state of being easily, conveniently, or reasonably
                            done.
                            If a project is 'feasible,' it means that we can do it, make it, or achieve it. It is
                            'doable'.
                            The potential viability of a project is
                            concerned with the risk and the potential of survival of the project. Will this project
                            survive
                            once it has been developed?
                        <div class="heading-5">
                            The purpose of feasibility studies is to:
                        </div>
                        <ul class="montserrat-bullets paragraph">
                            <li>
                                assess the practicality of a project;
                            </li>
                            <li>
                                identify potential flaws or constraints; and
                            </li>
                            <li>
                                determine the sustainability of the proposed development, in relation to its
                                location
                                and
                                surrounding
                                economic activities (current supply, market trends, and household expenditure
                                patterns).
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="heading-2 text-center pb-4">
                WE OFFER TWO LEVELS OF FEASIBILITY STUDIES:
            </div>
            <div class="container">
                <div class="row">
                    <div class="row">
                        <div class="col-6">
                            <div class="numbers-right-border">
                                <div class="heading-2 text-center">
                                    LEVEL
                                </div>
                                <div class="numbers text-center">
                                    1
                                </div>
                                <div class="align-height">
                                    <div class="heading-5">
                                        Desktop feasibility analysis:
                                    </div>
                                    <ul class="montserrat-bullets paragraph">
                                        <li>
                                            15 to 20 page report
                                        </li>
                                        <li>
                                            Desktop location analysis
                                        </li>
                                        <li>
                                            Demographic overview
                                        </li>
                                        <li>
                                            Market trend analysis
                                        </li>
                                        <li>
                                            Comparative supply analysis
                                        </li>
                                        <li>
                                            3 to 4 week delivery
                                        </li>
                                    </ul>
                                </div>
                                <div class="text-center paragraph">
                                    R15k - R20k
                                </div>
                                <div class="text-center">
                                    <button class="small-button button" id="LearnMore1"
                                        [ngClass]="{'toggle-btn-color': newColorbtn1}"
                                        (click)="toggleColorbtn1(0)">LEARN
                                        MORE</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="heading-3 text-center">
                                LEVEL
                            </div>
                            <div class="numbers text-center">
                                2
                            </div>
                            <div class="align-height">
                                <div class="heading-5">
                                    Full feasibility analysis:
                                </div>
                                <ul class="montserrat-bullets paragraph">
                                    <li>
                                        20 to 25 page report
                                    </li>
                                    <li>
                                        Includes all elements of Level 1<br />
                                        PLUS a visit to and analysis of<br />
                                        the site and surrounds
                                    </li>
                                    <li>
                                        4 to 6 week delivery
                                    </li>
                                </ul>
                            </div>
                            <div class="text-center paragraph">
                                R35k - R60k
                            </div>
                            <div class="text-center">
                                <button class="small-button button" id="btn" id="LearnMore2"
                                    [ngClass]="{'toggle-btn-color': newColorbtn2}" (click)="toggleColorbtn2(1)">LEARN
                                    MORE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</mat-sidenav-container>