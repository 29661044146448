import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListingService } from 'src/app/Services/propertypros/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';

@Component({
  selector: 'app-workflow-allocate',
  templateUrl: './workflow-allocate.component.html',
  styleUrls: ['./workflow-allocate.component.scss']
})
export class WorkflowAllocateComponent implements OnInit {
  listingAddress: string;
  propertyId: number;
  customListing: boolean;
  
  user = this.sharedService.getUserModel();

  assignableUsers;
  listing = {juniorPlanner: {id: null}, seniorPlanner: {id: null}};

  constructor(private route: ActivatedRoute, private router: Router, private listingService: PropertyListingService, private sharedService: PropertiProSharedService) { }

  ngOnInit(): void {
    this.getPropertyParams();
    this.getAssignableUsers();
    this.getPropertyListing();
  }

  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;
      }
    );
  }

  goBack() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['admin/property-listings/workflow'],
        {
          queryParams: {
            propertyId: this.propertyId,
            listingAddress: this.listingAddress,
            customListing: this.customListing
          }
        })
    );
    window.open(url, "_self");
  }

  getPropertyListing() {
    var username: string = null;
    if(['SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner', 'NetworkPlanner'].includes(this.user.userRole)){
      username = this.user.userName;
    }
    this.listingService.apiV1PropertyListingGetPropertyListingsGet$Response({username: username, id: this.propertyId }).subscribe({
      next: async (result: any) => {
        this.listing = JSON.parse(result.body)[0];
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem getting property listings");
      }
    })
  }

  getAssignableUsers() {
    this.listingService.apiV1PropertyListingGetAssignableUsersGet$Response().subscribe({
      next: (result: any) => {
        this.assignableUsers = JSON.parse(result.body);
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem getting assignable users");
      }
    })
  }

  assignUserRole($event: string, roleType: number) {
    if($event != "null"){
      this.listingService.apiV1PropertyListingAssignListingRoleGet$Response({ listingId: this.propertyId, userId: $event, roleType: roleType }).subscribe({
        next: (result: any) => {
  
        },
        error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Problem assigning role");
        }
      })
    }
  }
}
