import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { ConveyancerFormVm } from 'src/app/Services/propertypros/models';
import { ConveyancerService, UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-conveyancer-register-form',
  templateUrl: './conveyancer-register-form.component.html',
  styleUrls: ['./conveyancer-register-form.component.scss']
})
export class ConveyancerRegisterFormComponent implements OnInit {

  pageNumber: Number = 1;
  loading: boolean = false;
  user: any = null;

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'PERSONAL INFORMATION' },
    { key: 2, value: 'FIRM INFORMATION' },
    { key: 3, value: 'SUBMIT DOCUMENTS' },
    { key: 4, value: 'SIGN CONTRACT' },
  ];

  form: ConveyancerFormVm = {
    conveyancerFirmInformation: null,
    conveyancerPersonalInformation: null,
    paths: null,
    username: ''
  }

  constructor(private router: Router, private conveyancerService: ConveyancerService, private sharedService: PropertiProSharedService, private userService: UserService) { }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
    GlobalMethods.scrollToTop();
    sessionStorage.removeItem('originalUrl');
    this.user = sessionStorage.getItem('user');
    if (!this.user) {
      this.router.navigateByUrl('/login')
    } else {
      this.IsNormalUser();
    }
  }

  submitForm() {
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.conveyancerService.apiV1ConveyancerCaptureConveyancerDetailsPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all the required fields are completed').then(result => {
              this.pageNumber = 3;
            });
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all the required fields are completed').then(result => {
            this.pageNumber = 3;
          })
          this.loading = false;
        }
      })
  }

  changeUserRole(){
    let user = this.sharedService.getUserModel();
    user.userRole = UserRoles.conveyancer;
    sessionStorage.setItem("user", JSON.stringify(user));
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
    this.addOrRemoveBackgroundImage();
  }

  addOrRemoveBackgroundImage(){
    if(this.pageNumber == 4){
      document.getElementById('main').classList.add('background-image');
    }else{
      document.getElementById('main').classList.remove('background-image');
    }
  }

  assignConveyancerPersonalInformation($event) {
    this.form.conveyancerPersonalInformation = $event;
  }

  assignConveyancerInformation($event) {
    this.form.conveyancerFirmInformation = $event;

  }

  assignFiles($event) {
    this.form.paths = $event;
    this.submitForm();
  }

  IsNormalUser() {
    const userModel = this.sharedService.getUserModel();

    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
    .subscribe({
      next: (result) => {
        if (result.status == HttpStatusCode.Conflict) {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Conveyancer').then(result => {
            this.router.navigateByUrl('/home');
          })
        }
        this.loading = false;
      }
      , error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Conveyancer').then(result => {
          this.router.navigateByUrl('/home');
        })
        this.loading = false;
      }
    })
  }

}
