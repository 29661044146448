<div class="approve-user-component">
    <form [formGroup]="form">
        <mat-button class="close-button" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </mat-button>
        <mat-card-header>
            <mat-card-title>
                <h1>Network Planners Approval</h1>
            </mat-card-title>
            <mat-card-subtitle>
                <h3>Verification of planners</h3>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="col-12">
                <div class="checkbox-container">
                    <strong><mat-checkbox class="checkbox" [formControl]="form.controls['sacplanCertificate']">SACPLAN
                            certified: renewed</mat-checkbox></strong>
                </div>
                <div class="checkbox-container">
                    <strong><mat-checkbox class="checkbox" [formControl]="form.controls['townPlanningDegree']">Town
                            planning degree certificate</mat-checkbox></strong>
                </div>
                <div class="checkbox-container">
                    <strong><mat-checkbox class="checkbox" [formControl]="form.controls['relevantExperience']">Relevant
                            experience: 5 years plus</mat-checkbox></strong>
                </div>
                <div class="checkbox-container">
                    <strong><mat-checkbox class="checkbox" [formControl]="form.controls['idOrPassportProvided']">Copy of
                            ID (RSA resident) or Passport (non-resident)</mat-checkbox></strong>
                </div>
            </div>
            <div class="col-12 buttons-container">
                <div class="buttons-spacing">
                    <mat-button class="cancel-button" (click)="denyApplication()">
                        <span>Deny</span>
                    </mat-button>
                </div>
                <div class="buttons-spacing">
                    <mat-button class="confirm-button" (click)="confirmApplication()">
                        <span>Confirm</span>
                    </mat-button>
                </div>
            </div>
        </mat-card-content>
    </form>
</div>