import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PropertiProSharedService } from './properti-pro-shared.service';
import { JwtInterceptor } from './helper/jwtInterceptor';
import { ErrorInterceptor } from './helper/errorInterceptor';
import { Ng9PasswordStrengthBarModule } from 'ng9-password-strength-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PropertyInvestigationsComponent } from './pages/property-investigations/property-investigations.component';
import { PropertyValuationsComponent } from './pages/property-valuations/property-valuations.component';
import { FeasibilityStudiesComponent } from './pages/feasibility-studies/feasibility-studies.component';
import { TimePickerDialog } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { MarketGapAnalysisComponent } from './pages/market-gap-analysis/market-gap-analysis.component';
import { TownPlanningApplicationsComponent } from './pages/town-planning-applications/town-planning-applications.component';
import { Level1InvestigationsComponent } from './pages/property-investigations/level1-investigations/level1-investigations.component';
import { ShareInOurDatabaseComponent } from './pages/share-in-our-database/share-in-our-database.component';
import { Level2InvestigationsComponent } from './pages/property-investigations/level2-investigations/level2-investigations.component';
import { Level3InvestigationsComponent, TimePickerDialogInvestigation } from './pages/property-investigations/level3-investigations/level3-investigations.component';
import { PlannerComponent } from './pages/register/planner/planner.component';
import { EstateAgentComponent } from './pages/register/estate-agent/estate-agent.component';
import { ValuerComponent } from './pages/register/valuer/valuer.component';
import { FeasibilityStudiesRequestComponent } from './pages/feasibility-studies/feasibility-studies-request/feasibility-studies-request/feasibility-studies-request.component';
import { TownPlanningApplicationRequestComponent, TimePickerDialogPlanning } from './pages/town-planning-applications/town-planning-applications-request/town-planning-application-request/town-planning-application-request.component';
import { PropertyValuationRequestComponent, TimePickerDialogValuation } from './pages/property-valuations/property-valuation-request/property-valuation-request/property-valuation-request.component';
import { CollaborationAgreementComponent } from './pages/collaboration-agreement/collaboration-agreement.component';
import { PlannerRegistrationComponent } from './pages/register/planner/registration/planner-registration/planner-registration.component';
import { LoginValuersComponent } from './pages/login/login-valuers/login-valuers.component';
import { RegisterValuerComponent } from './pages/register/valuer/register-valuer/register-valuer.component';
import { ContactUSComponent } from './pages/contact/contact-us/contact-us.component';
import { ProgressComponent } from './pages/progress/progress/progress.component';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { EstateAgentAgreementComponent } from './pages/register/estate-agent/agreement/estate-agent-agreement/estate-agent-agreement.component';
import { EstateAgentRegistrationComponent } from './pages/register/estate-agent/registration/estate-agent-registration/estate-agent-registration.component';
import { ConveyancerOptionComponent } from './pages/register/conveyancer/option/conveyancer-option/conveyancer-option.component';
import { ConveyancerPersonalCompanyDetailsComponent } from './pages/register/conveyancer/option/option_B/conveyancer-personal-company-details/conveyancer-personal-company-details.component';
import { ConveyancerRegistrationComponent } from './pages/register/conveyancer/registration/conveyancer-registration/conveyancer-registration.component';
import { ConveyancerPersonalInfoComponent } from './pages/register/conveyancer/option/option_B/steps/conveyancer-personal-info/conveyancer-personal-info.component';
import { RouterModule } from '@angular/router';
import { OptionAComponent } from './pages/register/conveyancer/option/option-a/option-a.component';
import { MatMenuModule } from '@angular/material/menu';
import { propertyprosApiModule } from './Services/propertypros/propertypros-api.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { environment } from '../environments/environment';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { SellingMyPropertyComponent } from './pages/selling-my-property/selling-my-property.component';
import { CommissionStructureDialog } from './pages/selling-my-property/selling-my-property.component';
import { OwnershipDetailsComponent } from './pages/selling-my-property/ownership-details/ownership-details.component';
import { CoOwnerDialog } from './pages/selling-my-property/ownership-details/ownership-details.component';
import { PropertyDetailsComponent, PropertyDetailsDialog } from './pages/selling-my-property/property-details/property-details.component';
import { PropertyFormComponent } from './pages/selling-my-property/property-form/property-form.component';
import { ContractDetailsComponent } from './pages/selling-my-property/contract-details/contract-details.component';
import { UploadDocumentsComponent } from './pages/selling-my-property/upload-documents/upload-documents.component';
import { UploadDocumentsReportDialog } from './pages/selling-my-property/upload-documents/upload-documents.component';
import { SubmissionCompletedComponent } from './pages/selling-my-property/submission-completed/submission-completed.component';
import { PlannerRegisterFormComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form.component';
import { PlannerRegisterFormInformationComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-information/planner-register-form-information.component';
import { PlannerRegisterFormExtendedNetworkComponent, UrbanPlannerExtendedNetworkProfessionalDialog } from './pages/register/planner/registration/planner-register-form/planner-register-form-extended-network/planner-register-form-extended-network.component';
import { PlannerRegisterFormDocumentsComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-documents/planner-register-form-documents.component';
import { PlannerRegisterFormContractComponent } from './pages/register/planner/registration/planner-register-form/planner-register-form-contract/planner-register-form-contract.component';
import { EstateAgentRegisterFormComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-register-form.component';
import { EstateAgentSignContractComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-sign-contract/estate-agent-sign-contract.component';
import { EstateAgentPersonalInformationComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-personal-information/estate-agent-personal-information.component';
import { EstateAgentCompanyInformationComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-company-information/estate-agent-company-information.component';
import { EstateAgentSubmitDocumentsComponent } from './pages/register/estate-agent/estate-agent-register-form/estate-agent-submit-documents/estate-agent-submit-documents.component';
import { ConveyancerRegisterFormComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-register-form.component';
import { ConveyancerFirmInformationComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-firm-information/conveyancer-firm-information.component';
import { ConveyancerPersonalInformationComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-personal-information/conveyancer-personal-information.component';
import { ConveyancerSubmitDocumentsComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-submit-documents/conveyancer-submit-documents.component';
import { ConveyancerSignContractComponent } from './pages/register/conveyancer/conveyancer-register-form/conveyancer-sign-contract/conveyancer-sign-contract.component';
import { ConveyancerRegisterDetailsComponent } from './pages/register/conveyancer/conveyancer-register-details/conveyancer-register-details.component';
import { ValuerInformationComponent } from './pages/register/valuer/valuer-register-form/valuer-information/valuer-information.component';
import { ValuerSubmitDocumentsComponent } from './pages/register/valuer/valuer-register-form/valuer-submit-documents/valuer-submit-documents.component';
import { ValuerSignContractComponent } from './pages/register/valuer/valuer-register-form/valuer-sign-contract/valuer-sign-contract.component';
import { ValuerStepsComponent } from './pages/register/valuer/valuer-steps/valuer-steps.component';
import { ValuerRegisterFormComponent } from './pages/register/valuer/valuer-register-form/valuer-register-form.component';
import { MapComponent } from './map/map.component';
import { SiodRegisterComponent } from './pages/share-in-our-database/siod-register/siod-register.component';
import { SiodFormComponent } from './pages/share-in-our-database/siod-form/siod-form.component';
import { SiodSubscribeComponent } from './pages/share-in-our-database/siod-subscribe/siod-subscribe.component';
import { PropertyForSaleComponent } from './pages/property-for-sale/property-for-sale.component';
import { PropertyForSaleSearchComponent } from './pages/property-for-sale/property-for-sale-search/property-for-sale-search.component';
import { PropertyForSaleOptionsComponent } from './pages/property-for-sale/property-for-sale-options/property-for-sale-options.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PropertyForSalePurchaseDocumentsDialogComponent } from './pages/property-for-sale/property-for-sale-options/property-for-sale-purchase-documents-dialog/property-for-sale-purchase-documents-dialog.component';
import { PropertyForSaleCheckoutComponent } from './pages/property-for-sale/property-for-sale-checkout/property-for-sale-checkout.component';
import { ContactEstateAgentComponent } from './pages/contact/contact-estate-agent/contact-estate-agent.component';
import { ContactTownPlannerComponent } from './pages/contact/contact-town-planner/contact-town-planner.component';
import { PropertyForSaleOfferToPurchaseComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase/property-for-sale-offer-to-purchase.component';
import { PropertyForSaleOfferToPurchaseWithConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-with-conditions/property-for-sale-offer-to-purchase-with-conditions.component';
import { PropertyForSaleOfferToPurchaseFormComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-offer-to-purchase-form.component';
import { PropertyForSaleFormConditionsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-conditions/property-for-sale-form-conditions.component';
import { PropertyForSaleFormPurchasePriceComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-purchase-price/property-for-sale-form-purchase-price.component';
import { PropertyForSaleFormPurchaserDetailsComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form/property-for-sale-form-purchaser-details/property-for-sale-form-purchaser-details.component';
import { SiodConfirmComponent } from './pages/share-in-our-database/siod-confirm/siod-confirm.component';
import { SiodAddComponent } from './pages/share-in-our-database/siod-add/siod-add.component';
import { PropertyForSaleOfferToPurchaseFormEndComponent } from './pages/property-for-sale/property-for-sale-offer-to-purchase-form-end/property-for-sale-offer-to-purchase-form-end.component';
import { AdminHomeComponent } from './pages/admin-portal/admin-home/admin-home.component';
import { AdminMyProfileComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile.component';
import { AdminMyProfilePersonalDetailsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-personal-details/admin-my-profile-personal-details.component';
import { AdminMyProfileWorkDetailsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-work-details/admin-my-profile-work-details.component';
import { AdminMyProfileDocumentsComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-documents/admin-my-profile-documents.component';
import { AdminMyProfilePersonalDetailsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-personal-details/admin-my-profile-personal-details-edit-dialog/admin-my-profile-personal-details-edit-dialog.component';
import { AdminMyProfileWorkDetailsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-work-details/admin-my-profile-work-details-edit-dialog/admin-my-profile-work-details-edit-dialog.component';
import { AdminMyProfileDocumentsEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-documents/admin-my-profile-documents-edit-dialog/admin-my-profile-documents-edit-dialog.component';
import { AdminMyProfileSiodComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-siod/admin-my-profile-siod.component';
import { AdminMyProfileFavouritesComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-favourites/admin-my-profile-favourites.component';
import { AdminMyProfileBillingComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-billing/admin-my-profile-billing.component';
import { AdminMyProfileBillingEditDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-billing/admin-my-profile-billing-edit-dialog/admin-my-profile-billing-edit-dialog.component';
import { AdminMyProfileExtendedNetworkComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-extended-network/admin-my-profile-extended-network.component';
import { AdminMyProfileExtendedNetworkDialogComponent } from './pages/admin-portal/admin-my-profile/admin-my-profile-extended-network/admin-my-profile-extended-network-dialog/admin-my-profile-extended-network-dialog.component';
import { AdminPropertyListingsComponent, PropertyListingCustomListingDialog } from './pages/admin-portal/admin-property-listings/admin-property-listings.component';
import { AdminPropertyListingsWorkflowTasksComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/property-listings-workflow-tasks.component';
import { AdminPropertyListingsWorkflowTasksPropertyDetailsComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-property-details/workflow-property-details.component';
import { AdminPropertyListingsWorkflowComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow.component';
import { WorkflowAddendumsComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-addendums/workflow-addendums.component';
import { WorkflowCcfComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-ccf/workflow-ccf.component';
import { PropertyListingsReportFormComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/property-listings-report-form.component';
import { PropertyListingsPlanningReportComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/planning-report/planning-report.component';
import { PropertyListingsAddendumOneComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/addendum-one/addendum-one.component';
import { PropertyListingsAddendumTwoComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/addendum-two/addendum-two.component';
import { PropertyListingsAddendumThreeComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/addendum-three/addendum-three.component';
import { PropertyListingsValuationComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-report-form/valuation/valuation.component';
import { ReportPdfComponent } from './pages/tests/report-pdf/report-pdf.component';
import { RegisterChoicesComponent } from './pages/register/register-choices/register-choices.component';
import { AdminManageUsersComponent } from './pages/admin-portal/admin-manage-users/admin-manage-users.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddUserComponent } from './pages/admin-portal/admin-manage-users/add-user/add-user.component';
import { EditUserComponent } from './pages/admin-portal/admin-manage-users/edit-user/edit-user.component';
import { RemoveUserComponent } from './pages/admin-portal/admin-manage-users/remove-user/remove-user.component';
import { ApproveUserComponent } from './pages/admin-portal/admin-manage-users/approve-user/approve-user.component';
import { VerifyUserComponent } from './pages/admin-portal/admin-manage-users/verify-user/verify-user.component';
import { UserRoleDirective } from './Services/user-role.directive';
import { WorkflowAllocateComponent } from './pages/admin-portal/admin-property-listings/property-listings-workflow/property-listings-workflow-tasks/workflow-allocate/workflow-allocate.component';
import { RemoveLeadingZerosPipe } from './pipes/remove-leading-zeros.pipe';

@NgModule({
  imports: [
    MatDialogModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatExpansionModule,
    HttpClientModule,
    CommonModule,
    Ng9PasswordStrengthBarModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSelectModule,
    MatCardModule,
    ScrollingModule,
    RouterModule,
    MatMenuModule,
    PdfJsViewerModule,
    propertyprosApiModule.forRoot({
      rootUrl: environment.APIUrl
    })
  ],

  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AboutUsComponent,
    PropertyInvestigationsComponent,
    UserRoleDirective,
    PropertyValuationsComponent,
    SellingMyPropertyComponent,
    FeasibilityStudiesComponent,
    TimePickerDialog,
    TimePickerDialogValuation,
    MarketGapAnalysisComponent,
    TownPlanningApplicationsComponent,
    Level1InvestigationsComponent,
    ShareInOurDatabaseComponent,
    Level2InvestigationsComponent,
    Level3InvestigationsComponent,
    TimePickerDialogInvestigation,
    PlannerComponent,
    EstateAgentComponent,
    ValuerComponent,
    FeasibilityStudiesRequestComponent,
    TownPlanningApplicationRequestComponent,
    TimePickerDialogPlanning,
    PropertyValuationRequestComponent,
    CollaborationAgreementComponent,
    PlannerRegistrationComponent,
    ContactUSComponent,
    ProgressComponent,
    DragAndDropDirective,
    LoginValuersComponent,
    RegisterValuerComponent,
    EstateAgentAgreementComponent,
    UrbanPlannerExtendedNetworkProfessionalDialog,
    EstateAgentRegistrationComponent,
    ConveyancerOptionComponent,
    ConveyancerPersonalCompanyDetailsComponent,
    ConveyancerRegistrationComponent,
    ConveyancerPersonalInfoComponent,
    OptionAComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    SellingMyPropertyComponent,
    CommissionStructureDialog,
    OwnershipDetailsComponent,
    CoOwnerDialog,
    PropertyDetailsComponent,
    PropertyFormComponent,
    ContractDetailsComponent,
    UploadDocumentsComponent,
    UploadDocumentsReportDialog,
    SubmissionCompletedComponent,
    PlannerRegisterFormComponent,
    PlannerRegisterFormInformationComponent,
    PlannerRegisterFormExtendedNetworkComponent,
    PlannerRegisterFormDocumentsComponent,
    PlannerRegisterFormContractComponent,
    EstateAgentRegisterFormComponent,
    EstateAgentSignContractComponent,
    EstateAgentPersonalInformationComponent,
    PropertyListingCustomListingDialog,
    EstateAgentCompanyInformationComponent,
    EstateAgentSubmitDocumentsComponent,
    ConveyancerRegisterFormComponent,
    ConveyancerFirmInformationComponent,
    ConveyancerPersonalInformationComponent,
    ConveyancerSubmitDocumentsComponent,
    ConveyancerSignContractComponent,
    ConveyancerRegisterDetailsComponent,
    ValuerInformationComponent,
    ValuerSubmitDocumentsComponent,
    ValuerSignContractComponent,
    ValuerStepsComponent,
    ValuerRegisterFormComponent,
    SiodRegisterComponent,
    SiodFormComponent,
    SiodSubscribeComponent,
    MapComponent,
    PropertyForSaleComponent,
    PropertyForSaleSearchComponent,
    PropertyForSaleOptionsComponent,
    PropertyForSalePurchaseDocumentsDialogComponent,
    PropertyForSaleCheckoutComponent,
    ContactEstateAgentComponent,
    ContactTownPlannerComponent,
    PropertyForSaleOfferToPurchaseComponent,
    PropertyForSaleOfferToPurchaseWithConditionsComponent,
    PropertyForSaleOfferToPurchaseFormComponent,
    PropertyForSaleFormConditionsComponent,
    PropertyForSaleFormPurchasePriceComponent,
    PropertyForSaleFormPurchaserDetailsComponent,
    SiodConfirmComponent,
    SiodAddComponent,
    PropertyForSaleOfferToPurchaseFormEndComponent,
    AdminHomeComponent,
    AdminMyProfileComponent,
    AdminMyProfilePersonalDetailsComponent,
    AdminMyProfileWorkDetailsComponent,
    AdminMyProfileDocumentsComponent,
    AdminMyProfilePersonalDetailsEditDialogComponent,
    AdminMyProfileWorkDetailsEditDialogComponent,
    AdminMyProfileDocumentsEditDialogComponent,
    AdminMyProfileSiodComponent,
    AdminMyProfileFavouritesComponent,
    AdminMyProfileBillingComponent,
    AdminMyProfileBillingEditDialogComponent,
    AdminMyProfileExtendedNetworkComponent,
    AdminMyProfileExtendedNetworkDialogComponent,
    AdminPropertyListingsComponent,
    AdminPropertyListingsWorkflowComponent,
    AdminPropertyListingsWorkflowTasksComponent,
    AdminPropertyListingsWorkflowTasksPropertyDetailsComponent,
    WorkflowAddendumsComponent,
    WorkflowCcfComponent,
    PropertyListingsReportFormComponent,
    PropertyListingsPlanningReportComponent,
    PropertyListingsAddendumOneComponent,
    PropertyListingsAddendumTwoComponent,
    PropertyListingsAddendumThreeComponent,
    PropertyListingsValuationComponent,
    PropertyDetailsDialog,
    ReportPdfComponent,
    RegisterChoicesComponent,
    AdminManageUsersComponent,
    AddUserComponent,
    EditUserComponent,
    RemoveUserComponent,
    ApproveUserComponent,
    VerifyUserComponent,
    UserRoleDirective,
    WorkflowAllocateComponent,
    RemoveLeadingZerosPipe,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PropertiProSharedService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [  ]
})
export class AppModule { }
