<main>
  <div *ngIf="!adminActive" class="nav-background">
    <div *ngIf="router.url === '/home'" id="logo-top" class="text-center">
      <img class="logo mt-3" src="/assets/images/home/logo.png" alt="PropertyMatrix logo">
    </div>
    <nav id="top-nav" class="container-fluid navbar navbar-expand-md">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-3">
          <li class="nav-item">
            <a class="nav-link heading-3-light" href="home" routerLinkActive="active" routerLink="/home">
              HOME
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link heading-3-light" href="aboutUs" routerLinkActive="active" routerLink="/aboutUS">
              ABOUT US
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link heading-3-light" routerLinkActive="active" routerLink="/propertyForSale">
              PROPERTY FOR SALE
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link heading-3-light" routerLinkActive="active" href="sellingMyProperty"
              routerLink="/sellingMyProperty">
              SELL YOUR PROPERTY
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link heading-3-light" routerLinkActive="active" routerLink="/shareInOurDatabase">
              SHARE IN OUR DATABASE
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link heading-3-light" routerLinkActive="active" [ngClass]="{'active' : dropdown}">
              ADDITIONAL SERVICES
            </a>
            <ul class="dropdown-content">
              <li>
                <a href="propertyInvestigation" routerLink="/propertyInvestigation" routerLinkActive="active"
                  id="PropertyInvestigations" class="dropdown-item paragraph">
                  Property Investigations
                </a>
              </li>
              <li>
                <a href="feasibilityStudies" routerLink="/feasibilityStudies" routerLinkActive="active"
                  id="FeasibilityStudy" class="dropdown-item paragraph">
                  Feasibility Studies
                </a>
              </li>
              <li>
                <a href="townPlanningApplication" routerLink="/townPlanningApplication" routerLinkActive="active"
                  id="PlanningApplication" class="dropdown-item paragraph">
                  Planning Applications
                </a>
              </li>
              <li>
                <a href="propertyValuation" routerLink="/propertyValuation" routerLinkActive="active"
                  id="PropertyValuation" class="dropdown-item paragraph">
                  Property Valuation
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link heading-3-light" href="#" routerLinkActive="active" routerLink="/contactUS">
              CONTACT US
            </a>
          </li>
          <li class="nav-item" *ngIf="!isLoggedIn">
            <a class="nav-link heading-3-light" href="login" routerLinkActive="active" routerLink="/login">
              LOG IN
            </a>
          </li>
          <li class="nav-item dropdown" *ngIf="isLoggedIn">
            <a class="nav-link heading-3-light" routerLinkActive="active" [ngClass]="{'active' : dropdown}">
              <mat-icon>menu</mat-icon>
            </a>
            <ul class="dropdown-content pos-left">
              <li>
                <a href="admin/home" routerLink="/admin/home" routerLinkActive="active" class="dropdown-item paragraph"
                  (click)="enableAdminNav()">
                  Admin Portal
                </a>
              </li>
              <li class="nav-item" *ngIf="isLoggedIn">
                <a href class="dropdown-item paragraph" (click)="logout()">
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div> <!-- navbar-collapse.// -->
    </nav>
  </div>
  <div *ngIf="adminActive" class="row admin-nav-bar-color">
    <div class="main-title col">
      Property Matrix
    </div>
    <div class="col">
      <div *ngIf="router.url === '/admin/home'" class="heading-1 text-center pt-2">{{activeUserRole}}</div>
      <nav id="top-nav" class="container-fluid navbar navbar-expand-md admin-nav-bar">
        <div style="display: flex;">
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/admin/home">
              HOME
            </a>
          </div>
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/admin/property-listings"
              *appUserRole="['HeadAdministrator', 'HeadOfFinances', 'AgentPortalAdministrator', 'PlannerPortalAdministrator', 'ConsultantAdministrator',
                'SeniorAgent', 'SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner', 'SeniorValuer',
                'NetworkAgent', 'NetworkPlanner', 'NetworkValuer']">
              LISTINGS
            </a>
          </div>
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/contactUS"
              *appUserRole="['HeadAdministrator', 'estateAgent', 'conveyancer', 'valuer']">
              ORDERS
            </a>
          </div>
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/contactUS"
              *appUserRole="['HeadAdministrator', 'estateAgent', 'conveyancer', 'valuer']">
              REFERRALS
            </a>
          </div>
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/admin/user-management"
              *appUserRole="['HeadAdministrator', 'estateAgent', 'conveyancer', 'valuer']">
              USERS
            </a>
          </div>
          <div class="m-2 nav-item">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" routerLink="/admin/myProfile">
              PROFILE
            </a>
          </div>
          <div class="nav-item dropdown" *ngIf="isLoggedIn">
            <a class="nav-link heading-3 admin-nav" routerLinkActive="active" [ngClass]="{'active' : dropdown}">
              <mat-icon>menu</mat-icon>
            </a>
            <ul class="dropdown-content pos-left">
              <li>
                <a href="home" routerLink="/home" routerLinkActive="active" class="dropdown-item paragraph"
                  (click)="disableAdminNav()">
                  Main Site
                </a>
              </li>
              <li class="nav-item" *ngIf="isLoggedIn">
                <a href class="dropdown-item paragraph" (click)="logout()">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</main>
<router-outlet></router-outlet>