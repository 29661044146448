import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { ForgotPasswordInterface } from './models/forgotPassword';
import { ResetPasswordInterface } from './models/resetPassword';
import { User } from './models/User';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { PendingUser } from './models/pendingApprovals';
import { environment } from 'src/environments/environment';
import { ShareInOurDatabaseFormVm } from './Services/propertypros/models';
import { AdminService, AuthenticateService, MapsService, PropertyListingService, UserService } from './Services/propertypros/services';
import { GlobalConstants } from './common/global-constants';
import { FileModel } from './models/FileModel';
import { GlobalMethods } from './common/global-methods';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class PropertiProSharedService {

  readonly APIUrl = environment.APIUrl;
  private userSubject: BehaviorSubject<User>;
  public user: User;
  public inActiveUser: Observable<PendingUser>;
  public userRole: any;


  constructor(private http: HttpClient, private router: Router, private authService: AuthenticateService, private userService: UserService
    , private mapsService: MapsService, private sanitizer: DomSanitizer, private listingService: PropertyListingService) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('user')));
  }

  siodFormSubject: Subject<ShareInOurDatabaseFormVm> = new Subject<ShareInOurDatabaseFormVm>();
  setSiodForm(formData: ShareInOurDatabaseFormVm) {
    this.siodFormSubject.next(formData)
  }

  get userValue(): User {
    return this.userSubject.value;
  }

  public getToken(): string {
    let userModel = this.getUserModel();
    return userModel.token;
  }

  authenticateCreds(email: string, password: string) {
    const body = {
      email, password
    }

    return this.http.post<any>(this.APIUrl + 'Authenticate/login', body)
      .pipe(map(user => {
        sessionStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
      }));
  }

  authenticateConveyancerPassword(password: string) {
    console.log("Service " + password);
    return this.http.post<any>(this.APIUrl + 'Authenticate/conveyancer/' + password, null);
  }

  registerUser(val) {
    return this.http.post<any>(this.APIUrl + 'Authenticate/register', { val });
  }

  forgotPassword = (body: ForgotPasswordInterface) => {
    return this.http.post(this.APIUrl + 'Authenticate/forgotPassword', { body });
  }

  resetPassword = (body: ResetPasswordInterface) => {
    return this.http.post(this.APIUrl + 'Authenticate/resetPassword', { body });
  }

  logout() {
    sessionStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    return sessionStorage.getItem('user') == null ? false : true;
  }

  inactiveUsers() {

    const token = this.getToken();
    return this.http.get(this.APIUrl + 'User/inactiveProfiles',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.getToken()}`
        }
      });
  }

  updateInactiveUser(email: string) {
    return this.http.post<any>(this.APIUrl + 'User/updateInactiveProfile/' + email.toString(), null);
  }

  removeInactiveUser(email: string) {
    return this.http.post<any>(this.APIUrl + 'User/updateInactiveProfile/' + email.toString(), null);
  }

  addFiles(formData) {
    console.log('Back-end: ' + formData);
    return this.http.post(this.APIUrl + 'User/AddFiles', formData);
  }

  getbyId(id: string) {
    return this.http.get<User>(this.APIUrl + `User/profile/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getToken().toString()}`
        }
      });
  };

  getUserModel(): User {
    let user = sessionStorage.getItem('user');
    return JSON.parse(JSON.parse(user)) as User;
  }

  getUsername(): string {
    let userModel = this.getUserModel()
    return userModel.userName;
  }

  getUserRole(): string {
    let userModel = this.getUserModel()
    return userModel.userRole;
  }

  async uploadFiles(files: FileModel[]) {
    const username: string = this.getUsername();
    let formData = new FormData();
    files.forEach(fileObj => {
      formData.append(`${username}|${fileObj.id.toString()}`, fileObj.file);
    });
    formData.append('username', username);

    const url = environment.APIUrl + AdminService.ApiV1AdminUpdateDocumentsPostPath;
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)
    try {
      let promise = this.http.post(url, formData, { headers: headers }).pipe(map(response => response));
      await lastValueFrom(promise);
      return true;
    } catch (error) {
      GlobalMethods.tinyErrorAlert('Error', 'Error uploading files');
      return false;
    }
  }

  async getAddressImage(propertyKey: string) {
    let result = null
    try {
      let promise = this.mapsService.apiV1MapsGetAddressImageGet$Response({ propertyKey: propertyKey }).pipe(map(response => response));
      result = await lastValueFrom(promise);
    } catch (error) {
      GlobalMethods.tinyErrorAlert('Error', 'Problem finding image');
    } finally {
      return result;
    }
  }

  adminPageActiveCheck() {
    let adminNavShow = sessionStorage.getItem("adminNav");
    let user = sessionStorage.getItem('user');
    if (!adminNavShow || !user) {
      this.router.navigateByUrl('/home')
    }
    if (adminNavShow == "false") {
      this.router.navigateByUrl('/home')
    }
  }

  async getImageFromDataUri(dataURI: string): Promise<SafeUrl> {
    var blob = await (await fetch(dataURI)).blob();
    let objectURL = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  async deletePropertyListingFile(fileId: string, type: number, custom: boolean) {
    let result = null
    try {
      let promise = this.listingService.apiV1PropertyListingDeletePropertyListingFilePost$Response({ fileId: fileId, type: type, custom: custom }).pipe(map(response => response));
      result = await lastValueFrom(promise);
    } catch (error) {
      GlobalMethods.tinyErrorAlert('Error', 'Problem deleting file');
    } finally {
      return result;
    }
  }

  //TODO: Update user role in storage
  // setUpdatedUserDetails(){
  //   let username = this.getUsername();
  //   this.authService.apiV1AuthenticateGetUserDetailsGet$Response({username: username}).subscribe(
  //     {
  //       next: (result) => {
  //         sessionStorage.setItem("adminNav", "false");
  //           let userResultJson = JSON.stringify(result);
  //           console.log(userResultJson, 4)
  //           sessionStorage.setItem('user', userResultJson);
  //           let user = this.getUserModel();
  //           sessionStorage.setItem("userRole", user.userRole);
  //       }
  //     }
  //   )
  // }
}
