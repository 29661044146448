// Angular Imports
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Angular Material Imports
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

// Application Internal Imports
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValueItem } from 'src/app/common/globalModels/KeyValueItem';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { KeyValueRoleTuple } from 'src/app/common/globalModels/KeyValueRoleTuple';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { PropertyListingCustomListingVm } from 'src/app/Services/propertypros/models';
import { PropertyListingService } from 'src/app/Services/propertypros/services/property-listing.service';
import { UserRoles } from 'src/app/common/global-constants';

@Component({
  selector: 'app-admin-property-listings',
  templateUrl: './admin-property-listings.component.html',
  styleUrls: ['./admin-property-listings.component.scss']
})
export class AdminPropertyListingsComponent implements OnInit {

  sortBy: number = 1;
  tabIndex: number = 1;
  headingTabIndex: number = 1;

  sortByItems: KeyValuePair[] = [
    { key: 1, value: 'Status' },
    { key: 2, value: 'Planner' },
    { key: 3, value: 'Agent' },
    { key: 4, value: 'Data Capturer' }
  ]

  tabItems: KeyValueRoleTuple[] = [
    {
      key: 1,
      value: 'Single',
      role: [
        'HeadAdministrator',
        'HeadOfFinances',
        'AgentPortalAdministrator',
        'PlannerPortalAdministrator',
        'ConsultantAdministrator',
        'SeniorAgent',
        'SeniorPlanner',
        'HeadOfJuniorPlanners',
        'JuniorPlanner',
        'SeniorValuer',
        'NetworkAgent',
        'NetworkPlanner',
        'NetworkValuer',
      ]
    },
    {
      key: 2,
      value: 'Multiple',
      role: ['PlannerPortalAdministrator'],
    }
  ]

  headingTabItems: KeyValueItem[] = [
    { key: 1, value: 'My Tasks', icon: "assignment" },
    { key: 2, value: 'Published', icon: "computer" }
  ]

  listings = [];
  multipleListings = [];

  isHeadPlanner: boolean = false;
  userRole: string;
  userRoles = UserRoles;

  propertyListings: any;
  customListings: any[];
  filteredPropertyListings: any;
  filteredCustomListings: any;
  user = this.sharedService.getUserModel();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private sharedService: PropertiProSharedService,
    private listingService: PropertyListingService
  ) { }

  ngOnInit(): void {
    GlobalMethods.checkPageRolePermissions(
      this.user,
      [
        'HeadAdministrator',
        'HeadOfFinances',
        'AgentPortalAdministrator',
        'PlannerPortalAdministrator',
        'ConsultantAdministrator',
        'SeniorAgent',
        'SeniorPlanner',
        'HeadOfJuniorPlanners',
        'JuniorPlanner',
        'SeniorValuer',
        'NetworkAgent',
        'NetworkPlanner',
        'NetworkValuer'
      ],
      this.router
    );

    // Shared Service Operations
    this.sharedService.adminPageActiveCheck();

    // Component Initialization
    this.getPropertyListings();
    this.getUserRole();
    this.getCustomListings();
  }

  selectTab(id: number) {
    this.tabIndex = id;
  }

  selectHeadingTab(tab: number) {
    this.headingTabIndex = tab;
  }

  headingTab() {
    this.filteredPropertyListings = this.propertyListings;
    this.filteredCustomListings = this.customListings;

    const tabItem = this.headingTabItems.find(item => item.key === this.headingTabIndex);
    return tabItem ? tabItem.value.toUpperCase() : null;
  }

  deleteCustomListing(id: number, idx: number) {
    this.listingService.apiV1PropertyListingDeleteCustomListingPost$Response({ id: id }).subscribe({
      next: (result: any) => {
        if (result.ok) {
          this.customListings.splice(idx, 1);
        }
      }
    })
  }

  getCustomListings() {
    this.listingService.apiV1PropertyListingGetCustomMultipleListingGet$Response().subscribe({
      next: (result: any) => {
        let resultJson = JSON.parse(result.body);
        this.customListings = resultJson;
      }
    })
  }

  getPropertyListings() {
    var username: string = null;
    if (['SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner', 'NetworkPlanner'].includes(this.userRole)) {
      username = this.user.userName;
    }
    this.listingService.apiV1PropertyListingGetPropertyListingsGet$Response({ username: username }).subscribe({
      next: async (result: any) => {
        let resultJson = JSON.parse(result.body);
        this.propertyListings = resultJson;
        for (const listing of this.propertyListings) {
          listing.image = await this.sharedService.getImageFromDataUri(listing.sasUri)
        }
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem getting property listings");
      }
    })
  }

  generateListings() {
    this.listingService.apiV1PropertyListingGenerateListingsGet$Response().subscribe({
      next: (result: any) => {
        this.getPropertyListings();
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem generating listings");
      }
    })
  }

  goToPropertyListingTasksTab(listing: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/admin/property-listings/workflow'],
        {
          queryParams: {
            propertyId: listing.propertyId ?? listing.id,
            listingAddress: listing.addressTitle ?? listing.listingName,
            customListing: listing.listingName != null
          }
        }
      )
    );
    window.open(url, '_blank');
  }

  getUserRole() {
    let user = this.sharedService.getUserModel();
    this.userRole = user.userRole;
    this.isHeadPlanner = this.userRole == this.userRoles.HeadPlanner;
  }

  downloadPropertyReport(id: number, multiple: boolean) {
    this.listingService.apiV1PropertyListingDownloadPropertyReportGet$Response({ id: id, multipleReport: multiple }).subscribe({
      next: (result: any) => {
        const resultJson = JSON.parse(result.body);
        this.downloadPdf(resultJson.content, resultJson.fileName)
      },
      error: (error: any) => {

      },
    })
  }

  downloadPdf(byteArray: any, fileName: any) {
    const source = `data:application/pdf;base64,${byteArray}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  openDialog(listing?: PropertyListingCustomListingVm) {
    console.log(listing, 22)
    const dialogRef = this.dialog.open(PropertyListingCustomListingDialog, {
      width: '100%',
      height: '80vh',
      data: { listings: this.propertyListings, listing: listing }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCustomListings();
      }
    });
  }
}

@Component({
  selector: 'app-admin-property-listings-dialog',
  templateUrl: './admin-property-listings-dialog.html',
  styleUrls: ['./admin-property-listings.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PropertyListingCustomListingDialog implements OnInit {

  listingName: string = ''
  search: string = '';
  addedListings = []

  constructor(
    public dialogRef: MatDialogRef<PropertyListingCustomListingDialog>,
    private cdr: ChangeDetectorRef,
    private listingService: PropertyListingService, private sharedService: PropertiProSharedService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.listing) {
      this.addedListings = this.data.listing.listings;
      this.listingName = this.data.listing.listingName;
      console.log(this.data.listing, 23)
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  searchListings() {
    if (!this.search) {
      return this.data.listings;
    }
    let search = this.search.toUpperCase()
    return this.data.listings.filter((f: { addressTitle: string; completeAddress: string; }) => f.addressTitle?.toUpperCase().includes(search) || f.completeAddress?.toUpperCase().includes(search))
  }

  addListing(listing: any) {
    this.addedListings.push(listing);
  }

  removeListing(listingId: number) {
    let idx = this.addedListings.findIndex(f => f.propertyListingId == listingId);
    this.addedListings.splice(idx, 1)
  }

  isListingAdded(listingId: number) {
    return this.addedListings.find(f => f.propertyListingId == listingId);
  }

  postListing() {
    let model = {
      listingName: this.listingName,
      propertyListingIds: this.addedListings.map(m => m.propertyId),
      username: this.sharedService.getUsername(),
      id: this.data.listing?.id
    } as PropertyListingCustomListingVm;
    if (this.data.listing?.id) {
      this.updateListing(model);
    } else {
      this.saveListing(model);
    }
  }

  updateListing(model: PropertyListingCustomListingVm) {
    this.listingService.apiV1PropertyListingUpdateCustomMultipleListingPost$Response({ body: model }).subscribe({
      next: (result: any) => {
        this.dialogRef.close(result);
      },
      error: (error: any) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem creating custom listing");
      }
    })
  }

  saveListing(model: PropertyListingCustomListingVm) {
    this.listingService.apiV1PropertyListingCreateCustomMultipleListingPost$Response({ body: model }).subscribe({
      next: (result: any) => {
        this.dialogRef.close(result);
      },
      error: (error: any) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem creating custom listing");
      }
    })
  }
}
