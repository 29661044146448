/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EstateAgentFormVm } from '../models/estate-agent-form-vm';

@Injectable({
  providedIn: 'root',
})
export class EstateAgentService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1EstateAgentCaptureEstateAgentFormPost
   */
  static readonly ApiV1EstateAgentCaptureEstateAgentFormPostPath = '/api/v1/EstateAgent/CaptureEstateAgentForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1EstateAgentCaptureEstateAgentFormPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1EstateAgentCaptureEstateAgentFormPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentFormVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, EstateAgentService.ApiV1EstateAgentCaptureEstateAgentFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1EstateAgentCaptureEstateAgentFormPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1EstateAgentCaptureEstateAgentFormPost(params?: {
    context?: HttpContext
    body?: EstateAgentFormVm
  }
): Observable<void> {

    return this.apiV1EstateAgentCaptureEstateAgentFormPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
