import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { ValuerFormVm } from 'src/app/Services/propertypros/models';
import { UserService, ValuerService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-valuer-register-form',
  templateUrl: './valuer-register-form.component.html',
  styleUrls: ['./valuer-register-form.component.scss']
})
export class ValuerRegisterFormComponent implements OnInit {

  pageNumber: Number = 1;
  loading: boolean = false;
  user: any = null;

  form: ValuerFormVm = {
    paths: null,
    username: '',
    valuerInformation: null
  }

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'INFORMATION' },
    { key: 2, value: 'SUBMIT DOCUMENTS' },
    { key: 3, value: 'SIGN CONTRACT' },
  ];

  constructor(private router: Router, private valuerService: ValuerService, private sharedService: PropertiProSharedService, private userService: UserService) { }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
    GlobalMethods.scrollToTop();
    sessionStorage.removeItem('originalUrl');
    this.user = sessionStorage.getItem('user');
    if (!this.user) {
      this.router.navigateByUrl('/login')
    } else {
      this.isNormalUser();
    }
  }

  submitForm(){
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.valuerService.apiV1ValuerSaveValuerDetailsPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all require fields are comleted').then(result => {
              this.pageNumber = 2;
            });
          }
          this.loading = false;
          this.addOrRemoveBackgroundImage();
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all require fields are comleted').then(result => {
            this.pageNumber = 2;
          })
          this.loading = false;
        }
      })
  }

  assignValuerInformation($event) {
    this.form.valuerInformation = $event;
  }

  assignFiles($event) {
    this.form.paths = $event;
    this.addOrRemoveBackgroundImage();
    this.submitForm();
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
    this.addOrRemoveBackgroundImage();
  }

  addOrRemoveBackgroundImage(){
    if(this.pageNumber == 3){
      document.getElementById('main').classList.add('background-image');
    }else{
      document.getElementById('main').classList.remove('background-image');
    }
  }

  isNormalUser() {
    const userModel = JSON.parse(JSON.parse(this.user));

    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Valuer').then(result => {
              this.router.navigateByUrl('/home');
            })
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as a Valuer').then(result => {
            this.router.navigateByUrl('/home');
          })
          this.loading = false;
        }
      })
  }
}
