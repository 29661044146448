import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { PropertyListingService } from 'src/app/Services/propertypros/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-listings-report-form',
  templateUrl: './property-listings-report-form.component.html',
  styleUrls: ['./property-listings-report-form.component.scss']
})
export class PropertyListingsReportFormComponent implements OnInit {

  propertyId: number;
  listingAddress: string;
  customListing: boolean;
  pageNumber: number = 1;
  reportDetails = null;
  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'PLANNING REPORT' },
    { key: 2, value: 'ADDENDUM 1' },
    { key: 3, value: 'ADDENDUM 2' },
    { key: 4, value: 'ADDENDUM 3' },
    { key: 5, value: 'VALUATION' },
  ]
  loading: boolean = false;

  constructor(private route: ActivatedRoute, private listingService: PropertyListingService, private sharedService: PropertiProSharedService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getPropertyParams();
  }

  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;
        this.getReportDetails();
      }
    );
  }

  getReportDetails() {
    this.listingService.apiV1PropertyListingGetPlanningReportDetailsGet$Response({ propertyId: this.propertyId, custom: this.customListing }).subscribe({
      next: async (result: any) => {
        let resultJson = JSON.parse(result.body);
        this.reportDetails = await this.getImages(resultJson);
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert("Error", "Problem getting report info");
      }
    })
  }

  async getImages(reportDetails: any) {
    if (reportDetails?.planningReport?.showFiles != null && reportDetails?.planningReport?.showFiles != undefined) {
      for (let file of reportDetails.planningReport.showFiles) {
        file.picture = await this.sharedService.getImageFromDataUri(file.sasUri);
      }
    }
    if (reportDetails?.addendumOne?.showFiles != null && reportDetails?.addendumOne?.showFiles != undefined) {
      for(let showFiles of reportDetails.addendumOne.showFiles){
        if(showFiles != null){
          for (let file of showFiles) {
            file.picture = await this.sharedService.getImageFromDataUri(file.sasUri);
          }
        }
      }
    }
    return reportDetails;
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  async postReport(submit: boolean) {
    this.loading = true;
    let formData = new FormData();
    const username: string = this.sharedService.getUsername();
    formData.append('username', username);
    formData.append('listing', JSON.stringify(this.reportDetails));
    if (this.reportDetails.planningReport.files) {
      this.reportDetails.planningReport.files.forEach(file => {
        formData.append(`planningReport|${file.id}`.toUpperCase(), file.file);
      });
    }
    if (this.reportDetails.addendumOne.files) {
      this.reportDetails.addendumOne.files.forEach(file => {
        formData.append(`addendumOne|${file.id}|${file.propertyNumber}`.toUpperCase(), file.file);
      });
    }
    if (this.reportDetails.addendumTwo.propertyListingServitudeFiles) {
      this.reportDetails.addendumTwo.propertyListingServitudeFiles.forEach(file => {
        formData.append(`addendumTwo|${file.id}|${file.propertyNumber}`.toUpperCase(), file.file);
      });
    }

    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)
    this.http.post(environment.APIUrl + PropertyListingService.ApiV1PropertyListingSavePropertyListingDataPostPath, formData, { headers: headers, params: { custom: this.customListing} }).subscribe({
      next: async (result: any) => {
        this.loading = false;
        let alertResult = await GlobalMethods.tinySuccessAlert('Success', 'Report succesfully saved!');
        if (alertResult) {
          location.reload();
        }
      },
      error: (error) => {
        this.loading = false;
        GlobalMethods.tinyErrorAlert('Error', 'Problem saving report');
      }
    })
  }

  submitReport() {
    this.postReport(true);
  }

  saveReport() {
    this.postReport(false);
  }
}
