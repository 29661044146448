import { Component, OnInit } from '@angular/core';
import { SubscriptionVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-admin-my-profile-siod',
  templateUrl: './admin-my-profile-siod.component.html',
  styleUrls: ['./admin-my-profile-siod.component.scss']
})
export class AdminMyProfileSiodComponent implements OnInit {

  subscriptions: SubscriptionVm[] = []

  constructor() { }

  ngOnInit(): void {
    let subscription: SubscriptionVm = {
      sectorSelection: [],
      sellingPrice: [],
      landSize: [],
      bedrooms: [],
      bathrooms: [],
      parking: [],
      totalSize: [],
      propertyValues: [],
      showPlusIcon: true
    }
    for (let index = 0; index < 3; index++) {
      this.subscriptions.push(subscription)      
    }
  }

  deleteSubscription(index: number) {
    this.subscriptions.splice(index, 1);
  }
}
