import { Component, ChangeDetectorRef, Inject, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { CoOwnerDialogData } from 'src/app/models/sellingMyProperty/CoOwnerDialogData';
import {
  OwnershipDetailsVm
  , OwnershipPersonalDetailsVm
  , AddressVm
} from 'src/app/Services/propertypros/models';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-ownership-details',
  templateUrl: './ownership-details.component.html',
  styleUrls: ['./ownership-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class OwnershipDetailsComponent {
  @ViewChild(MatTable) table: MatTable<any>;
  constructor(public dialog: MatDialog) {}

  pageNumber = 1;
  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() ownershipDetailsEvent = new EventEmitter<OwnershipDetailsVm>();
  emailPattern: string = GlobalConstants.emailPattern;

  confirmConsent: boolean = false;
  personIsLegalRepOfEntity: number = 0;
  moreThanOneOwner: number = 0;
  numberOfCoOwners: number = 0;
  spouseIsSouthAfricanResident: number = 0;
  ownerIsSouthAfricanResident: number = 0;
  coOwners: OwnershipPersonalDetailsVm[] = [];

  propertyOwnershipType: KeyValuePair[] = [
    { key: 1, value: 'Natural person(s)' },
    { key: 2, value: 'Juristic person(s) - (company, cc, NPO, trust, etc.)' },
    { key: 3, value: 'Both natural person(s) and juristic person(s)'}
  ];
  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];
  maritialStatuses: KeyValuePair[] = [
    { key: 1, value: 'Unmarried' },
    { key: 2, value: 'Married out of community' },
    { key: 3, value: 'Married in community' }
  ];
  multipleOwners: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, there are multiple owners' }
  ];

  ownerDetails: OwnershipPersonalDetailsVm = {
    personalDetails: {
      emailAddress: '',
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      alternativeNumber: '',
      rsa_Resident: false,
    },
    maritialStatus: 0,
    propertyOwnershipType: 0,
    legalEntityName: '',
    legalEntityRegistrationNumber: '',
    legalEntityVATNumber: '',
    moreThanOneOwner: false,
    confirmConsent: false,
    spouseDetails: {
      rsa_Resident: false,
      alternativeNumber: '',
      emailAddress: '',
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
    }
  }

  physicalAddress: AddressVm = {
    streetName: '',
    streetNo: '',
    suburb: '',
    unitNo: '',
    complexName: '',
    city: '',
    postalCode: '',
  }

  displayedColumns: string[] = ['co-owner', 'action1', 'action2'];

  queryBody: OwnershipDetailsVm = {
    coOwners: this.coOwners,
    ownerDetails: this.ownerDetails,
    pysicalAddress: this.physicalAddress,
  }

  coOwner: OwnershipPersonalDetailsVm = {
    confirmConsent: false,
    personalDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false
    },
    spouseDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false
    }
  }

  clearCoOwner() {
    this.coOwner = {
      confirmConsent: false,
      personalDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        phoneNumberConfirmed: false,
        landline: '',
        title: '',
      },
      spouseDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        phoneNumberConfirmed: false,
        landline: '',
        title: '',
      }
    }
  }

  submitForm() {
    this.queryBody.ownerDetails.personalDetails.rsa_Resident = this.ownerIsSouthAfricanResident == 1;
    this.queryBody.ownerDetails.spouseDetails.rsa_Resident = this.spouseIsSouthAfricanResident == 1;
    this.queryBody.ownerDetails.moreThanOneOwner = this.moreThanOneOwner == 2;
    
    this.gotoPropertyDetails();
  }

  openAddCoOwnerDialog() {
    const dialogRef = this.dialog.open(CoOwnerDialog, {
      width: '60vw',
      height: '80vh',
      data: { coOwner: this.coOwner, index: 0, showDelete: false },
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.coOwners.push(result);
        this.table.renderRows();
        this.clearCoOwner();
      }
    });
  }

  openEditCoOwnerDialog(coOwner: OwnershipPersonalDetailsVm) {
    const dialogRef = this.dialog.open(CoOwnerDialog, {
      width: '60vw',
      height: '80vh',
      data: { coOwner: coOwner, index: 0, showDelete: false },
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.table.renderRows();
      }
    });
  }

  deleteCoOwner(coOwner: OwnershipPersonalDetailsVm, index: number) {
    const dialogRef = this.dialog.open(CoOwnerDialog, {
      data: { coOwner: coOwner, index: index, showDelete: true, deleteCoOwner: false },
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleteCoOwner == true) {
          this.table.renderRows();
          this.coOwners.splice(index, 1);
        }
      }
    });
  }

  resetLegalEntityFields() {
    this.ownerDetails.legalEntityName = '';
    this.ownerDetails.legalEntityRegistrationNumber = '';
    this.ownerDetails.legalEntityVATNumber = '';
  }

  gotoPropertyDetails() {
    this.ownershipDetailsEvent.emit(this.queryBody);
    this.pageNumberEvent.emit(2);
    GlobalMethods.scrollToTop();
  }
}

@Component({
  selector: 'onwership-details-co-owner-details-dialog',
  templateUrl: './onwership-details-co-owner-details-dialog.html',
  styleUrls: ['../../../../css/2-modules/_forms.scss']
})
export class CoOwnerDialog {
  constructor(
    public dialogRef: MatDialogRef<CoOwnerDialog>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: CoOwnerDialogData,
  ) { }

  emailPattern: string = GlobalConstants.emailPattern;

  maritialStatuses: KeyValuePair[] = [
    { key: 1, value: 'Unmarried' },
    { key: 2, value: 'Married out of community' },
    { key: 3, value: 'Married in community' }
  ];

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveCoOwner(formIsValid: Boolean) {
    if (formIsValid == false) {
      GlobalMethods.tinyErrorAlert("Error",'Please fill in all required fields');
      return;
    }
    this.dialogRef.close(this.data.coOwner);
  }

  deleteCoOwner() {
    this.data.deleteCoOwner = true;
    this.dialogRef.close(this.data);
  }

  scroll(elemeintId) {
    GlobalMethods.scrollToId(elemeintId);
  }
}