<div class="row mt-3" *ngFor="let report of propertySections; let i = index">
    <div class="cols-12 heading-2 mt-3 section-title" *ngIf="customListing">
        <div class="row">
            <div class="col-11">Property {{counter[i]}} - {{report.value}}</div>
            <div class="col-1">
                <button (click)="collapseOrExpandSection(report)" mat-icon-button>
                    <mat-icon *ngIf="!report.checked">expand_more</mat-icon>
                    <mat-icon *ngIf="report.checked">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="!report.checked">
        <div class="cols-12" *ngFor="let section of sections; let j = index">
            <div class="row mt-4" [ngClass]="{'section-title': !customListing}">
                <div class="col-11 heading-4">{{j + 1}}. {{section.value}}</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12 mt-2"><span class="paragraph">Provides the basic deeds related information.</span></div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        <table>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>Version</td>
                                    <td>N/A</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Title Deed Number</td>
                                    <td>{{reportDetails.addendumThree.titleDeedNumber[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Capture Date</td>
                                    <td>{{reportDetails.addendumThree.captureDate[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Registration Date</td>
                                    <td>{{reportDetails.addendumThree.registrationDate[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Transaction Date</td>
                                    <td>{{reportDetails.addendumThree.transactionDate[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Price</td>
                                    <td>R {{reportDetails.addendumThree.price[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Deeds Office</td>
                                    <td>{{reportDetails.addendumThree.deedsOffice[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Deeds Town</td>
                                    <td>{{reportDetails.addendumThree.deedsTown[i]}}</td>
                                </tr>
                                <tr class="cloud-bottom-border">
                                    <td>Deeds Authority</td>
                                    <td>{{reportDetails.addendumThree.deedsAuthority[i]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12"><span class="heading-5">DEEDS CURRENT OWNER INFORMATION</span></div>
                </div>
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Name</td>
                                    <td>ID Number</td>
                                    <td>Marital Status</td>
                                    <td>Owner Type</td>
                                    <td>Owner Share</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.currentOwnerInfo[i].length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.currentOwnerInfo[i]">
                                    <td>{{info.name}}</td>
                                    <td>{{info.id_Number}}</td>
                                    <td>{{info.maritialStatus}}</td>
                                    <td>{{info.ownerType}}</td>
                                    <td>{{info.ownerShare}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.currentOwnerInfo[i].length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 3" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Transaction date</td>
                                    <td>Registration date</td>
                                    <td>MPrice</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.transferHistory[i].length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.transferHistory[i]">
                                    <td>{{info.transactionDate}}</td>
                                    <td>{{info.registrationDate}}</td>
                                    <td>R {{info.price}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.transferHistory[i].length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 4" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Registration Date</td>
                                    <td>Title Deed</td>
                                    <td>Name</td>
                                    <td>ID Number</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.ownerHistory[i]?.length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.ownerHistory[i]">
                                    <td>{{info.registrationDate}}</td>
                                    <td>{{info.titleDeed}}</td>
                                    <td>{{info.name}}</td>
                                    <td>{{info.id_Number}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.ownerHistory[i]?.length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 5" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Registration Date</td>
                                    <td>Number</td>
                                    <td>Holder</td>
                                    <td>Amount</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.bondInformation[i]?.length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.bondInformation[i]">
                                    <td>{{info.registrationDate}}</td>
                                    <td>{{info.number}}</td>
                                    <td>{{info.holder}}</td>
                                    <td>{{info.amount}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.bondInformation[i]?.length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 6" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Endorsement Number</td>
                                    <td>Bond Holder</td>
                                    <td>Bond Amount</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.endorsements[i]?.length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.endorsements[i]">
                                    <td>{{info.endorsementNumber}}</td>
                                    <td>{{info.bondHolder}}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.endorsements[i]?.length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 7" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12 mt-2"><span class="paragraph">This property was consolidated from the following:</span></div>
                </div>
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Registration Date</td>
                                    <td>Title Deed</td>
                                    <td>Erf/Portion</td>
                                    <td>Extenet</td>
                                    <td>Deeds Town</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.consolidations[i]?.length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.consolidations[i]">
                                    <td>{{info.registrationDate}}</td>
                                    <td>{{info.titleDeed}}</td>
                                    <td>{{info.erfPortion}}</td>
                                    <td>{{info.extent}}</td>
                                    <td>{{info.titleDeed}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.consolidations[i]?.length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 8" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12 mt-2"><span class="paragraph">This property was subdivided from the following:</span></div>
                </div>
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Registration Date</td>
                                    <td>Title Deed</td>
                                    <td>Erf/Portion</td>
                                    <td>Extenet</td>
                                    <td>Deeds Town</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.subdivisions[i]?.length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.subdivisions[i]">
                                    <td>{{info.registrationDate}}</td>
                                    <td>{{info.titleDeed}}</td>
                                    <td>{{info.erfPortion}}</td>
                                    <td>{{info.extent}}</td>
                                    <td>{{info.titleDeed}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.subdivisions[i]?.length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-5">
    <button (click)="goToValuation()" class="button">Next</button>
</div>