import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from 'src/app/models/User';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: User;

  constructor(private userService: UserService, private authService: PropertiProSharedService) { 
  }

  ngOnInit(): void { 
    this.currentUser = this.authService.userValue;
    console.log('Current user: ' + JSON.stringify(this.currentUser));
    this.authService.getbyId(this.currentUser.userID).pipe(first()).subscribe()
  }
}
