import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { EstateAgentCompanyInformationVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-estate-agent-company-information',
  templateUrl: './estate-agent-company-information.component.html',
  styleUrls: ['./estate-agent-company-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class EstateAgentCompanyInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() EstateAgentCompanyInformationEvent = new EventEmitter<EstateAgentCompanyInformationVm>();

  affiliatedWithFranchisedEstateAgency: number = 0;
  principalOfBranch: number = 0;
  reportsToPrincipal: number = 0;
  eaab_RegisteredPrincipal: number = 0;

  form: EstateAgentCompanyInformationVm = {
    branchAddress: {
      city: '',
      complexName: '',
      currentLandUse: '',
      postalCode: '',
      streetName: '',
      streetNo: '',
      unitNo: '',
      suburb: ''
    },
    bankDetails: {
      accountHolder: '',
      accountNumber: '',
      bank: '',
      branchName: '',
      branchNumber: '',
      typeOfAccount: ''
    },
    principalDetails: {
      alternativeNumber: '',
      companyName: '',
      confirmEmail: '',
      emailAddress: '',
      id_Number: '',
      landline: '',
      name: '',
      phoneNumber: '',
      surname: '',
    },
    eaab_FidelityFindCetrificateNumber: '',
    agencyName: '',
    branchName: '',
    eaab_RegisteredPrincipal: false,
    firmPinNumber: '',
    firmVatNumber: '',
    individualReferenceNumber: '',
    isAffiliatedWithFranchisedEstateAgency: false,
    isPrincipalOfBranch: false,
    reportsToPrincipal: false,
  }

  affiliatedWithFranchisedEstateAgencyRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'I am affiliated with a franchised estate agency' },
    { key: 2, value: 'I am an independent (free) estate agent/business, not linked to any franchise' },
  ];

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' },
  ]

  constructor() { }

  submitForm(){
    this.form.isAffiliatedWithFranchisedEstateAgency = this.affiliatedWithFranchisedEstateAgency == 1;
    this.form.isPrincipalOfBranch = this.principalOfBranch == 1;
    this.form.reportsToPrincipal = this.reportsToPrincipal == 1;
    this.form.eaab_RegisteredPrincipal = this.eaab_RegisteredPrincipal == 1;
    this.pageNumberEvent.emit(3);
    this.EstateAgentCompanyInformationEvent.emit(this.form);
  }

  ngOnInit(): void {
  }

}
