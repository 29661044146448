import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { AddressDetailsVm, AdminPropertyDetailsVm, PropertyAddressDetailsVm, RiskscapeAddressData } from 'src/app/Services/propertypros/models';
import { MapsService, SellingMyPropertyService } from 'src/app/Services/propertypros/services';
import { PropertyListingService } from 'src/app/Services/propertypros/services/property-listing.service';

@Component({
  selector: 'app-admin-property-listings-workflow-tasks-property-details',
  templateUrl: './workflow-property-details.component.html',
  styleUrls: ['./workflow-property-details.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class AdminPropertyListingsWorkflowTasksPropertyDetailsComponent implements OnInit {

  propertyId: number;
  listingAddress: string;
  customListing: boolean;
  propertyAddressDetailsVm: PropertyAddressDetailsVm;

  preferedOption: number;
  ownershipDetailsId: number = 0;
  propertyDetails: AdminPropertyDetailsVm[] = [];
  addressDetails: AddressDetailsVm[] = [];
  loadingAddress: boolean = false;
  loadingCadaster: boolean = false;
  cadasterPicture: SafeUrl[] = [];

  riskScapeAddressData: RiskscapeAddressData[] = []
  physicalAddress: any[] = []
  addressGeocode: any[] = []
  imageBase64: string[] = []
  user = this.sharedService.getUserModel();

  constructor(private route: ActivatedRoute, 
    private propertyListingService: PropertyListingService, 
    private mapsService: MapsService, 
    private sharedService: PropertiProSharedService, 
    private sanitizer: DomSanitizer,
    private sellingMyPropertyService: SellingMyPropertyService,
    private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.checkPageRolePermissions(this.user, 
      ['SeniorPlanner', 'HeadOfJuniorPlanners', 'JuniorPlanner'],
      this.router);
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
        this.customListing = params.customListing;

        this.propertyListingService.apiV1PropertyListingGetAddressDetailsGet$Response({ propertyId: this.propertyId, custom: this.customListing }).subscribe({
          next: (result: any) => {
            this.propertyDetails = JSON.parse(result.body);
            this.ownershipDetailsId = this.propertyDetails[0].ownershipDetailsId;
          },
          error: (error: any) => {
            console.error(error);
          }
        })
      }
    );
  }

  getAddress() {
    this.loadingAddress = true;
    for(let i = 0; i < this.propertyDetails.length; i++){
      this.GetAddressGeoCode(i);
    }
    this.loadingAddress = false;
    GlobalMethods.scrollToBottom();
  }

  GetAddressGeoCode(index: number){
    this.mapsService.apiV1MapsGetAddressGeocodePost$Response({ body: this.propertyDetails[index] }).subscribe({
      next: (result: any) => {
        this.addressGeocode.push(JSON.parse(result.body));
        this.getAddressImage(index, this.addressGeocode[index].attributes.property_key);
        this.GetAddressDetails(index);
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  GetAddressDetails(index: number){
    this.mapsService.apiV1MapsGetAddressDetailsGet$Response({ addressId: this.addressGeocode[index].attributes.address_id }).subscribe({
      next: (result: any) => {
        this.loadingAddress = false;
        let details = JSON.parse(result.body);
        this.addressDetails.push({
          key: this.addressGeocode[index].attributes.property_key,
          data: {
            erfNumber: details.erfNumber,
            short_Address: details.short_address,
            subplace: details.subplace,
            municipality: details.municipality,
            province: details.province
          }
        });
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  async getAddressImage(index: number, propertyKey: string) {
    this.loadingCadaster = true;
    let result = await this.sharedService.getAddressImage(propertyKey);
    if (result?.ok) {
      const resultJson = JSON.parse(result.body);
      this.imageBase64.push(resultJson.encoded);
      const base64Response = await fetch(`data:image/jpeg;base64,${resultJson.encoded}`);
      const blob = await base64Response.blob();
      let objectURL = URL.createObjectURL(blob);
      this.cadasterPicture.push(this.sanitizer.bypassSecurityTrustUrl(objectURL));
    } else {
      GlobalMethods.tinyErrorAlert('Error', 'Problem finding image');
    }
  }

  confirm(){
    this.propertyAddressDetailsVm = {
      addressDetails: this.addressDetails,
      propertyDetails: this.propertyDetails,
      imageBase64: this.imageBase64
    }
    this.sellingMyPropertyService.apiV1SellingMyPropertyUpdatePropertyDetailsPost$Response({ body: this.propertyAddressDetailsVm, propertyId: this.propertyId })
      .subscribe({
        next: (result) => {
          GlobalMethods.tinySuccessAlert("Success", "You have successfully updated the Property Details.");
        }
        , error: (error) => {
          console.error(error);
        }
      }
    )
  }
}
