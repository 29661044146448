import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeyValueItem } from 'src/app/common/globalModels/KeyValueItem';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';

@Component({
  selector: 'app-admin-property-listings-workflow',
  templateUrl: './property-listings-workflow.component.html',
  styleUrls: ['./property-listings-workflow.component.scss']
})
export class AdminPropertyListingsWorkflowComponent implements OnInit {

  tab: number = 1;
  propertyId: number;
  listingAddress: string;

  headingTabItems: KeyValueItem[] = [
    { key: 1, value: 'Tasks', icon: "assignment" },
    // { key: 2, value: 'Under Offer', icon: "assignment" },
    // { key: 3, value: 'Users', icon: "assignment" },
    // { key: 4, value: 'Documents', icon: "assignment" },
  ]

  constructor(private sharedService: PropertiProSharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sharedService.adminPageActiveCheck();
    this.getPropertyParams();
  }

  selectTab(tabNumber: number) {
    this.tab = tabNumber;
  }

  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.propertyId = params.propertyId;
        this.listingAddress = params.listingAddress;
      }
    );
  }
}
