import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.scss']
})
export class RemoveUserComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialog: MatDialog,
    public userService: UserService    
  ) { }

  ngOnInit(): void {

  }

  removeUser() {
    this.userService.apiV1UserRemoveUserGet({userId: this.dialogData.userProfile.id})
    .subscribe(res => {
      this.dialog.closeAll();
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
