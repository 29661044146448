<main>
    <div class="container-fluid">
        <div class="container">
            <mat-card class="row">
                <div class="col-4 heading-2 d-flex">
                    <mat-icon class="mx-2 link" (click)="goBack()">arrow_back</mat-icon>
                    <span>{{listingAddress}}</span>
                </div>
                <div class="col-3">
                    <span>Allocate SP, HJP, JP and NP</span>
                </div>
            </mat-card>
            <mat-card class="mt-3">
                <div class="row">
                    <span class="card-header">Allocation</span>
                </div>
                <div class="row mt-2" *ngIf="assignableUsers && listing">
                    <label class="paragraph bold">Assign Senior Planner</label>
                    <select class="paragraph mx-2 col-10 py-1 mt-1"
                        (ngModelChange)="assignUserRole($event, 18)"
                        [(ngModel)]="listing.seniorPlanner.id">
                        <option [value]="null">Please select a Senior Planner</option>
                        <option *ngFor="let user of assignableUsers.seniorPlanners" [value]="user.id">{{user.firstName}} {{user.lastName}}</option>
                    </select>
                </div>
                <div class="row mt-2" *ngIf="assignableUsers && listing">
                    <label class="paragraph bold">Assign Head Junior Planner</label>
                    <select class="paragraph mx-2 col-10 py-1 mt-1"
                        (ngModelChange)="assignUserRole($event, 10)"
                        [(ngModel)]="listing.headOfJuniorPlanner.id">
                        <option [value]="null">Please select a Head Junior Planner</option>
                        <option *ngFor="let user of assignableUsers.headOfJuniorPlanners" [value]="user.id">{{user.firstName}} {{user.lastName}}</option>
                    </select>
                </div>
                <div class="row mt-2" *ngIf="assignableUsers && listing">
                    <label class="paragraph bold">Assign Junior Planner</label>
                    <select class="paragraph mx-2 col-10 py-1 mt-1"
                        (ngModelChange)="assignUserRole($event, 7)"
                        [(ngModel)]="listing.juniorPlanner.id">
                        <option [value]="null">Please select a Junior Planner</option>
                        <option *ngFor="let user of assignableUsers.juniorPlanners" [value]="user.id">{{user.firstName}} {{user.lastName}}</option>
                    </select>
                </div>
                <div class="row mt-2" *ngIf="assignableUsers && listing">
                    <label class="paragraph bold">Assign Network Planner</label>
                    <select class="paragraph mx-2 col-10 py-1 mt-1"
                        (ngModelChange)="assignUserRole($event, 11)"
                        [(ngModel)]="listing.networkPlanner.id">
                        <option [value]="null">Please select a Network Planner</option>
                        <option *ngFor="let user of assignableUsers.networkPlanners" [value]="user.id">{{user.firstName}} {{user.lastName}}</option>
                    </select>
                </div>
            </mat-card>
        </div>
    </div>
</main>