/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MetrosVm } from '../models/metros-vm';
import { UserListVm } from '../models/user-list-vm';
import { UserRoleVm } from '../models/user-role-vm';
import { UserListResult } from '../models/user-list-result';

@Injectable({
  providedIn: 'root',
})
export class UserService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1UserProfileIdGet
   */
  static readonly ApiV1UserProfileIdGetPath = '/api/v1/User/profile/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserProfileIdGet$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserProfileIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserProfileIdGet(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserProfileIdGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserInactiveProfilesGet
   */
  static readonly ApiV1UserInactiveProfilesGetPath = '/api/v1/User/inactiveProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserInactiveProfilesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserInactiveProfilesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserInactiveProfilesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserInactiveProfilesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserInactiveProfilesGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserInactiveProfilesGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserUpdateInactiveProfileEmailPost
   */
  static readonly ApiV1UserUpdateInactiveProfileEmailPostPath = '/api/v1/User/updateInactiveProfile/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUpdateInactiveProfileEmailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUpdateInactiveProfileEmailPost$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserUpdateInactiveProfileEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUpdateInactiveProfileEmailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUpdateInactiveProfileEmailPost(params: {
    email: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserUpdateInactiveProfileEmailPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserRemoveInactiveProfileEmailPost
   */
  static readonly ApiV1UserRemoveInactiveProfileEmailPostPath = '/api/v1/User/removeInactiveProfile/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRemoveInactiveProfileEmailPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserRemoveInactiveProfileEmailPost$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserRemoveInactiveProfileEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRemoveInactiveProfileEmailPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserRemoveInactiveProfileEmailPost(params: {
    email: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserRemoveInactiveProfileEmailPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserIsNormalUserGet
   */
  static readonly ApiV1UserIsNormalUserGetPath = '/api/v1/User/IsNormalUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserIsNormalUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserIsNormalUserGet$Response(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserIsNormalUserGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserIsNormalUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserIsNormalUserGet(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserIsNormalUserGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserGetEndorsementGet
   */
  static readonly ApiV1UserGetEndorsementGetPath = '/api/v1/User/GetEndorsement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetEndorsementGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetEndorsementGet$Response(params?: {
    userId?: string;
    endorsement?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetEndorsementGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('endorsement', params.endorsement, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetEndorsementGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetEndorsementGet(params?: {
    userId?: string;
    endorsement?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserGetEndorsementGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserGetAllUsersGet
   */
  static readonly ApiV1UserGetAllUsersGetPath = '/api/v1/User/GetAllUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetAllUsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetAllUsersGet$Plain$Response(params?: {
    searchVal?: string;
    filter?: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<UserListResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetAllUsersGetPath, 'get');
    if (params) {
      rb.query('searchVal', params.searchVal, {});
      rb.query('filter', params.filter, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<UserListResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetAllUsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetAllUsersGet$Plain(params?: {
    searchVal?: string;
    filter?: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<UserListResult> {

    return this.apiV1UserGetAllUsersGet$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<UserListResult>) => r.body as UserListResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetAllUsersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetAllUsersGet$Json$Response(params?: {
    searchVal?: string;
    filter?: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<UserListResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetAllUsersGetPath, 'get');
    if (params) {
      rb.query('searchVal', params.searchVal, {});
      rb.query('filter', params.filter, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<UserListResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetAllUsersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetAllUsersGet$Json(params?: {
    searchVal?: string;
    filter?: string;
    offset?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<UserListResult> {

    return this.apiV1UserGetAllUsersGet$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<UserListResult>) => r.body as UserListResult)
    );
  }

  /**
   * Path part for operation apiV1UserRemoveUserGet
   */
  static readonly ApiV1UserRemoveUserGetPath = '/api/v1/User/removeUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRemoveUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserRemoveUserGet$Response(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserRemoveUserGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRemoveUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserRemoveUserGet(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserRemoveUserGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserGetMetroGet
   */
  static readonly ApiV1UserGetMetroGetPath = '/api/v1/User/GetMetro';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetMetroGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetMetroGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<MetrosVm>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetMetroGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<MetrosVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetMetroGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetMetroGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<MetrosVm>> {

    return this.apiV1UserGetMetroGet$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<MetrosVm>>) => r.body as Array<MetrosVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetMetroGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetMetroGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<MetrosVm>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetMetroGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<MetrosVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetMetroGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetMetroGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<MetrosVm>> {

    return this.apiV1UserGetMetroGet$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<MetrosVm>>) => r.body as Array<MetrosVm>)
    );
  }

  /**
   * Path part for operation apiV1UserUpdateUserProfilePut
   */
  static readonly ApiV1UserUpdateUserProfilePutPath = '/api/v1/User/UpdateUserProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUpdateUserProfilePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UserUpdateUserProfilePut$Response(params?: {
    context?: HttpContext
    body?: UserListVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserUpdateUserProfilePutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUpdateUserProfilePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1UserUpdateUserProfilePut(params?: {
    context?: HttpContext
    body?: UserListVm
  }
): Observable<void> {

    return this.apiV1UserUpdateUserProfilePut$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserGetUserRolesGet
   */
  static readonly ApiV1UserGetUserRolesGetPath = '/api/v1/User/GetUserRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetUserRolesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetUserRolesGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<UserRoleVm>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetUserRolesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<UserRoleVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetUserRolesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetUserRolesGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<UserRoleVm>> {

    return this.apiV1UserGetUserRolesGet$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<UserRoleVm>>) => r.body as Array<UserRoleVm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserGetUserRolesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetUserRolesGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<UserRoleVm>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.ApiV1UserGetUserRolesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<UserRoleVm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserGetUserRolesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserGetUserRolesGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<UserRoleVm>> {

    return this.apiV1UserGetUserRolesGet$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<UserRoleVm>>) => r.body as Array<UserRoleVm>)
    );
  }

}
