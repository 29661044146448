import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { ContractDetailsVm, OwnershipDetailsVm, PropertyDetailsVm, UploadDocumentDetailsVm, SellingMyPropertyFormVm } from 'src/app/Services/propertypros/models';
import { SellingMyPropertyService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss']
})
export class PropertyFormComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(private sharedService: PropertiProSharedService, private sellingMyPropertyService: SellingMyPropertyService, private route: Router, private pageTitle: Title) {
    this.pageTitle.setTitle('Sell Your Property Form')
   }

  pageNumber: number = 1;
  ownershipDetailsId: number = 0;
  sideNavPage: KeyValuePair = { key: 0, value: '' };
  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'OWNERSHIP DETAILS' },
    { key: 2, value: 'PROPERTY DETAILS' },
    { key: 3, value: 'CONTRACT DETAILS' },
    { key: 4, value: 'UPLOAD DOCUMENTS' },
  ]
  loading: boolean = false;

  ownershipDetails: OwnershipDetailsVm = null;
  propertyDetails: PropertyDetailsVm = null;
  contractDetails: ContractDetailsVm = null;
  uploadDocumentDetails: UploadDocumentDetailsVm = null;

  form: SellingMyPropertyFormVm = {
    ownershipDetails: null,
    contractDetails: null,
    propertyDetails: null,
    uploadDocumentDetails: null
  }

  sideNavPages: KeyValuePair[] = [
    { key: 1, value: 'How to locate your property and determine the correct property description' },
    { key: 2, value: 'Factors considered for Value Assessment' },
    { key: 3, value: 'Formal Valuation Reports' },
    { key: 4, value: '' },
  ]

  ngOnInit(): void {
    let user = sessionStorage.getItem('user');
    if (!user) {
      this.route.navigateByUrl('/login')
    }
    GlobalMethods.scrollToTop();
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      this.scrollToTop();
    }
  }

  assignOwnershipDetails($event) {
    this.ownershipDetails = $event
  }

  assignPropertyDetails($event) {
    this.propertyDetails = $event
  }

  assignContractDetails($event) {
    this.contractDetails = $event
  }

  assignUploadDocumentDetails($event) {
    this.uploadDocumentDetails = $event
    this.submitForm()
  }

  submitForm() {
    this.form.ownershipDetails = this.ownershipDetails;
    this.form.propertyDetails = this.propertyDetails;
    this.form.contractDetails = this.contractDetails;
    this.form.uploadDocumentDetails = this.uploadDocumentDetails;
    this.form.username = this.sharedService.getUsername();

    this.loading = true;
    this.sellingMyPropertyService.apiV1SellingMyPropertySaveSellingMyPropertyFormPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Ok) {
            this.route.navigateByUrl('/sellingMyProperty/capture/finished');
          } else {
            GlobalMethods.tinyErrorAlert("Error", "Please ensure all required fields are completed.");
            this.loading = false;
          }
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert("Error", "Please ensure all required fields are completed.");
          this.loading = false;
        }
      })
  }

  scrollToTop() {
    GlobalMethods.scrollToId('top-page')
  }

  openNav($event) {
    this.sideNavPage = this.sideNavPages.find(f => f.key == $event);
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }
}
