<div class="row mt-3" *ngFor="let report of propertySections; let i = index">
    <div class="cols-12 heading-2 mt-3 section-title" *ngIf="customListing">
        <div class="row">
            <div class="col-11">Property {{counter[i]}} - {{report.value}}</div>
            <div class="col-1">
                <button (click)="collapseOrExpandSection(report)" mat-icon-button>
                    <mat-icon *ngIf="!report.checked">expand_more</mat-icon>
                    <mat-icon *ngIf="report.checked">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="!report.checked">
        <div class="cols-12" *ngFor="let section of sections; let j = index">
            <div class="row mt-4" [ngClass]="{'section-title': !customListing}">
                <div class="col-11 heading-4">{{j + 1}}. {{section.value}}</div>
                <div class="col-1">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                    </button>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        <table>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>Excluding VAT and commision</td>
                                    <td>
                                        <span *ngIf="reportDetails.valuation.sellerAskingPrice[i] > 0">R
                                            {{reportDetails.valuation.sellerAskingPrice[i]}}</span>
                                        <span *ngIf="reportDetails.valuation.sellerAskingPrice[i] == 0 || reportDetails.valuation.sellerAskingPrice[i] == null">Seller uncertain</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-6 paragraph">
                        <div class="paragraph">
                            <div class="row">
                                <div class="col-6 bold">Valuation Confidence Level</div>
                                <div class="col-6">{{reportDetails.valuation.valuationConfidenceLevel[i]}}</div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 bold">Valuation Estimated Date</div>
                                <div class="col-6">{{reportDetails.valuation.valuationEstimatedDate[i]}}</div>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="col-6 paragraph">
                        <div class="paragraph">
                            <div class="row">
                                <div class="col-6 bold">Valuation Estimated Price</div>
                                <div class="col-6">
                                    <span *ngIf="reportDetails.addendumThree.price[i]">R {{reportDetails.addendumThree.price[i]}}</span>
                                    <span *ngIf="!reportDetails.addendumThree.price[i]">No Price</span>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 bold">Lower Boundary</div>
                                <div class="col-6">R {{reportDetails.valuation.valuationEstimatedPriceLow[i]}}</div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 bold">Upper Boundary</div>
                                <div class="col-6">R {{reportDetails.valuation.valuationEstimatedPriceHigh[i]}}</div>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 3" [hidden]="!section.checked">
                <div class="row">
                    <div class="col-12 paragraph">
                        <table>
                            <thead class="heading-gap">
                                <tr>
                                    <td>Transaction date</td>
                                    <td>Registration date</td>
                                    <td>MPrice</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="reportDetails.addendumThree.transferHistory[i].length > 0">
                                <tr class="cloud-bottom-border" *ngFor="let info of reportDetails.addendumThree.transferHistory[i]">
                                    <td>{{info.transactionDate}}</td>
                                    <td>{{info.registrationDate}}</td>
                                    <td>R {{info.price}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="reportDetails.addendumThree.transferHistory[i].length == 0">
                                <tr class="cloud-bottom-border">
                                    <td>N/A</td>
                                    <td>N/A</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        <table>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>Price with inflation</td>
                                    <td>
                                        <span *ngIf="reportDetails.valuation.sellerAskingPrice[i] > 0">R
                                            {{reportDetails.valuation.sellerAskingPrice[i]}}</span>
                                        <span *ngIf="reportDetails.valuation.sellerAskingPrice[i] == 0 || reportDetails.valuation.sellerAskingPrice[i] == null">Seller uncertain</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="cols-12 mt-4" *ngIf="section.id == 4" [hidden]="!section.checked">
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        <table>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>Price</td>
                                    <td><input [(ngModel)]="reportDetails.valuation.proposedSellingPrice[i]"
                                        class="paragraph my-1" type="number"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 paragraph">
                        <table>
                            <thead>
                                <tr>
                                    <th>Confident Level</th>
                                    <th class="motivate-column">Motivate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cloud-bottom-border">
                                    <td>
                                        <div class="row">
                                            <mat-radio-group name="confidenceLevelRadioOptions" [(ngModel)]="reportDetails.valuation.confidenceLevel[i]">
                                                <mat-radio-button class="paragraph" *ngFor="let selectionValue of confidenceLevelRadioOptions"
                                                    [value]="selectionValue.key">
                                                    {{selectionValue.value}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </td>
                                    <td class="motivate-column">
                                        <textarea [(ngModel)]="reportDetails.valuation.motivateReason[i]" rows="4"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="justify-content-center d-flex mt-5" *appUserRole="['HeadOfJuniorPlanners', 'JuniorPlanner']">
    <button (click)="saveReport()" class="button mx-2">Save</button>
    <button (click)="submitReport()" class="button mx-2">Send</button>
</div>