<!--Delete section-->
<section *ngIf="data.showDelete == true">
    <div class="heading-2">
        Are you sure you want to delete Co-owner {{data.index + 1}} ({{data.coOwner.personalDetails.name}})?
    </div>
    <div class="row mt-3">
        <div class="col"></div>
        <div class="col-lg-4 col-6">
            <div class="row">
                <div class="col-6"><button mat-button (click)="onNoClick()">No</button></div>
                <div class="col-6"><button mat-button cdkFocusInitial color="warn"
                        (click)="deleteCoOwner()">Yes</button></div>
            </div>
        </div>
    </div>
</section>
<!--Add co-owner section-->
<section *ngIf="data.showDelete == false">
    <!--Personal Info-->
    <form id="ownershipForm" novalidate #ownershipForm="ngForm">
        <section class="submission-form">
            <div class="heading-5">
                <div class="row">
                    <div class="col-12">
                        Details of <span class="coral">co-owner</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalName" for="txtCoOwnerPersonalName" class="paragraph">Name:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalName" class="paragraph"
                        name="coOwnerName" [(ngModel)]="data.coOwner.personalDetails.name" required #coOwnerName="ngModel"
                        [ngClass]="{'invalid-input': coOwnerName.invalid, 'valid-input': coOwnerName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalSurname" for="txtCoOwnerPersonalSurname"
                        class="paragraph">Surname:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalSurname" class="paragraph"
                        name="coOwnerSurname" [(ngModel)]="data.coOwner.personalDetails.surname" required #coOwnerSurname="ngModel"
                        [ngClass]="{'invalid-input': coOwnerSurname.invalid, 'valid-input': coOwnerSurname.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerWorkPhone" for="txtCoOwnerWorkPhone" class="paragraph">Work Phone:</label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtCoOwnerWorkPhone" name="coOwnerWorkPhone" class="paragraph not-required"
                        [(ngModel)]="data.coOwner.personalDetails.alternativeMobile">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalMobile" for="txtCoOwnerPersonalMobile"
                        class="paragraph">Mobile:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalMobile" name="coOwnerPhoneNumber"
                        class="paragraph" [(ngModel)]="data.coOwner.personalDetails.phoneNumber" required #coOwnerPhoneNumber="ngModel"
                        minlength="10"
                        [ngClass]="{'invalid-input': coOwnerPhoneNumber.invalid, 'valid-input': coOwnerPhoneNumber.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(coOwnerPhoneNumber.errors && (coOwnerPhoneNumber.dirty || coOwnerPhoneNumber.touched)) && coOwnerPhoneNumber.errors.minlength">
                        *Mobile number must contain at least the 10 numbers
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalEmailAddress" for="txtCoOwnerPersonalEmailaddress"
                        class="paragraph">Email
                        Address:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalEmailaddress"
                        name="coOwnerEmailAddress" class="paragraph" [(ngModel)]="data.coOwner.personalDetails.emailAddress" required
                        #coOwnerEmailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': coOwnerEmailAddress.invalid, 
                                                    'valid-input': coOwnerEmailAddress.valid}">
                    <small class="form-control-feedback"
                        *ngIf="coOwnerEmailAddress.errors && (coOwnerEmailAddress.dirty || coOwnerEmailAddress.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalConfirmEmail" for="txtCoOwnerPersonalConfirmEmail"
                        class="paragraph">Confirm
                        Email:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalConfirmEmail"
                        name="coOwnerConfirmEmail" class="paragraph" required #coOwnerConfirmEmail="ngModel"
                        [pattern]="emailPattern" [(ngModel)]="data.coOwner.personalDetails.confirmEmail"
                        [ngClass]="{'invalid-input': (coOwnerEmailAddress.value != coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched), 
                                    'valid-input': (coOwnerEmailAddress.value == coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched)}">
                    <small class="form-control-feedback" [ngClass]="{'confirmEmail-invalid': (coOwnerEmailAddress.value != coOwnerConfirmEmail.value) && (coOwnerConfirmEmail.dirty || coOwnerConfirmEmail.touched), 
                                    'confirmEmail-valid': coOwnerEmailAddress.value == coOwnerConfirmEmail.value}">
                        *Confirm Email does not match Email Address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblCoOwnerPersonalIdNumber" for="txtCoOwnerPersonalIdNumber"
                        class="paragraph">Identity/Passport
                        Number:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCoOwnerPersonalIdNumber" name="coOwnerid_Number"
                        class="paragraph" [(ngModel)]="data.coOwner.personalDetails.id_Number" required #coOwnerid_Number="ngModel"
                        minlength="9" [ngClass]="{'invalid-input': coOwnerid_Number.invalid, 
                                                    'valid-input': coOwnerid_Number.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(coOwnerid_Number.errors && (coOwnerid_Number.dirty || coOwnerid_Number.touched)) && coOwnerid_Number.errors.minlength">
                        *Must must contain at least 9 characters
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Marital status of this co-owner?
                    </div>
                </div>
                <div class="col-12">
                    <mat-radio-group name="radioCoOwnerMaritialStatus" [(ngModel)]="data.coOwner.maritialStatus">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of maritialStatuses"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </section>
        <!--Spouse Section-->
        <section *ngIf="data.coOwner.maritialStatus == 2 || data.coOwner.maritialStatus == 3">
            <div class="submission-form">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Is your spouse a resident of the Republic
                    of South
                    Africa?
                </div>

                <div class="heading-5">
                    Details of <span class="coral">your spouse</span>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpouseTitle" for="txtCoOwnerSpouseTitle" class="paragraph">Title:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerSpouseTitle" class="paragraph"
                            name="coOwnerSpouseTitle" [(ngModel)]="data.coOwner.spouseDetails.title" required
                            #coOwnerSpouseTitle="ngModel"
                            [ngClass]="{'invalid-input': coOwnerSpouseTitle.invalid, 'valid-input': coOwnerSpouseTitle.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpousePersonalName" for="txtCoOwnerSpousePersonalName"
                            class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerSpousePersonalName" class="paragraph"
                            name="coOwnerSpouseName" [(ngModel)]="data.coOwner.spouseDetails.name" required
                            #coOwnerSpouseName="ngModel"
                            [ngClass]="{'invalid-input': coOwnerSpouseName.invalid, 'valid-input': coOwnerSpouseName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpousePersonalSurname" for="txtCoOwnerSpousePersonalSurname"
                            class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerSpousePersonalSurname"
                            class="paragraph" name="coOwnerSpouseSurname"
                            [(ngModel)]="data.coOwner.spouseDetails.surname" required #coOwnerSpouseSurname="ngModel"
                            [ngClass]="{'invalid-input': coOwnerSpouseSurname.invalid, 'valid-input': coOwnerSpouseSurname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpouseWorkPhone" for="txtCoOwnerSpousePersonalLandline" class="paragraph">Work
                            Phone:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="lblCoOwnerSpouseWorkPhone" name="coOwnerSpouseWorkPhone"
                            class="paragraph not-required" [(ngModel)]="data.coOwner.spouseDetails.alternativeMobile">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpousePersonalMobile" for="txtCoOwnerSpousePersonalMobile"
                            class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerSpousePersonalMobile"
                            name="coOwnerSpousePhoneNumber" class="paragraph"
                            [(ngModel)]="data.coOwner.spouseDetails.phoneNumber" required #coOwnerSpousePhoneNumber="ngModel"
                            minlength="10"
                            [ngClass]="{'invalid-input': coOwnerSpousePhoneNumber.invalid, 'valid-input': coOwnerSpousePhoneNumber.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(coOwnerSpousePhoneNumber.errors && (coOwnerSpousePhoneNumber.dirty || coOwnerSpousePhoneNumber.touched)) && coOwnerSpousePhoneNumber.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpousePersonalEmailAddress" for="txtCoOwnerSpousePersonalEmailaddress"
                            class="paragraph">Email
                            Address:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerSpousePersonalEmailaddress"
                            name="coOwnerSpouseEmailAddress" class="paragraph"
                            [(ngModel)]="data.coOwner.spouseDetails.emailAddress" required
                            #coOwnerSpouseEmailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': coOwnerSpouseEmailAddress.invalid, 
                                                'valid-input': coOwnerSpouseEmailAddress.valid}">
                        <small class="form-control-feedback"
                            *ngIf="coOwnerSpouseEmailAddress.errors && (coOwnerSpouseEmailAddress.dirty || coOwnerSpouseEmailAddress.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblCoOwnerSpousePersonalIdNumber" for="txtCoOwnerSpousePersonalIdNumber"
                            class="paragraph">Identity/Passport
                            Number:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtCoOwnerCoOwnerSpousePersonalIdNumber"
                            name="coOwnerSpouseid_Number" class="paragraph"
                            [(ngModel)]="data.coOwner.spouseDetails.id_Number" required
                            #coOwnerSpouseid_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': coOwnerSpouseid_Number.invalid, 
                                                'valid-input': coOwnerSpouseid_Number.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(coOwnerSpouseid_Number.errors && (coOwnerSpouseid_Number.dirty || coOwnerSpouseid_Number.touched)) && coOwnerSpouseid_Number.errors.minlength">
                            *Must must contain at least 9 characters
                        </small>
                    </div>
                </div>
            </div>
        </section>
        <!--Confirm Section-->
        <section *ngIf="data.coOwner.maritialStatus > 0" class="submission-form">
            <div class="heading-5"><u>Please confirm</u></div>
            <div class="row">
                <div class="col-12">
                    <label class="checkbox-container">
                        <span class="paragraph">I have permission from all other owners to fill in this form
                            and to
                            provide Property Matrix with information on their behalf. I can
                            confirm that all owners agree to sell this property.</span>
                        <input type="checkbox" [(ngModel)]="data.coOwner.confirmConsent"
                            name="coOwnerConfirmPermission" #coOwnerConfirmPermission="ngModel"
                            id="cbCoOwnerConfirmPermission" data-toggle="toggle" (click)="scroll('btnSaveCoOwner')" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="row text-center mt-3" >
                    <div class="col-12">
                        <button id="btnSaveCoOwner" mat-button class="button heading-4 mt-2" [disabled]="!(data.coOwner.confirmConsent && ownershipForm.valid)" [mat-dialog-close]="data"
                            (click)="saveCoOwner(ownershipForm.valid)">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </form>
</section>