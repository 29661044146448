import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { UrbanPlannerFormVm } from 'src/app/Services/propertypros/models';
import { UrbanPlannerService, UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-planner-register-form',
  templateUrl: './planner-register-form.component.html',
  styleUrls: ['./planner-register-form.component.scss']
})
export class PlannerRegisterFormComponent implements OnInit {

  pageNumber: Number = 1;
  loading: boolean = false;
  user: any = null;
  fileUploader: FileUploader = null;

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'INFORMATION' },
    { key: 2, value: 'EXTENDED NETWORK' },
    { key: 3, value: 'SUBMIT DOCUMENTS' },
    { key: 4, value: 'SIGN CONTRACT' },
  ];

  form: UrbanPlannerFormVm = {
    urbanPlannerDetails: null,
    urbanPlannerExtendedNetwork: null,
    paths: null,
    username: ''
  }

  constructor(private router: Router, private urbanPlannerService: UrbanPlannerService, private sharedService: PropertiProSharedService, private userService: UserService) { }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
    sessionStorage.removeItem('originalUrl');
    this.user = sessionStorage.getItem('user');
    if (!this.user) {
      this.router.navigateByUrl('/login')
    } else {
      this.isNormalUser();
    }
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  assignUrbanPlannerDetails($event) {
    this.form.urbanPlannerDetails = $event;
  }

  assignUrbanPlannerExtendedNetwork($event) {
    this.form.urbanPlannerExtendedNetwork = $event;
  }

  assignFiles($event) {
    this.form.paths = $event;
    this.addOrRemoveBackgroundImage();
    this.submitForm();
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  addOrRemoveBackgroundImage(){
    if(this.pageNumber == 4){
      document.getElementById('main').classList.add('background-image');
    }else{
      document.getElementById('main').classList.remove('background-image');
    }
  }

  submitForm() {
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.urbanPlannerService.apiV1UrbanPlannerCaptureUrbanPlannerDetailsPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {

            });
            //TODO
            //this.sharedService.setUpdatedUserDetails();
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {
          })
          this.loading = false;
        }
      })
  }

  isNormalUser() {
    const userModel = JSON.parse(JSON.parse(this.user));

    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an urban planner').then(result => {
              this.router.navigateByUrl('/home');
            })
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an urban planner').then(result => {
            this.router.navigateByUrl('/home');
          })
          this.loading = false;
        }
      })
  }
}
