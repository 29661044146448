<form id="companyInformationForm" novalidate #companyInformationForm="ngForm" class="submission-form">
    <section class="mat-input-section">
        <div class="heading-2 mt-4">STEP 2: WORK-RELATED INFORMATION</div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Your EAAB Fidelity Fund
                    Certificate number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txteaab_FidelityFindCetrificateNumber" class="paragraph"
                    name="eaab_FidelityFindCetrificateNumber" [(ngModel)]="form.eaab_FidelityFindCetrificateNumber"
                    required #eaab_FidelityFindCetrificateNumber="ngModel"
                    [ngClass]="{'invalid-input': eaab_FidelityFindCetrificateNumber.invalid, 'valid-input': eaab_FidelityFindCetrificateNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Your individual
                    reference number: </label>
            </div>
            <div class="col-7">
                <input type="text" id="txtIndividualReferenceNumber" class="paragraph" name="individualReferenceNumber"
                    [(ngModel)]="form.individualReferenceNumber" #individualReferenceNumber="ngModel">
            </div>
        </div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>Please select:
        </div>
        <div>
            <mat-radio-group name="affiliatedWithFranchisedEstateAgency"
                [(ngModel)]="affiliatedWithFranchisedEstateAgency">
                <mat-radio-button class="paragraph"
                    *ngFor="let selectionValue of affiliatedWithFranchisedEstateAgencyRadioOptions"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </section>
    <section *ngIf="affiliatedWithFranchisedEstateAgency > 0" class="submission-form">
        <div *ngIf="affiliatedWithFranchisedEstateAgency == 1" class="submission-form">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Name of the agency:</label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtAgencyName" class="paragraph" name="agencyName"
                        [(ngModel)]="form.agencyName" required #agencyName="ngModel"
                        [ngClass]="{'invalid-input': agencyName.invalid, 'valid-input': agencyName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Name of the branch you
                        are operating from:</label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtBranchName" class="paragraph not-required" name="branchName"
                        [(ngModel)]="form.branchName" #branchName="ngModel">
                </div>
            </div>
        </div>
        <div *ngIf="affiliatedWithFranchisedEstateAgency == 2" class="submission-form">
            <div class="row">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': agencyName.invalid, 'mat-valid-input': agencyName.valid}">Under
                        which name do you operate as independent agent/business?</label></div>
                <div class="col-10">
                    <input name="agencyName" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.agencyName" required #agencyName="ngModel">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Your firm PIN nr:</label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtFirmPinNumber" class="paragraph" name="firmPinNumber"
                        [(ngModel)]="form.firmPinNumber" required #firmPinNumber="ngModel"
                        [ngClass]="{'invalid-input': firmPinNumber.invalid, 'valid-input': firmPinNumber.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">If registered for VAT,
                        your firm's VAT number:</label>
                </div>
                <div class="col-7">
                    <input type="text" id="txtFirmVatNumber" class="paragraph not-required" name="firmVatNumber"
                        [(ngModel)]="form.firmVatNumber" #firmVatNumber="ngModel">
                </div>
            </div>
        </div>
        <div class="heading-5">
            <span *ngIf="affiliatedWithFranchisedEstateAgency == 1">Physical address of the branch:</span>
            <span *ngIf="affiliatedWithFranchisedEstateAgency == 2">The physical address of your office:</span>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                    class="paragraph not-required" [(ngModel)]="form.branchAddress.unitNo">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                    class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                    class="paragraph not-required" [(ngModel)]="form.branchAddress.complexName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                    class="paragraph" [(ngModel)]="form.branchAddress.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                    class="paragraph" [(ngModel)]="form.branchAddress.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                    [(ngModel)]="form.branchAddress.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                    [(ngModel)]="form.branchAddress.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
        <div *ngIf="affiliatedWithFranchisedEstateAgency == 1" class="submission-form">
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>Are you the principal of the branch?
            </div>
            <div>
                <mat-radio-group name="principalOfBranch" [(ngModel)]="principalOfBranch">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="affiliatedWithFranchisedEstateAgency == 2" class="submission-form">
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>Are you registered as a principal at the EAAB?
            </div>
            <div>
                <mat-radio-group name="eaab_RegisteredPrincipal" [(ngModel)]="eaab_RegisteredPrincipal">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="eaab_RegisteredPrincipal > 0">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>Do you report to any principal?
                </div>
                <div>
                    <mat-radio-group name="reportsToPrincipal" [(ngModel)]="reportsToPrincipal">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </section>
    <section
        *ngIf="(affiliatedWithFranchisedEstateAgency == 1 && principalOfBranch == 2) || (affiliatedWithFranchisedEstateAgency == 2 && reportsToPrincipal == 1)"
        class="submission-form">
        <div class="heading-5">Please provide the following details <span class="coral-text">of your principal:</span>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                    [(ngModel)]="form.principalDetails.name" required #name="ngModel"
                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                    [(ngModel)]="form.principalDetails.surname" required #surname="ngModel"
                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Landline:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtLandline" name="landline" class="paragraph not-required"
                    [(ngModel)]="form.principalDetails.landline">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber" class="paragraph"
                    [(ngModel)]="form.principalDetails.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblWorkPhone" for="txtWorkPhone" class="paragraph">Alternative Mobile:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtWorkPhone" name="workPhone" class="paragraph not-required"
                    [(ngModel)]="form.principalDetails.alternativeMobile">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                    Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                    class="paragraph" [(ngModel)]="form.principalDetails.emailAddress" required #emailAddress="ngModel"
                    [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                    Email:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                    class="paragraph" required #confirmEmail="ngModel" [pattern]="emailPattern"
                    [(ngModel)]="form.principalDetails.confirmEmail"
                    [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                'confirm-valid': emailAddress.value == confirmEmail.value}">
                    *Confirm Email does not match Email Address
                </small>
            </div>
        </div>
    </section>
    <section *ngIf="reportsToPrincipal > 0 || principalOfBranch > 0" class="submission-form">
        <div class="heading-4">Bank account details</div>
        <div class="heading-5">Account where your commission – with the consent of your franchise (if applicable) – can
            be paid into</div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Account holder:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAccountHolder" class="paragraph" name="accountHolder"
                    [(ngModel)]="form.bankDetails.accountHolder" required #accountHolder="ngModel"
                    [ngClass]="{'invalid-input': accountHolder.invalid, 'valid-input': accountHolder.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Bank:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtBank" class="paragraph" name="bank"
                    [(ngModel)]="form.bankDetails.bank" required #bank="ngModel"
                    [ngClass]="{'invalid-input': bank.invalid, 'valid-input': bank.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Branch name:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtBranchName" name="landline" class="paragraph not-required"
                    [(ngModel)]="form.bankDetails.branchName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Branch number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtBranchNumber" class="paragraph" name="branchNumber"
                    [(ngModel)]="form.bankDetails.branchNumber" required #branchNumber="ngModel"
                    [ngClass]="{'invalid-input': branchNumber.invalid, 'valid-input': branchNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Type of account:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtTypeOfAccount" class="paragraph" name="typeOfAccount"
                    [(ngModel)]="form.bankDetails.typeOfAccount" required #typeOfAccount="ngModel"
                    [ngClass]="{'invalid-input': typeOfAccount.invalid, 'valid-input': typeOfAccount.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label class="paragraph">Account number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAccountNumber" class="paragraph" name="accountNumber"
                    [(ngModel)]="form.bankDetails.accountNumber" required #accountNumber="ngModel"
                    [ngClass]="{'invalid-input': accountNumber.invalid, 'valid-input': accountNumber.valid}">
            </div>
        </div>
    </section>
    <section>
        <div class="row text-center">
            <div class="col-10">
                <button id="btnSaveFinal" class="button heading-4" [disabled]="companyInformationForm.invalid"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>