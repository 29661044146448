<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="pdf-viewer-container mt-2">
            <ng2-pdfjs-viewer #pdfViewer [download]="false"></ng2-pdfjs-viewer>
        </div>
    </mat-sidenav>
    <main>
        <div class="container-fluid">
            <ng-container *ngFor="let addendum of addendums; let i = index">
                <div class="heading-2 text-center mt-4">ADDENDUMS - EXTERNAL PROFESSIONALS for Property {{addendumName(addendum)}}</div>
                <div class="row mt-4">
                    <div class="col-4 col-xl-3" *ngFor="let info of addendum">
                        <div class="row">
                            <div class="col-6">
                                <mat-icon class="file-icon">description</mat-icon>
                            </div>
                            <div class="col-6 paragraph vertical-center view-file"
                                (click)="openAddendum(info.fileId, info.fileType)">
                                View
                            </div>
                        </div>
                        <div class="row paragraph">
                            <div class="col-6">Published By:</div>
                            <div class="col-6">{{ info.publishedBy }}</div>
                        </div>
                        <hr>
                        <div class="row paragraph">
                            <div class="col-6">Type:</div>
                            <div class="col-6">{{ info.typeOfReport }}</div>
                        </div>
                        <hr>
                        <div class="row paragraph">
                            <div class="col-6">Created:</div>
                            <div class="col-6">{{ info.created | date }}</div>
                        </div>
                        <hr>
                    </div>
                </div>
            </ng-container>
            <div class="row mt-4">
                <div class="col-12">
                    <button class="button" (click)="openAddReportDialog()" id="addReportButton">
                        Add Report
                    </button>
                </div>
            </div>
        </div>
    </main>
</mat-sidenav-container>