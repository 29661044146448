<!--Delete Report Confirmation-->
<section *ngIf="data.showDelete == true">
    <div class="heading-2">
        Are you sure you want to delete report {{data.index + 1}}?
    </div>
    <div class="row mt-4">
        <div class="col"></div>
        <div class="col-lg-4 col-6">
            <div class="row">
                <div class="col-6"><button mat-button (click)="onNoClick()">No</button></div>
                <div class="col-6"><button mat-button color="warn" (click)="deleteReport()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Add/Edit Report-->
<section *ngIf="data.showDelete == false">
    <form id="reportDialogForm" novalidate #reportDialogForm="ngForm">
        <div class="submission-form">
            <div class="heading-2">Report {{data.reportNumber}}:</div>
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <div class="col-4">
                            <label id="lblTypeOfReport" for="txtTypeOfReport" class="paragraph">Type of report:</label>
                        </div>
                        <div class="col-8">
                            <input type="text" placeholder="Mandatory" id="txtTypeOfReport" name="typeOfReport"
                                class="paragraph" [(ngModel)]="data.report.typeOfReport" required #typeOfReport="ngModel"
                                [ngClass]="{'invalid-input': typeOfReport.invalid, 'valid-input': typeOfReport.valid}"
                                autocomplete="off">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label id="lblDateReleased" for="txtDateReleased" class="paragraph">Date done/released:</label>
                        </div>
                        <div class="col-8">
                            <input type="date" placeholder="Mandatory" id="txtDateReleased" name="dateReleased"
                                class="paragraph date-picker" [(ngModel)]="data.report.dateReleased" required
                                #dateReleased="ngModel"
                                [ngClass]="{'invalid-input': dateReleased.invalid, 'valid-input': dateReleased.valid}"
                                autocomplete="off" onclick="this.showPicker()">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-4">
                            <label id="lblPublishedBy" for="txtPublishedBy" class="paragraph">Published by:</label>
                        </div>
                        <div class="col-8">
                            <input type="text" placeholder="Mandatory" id="txtPublishedBy" name="publishedBy" class="paragraph"
                                [(ngModel)]="data.report.publishedBy" required #publishedBy="ngModel"
                                [ngClass]="{'invalid-input': publishedBy.invalid, 'valid-input': publishedBy.valid}"
                                autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="block-wrapper text-center heading-5">
                        <div *ngIf="!data.uploadReports" class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, 0)">
                            <input type="file" #fileDropRef id="fileDropRef" (change)="selectUpload($event.target.files, 0)"/>
                            <div class="row">
                                <div class="col-12">Report</div>
                                <div class="col-12 paragraph">(Click or drop a file)</div>
                                <div class="col-12">
                                    <mat-icon class="file-icon">cloud_upload</mat-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="data.uploadReports" class="grey-block">
                            <div class="row paragraph">
                                <div class="col-12">
                                    <mat-icon class="file-icon">insert_drive_file</mat-icon>
                                </div>
                                <h4 class="col-12 paragraph bold">
                                    {{data.uploadReports.file.name}}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center mt-3">
                <div class="col-12">
                    <button id="btnSaveCoOwner" mat-button [disabled]="reportDialogForm.invalid" class="button heading-4 mt-2"
                        [mat-dialog-close]="data">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    </form>
</section>