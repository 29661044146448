import { ComponentType } from '@angular/cdk/portal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { map } from 'rxjs';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FileUploader } from 'src/app/models/file-uploader';
import { ReportDialogData } from 'src/app/models/sellingMyProperty/ReportDialogData';
import { ReportFile } from 'src/app/models/sellingMyProperty/ReportFile';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { CheckboxVm, UploadDocumentDetailsVm, UploadDocumentsFileReturnVm, UploadDocumentsReportDetailsVm } from 'src/app/Services/propertypros/models';
import { FileService } from 'src/app/Services/propertypros/services';
import { environment } from 'src/environments/environment';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class UploadDocumentsComponent {
  @ViewChild(MatTable) table: MatTable<any>;
  constructor(private sharedService: PropertiProSharedService, public dialog: MatDialog, private http: HttpClient) { }

  @Output() openSideNavEvent = new EventEmitter<number>();
  @Output() uploadDocumentDetailsEvent = new EventEmitter<UploadDocumentDetailsVm>();
  @Input() ownershipDetailsId: number = 0;

  fileUploader = new FileUploader();
  emailPattern: string = GlobalConstants.emailPattern;

  clicked: boolean = false;
  loading: boolean = false;
  professionalReportsActive: boolean = false;

  displayedColumns: string[] = ['report', 'action1', 'action2'];

  canMakeAvailableOptionsCheckBox: CheckboxVm[] = [
    { id: 1, value: 'Title deed', checked: false },
    { id: 2, value: 'Approved building plans (indicating all structures built on the site)', checked: false },
    { id: 3, value: 'Older buildings plans – not approved nor updated', checked: false },
    { id: 4, value: 'Other maps/plans', checked: false },
    { id: 5, value: 'Professional reports (done by engineers, environmental- or geotechnical specialists, etc.)', checked: false },
    { id: 6, value: 'Other/related documents', checked: false },
    { id: 7, value: 'Pictures of the property (recently taken - available in png or jpeg format) ', checked: false },
    { id: 8, value: 'None of the above', checked: false },
  ]

  form: UploadDocumentDetailsVm = {
    availableOptions: this.canMakeAvailableOptionsCheckBox,
    troubleUploadingCopies: false,
    reports: [],
    paths: [],
  }

  formData: FormData = new FormData();

  report: UploadDocumentsReportDetailsVm = {
    dateReleased: null,
    publishedBy: '',
    typeOfReport: '',
  }

  uploadBlocks: any[] = [
    { key: 1, value: 'Municipal rates account', file: null },
    { key: 2, value: 'RSA residents: Copy of ID', file: null },
    { key: 3, value: 'Non-RSA residents: Copy of Passport', file: null },
    { key: 4, value: 'Married in community: Copy of spouse\'s ID', file: null },
    { key: 5, value: 'Commission claim from Agent', file: null },
  ]

  uploadReports: any[] = []

  submitForm() {
    this.loading = true;
    if(this.fileUploader.files.length == 0){
      this.emitFinalForm(null)
      return;
    }
    let formData = new FormData();
    const username: string = this.sharedService.getUsername();
    this.fileUploader.files.forEach(fileObj => {
      formData.append(`${username}|${fileObj.id.toString()}|${this.ownershipDetailsId}|`, fileObj.file);
    });
    formData.append('username', username);
    let headers = new HttpHeaders();
    headers = headers.set("key", GlobalConstants.fileUploadKey)

    this.http.post(environment.APIUrl + FileService.ApiV1FileUploadFilesPostPath, formData, {headers: headers}).pipe(
      map((result) => {
        return result;
      })).subscribe({
        next: (result) => {
          this.emitFinalForm(result);
        },
        error: () => {
          this.loading = false;
          GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.");
        }
      });
  }
  emitFinalForm(result: any) {
    this.form.availableOptions = this.canMakeAvailableOptionsCheckBox.filter(f => f.checked == true);
    if (result) {
      this.form.paths = result as Array<UploadDocumentsFileReturnVm>;
    } 
    this.uploadDocumentDetailsEvent.emit(this.form);
    this.loading = false;
  }
  selectUpload(files, id) {
    this.fileUploader.specificFileBrowseHandler(files, id);
    this.updateShownBlocks(files[0], id);
  }
  dropUpload($event, id) {
    this.fileUploader.specificOnFileDropped($event, id);
    this.updateShownBlocks($event[0], id);
  }
  deleteFile(block, blockIndex) {
    this.uploadBlocks[blockIndex].file = null;
    let item = this.fileUploader.files.find(f => f.id == block.key);
    let fileIndex = this.fileUploader.files.indexOf(item);
    this.fileUploader.deleteFile(fileIndex);
  }
  updateShownBlocks(file, id) {
    let uploadBlock = this.uploadBlocks[id - 1]
    uploadBlock.show = this.fileUploader.files.filter(f => f.id == id).length > 0;
    uploadBlock.file = file;
  }
  checkprofessionalReportsActive() {
    if (this.clicked == true) {
      this.clicked = false;
      return;
    }
    this.clicked = true;
    setTimeout(() => {
      this.professionalReportsActive = this.canMakeAvailableOptionsCheckBox.find(f => f.id == 5).checked;
      if (this.professionalReportsActive == true) {
        setTimeout(() => {
          GlobalMethods.scrollToTop();
        }, 50);
      }
    }, 1);
  }
  openDialog<T>(dialogComponent: ComponentType<T>, width: string, height: string, data: object) {
    return this.dialog.open(dialogComponent, {
      width: width,
      height: height,
      data: data
    });
  }
  openAddReportDialog() {
    const dialogRef = this.openDialog(UploadDocumentsReportDialog, '75vw', '60vh', 
    { 
      report: this.report, index: 0, showDelete: false, reportNumber: this.form.reports.length + 1
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var reportfile: any[] = [result.uploadReports.file];
        this.uploadReports.push(result.uploadReports);
        this.fileUploader.specificFileBrowseHandler(reportfile, 6);
        this.form.reports.push(result.report);
        this.table.renderRows();
        this.clearReport();
      }
    });
  }
  openEditReportDialog(report: UploadDocumentsReportDialog, index: number) {
    const dialogRef = this.openDialog(UploadDocumentsReportDialog, '75vw', '60vh', 
    { 
      report: report, index: index, showDelete: false, reportNumber: index + 1
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.table.renderRows();
      }
    });
  }
  deleteReport(report: UploadDocumentsReportDialog, index: number) {
    const dialogRef = this.openDialog(UploadDocumentsReportDialog, null, null, 
      { 
        report: report, index: index, showDelete: true, deleteReport: false 
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleteReport == true) {
          this.table.renderRows();
          this.form.reports.splice(index, 1);
        }
      }
    });
  }
  clearReport() {
    this.report = {
      dateReleased: null,
      publishedBy: '',
      typeOfReport: '',
    }
  }
  openSideNav(page: number) {
    this.openSideNavEvent.emit(page);
  }
  canMakeAvailableContainsChecked() {
    return this.canMakeAvailableOptionsCheckBox.filter(f => f.checked == true).length > 0;
  }
}

@Component({
  selector: 'upload-documents-report-dialog',
  templateUrl: './upload-documents-report-dialog.html',
  styleUrls: ['./upload-documents.component.scss', '../../../../css/2-modules/_forms.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ]
})
export class UploadDocumentsReportDialog {
  constructor(
    public dialogRef: MatDialogRef<UploadDocumentsReportDialog>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ReportDialogData
  ) { }

  selectUpload(files) {
    this.updateShownBlocks(files[0]);
  }

  dropUpload($event) {
    this.updateShownBlocks($event[0]);
  }

  updateShownBlocks(file) {
    var report: ReportFile = { key: 1, value: 'Report ' + 1, file: file };
    this.data.uploadReports = report;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitReport() {
    this.dialogRef.close({report: this.data.report, reportFiles: this.data.uploadReports});
  }

  deleteReport() {
    this.data.deleteReport = true;
    this.dialogRef.close(this.data);
  }
}