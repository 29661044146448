<main style="background-color: white">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                REGISTRATION
                            </h1>
                        </div>
                    </div>
                    <div class="paragraph text-center">
                        <p>Congratulations on the smart decision to join the <strong>Property Matrix</strong> network.
                        </p>
                        <p><strong>It will take about 15-20 minutes to complete this form through 4 easy steps:</strong>
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    1
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Provide futher
                                    personal and
                                    company
                                    information
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    2
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Details of
                                    your extended
                                    professional
                                    network
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center right-border">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    3
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Upload
                                    and submit
                                    a few
                                    documents
                                </div>
                            </div>
                        </div>
                        <div class="col-3 text-center">
                            <div class="row">
                                <div class="col heading-3 bold">
                                    STEP
                                </div>
                            </div>
                            <div class="row">
                                <div class="col numbers bold">
                                    4
                                </div>
                            </div>
                            <div class="row">
                                <div class="col heading-5 bold">
                                    Await
                                    confirmation
                                    email with
                                    contract
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph m-5">
                        <strong>For STEP 3, please have the following ready to submit in PDF format:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li>Copy of your identification document</li>
                            <li>Up to date registration certificate with SACPLAN</li>                            
                        </ul>
                        <strong>Optional:</strong>
                        <ul class="montserrat-bullets paragraph align-height paragraph">
                            <li>Certified copy of your town and regional planning degree certificate</li>
                            <li>Certified copy(ies) of all additional professional qualifications and/or designations</li>                            
                            <li>A photo of yourself to be used by <strong>Property Matrix</strong> for office & admin purposes only</li>                            
                            <li>A logo of your company to be used by <strong>Property Matrix</strong> for marketing purposes</li>                            
                        </ul>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="paragraph text-center">
                                <button class="button heading-3" id="proceed" [routerLink]="['/plannerRegisterForm']">
                                    PROCEED
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</main>