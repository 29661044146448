<mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav class="container" #sidenav (keydown.escape)="closeNav()" position="end">
        <div class="row">
            <div class="col-11 pt-2">
                <h1 class="heading-1">PROPERTY TYPE (LAND USES)</h1>
            </div>
            <div class="col-1 btn-close-align pt-2">
                <button type="button" id="btnClose" class="btn-close btn-close" (click)="closeNav()"
                    aria-label="Close"></button>
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">BUSINESS & SERVICES</th>
                    </tr>
                    <tr>
                        <td><b>Retail</b></td>
                    </tr>
                    <tr>
                        <td>Shop, Market, Mail</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Administrative</b></td>
                    </tr>
                    <tr>
                        <td>Bank, Financial services</td>
                    </tr>
                    <tr>
                        <td>Office building/block/park</td>
                    </tr>
                    <tr>
                        <td>Call centre, Computres</td>
                    </tr>
                    <tr>
                        <td>Postal services, Internet café</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Beauty</b></td>
                    </tr>
                    <tr>
                        <td>Salon, Beauty/health spa</td>
                    </tr>
                    <tr>
                        <td><b>Vehicles</b></td>
                    </tr>
                    <tr>
                        <td>Filling station, Car wash</td>
                    </tr>
                    <tr>
                        <td>Services: Repair, Testing</td>
                    </tr>
                    <tr>
                        <td>Sales: Dealership, Display</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Food & Drinks</b></td>
                    </tr>
                    <tr>
                        <td>Food, Beverages, Alcohol</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Animal Care (excl. sales)</b></td>
                    </tr>
                    <tr>
                        <td>Vet, Rehab, SPCA</td>
                    </tr>
                    <tr>
                        <td>Kennels, Boarding</td>
                    </tr>
                    <tr>
                        <td>Grooming, Training</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Plants</b></td>
                    </tr>
                    <tr>
                        <td>Garden centre, Nursery</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Household Services</b></td>
                    </tr>
                    <tr>
                        <td>Laundry, Carpet/dry cleaners</td>
                    </tr>
                    <tr>
                        <td>Appliance repairs</td>
                    </tr>
                    <tr>
                        <td>Recycling centre</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Workshops</b></td>
                    </tr>
                    <tr>
                        <td>Lawn, Paint, Print, Plumbing</td>
                    </tr>
                </table>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">RESIDENTIAL & LODGING</th>
                    </tr>
                    <tr>
                        <td><b>Single Residential</b></td>
                    </tr>
                    <tr>
                        <td>Dwelling house</td>
                    </tr>
                    <tr>
                        <td>House with 2nd dwelling</td>
                    </tr>
                    <tr>
                        <td>House with home business</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Multiple Residential</b></td>
                    </tr>
                    <tr>
                        <td>Estate, Complex</td>
                    </tr>
                    <tr>
                        <td>Block of flats</td>
                    </tr>
                    <tr>
                        <td>Old age/nursing home</td>
                    </tr>
                    <tr>
                        <td>Embassy, Consulate</td>
                    </tr>
                    <tr>
                        <td>Mobile units, Home park</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Temporary Accommodation</b></td>
                    </tr>
                    <tr>
                        <td>Guest & Short stay</td>
                    </tr>
                    <tr>
                        <td>Leisure: Resort, Camping</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">SPORT & ENTERTAINMENT</th>
                        </tr>
                        <tr>
                            <td><b>Sport</b></td>
                        </tr>
                        <tr>
                            <td>Facilities, Grounds</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Entertainment</b></td>
                        </tr>
                        <tr>
                            <td>Hall, Conference, Expo</td>
                        </tr>
                        <tr>
                            <td>Amusement, Entertainment</td>
                        </tr>
                    </table>
                </div>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">MEDICAL & WELFARE</th>
                        </tr>
                        <tr>
                            <td><b>Medical & Health Care</b></td>
                        </tr>
                        <tr>
                            <td>Pharmacy</td>
                        </tr>
                        <tr>
                            <td>Clinic, Medical suites</td>
                        </tr>
                        <tr>
                            <td>Hospital</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Institutional Welfare</b></td>
                        </tr>
                        <tr>
                            <td>Special care, Supervision</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">EDUCATION & RESEARCH</th>
                    </tr>
                    <tr>
                        <td><b>Education</b></td>
                    </tr>
                    <tr>
                        <td>After school, Day care</td>
                    </tr>
                    <tr>
                        <td>Pre-primary, Creche</td>
                    </tr>
                    <tr>
                        <td>Primary & Secondary</td>
                    </tr>
                    <tr>
                        <td>Tertiary, Technical</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Research & Developent</b></td>
                    </tr>
                    <tr>
                        <td>Laboratory, Research inst.,</td>
                    </tr>
                    <tr>
                        <td>Library, Museums, Gallery</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">INDUSTRIAL</th>
                        </tr>
                        <tr>
                            <td><b>General Industry</b></td>
                        </tr>
                        <tr>
                            <td>Panel-beater, Scrap yard</td>
                        </tr>
                        <tr>
                            <td>Abattoir, Noxious</td>
                        </tr>
                        <tr>
                            <td>Other industrial</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Storage & Distribution</b></td>
                        </tr>
                        <tr>
                            <td>Containers, Self/public storage</td>
                        </tr>
                        <tr>
                            <td>Warehouse, Yards, Depot</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-3">
                <table>
                    <tr>
                        <th class="heading-5">TRANSPORT</th>
                    </tr>
                    <tr>
                        <td><b>Land</b></td>
                    </tr>
                    <tr>
                        <td>Parking site</td>
                    </tr>
                    <tr>
                        <td>Truck stop</td>
                    </tr>
                    <tr>
                        <td>Taxi rank</td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td><b>Air</b></td>
                    </tr>
                    <tr>
                        <td>Airport, hanger</td>
                    </tr>
                    <tr>
                        <td>Helistop, Heliport</td>
                    </tr>
                </table>
                <table class="bottom-border">
                    <tr>
                        <td><b>Water</b></td>
                    </tr>
                    <tr>
                        <td>Shipping port, Boat house</td>
                    </tr>
                </table>
                <div class="margin">
                    <table>
                        <tr>
                            <th class="heading-5">COMMUNITY & RELIGIOUS</th>
                        </tr>
                        <tr>
                            <td><b>Community</b></td>
                        </tr>
                        <tr>
                            <td>Community centre</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td><b>Religious</b></td>
                        </tr>
                        <tr>
                            <td>Religious centre, Place of
                                worship</td>
                        </tr>
                    </table>
                    <table class="bottom-border">
                        <tr>
                            <td><b>Funeral Services</b></td>
                        </tr>
                        <tr>
                            <td>Burial site, Pet cemetery</td>
                        </tr>
                        <tr>
                            <td>Crematorium, Funeral parlour</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <img src="../../../assets/images/home/Land Uses.png" alt="map with property matrix logo on"
            class="sidenav-image">
    </mat-sidenav>
    <div class="page-1" *ngIf="page == 1">
        <div class="container-fluid background-image">
            <div class="container center-block">
                <div class="heading-1 text-center pb-3">
                    SHARE IN OUR DATABASE:
                </div>
                <div class="paragraph text-center pb-3">
                    Our extensive range and unique classification of <u (click)="openNav()">property
                        types</u>
                    (land
                    uses) are exceptional, not equalled by
                    any
                    other
                    property website you have visited before. You will be able to indicate your exact property needs:
                    whether it
                    be
                    properties
                    relating to business, beauty, retail, culture, religion, education, animals, plants, vehicles,
                    medical
                    services,
                    residential with
                    home businesses… the list goes on.
                </div>
                <div class="paragraph text-center pb-4">
                    You name the industry. <b>Matrix</b> provides you with the property.
                </div>
                <div class="heading-4 text-center pb-1">
                    OUR OFFER:
                </div>
                <div class="heading-4 text-center pb-1">
                    You can subscribe to this knowledge advantage free of charge.
                </div>
                <ul class="montserrat-bullets paragraph pt-3">
                    <li>
                        We will notify you straight away when a property – according to your exact needs/niche – enters
                        the
                        market. This tool will radically reduce the time you spend on property investigations and
                        research.
                    </li>
                    <li>
                        <b>Every property presented to you will be accompanied by a planning report</b>, signed off by
                        an
                        experienced
                        professional planner, indicating its potential; possible uses (zoning) and, if applicable, –
                        “ripeness”
                        (whether a planning application is approved or in process).
                    </li>
                </ul>
                <div class="text-center py-1">
                    <button (click)="page = 2" class="button heading-4">REQUEST</button>
                </div>
            </div>
        </div>
    </div>
</mat-sidenav-container>
<div *ngIf="page == 2" class="background container-fluid">
    <div class="heading-1 text-center pb-3">SUBSCRIBE</div>
    <div class="paragraph text-center pb-3">Congratulations on the smart decision to tap into the <b>Property Matrix</b>
        database.</div>
    <div class="paragraph text-center pb-4"><b>It will take about 5-10 minutes to complete your first subscription
            through 4
            easy
            steps:</b></div>
    <div class="row pb-4">
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">1</div>
                <div class="heading-5 text-center">Required</div>
            </div>
        </div>
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">2</div>
                <div class="heading-5 text-center">Kick off with
                    subscribtion one</div>
            </div>
        </div>
        <div class="col-3">
            <div class="numbers-right-border">
                <div class="heading-3 text-center charcoal">STEP</div>
                <div class="numbers text-center">3</div>
                <div class="heading-5 text-center">Approve
                    subscription</div>
            </div>
        </div>
        <div class="col-3">
            <div class="heading-3 text-center charcoal">STEP</div>
            <div class="numbers text-center">4</div>
            <div class="heading-5 text-center">Add subscriptions
                or submit</div>
        </div>
    </div>
    <div class="pb-3 note-style">
        <div class="heading-5"><b>Please note:</b></div>
        <ul class="montserrat-bullets paragraph">
            <li>Multiple subscriptions will be required if you would like to be notified of different property
                types/sectors within different locations</li>
            <li>Property Matrix will not spam you - your selections will determine the number of notification
                emails you are about to receive.</li>
            <li>You will receive log-in details via email to add, delete or edit subscriptions at any given time.</li>
        </ul>
    </div>
    <div class="text-center">
        <button class="button heading-4" (click)="continueToForm()">PROCEED</button>
    </div>
</div>