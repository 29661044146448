
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { AddressVm, PropertyDetailsVm } from 'src/app/Services/propertypros/models';
import * as L from 'leaflet';
import { MapsService } from 'src/app/Services/propertypros/services';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PropertyDetailsComponent implements OnInit, AfterViewInit {

  constructor(private http: HttpClient, private mapsService: MapsService, private sharedService: PropertiProSharedService, public dialog: MatDialog) { }

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() openSideNavEvent = new EventEmitter<number>();
  @Output() propertyDetailsEvent = new EventEmitter<PropertyDetailsVm>();
  @Input() ownershipDetailsId: number = 0;

  public static activeSchemeProperty;

  preferedOption: number;
  hasImprovementPotential: number = 0;
  valueAlteringInfluences: number = 0;
  confirmCorrectAddress: number = 0;
  addressGeocodeData: any = null;
  addressLatLonData: any = null;
  addressDetailsData: any = null;
  addressSchemeData: PropertyDetailsDialogData;
  propertyDetailsData: any = null;
  loadingAddress: boolean = false;
  loadingCadaster: boolean = false;
  cadasterPicture: any = null;
  loaderHtml: string = `<div class="loader"></div>`;
  activeSchemeProperty: any;

  private map;
  activeMarker;

  smallMapIcon = L.icon({
    iconUrl: '../../assets/images/maps/icons/map-marker.png',
    iconSize: [25, 32],
  });

  mediumMapIcon = L.icon({
    iconUrl: '../../assets/images/maps/icons/map-marker.png',
    iconSize: [54, 64],
  });

  cadasterPictureBase64: string;

  physicalAddress: AddressVm = {
    unitNo: '',
    complexName: '',
    streetNo: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
  };

  form: PropertyDetailsVm = {
    sellMoreThanOneProperty: false,
    propertyDescription: '',
    confirmCorrectAddressDetails: false,
    mainPropertyUseOther: '',
    approximateBuildingAgeOther: null,
    hasValueAlteringInfluences: false,
    valueAlteringInfluencesDescription: '',
    tenantedBuildingsAmount: 0,
    hasImprovementPotential: false,
    futurePotential: '',
    latitude: '',
    longitude: '',
    correctAddress: false,
    askingPrice: 0,
    ownershipDetailsId: this.ownershipDetailsId,
    physicalAddress: this.physicalAddress,
    riskscapeInfo: {
      neighbourhoodId: '',
      sectionalTitleUnitNumber: '',
      subplaceId: '',
      surveyorGeneralKey: ''
    },
    riskscapeAddressGeocode: {
      data: []
    },
    riskscapeAddress: {
      data: null
    },
    riskscapePositionLookup: {
      data: null
    },
    riskscapePropertyDetails: {
      data: null
    },
  };

  askingPriceOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'My asking price: R' },
    { key: 2, value: 'I am uncertain. The Matrix value assessment team can do an objective assessment to determine the open market value of my property.' },
  ];
  allBuildingsDoneWithApprovedBuildingPlansOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes, all on approved building plans' },
    { key: 2, value: 'Yes, but plans are not approved and/or outdated (not all alterations and/or additions are indicated)' },
    { key: 3, value: 'No, I don\'t have building plans or any access to such' },
  ];
  hasCopiesOfApprovedBuildingPlansOptionsRadio: KeyValuePair[] = [
    { key: 1, value: '0-5' },
    { key: 2, value: 'More than 5' }
  ];
  tenantedBuildingsAmountOptionsRadio: KeyValuePair[] = [
    { key: 1, value: '0-5' },
    { key: 2, value: 'More than 5' }
  ];
  confirmCorrectAddressOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, please contact me to confirm my address location' }
  ];
  basicYesNoOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];
  occupationStatusOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Unoccupied' },
    { key: 2, value: 'Owner occupied' },
    { key: 3, value: 'Tenanted' },
  ];
  preferedOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Option 1: mobile application' },
    { key: 2, value: 'Option 2: details from municipal account or title deed' },
    { key: 3, value: 'Option 3: provide the correct street address only' }
  ];
  pleaseConfirmOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'I agree with the property boundary as indicated on the map via the Khaya app.' },
    { key: 2, value: 'I do not agree with the boundary as indicated on the map – corrections are required' }
  ];
  mainPropertyUseOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Vacant land' },
    { key: 2, value: 'Residential' },
    { key: 3, value: 'Industrial' },
    { key: 4, value: 'Offices' },
    { key: 5, value: 'Retail' },
    { key: 6, value: 'Other uses/specialised property – Please describe:' },
  ];
  approximateBuildingAgeOptionsRadio: KeyValuePair[] = [
    { key: 1, value: 'Newly constructed' },
    { key: 2, value: 'Less than 2 years' },
    { key: 3, value: '2 - 10 years' },
    { key: 4, value: '10 to 30 years' },
    { key: 5, value: '30 to 45 years ' },
    { key: 6, value: '45 to 60 years ' },
    { key: 7, value: 'Older than 60 years' },
    { key: 8, value: 'Uncertain, but I estimate:' },
  ];

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  submitForm() {
    this.form.hasImprovementPotential = this.hasImprovementPotential == 1;
    this.form.hasValueAlteringInfluences = this.valueAlteringInfluences == 1;
    this.form.correctAddress = this.confirmCorrectAddress == 1;
    this.form.imageBase64 = this.cadasterPictureBase64;
    if (this.form.riskscapeAddress.data == null){
      this.getAddressDetails(this.addressGeocodeData.attributes.address_id)
      this.getPropertyDetails(this.addressGeocodeData.attributes.property_key, this.addressGeocodeData.attributes.unit_number)
    }
      console.log(this.form, 11)
    this.gotoContractDetails();
    GlobalMethods.scrollToTop();
  }

  getAddressGeoCode() {
    this.loadingAddress = true;
    this.confirmCorrectAddress = 0;
    this.mapsService.apiV1MapsGetAddressGeocodePost$Response({ body: this.physicalAddress }).subscribe({
      next: (result: any) => {
        this.addressGeocodeData = JSON.parse(result.body);
        console.log(this.addressGeocodeData, 1)
        this.initMap();
        this.loadingAddress = false;
        if (this.addressGeocodeData.attributes.scheme_id) {
          this.getSchemeData(this.addressGeocodeData.attributes.scheme_id);
        } else {
          this.form.riskscapeAddressGeocode.data[0] = this.addressGeocodeData
          this.getAddressImage(this.addressGeocodeData.attributes.property_key);
          this.form.riskscapeAddress.data = null;
          this.form.riskscapePositionLookup.data = null;
          this.form.riskscapePropertyDetails.data = null;
          this.form.riskscapeInfo.surveyorGeneralKey = this.addressGeocodeData.attributes.property_key;
          this.form.riskscapeInfo.sectionalTitleUnitNumber = this.addressGeocodeData.attributes.unit_number;
        }
        GlobalMethods.scrollToBottom();
      },
      error: (error: any) => {
        this.loadingAddress = false;
        GlobalMethods.tinyErrorAlert('Error', 'Problem finding address');
      }
    })
  }

  async getAddressImage(propertyKey: string) {
    this.loadingCadaster = true;
    let result = await this.sharedService.getAddressImage(propertyKey);
    if (result?.ok) {
      const resultJson = JSON.parse(result.body);
      this.cadasterPictureBase64 = resultJson.encoded;
      const base64Response = await fetch(`data:image/jpeg;base64,${this.cadasterPictureBase64}`);
      const blob = await base64Response.blob();
      let objectURL = URL.createObjectURL(blob);
      this.cadasterPicture = objectURL;
      const markerHtml = this.generatePopupText();
      this.activeMarker.bindPopup(markerHtml, {
        maxWidth: 1000
      }).openPopup();
    } else {
      GlobalMethods.tinyErrorAlert('Error', 'Problem finding image');
    }
    this.loadingCadaster = false;
  }

  getAddressFromLatLon(lat: string, lon: string) {
    this.mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet$Response({ lat: lat, lon: lon }).subscribe({
      next: (result: any) => {
        this.form.riskscapeAddressGeocode.data[0] = null;
        this.form.riskscapePositionLookup.data = JSON.parse(result.body);
        console.log(this.form.riskscapePositionLookup.data, 2)
        this.addressLatLonData = this.form.riskscapePositionLookup.data;
        if (this.form.riskscapePositionLookup.data.type == 'scheme') {
          this.getSchemeData(this.form.riskscapePositionLookup.data.attributes.scheme_id)
        }
        if (this.form.riskscapePositionLookup.data.attributes.property_key) {
          this.getAddressDetails(this.form.riskscapePositionLookup.data.attributes.address_id)
          this.getAddressImage(this.form.riskscapePositionLookup.data.attributes.property_key);
          this.getPropertyDetails(this.form.riskscapePositionLookup.data.attributes.property_key, "00000")
          this.form.riskscapeInfo.surveyorGeneralKey = this.form.riskscapePositionLookup.data.attributes.property_key;
          this.form.riskscapeInfo.sectionalTitleUnitNumber = "00000";
        }
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'Problem finding location');
      }
    })
  }

  getAddressDetails(addressId: string) {
    this.mapsService.apiV1MapsGetAddressDetailsGet$Response({ addressId: addressId }).subscribe({
      next: (result: any) => {
        this.form.riskscapeAddress.data = JSON.parse(result.body);
        console.log(this.form.riskscapeAddress.data, 3)
        this.physicalAddress = {
          unitNo: '',
          complexName: this.form.riskscapeAddress.data.complex ?? '',
          streetNo: this.form.riskscapeAddress.data.street_number,
          streetName: this.form.riskscapeAddress.data.street,
          suburb: this.form.riskscapeAddress.data.subplace,
          city: this.form.riskscapeAddress.data.city_town,
          postalCode: this.form.riskscapeAddress.data.postal_code,
        }
      },
      error: (error) => {

      }
    })
  }

  getSchemeData(schemeId: string) {
    this.mapsService.apiV1MapsGetUnitsFromSchemeIdGet$Response({ schemeId: schemeId }).subscribe({
      next: (result: any) => {
        const resultJson = JSON.parse(result.body);
        console.log(resultJson, 4)
        this.addressSchemeData = {
          schemeData: resultJson
        };
        this.openSchemeOptionDialog()
      },
      error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'Problem finding scheme');
      }
    })
  }

  openSchemeOptionDialog() {
    const dialogRef = this.dialog.open(PropertyDetailsDialog, {
      width: '60vw',
      maxWidth: '300px',
      data: this.addressSchemeData,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPropertyDetails(result.property_key, result.unit_number);
        this.getAddressImage(result.property_key);
        this.form.riskscapeInfo.surveyorGeneralKey = result.property_key;
        this.form.riskscapeInfo.sectionalTitleUnitNumber = result.unit_number;
        console.log(result, 5)
      }
    });
  }

  async getPropertyDetails(propertyKey: string, unitNumber: string) {
    this.mapsService.apiV1MapsGetPropertyDetailsGet$Response({ propertyKey, unitNumber }).subscribe({
      next: (result: any) => {
        this.form.riskscapePropertyDetails.data = JSON.parse(result.body);
        console.log(this.form.riskscapePropertyDetails.data, 6);
        this.getAddressDetails(this.form.riskscapePropertyDetails.data.address_id)
      },
      error: (error: any) => {

      }
    })
  }

  gotoContractDetails() {
    this.pageNumberEvent.emit(3);
    this.propertyDetailsEvent.emit(this.form);
  }

  openSideNav(page: number) {
    this.openSideNavEvent.emit(page);
  }

  selectInput(index) {
    if (index != 0) {
      return;
    }
    const askingPriceElement: HTMLInputElement = document.querySelector('#askingPrice');
    setTimeout(() => {
      askingPriceElement.focus();
      askingPriceElement.select();
    }, 20);
  }

  setMapView(lat: number, lon: number) {
    if (this.activeMarker) {
      this.map.removeLayer(this.activeMarker)
    }

    this.activeMarker = L.marker([lat, lon], {
      icon: this.mediumMapIcon
    })

    this.activeMarker.addTo(this.map)
    this.activeMarker.bindPopup(this.loaderHtml, {
      maxWidth: 1000
    }).openPopup();
    this.map.setView([lat, lon], 18.5);
  }

  initMap() {
    let mapElement = document.getElementById('siodMap') as Element;
    if (mapElement) {
      mapElement.remove();
    }
    mapElement = document.getElementById('pfsMap') as Element;
    if (mapElement) {
      mapElement.remove();
    }
    setTimeout(() => {
      if (!this.map) {
        this.map = L.map('sypMap', {
          scrollWheelZoom: false,
          zoom: 14
        }).setView([0, 0]);

        this.map.doubleClickZoom.disable();

        L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
          maxZoom: 50,
          minZoom: 1,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(this.map);

        this.map.on('click', function (e) {
          this.setMapView(e.latlng.lat, e.latlng.lng)
          let latStr = e.latlng.lat.toString()
          let lonStr = e.latlng.lng.toString()
          if (latStr.length > 9) {
            latStr = latStr.substring(0, 9)
          }
          if (lonStr.length > 9) {
            lonStr = lonStr.substring(0, 9)
          }
          this.getAddressFromLatLon(latStr, lonStr);
        }.bind(this))
      }
      this.setMapView(this.addressGeocodeData.position.lat, this.addressGeocodeData.position.lon)
    }, 50);
  }

  generatePopupText() {
    return `<div id="addressShowPopup">
      <img width="580px" height="400px" src='${this.cadasterPicture}'>
    </div>`;
  }
}


@Component({
  selector: 'property-details-dialog',
  templateUrl: './property-details-dialog.html',
  styleUrls: ['property-details.component.scss']
})
export class PropertyDetailsDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PropertyDetailsDialogData>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: PropertyDetailsDialogData,
  ) { }

  ngOnInit(): void {
    console.log(this.data.schemeData)
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  selectProperty(property: any) {
    this.dialogRef.close(property);
  }
}

export interface PropertyDetailsDialogData {
  schemeData: any[]
}