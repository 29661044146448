export class ScrollingMethods{
    scrollToHTMLElement(element: HTMLElement){
      setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }, 10);
    }

    scrollToElement(element: Element){
      setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }, 10);
    }

    scrollToTop(){
      setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }, 10);
    }

    scrollToBottom(){
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 10);
    }
}