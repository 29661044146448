import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, finalize } from "rxjs";
import { UserListVm } from "src/app/Services/propertypros/models";
import { UserService } from 'src/app/Services/propertypros/services';


export class AdminManageUsersDataSource implements DataSource<UserListVm> {

    public usersSubject = new BehaviorSubject<UserListVm[]>([]);
    private recordCount = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public recordCount$ = this.recordCount.asObservable();

    constructor(private UserService: UserService) { }

    connect(collectionViewer: CollectionViewer): Observable<readonly UserListVm[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        return this.usersSubject.complete();
        return this.loadingSubject.complete();
    }

    loadDataTable( searchVal: string, filter: string, pageIndex: number, PageSize: number){
        this.loadingSubject.next(true);
        this.UserService.apiV1UserGetAllUsersGet$Json({
            searchVal: searchVal, 
            filter: filter, 
            limit: PageSize, 
            offset: (pageIndex * PageSize)
        })
        .pipe(
            //catchError((error) => of(<UserListVm>{ recordCount: 0, result: [] })),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result) => {
            this.usersSubject.next(result.userList as UserListVm[]);
            this.recordCount.next(result.recordCount);
        });
    }

}