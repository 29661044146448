import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { AdminService } from 'src/app/Services/propertypros/services';
import { AdminMyProfileWorkDetailsEditDialogComponent } from './admin-my-profile-work-details-edit-dialog/admin-my-profile-work-details-edit-dialog.component';

@Component({
  selector: 'app-admin-my-profile-work-details',
  templateUrl: './admin-my-profile-work-details.component.html',
  styleUrls: ['./admin-my-profile-work-details.component.scss']
})
export class AdminMyProfileWorkDetailsComponent implements OnInit {

  userRole: string;
  userRoles = UserRoles;

  myProfileWorkDetails: any[] = [
    { key: 1, value: null, title: 'Company Information' },
    { key: 2, value: null, title: 'Principal details' },
    { key: 3, value: null, title: 'Company Address' },
    { key: 4, value: null, title: 'Account details' },
    { key: 5, value: null, title: 'Supporting Staff Member: Town Planner' },
    { key: 6, value: null, title: 'Supporting Staff Member: Administrative' },
  ];

  constructor(private adminService: AdminService, private sharedService: PropertiProSharedService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getWorkDetails();
    this.getUserRole();
  }

  editInfo(typeKey: number) {
    let data = this.myProfileWorkDetails.find(f => f.key == typeKey);
    const dialogRef = this.dialog.open(AdminMyProfileWorkDetailsEditDialogComponent, {
      width: '60vw',
      maxHeight: '80vh',
      data: { editType: typeKey, data: data } as AdminEditDialogModel,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        data.value = result.value
      } else {
        this.getWorkDetails();
      }
    });
  }

  getWorkDetails() {
    let userName = this.sharedService.getUsername();
    this.adminService.apiV1AdminGetWorkDetailsGet$Response({ username: userName })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          let body = JSON.parse(result.body);
          this.myProfileWorkDetails[0].value = body.companyInformation;
          this.myProfileWorkDetails[1].value = body.principalDetails;
          this.myProfileWorkDetails[2].value = body.branchAddress;
          this.myProfileWorkDetails[3].value = body.accountDetails;
          this.myProfileWorkDetails[4].value = body.supportTownPlannerDetails;
          this.myProfileWorkDetails[5].value = body.supportAdministrativeDetails;
          
          this.myProfileWorkDetails[2].title = this.userRole == UserRoles.estateAgent ? 'Branch Address' : this.myProfileWorkDetails[2].title;
        }
        , error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }

  getUserRole(){
    this.userRole = this.sharedService.getUserRole();
  }
}