<mat-sidenav-container (backdropClick)="close()" [hasBackdrop]="true">
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <div *ngIf="showButtons == true">
            <section class="rotate-buttons" [hidden]="isShow">
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 0}"
                    (click)="openSideNav(0, true)">LEVEL 1</button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 1}"
                    (click)="openSideNav(1, true)">LEVEL 2</button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 2}"
                    (click)="openSideNav(2, true)">LEVEL 3</button>
                <button class="sidenav-button heading-2" [ngClass]="{'open': open, 'active': tabIndex === 3}"
                    (click)="openSideNav(3, true)">LEVEL 4</button>
            </section>

            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel1">
                <div class="row">
                    <div class="col-11 heading-4-light py-3">
                        As part of the property investigation, Property Matrix offers to:
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white" (click)="close()"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Inspect the following:
                    </div>
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph-light">
                            <li>
                                Zoning of the property
                            </li>
                            <li>
                                Town Planning Scheme
                            </li>
                            <li>
                                Surveyor-General diagrams (for exact boundaries and possible servitudes)
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Review the following:
                    </div>
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph-light">
                            <li>
                                Strategic plans of the local council such as the Integrated Development Plan
                                (IDP) and
                                Spatial
                                Development Framework (SDF)
                            </li>
                            <li>
                                Precinct plan
                            </li>
                            <li>
                                Development policies
                            </li>
                            <li>
                                Municipal Road Master Plan (if available on council's public data); railway lines and
                                distances from stations – where applicable.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Provide our interpretation (where applicable) on:
                    </div>
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph-light">
                            <li>
                                Allowable land uses on your property
                            </li>
                            <li>
                                Potential: best possible future uses of the property
                            </li>
                            <li>
                                Planning parameters: maximum proposed zoning, density, height, etc.
                            </li>
                            <li>
                                Possible restraints (building lines, servitudes registered, etc.)
                            </li>
                            <li>
                                Development/expansion scope: bulk permitted and potential bulk
                            </li>
                            <li>
                                Whether a planning application will be required to meet the full potential; and
                            </li>
                            <li>
                                The most suitable and cost-effective approach
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Compile a planning report:
                    </div>
                    <div class="col-12">
                        <ul class="montserrat-bullets paragraph-light">
                            <li>
                                Our findings and recommendations will be made available in a professional town planning
                                report,
                                setting
                                out the restraints as well as the full potential - ways to develop, extend, or increase
                                the
                                value of your
                                property.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row text-center">
                    <div class="col-12 mb-5">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>

            <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel2">
                <div class="row">
                    <div class="col-11 heading-4-light py-3">
                        Factors considered for Value Assessment
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white " (click)="close()"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="row">
                    <p class="col-12 heading-5-light">
                        <span class="coral">Please note:</span> The list below is by no means exhaustive and will be
                        influenced by the property type
                        and valuation method applied. However, please be assured that your property will be objectively
                        assessed and valued in terms of a thorough investigation, according to property specific
                        parameters.
                    </p>
                    <br />
                    <p class="col-12 heading-5-light">
                        Every industry differs, but the Property Matrix value assessment team, assisted by our
                        professional valuer network, will generally take the following factors into consideration to
                        determine the open market value of your property:
                    </p>

                    <div class="col-12 heading-5-light">
                        Property price
                    </div>
                    <div class="col-12">
                        <div class="paragraph-light">
                            Previous purchase price(s) and date(s), as well as current municipal value.
                        </div>
                    </div>
                    <br />
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Location
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            Macro- and micro-location, accessibility and exposure, demographic trends, developments in
                            the
                            area,
                            surrounding properties and amenities.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Site specifics
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            Property size and shape, planning parameters (existing zoning, possible future zoning,
                            density,
                            height,
                            coverage, floor area ratio (FAR) and building lines), servitudes and other endorsements.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Site improvements
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            Type and quality of buildings on site, building sizes, building grades, age of buildings,
                            parking provision, site
                            security, surrounding works.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Building tenacy
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            Owner occupied or tenanted, letability of buildings, number and type of tenants, tenant
                            spread
                            and rating,
                            lease terms and escalations, lease renewals or tenant failures
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Market conditions
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            General macro economy, supply and demand; and where applicable: rental rates, vacancy rates
                            and
                            vacancy periods.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 heading-5-light">
                        Comparative Market Analysis
                    </div>
                    <div class="col-12 ">
                        <p class="paragraph-light">
                            Comparable sales in the nearby surrounds, comparable sales in similar investor nodes,
                            comparable
                            rentals in
                            the nearby surrounds and saleability of the subject property.
                        </p>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>

            <div class="container-side sidenav-container container " [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel3">
                <div class="row">
                    <div class="col-11 heading-4-light py-3">
                        Advert on our website
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white " (click)="close()"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="row">
                    <p class="paragraph-light text-center">
                        Property Matrix cannot be compared to other websites advertising property. Each of our property
                        advertisements is
                        substantiated by a professional planning report, supporting documents, and where applicabile:
                        specialized reports
                        and maps. We provide expert advice. We advertise potential.
                    </p>
                    <p class="paragraph-light text-center">
                        For examples on how we will advertise your property, please click on any property advertised
                        under the “Property for Sale” section of this website.
                    </p>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>

            <div class="container-side sidenav-container container " [ngClass]="{'container-overflow' : addOverflow}"
                fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel4">
                <div class="row">
                    <div class="col-11 heading-4-light py-3">
                        Trace targeted buyers
                    </div>
                    <div class="col-1 btn-close-align pt-2">
                        <button type="button" class="btn-close btn-close-white " (click)="close()"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="row">
                    <p class="paragraph-light text-center">
                        The Property Matrix database is designed to link the supply (your property) with the demand:
                        buyers
                        who indicated
                        their precise property needs via our extensive and unique property 'menu'.
                    </p>
                    <p class="paragraph-light text-center">
                        Once we have done our investigation and identified the potential of your property, the
                        Matrix-'matching' can be done
                        instantly.
                    </p>
                    <p class="paragraph-light text-center">
                        We are fully geared to facilitate faster and transparent property sales.
                    </p>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showButtons == false" class="container-side sidenav-container container"
            [ngClass]="{'container-overflow' : addOverflow}" fxLayout="column" fxLayoutAlign="space-between start">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Formal Valuation Reports
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white" (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row">
                <p class="col-12 heading-5-light">
                    <span class="coral">Please note:</span> Our national panel of selected Professional Valuers –
                    all registered with the South
                    African Council for the Property Valuers Profession (“SACPVP”) - will provide this service at
                    a competitive rate. Be assured that in terms of the Professional Valuers code of conduct, all
                    valuations will be performed in an independent and objective manner.
                </p>
                <br />
                <div class="col-12 heading-5-light">
                    Description of a Formal Valuation Report:
                </div>
                <div class="col-12">
                    <div class="paragraph-light">
                        This is a detailed report and will also include a site visit by the property valuer. The report
                        will contain
                        full details of market research and all relevant information and will be a more accurate
                        determination of
                        the market value of the property. This type of report can also be used for negotiation purposes,
                        tax and
                        audit purposes, financing purposes, etc. The report also provides a replacement value of the
                        property
                        improvements for insurance purposes.
                    </div>
                </div>
                <br />
            </div>
            <div class="row">
                <div class="col-12 ">
                    <p class="paragraph-light">
                        There are various methods commonly used for determining the market value of real estate. These
                        methods
                        of valuation comprise:
                    </p>
                    <ul class="montserrat-bullets paragraph-light">
                        <li>Direct comparable sales approach</li>
                        <li>Cost approach</li>
                        <li>Income approach</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="paragraph-light">
                        Every property is unique and will be valued according to the type of property and improvements
                        there-on.
                        Our valuation reports generally incorporate the following phases:
                    </p>
                    <ul class="montserrat-bullets paragraph-light">
                        <li>Phase 1: Data collection</li>
                        <li>Phase 2: Site inspection</li>
                        <li>Phase 3: Market research</li>
                        <li>Phase 4: Analysis and calculations</li>
                        <li>Phase 5: Report writing and conclusion</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    DELIVERABLES AND TIMEFRAMES
                </div>
                <div class="col-12 ">
                    <ul class="montserrat-bullets paragraph-light">
                        <li>15 to 20-page report in PDF format</li>
                        <li>Includes site visit</li>
                        <li>Provides full detail of all market research and calculations</li>
                        <li>10 to 12 working days for delivery</li>
                        <li><strong>Please note:</strong> This type of report will provide all the relevant information
                            required for a financing
                            application however most financial institutions / banks have their own templates and
                            requirements. We
                            will be able to transfer the information to the relevant template, however this will incur a
                            small additional
                            fee and the instruction will have to be issued through the specific bank.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    FEE STRUCTURE (estimates only)
                </div>
                <div class="col-12 ">
                    <p class="paragraph-light">
                        All fees as indicated in the table below exclude VAT.
                        <strong>Important:</strong> This is only a general indication of fees; each request for a
                        valuation will be assessed
                        individually and a property specific quotation will be provided.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="fee-schedule-table">
                        <table id="fee-schedule-table">
                            <thead>
                                <tr class="heading-3-light text-center">
                                    <th colspan="2">FEE SCHEDULE: FORMAL VALUATION REPORT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="paragraph" style="background-color: #dcdcdc;">
                                    <td colspan="2"><span><strong>Vancant land</strong></span></td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Vacant land (single zoning)</span></td>
                                    <td class="text-center">R4 500</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Vacant land with township rights</span></td>
                                    <td class="text-center">Depends on size of development</td>
                                </tr>
                                <tr class="paragraph" style="background-color: #dcdcdc;">
                                    <td colspan="2"><span><strong>Land with built improvements
                                                (residential/industrial/offices/flats/retail/other)</strong></span></td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Single land use; value less than R5-million</span></td>
                                    <td class="text-center">R3 500 to R5 000</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Single land use; value between R5-million and R20-million</span></td>
                                    <td class="text-center">R5 500 to R8 500</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Single land use; value between R20-million and R50-million</span></td>
                                    <td class="text-center">R9 500 to R12 500</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Single land use; value above R50-million</span></td>
                                    <td class="text-center">R17 500 +</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Multiple land uses; value less than R5-million</span></td>
                                    <td class="text-center">R5 000 to R6 500</td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Multiple land uses; value between R5-million and R20-million</span></td>
                                    <td class="text-center">R7 500 to R11 500</td>
                                </tr>

                                <tr class="paragraph-light">
                                    <td><span>Multiple land uses; value between R20-million and R50-million</span></td>
                                    <td class="text-center"><span>R11 500 to R15 500</span></td>
                                </tr>
                                <tr class="paragraph-light">
                                    <td><span>Multiple land uses; value above R50-million</span></td>
                                    <td class="text-center"><span>R22 500 +</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 ">
                    <p class="paragraph-light">
                        <span class="heading-5-light">OBTAIN QUOTE:</span> A quotation for a formal valuation of your
                        property can be requested under “Additional
                        Services”; “Property Valuation” (level 2 to be selected).
                    </p>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <button class="heading-3 button-light" (click)="close()">GOT IT</button>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <main style="background-color: white">
        <div class="container-fluid" *ngIf="page == 1">
            <section class="content">
                <div class="content-padding">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h1 class="heading-1 text-center">
                                    SELLING MY PROPERTY
                                </h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="paragraph text-center">
                                    We are delighted to add our professional expertise to your property journey.
                                </p>
                                <p class="paragraph text-center">
                                    Once we have received your details, and agreed on a selling price, you will
                                    automatically be
                                    linked to a SACPLAN*-registered planner, who will launch a detailed investigation on
                                    the
                                    context, classification (“zoning”) and, most of all, the potential of your property.
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-6 heading-4 text-center mb-3 bottom-border">
                                        OUR OFFER - ALL FREE OF CHARGE:
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3">
                                    <div class="numbers-right-border">
                                        <div class="paragraph align-height-2">
                                            <span class="numbers side-numbers">1</span>
                                            <span class="heading-5">Investigation & Report*</span>
                                            <br>
                                            A professional town planning investigation on your
                                            property to generate an
                                            advanced planning report, indicating ways to develop, extend, or improve
                                            it.
                                        </div>
                                        <div class="text-center paragraph">
                                            Worth 4k
                                        </div>
                                        <br>
                                        <div class="text-center">
                                            <button class="small-button button" id="LearnMore1"
                                                (click)="openSideNav(0, true)">
                                                LEARN MORE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="numbers-right-border">
                                        <div class="paragraph align-height-2">
                                            <span class="numbers side-numbers">2</span>
                                            <span class="heading-5">Value Assessment**</span>
                                            <br>
                                            Inspection and objective assessment of your property, according to property
                                            specific parameters, to determine the worth (open market value) thereof.
                                        </div>
                                        <div class="text-center paragraph">
                                            Worth 2k-5k
                                        </div>
                                        <br>
                                        <div class="text-center">
                                            <button class="small-button button" id="LearnMore2"
                                                (click)="openSideNav(1, true)">LEARN
                                                MORE</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="numbers-right-border">
                                        <div class="paragraph align-height-2">
                                            <span class="numbers side-numbers">3</span>
                                            <span class="heading-5">Advert on Website</span>
                                            <br>
                                            Your property will be advertised on our website and the property report
                                            (highlighting the potential) will be made available to all buyers – free of
                                            charge.
                                        </div>
                                        <div class="text-center paragraph">
                                            Worth 2k-4k
                                        </div>
                                        <br>
                                        <div class="text-center">
                                            <button class="small-button button" id="LearnMore3"
                                                (click)="openSideNav(2, true)">LEARN
                                                MORE</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="paragraph align-height-2">
                                        <span class="numbers side-numbers">4</span>
                                        <span class="heading-5">Trace Targeted Buyers</span>
                                        <br>
                                        Our database will trace all buyers who indicated an “appetite” for what your
                                        property offers. Each will be personally notified of your property being in the
                                        market.
                                    </div>
                                    <div class="text-center paragraph">
                                        Worth 1.5k
                                    </div>
                                    <br>
                                    <div class="text-center">
                                        <button class="small-button button" id="LearnMore4"
                                            (click)="openSideNav(3, true)">LEARN
                                            MORE</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="paragraph-small">
                                        * Not applicable for properties that cannot be developed, extended, or improved
                                        (e.g., sectional title residential, farms with the main/only land use to remain
                                        agriculture)
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="paragraph-small">
                                        ** Not applicable on selected land use typologies or a property which is
                                        specialised in nature
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col"></div>
                                <div class="col-10">
                                    <div class="paragraph text-center">
                                        <span class="heading-5">Property Matrix</span>
                                        is thus programmed to instantly link the supply with the demand and to provide
                                        all
                                        the relevant information buyers can trust. We are geared to enable faster and
                                        transparent transactions.Please “continue” for more information on the road
                                        ahead
                                        and the part you as seller can play.
                                    </div>
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="row mt-3 reduce-bottom-spacing">
                                <div class="col-12">
                                    <div class="paragraph text-center">
                                        <button class="button heading-3" id="continue" (click)="continue(2)">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="page == 2">
            <section class="other-content">
                <div class="content-padding">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                WHAT’S EXPECTED FROM YOU AS A SELLER?
                            </h1>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <h5 class="heading-5 text-center">
                                All the professional services listed (worth around R12k) are FREE of charge, but we will
                                need your commitment too.
                            </h5>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-12">
                            <div class="row">
                                <div class="col"></div>
                                <h4 class="col-6 heading-4 text-center pb-3 mt-1 bottom-border">
                                    To partner with Property Matrix, we will require:
                                </h4>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div @fade class="side-image your-time-image"></div>
                        </div>
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR TIME
                            </h2>
                            <p class="paragraph">
                                A period in which our team, led by a registered town and regional planner,
                                can do the investigation and market your property exclusively; not sharing
                                the opportunity with other agents/agencies, who do not form part of our
                                network. An undeniable fact is that the <strong>Property Matrix</strong> team is going
                                to invest significant time, experience, and professional knowledge in the
                                investigation (concerning the context and potential of your property)
                                without charge.
                            </p>
                            <p class="paragraph">
                                We cannot afford that another agent/agency (not partnering with us), or
                                yourself, has sold the property in the meantime or that you've withdrawn
                                the property from the market. We therefore require an exclusive mandate
                                for a period of 10 months to do proper marketing and to substantiate our
                                investment.
                            </p>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR TRUST
                            </h2>
                            <p class="paragraph">
                                We do not intend, either, to invest hours of our professional time and knowledge
                                and have your property “sit on the shelve” for ages. We need to hit “bull's eye” with
                                both our target market and the appropriate, market-related price of your property
                                from the get-go. This, however, will not be possible if the asking price does not
                                match the open market value of your property.
                            </p>
                            <p class="paragraph">
                                To assist with this and to provide you with peace of mind, <strong>Property
                                    Matrix</strong>
                                established a value assessment team, assisted by our national professional valuer
                                network, to do an objective assessment to determine the worth (economic value)
                                of your property. Click
                                <a (click)="openSideNav(1, true)" class="here">
                                    here
                                </a>
                                for details.
                            </p>
                            <p class="paragraph">
                                After our investigations, all the facts (the good, bad and the ugly) of your property
                                will be evaluated. <strong>Property Matrix</strong> will certainly focus on the
                                potential of your
                                property, but we also pride ourselves in trustworthy property information.
                            </p>
                            <p class="paragraph">
                                Our invitation to you as property owner is to trust us when our team recommends
                                the suitable, market-related selling price for your property.
                                We would like your property to sell as much as you do!
                            </p>
                        </div>
                        <div class="col-5">
                            <div @fade class="side-image your-trust-image"></div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-5">
                            <div @fade class="side-image your-Collaboration-image"></div>
                        </div>
                        <div class="col-6">
                            <h2 class="heading-2 text-center">
                                YOUR COLLABORATION
                            </h2>
                            <p class="paragraph">
                                <strong>Property Matrix</strong> is unique in the sense that our partnering estate
                                agents are trained
                                to collect very specialised information of your property to ensure that we ask the
                                correct selling price and most important … market its full potential.
                            </p>
                            <p class="paragraph">
                                As you know, we do not charge you to do a property investigation, property value
                                assessment or the advertising of your property on our website. The only income we
                                generate is via the commission payable when your property is sold. We consider
                                this as the remuneration for our initial professional input which we added on risk.
                            </p>
                            <div class="paragraph">
                                <strong>We trust that you will: </strong>
                            </div>
                            <ul class="montserrat-bullets paragraph align-height paragraph">
                                <li>Accept and honour our commission structure, as we operate on a professional
                                    level and will not engage in any disputes or arguments regarding the commission
                                    payable. Click <a (click)="openDialog()" class="here">
                                        here
                                    </a> for details</li>
                                <li>Allow (or arrange for) our partnering agent to have easy access to all buildings
                                    on your property (if not vacant land); and</li>
                                <li>Go out of your way to provide them with all the information they require (reports,
                                    maps, building plans, tenant rolls, pictures recently taken, etc.) in electronic
                                    format. </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <h1 class="heading-1 text-center">
                                ROAD AHEAD
                            </h1>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col-12">
                            <div class="row">
                                <div class="col"></div>
                                <h4 class="col-6 heading-4 text-center pb-3 mt-1 bottom-border">
                                    We propose launching your journey as follows:
                                </h4>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <!-- Stepper -->
                    <div class="row">
                        <div class="col-12">
                            <div class="stepper-first-border">
                                <div class="row stepper-first-border-dots">
                                    <div class="col-3">
                                        <div class="row no-margin">
                                            <div class="col-2 first-dot-cover-up"></div>
                                            <div class="col-10 first-dot">
                                                <div class="dot"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="dot"></div>
                                    </div>
                                    <div class="col-3">
                                        <div class="dot"></div>
                                    </div>
                                    <div class="col-3">
                                        <div class="dot"></div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-3">
                                        <div class="row">
                                            <div class="col-2 first-dot-cover-up"></div>
                                            <div class="col-10">
                                                <h2 class="heading-2 coral">STEP 1:</h2>
                                                <p class="paragraph">
                                                    You provide us with your
                                                    personal/legal entity details,
                                                    property information,
                                                    contract details (preferred
                                                    selling price), and the
                                                    relevant documents.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <h2 class="heading-2 coral">STEP 2:</h2>
                                        <p class="paragraph">The Property Matrix central
                                            office will launch an initial
                                            planning investigation on your
                                            property. Click <a (click)="navigateToNewTab('townPlanningApplication')"
                                                class="here">here</a> for
                                            details.
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <h2 class="heading-2 coral">STEP 3:</h2>
                                        <p class="paragraph">
                                            One of our partnering
                                            estate agents will contact
                                            you to arrange a visit to
                                            your property (your “own
                                            agent” can also register with
                                            Property Matrix if he/she
                                            meets our criteria).
                                        </p>
                                    </div>
                                    <div class="col-3">
                                        <h2 class="heading-2 coral">STEP 4:</h2>
                                        <p class="paragraph">
                                            Our value assessment
                                            team will conduct a proper
                                            comparative market analysis
                                            to determine whether your
                                            asking price is market related.
                                            Click <a (click)="navigateToNewTab('propertyValuation')"
                                                class="here">here</a> for details.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row stepper-second-border-dots">
                                <div class="col-3">
                                    <div class="dot"></div>
                                </div>
                                <div class="col-3">
                                    <div class="dot"></div>
                                </div>
                            </div>
                            <div class="stepper-second-border">
                                <div class="row" style="margin-left: 20px;">
                                    <div class="col-3 mt-4">
                                        <h2 class="heading-2 coral">STEP 6:</h2>
                                        <p class="paragraph">
                                            Once we agree on a marketrelated selling price, our
                                            office provides you with an
                                            exclusive mandate contract
                                            for your perusal and
                                            signature.
                                        </p>
                                    </div>
                                    <div class="col-9 mt-4">
                                        <h2 class="heading-2 coral">STEP 5:</h2>
                                        <p class="paragraph">If your asking price and the selling price (as proposed by
                                            the value assessment team) differs by more than
                                            25% and you would like to market the property at the higher price, you will
                                            have the opportunity to provide
                                            us with a full property valuation report to demonstrate that the property is
                                            priced realistically. The report
                                            needs to be done by a professional valuer, registered with the South African
                                            Council for the Property Valuers
                                            Profession (“SACPVP”), and at your own expense. Properties will not be
                                            marketed at a price that is deemed
                                            more than 25% of the determined market value.
                                            <strong>Please note:</strong> The Property Matrix national panel of selected
                                            professional
                                            valuers can also provide this service
                                            at a competitive rate. Be assured that, in terms of the professional valuers
                                            code of conduct, all valuations will
                                            be performed in an independent and objective manner. For our fee schedule,
                                            click <a (click)="openSideNav(0, false)" class="here">
                                                here
                                            </a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row stepper-second-border-dots">
                                <div class="col-2">
                                    <div class="dot"></div>
                                </div>
                                <div class="col-5">
                                    <div class="dot"></div>
                                </div>
                                <div class="col-2">
                                    <div class="dot"></div>
                                </div>
                                <div class="col-3">
                                    <div class="dot"></div>
                                </div>
                            </div>
                            <div class="stepper-third-border">
                            </div>
                            <div class="row mt-4" style="margin-left: 20px;">
                                <div class="col-2">
                                    <h2 class="heading-2 coral">STEP 7:</h2>
                                    <p class="paragraph">
                                        The planning
                                        investigation and
                                        the planning report
                                        are then finalised
                                        and the property
                                        advertisement will be
                                        designed.
                                    </p>
                                </div>
                                <div class="col-5">
                                    <h2 class="heading-2 coral">STEP 8:</h2>
                                    <p class="paragraph">
                                        The content of our investigation – both the planning report
                                        and the prospective advertisement - will be forwarded to you.
                                        <strong>Please note:</strong> If we could not agree on a market-related
                                        selling price and you are not proved to be correct via a
                                        professional, SACPVP-registered valuer), your property will
                                        unfortunately not be advertised on our website. We will,
                                        however, provide you with the opportunity to buy the property
                                        report (R4k). You will not be mandated to buy any of the
                                        reports - <strong>Property Matrix</strong> takes the risk in doing this work.
                                    </p>
                                </div>
                                <div class="col-2">
                                    <h2 class="heading-2 coral">STEP 9:</h2>
                                    <p class="paragraph">
                                        If in agreement,
                                        you sign off on
                                        the information
                                        and the proposed
                                        advert. Please be
                                        reminded: we focus
                                        on the potential of
                                        your property, but
                                        all information will
                                        be disclosed.
                                    </p>
                                </div>
                                <div class="col-3">
                                    <h2 class="heading-2 coral">STEP 10:</h2>
                                    <p class="paragraph">Your property gets advertised
                                        on this website and all buyers
                                        with an appetite for what your
                                        property offers, will receive
                                        direct notifications of your
                                        property being in the market.
                                        They will also have access
                                        to the full planning report,
                                        setting out it's potential – free
                                        of charge
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col"></div>
                                <h4 class="col-10 heading-4 text-center pb-3 mt-1">
                                    <div class="bottom-border text-center mb-2"></div>
                                    PLEASE CONTINUE IF YOU ARE READY TO PARTNER WITH US AND KICK OF WITH STEP 1.
                                </h4>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1 reduce-bottom-spacing">
                        <div class="col-12">
                            <div class="paragraph text-center">
                                <button class="button heading-3" id="continue" (click)="continue(3)">
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="page == 3">
            <section class="other-content">
                <div class="content-padding">
                    <div class="container">
                        <div class="row mt-3">
                            <div class="col-12">
                                <h1 class="heading-1 text-center">
                                    PROPERTY AND OWNER INFORMATION
                                </h1>
                            </div>
                            <div class="col-12">
                                <p class="paragraph text-center">
                                    Thank you for choosing <strong>Property Matrix</strong> to contribute to your
                                    journey. Your assistance to provide us with reliable information
                                    will ensure a speedy kick-off.
                                </p>
                            </div>
                            <div class="col-12">
                                <p class="paragraph text-center">
                                    <strong>It will take about 15-20 minutes to complete this form within 4 easy
                                        steps:</strong>
                                </p>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-1"></div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                1
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Ownership
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                2
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Property
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="numbers-right-border">
                                            <div class="heading-3">
                                                STEP
                                            </div>
                                            <div class="numbers">
                                                3
                                            </div>
                                            <div class="heading-5 mt-2">
                                                Contract
                                                <br />
                                                details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 text-center">
                                        <div class="heading-3">
                                            STEP
                                        </div>
                                        <div class="numbers">
                                            4
                                        </div>
                                        <div class="heading-5 mt-2">
                                            Upload
                                            <br />
                                            documents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-1"></div>
                            <div class="col-10">
                                <p class="paragraph"><strong>For step 4, please have the following ready in PDF
                                        format:</strong></p>
                                <ul class="montserrat-bullets paragraph">
                                    <li>If the owner/representative is a resident of RSA, clear copy of identity
                                        document (ID)</li>
                                    <li>If the owner/representative is not a resident of RSA, clear copy of passport
                                    </li>
                                    <li>If the owner is married in community, a copy of spouses ID/passport</li>
                                    <li>If the estate agent provided such, a copy of the commission claim form</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-11 mx-3">
                                <label class="checkbox-container">
                                    <span class="paragraph">I have read the information on the previous page of what is
                                        expected from me as Seller and what the way forward looks like.</span>
                                    <input type="checkbox" [(ngModel)]="hasReadPages" data-toggle="toggle" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="paragraph text-center">
                                    <div class="burnt-red heading-4" *ngIf="hasReadPages == false">
                                        Please confirm before continuing
                                    </div>
                                    <button class="button heading-3" id="propertyOwnerInfoContinue"
                                        (click)="continueToForms()" [disabled]="hasReadPages == false">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
</mat-sidenav-container>