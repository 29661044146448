import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Inject, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { GRI_DATE_FORMATS } from 'src/app/pages/selling-my-property/upload-documents/upload-documents.component';
import { UrbanPlannerExtendedNetworkVm, UrbanPlannerProfessionalVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-planner-register-form-extended-network',
  templateUrl: './planner-register-form-extended-network.component.html',
  styleUrls: ['./planner-register-form-extended-network.component.scss']
})
export class PlannerRegisterFormExtendedNetworkComponent implements OnInit {

  displayedColumns: string[] = ['professional', 'action1', 'action2'];
  @ViewChild(MatTable) table: MatTable<any>;
  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() UrbanPlannerExtendedNetworkEvent = new EventEmitter<UrbanPlannerExtendedNetworkVm>();

  professional: UrbanPlannerProfessionalVm = {
    linkToWebsite: '',
    personalDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false,
      landline: '',
      position: '',
    },
    profession: '',
  }

  urbanPlannerExtendedNetwork: UrbanPlannerExtendedNetworkVm = {
    professionals: [],
  }

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  submitForm() {
    this.pageNumberEvent.emit(3);
    this.UrbanPlannerExtendedNetworkEvent.emit(this.urbanPlannerExtendedNetwork)
  }

  openProfessionalsDialog() {
    const dialogRef = this.openDialog(UrbanPlannerExtendedNetworkProfessionalDialog, '75vw', '80vh', { professional: this.professional, showDelete: false });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.urbanPlannerExtendedNetwork.professionals.push(result.professional);
        this.table.renderRows();
        this.clearProfessional();
      }
    });
  }

  openEditProfessionalDialog(professional: UrbanPlannerProfessionalVm) {
    const dialogRef = this.openDialog(UrbanPlannerExtendedNetworkProfessionalDialog, '75vw', '80vh', { professional: professional, showDelete: false });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.table.renderRows();
      }
    });
  }

  deleteProfessional(professional: UrbanPlannerProfessionalVm, index: number) {
    const dialogRef = this.openDialog(UrbanPlannerExtendedNetworkProfessionalDialog, null, null, { professional: professional, showDelete: true, index: index });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleteProfessional == true) {
          this.table.renderRows();
          this.urbanPlannerExtendedNetwork.professionals.splice(index, 1);
        }
      }
    });
  }

  clearProfessional() {
    this.professional = {
      linkToWebsite: '',
      profession: '',
      personalDetails: {
        alternativeNumber: '',
        emailAddress: '',
        emailConfirmed: false,
        id_Number: '',
        name: '',
        phoneNumber: '',
        surname: '',
        rsa_Resident: false,
        companyName: ''
      },
    }
  }

  openDialog<T>(dialogComponent: ComponentType<T>, width: string, height: string, data: object) {
    return this.dialog.open(dialogComponent, {
      width: width,
      height: height,
      data: data
    });
  }
}

@Component({
  selector: 'planner-register-form-extended-network-professionals-dialog',
  templateUrl: './planner-register-form-extended-network-professionals-dialog.html',
  styleUrls: ['./planner-register-form-extended-network.component.scss', '../../../../../../../css/2-modules/_forms.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ]
})
export class UrbanPlannerExtendedNetworkProfessionalDialog {
  constructor(
    public dialogRef: MatDialogRef<UrbanPlannerExtendedNetworkProfessionalDialog>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ProfessionalDialogData
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteProfessional() {
    this.data.deleteProfessional = true;
    this.dialogRef.close(this.data);
  }
}

export interface ProfessionalDialogData {
  professional: UrbanPlannerProfessionalVm,
  showDelete: boolean,
  index: number,
  deleteProfessional: Boolean
}
