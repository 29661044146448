<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <section class="mat-input-section">
        <div class="heading-2 mt-4">STEP 1: PERSONAL AND COMPANY INFORMATION</div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>What is the highest qualification you have?
        </div>
        <mat-radio-group name="sacplanRegistrationType" [(ngModel)]="form.sacplanRegistrationType">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of sacplanRegistrationTypes"
                [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="form.sacplanRegistrationType > 0">
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': sacplan_RegistrationNumber.invalid, 'mat-valid-input': sacplan_RegistrationNumber.valid}">Full
                        SACPLAN
                        registration number:</label></div>
                <div class="col-10">
                    <input name="sacplan_RegistrationNumber" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.sacplan_RegistrationNumber" required #sacplan_RegistrationNumber="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': plannerGraduationYear.invalid, 'mat-valid-input': plannerGraduationYear.valid}">
                        which year did you graduate as a planner?</label></div>
                <div class="col-10">
                    <input name="plannerGraduationYear" class="paragraph mat-input-section" type="number"
                        [(ngModel)]="form.plannerGraduationYear" required #plannerGraduationYear="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': graduationInstitution.invalid, 'mat-valid-input': graduationInstitution.valid}">
                        From which institution did you graduate?</label></div>
                <div class="col-10">
                    <input name="graduationInstitution" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.graduationInstitution" required #graduationInstitution="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': statutoryPlanningExperienceYears.invalid, 'mat-valid-input': statutoryPlanningExperienceYears.valid}">How
                        many years of experience do you have in the field of land use management (statutory
                        planning) specifically?</label></div>
                <div class="col-10">
                    <input name="statutoryPlanningExperienceYears" class="paragraph mat-input-section" type="number"
                        [(ngModel)]="form.statutoryPlanningExperienceYears" required
                        #statutoryPlanningExperienceYears="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': companyNameOperatedUnder.invalid, 'mat-valid-input': companyNameOperatedUnder.valid}">Under
                        which name does you/your company operate?</label></div>
                <div class="col-10">
                    <input name="companyNameOperatedUnder" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.companyNameOperatedUnder" required #companyNameOperatedUnder="ngModel">
                </div>
            </div>
            <!-- Address -->
            <section class="submission-form">
                <div class="row">
                    <div class="col-12 paragraph">
                        <strong>What is the physical/street address of your company/office?</strong>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-3">
                        <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo"
                            class="paragraph">Building/
                            unit
                            no:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                            class="paragraph not-required" [(ngModel)]="form.addressDetails.unitNo">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                            class="paragraph">Estate/complex
                            name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                            class="paragraph not-required" [(ngModel)]="form.addressDetails.complexName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                            no:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                            class="paragraph" [(ngModel)]="form.addressDetails.streetNo" required
                            #streetNumber="ngModel"
                            [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                            Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                            class="paragraph" [(ngModel)]="form.addressDetails.streetName" required
                            #streetName="ngModel"
                            [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb"
                            class="paragraph" [(ngModel)]="form.addressDetails.suburb" required #suburb="ngModel"
                            [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                            [(ngModel)]="form.addressDetails.city" #city="ngModel" required
                            [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                    </div>
                </div>
            </section>
            <section>
                <div class="row">
                    <div class="col-10"><label class="paragraph mat-not-required">If applicable - your company
                            registration number?</label></div>
                    <div class="col-10">
                        <input name="companyVatNumber" class="paragraph mat-input-section" type="text"
                            [(ngModel)]="form.companyVatNumber" #companyVatNumber="ngModel">
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <div class="heading-5">
                    <span class="burnt-red">| </span>At which municipal council(s) do you mostly submit planning
                    applications?
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of municipalCouncilsMostlySubmittedToOptions; let index = i">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}<span
                                        [ngClass]="{'specify-text': form.municipalCouncilsMostlySubmittedToOther.length == 0}"
                                        *ngIf="municipalOtherCheck(option)"> – Please specify</span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.checked"
                                    [name]="municipalCouncilsMostlySubmittedToOptions"
                                    #municipalCouncilsMostlySubmittedToOptions="ngModel" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="municipalOtherCheck(option)">
                                <input name="municipalCouncilsMostlySubmittedToOther"
                                    class="paragraph mat-input-section" type="text"
                                    [(ngModel)]="form.municipalCouncilsMostlySubmittedToOther" required
                                    #municipalCouncilsMostlySubmittedToOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>Indicate any type of planning applications in which you have
                    extensive
                    experience and knowledge:
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of planningApplicationsExperienceAndKnowledgeOptions; let index = i">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}<span
                                        [ngClass]="{'specify-text': form.planningApplicationsExperienceAndKnowledgeOther.length == 0}"
                                        *ngIf="planningOtherCheck(option)"> – Please specify</span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.checked"
                                    [name]="planningApplicationsExperienceAndKnowledgeOptions"
                                    #planningApplicationsExperienceAndKnowledgeOptions="ngModel" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="planningOtherCheck(option)">
                                <div class="row mt-2">
                                    <div class="col-10">
                                        <input name="planningApplicationsExperienceAndKnowledgeOther" type="text"
                                            class="paragraph mat-input-section mat-input-section-other"
                                            [(ngModel)]="form.planningApplicationsExperienceAndKnowledgeOther" required
                                            #planningApplicationsExperienceAndKnowledgeOther="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <p class="paragraph"><strong>Please provide the details of one support staff member, such as a
                        technical/junior/candidate planner
                        that we may contact regarding minor <span class="coral-text">town planning</span>
                        queries:</strong></p>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="townPlanningName"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.name" #name="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="townPlanningSurname"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.surname" #surname="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Position:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningPosition" class="paragraph not-required"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.position">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Landline:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningLandline" class="paragraph not-required"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.landline">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningPhoneNumber" class="paragraph not-required"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.phoneNumber" #phoneNumber="ngModel"
                            minlength="10">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Email
                            Address:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningEmailAddress" class="paragraph not-required"
                            [(ngModel)]="form.townPlanningSupportStaffDetails.emailAddress" #emailAddress="ngModel"
                            [pattern]="emailPattern">
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <p class="paragraph"><strong>Please provide the details of one support staff member we may contact
                        regarding
                        <span class="coral-text">administrative</span>
                        queries:</strong></p>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeName"
                            [(ngModel)]="form.adminContactDetails.name" #name="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeSurname"
                            [(ngModel)]="form.adminContactDetails.surname" #surname="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Position:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningPosition" class="paragraph not-required"
                            [(ngModel)]="form.adminContactDetails.position">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Landline:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeLandline" class="paragraph not-required"
                            [(ngModel)]="form.adminContactDetails.landline">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativePhoneNumber" class="paragraph not-required"
                            [(ngModel)]="form.adminContactDetails.phoneNumber" #phoneNumber="ngModel" minlength="10">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Email
                            Address:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeEmailAddress" class="paragraph not-required"
                            [(ngModel)]="form.adminContactDetails.emailAddress" #emailAddress="ngModel"
                            [pattern]="emailPattern">
                    </div>
                </div>
            </section>
            <div class="row text-center">
                <div class="col-10">
                    <button id="btnSaveFinal" class="button heading-4" [disabled]="informationForm.invalid"
                        (click)="submitForm()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
</form>