/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressVm } from '../models/address-vm';
import { AdminEditPersonalDetailsTypeEnum } from '../models/admin-edit-personal-details-type-enum';
import { AdminEditWorkDetailsTypeEnum } from '../models/admin-edit-work-details-type-enum';
import { UrbanPlannerProfessionalVm } from '../models/urban-planner-professional-vm';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AdminGetPersonalDetailsGet
   */
  static readonly ApiV1AdminGetPersonalDetailsGetPath = '/api/v1/Admin/GetPersonalDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminGetPersonalDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetPersonalDetailsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminGetPersonalDetailsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminGetPersonalDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetPersonalDetailsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminGetPersonalDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminUpdatePersonalDetailsPost
   */
  static readonly ApiV1AdminUpdatePersonalDetailsPostPath = '/api/v1/Admin/UpdatePersonalDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUpdatePersonalDetailsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdatePersonalDetailsPost$Response(params?: {
    jsonData?: string;
    username?: string;
    typeId?: AdminEditPersonalDetailsTypeEnum;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUpdatePersonalDetailsPostPath, 'post');
    if (params) {
      rb.query('jsonData', params.jsonData, {});
      rb.query('username', params.username, {});
      rb.query('typeId', params.typeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUpdatePersonalDetailsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdatePersonalDetailsPost(params?: {
    jsonData?: string;
    username?: string;
    typeId?: AdminEditPersonalDetailsTypeEnum;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminUpdatePersonalDetailsPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminGetWorkDetailsGet
   */
  static readonly ApiV1AdminGetWorkDetailsGetPath = '/api/v1/Admin/GetWorkDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminGetWorkDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetWorkDetailsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminGetWorkDetailsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminGetWorkDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetWorkDetailsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminGetWorkDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminUpdateWorkDetailsPost
   */
  static readonly ApiV1AdminUpdateWorkDetailsPostPath = '/api/v1/Admin/UpdateWorkDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUpdateWorkDetailsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdateWorkDetailsPost$Response(params?: {
    jsonData?: string;
    username?: string;
    typeId?: AdminEditWorkDetailsTypeEnum;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUpdateWorkDetailsPostPath, 'post');
    if (params) {
      rb.query('jsonData', params.jsonData, {});
      rb.query('username', params.username, {});
      rb.query('typeId', params.typeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUpdateWorkDetailsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdateWorkDetailsPost(params?: {
    jsonData?: string;
    username?: string;
    typeId?: AdminEditWorkDetailsTypeEnum;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminUpdateWorkDetailsPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminGetDocumentsGet
   */
  static readonly ApiV1AdminGetDocumentsGetPath = '/api/v1/Admin/GetDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminGetDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetDocumentsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminGetDocumentsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminGetDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetDocumentsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminGetDocumentsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminUpdateDocumentsPost
   */
  static readonly ApiV1AdminUpdateDocumentsPostPath = '/api/v1/Admin/UpdateDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUpdateDocumentsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdateDocumentsPost$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUpdateDocumentsPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUpdateDocumentsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminUpdateDocumentsPost(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminUpdateDocumentsPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminGetBillingDetailsGet
   */
  static readonly ApiV1AdminGetBillingDetailsGetPath = '/api/v1/Admin/GetBillingDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminGetBillingDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetBillingDetailsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminGetBillingDetailsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminGetBillingDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetBillingDetailsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminGetBillingDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminUpdateBillingDetailsPost
   */
  static readonly ApiV1AdminUpdateBillingDetailsPostPath = '/api/v1/Admin/UpdateBillingDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUpdateBillingDetailsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminUpdateBillingDetailsPost$Response(params?: {
    username?: string;
    context?: HttpContext
    body?: AddressVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUpdateBillingDetailsPostPath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUpdateBillingDetailsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminUpdateBillingDetailsPost(params?: {
    username?: string;
    context?: HttpContext
    body?: AddressVm
  }
): Observable<void> {

    return this.apiV1AdminUpdateBillingDetailsPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminGetExtendedNetworkDetailsGet
   */
  static readonly ApiV1AdminGetExtendedNetworkDetailsGetPath = '/api/v1/Admin/GetExtendedNetworkDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminGetExtendedNetworkDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetExtendedNetworkDetailsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminGetExtendedNetworkDetailsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminGetExtendedNetworkDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminGetExtendedNetworkDetailsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminGetExtendedNetworkDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminDeleteExtendedNetworkProfessionalPost
   */
  static readonly ApiV1AdminDeleteExtendedNetworkProfessionalPostPath = '/api/v1/Admin/DeleteExtendedNetworkProfessional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminDeleteExtendedNetworkProfessionalPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminDeleteExtendedNetworkProfessionalPost$Response(params?: {
    professionalId?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminDeleteExtendedNetworkProfessionalPostPath, 'post');
    if (params) {
      rb.query('professionalId', params.professionalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminDeleteExtendedNetworkProfessionalPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdminDeleteExtendedNetworkProfessionalPost(params?: {
    professionalId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdminDeleteExtendedNetworkProfessionalPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminAddExtendedNetworkProfessionalPost
   */
  static readonly ApiV1AdminAddExtendedNetworkProfessionalPostPath = '/api/v1/Admin/AddExtendedNetworkProfessional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminAddExtendedNetworkProfessionalPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminAddExtendedNetworkProfessionalPost$Response(params?: {
    username?: string;
    context?: HttpContext
    body?: UrbanPlannerProfessionalVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminAddExtendedNetworkProfessionalPostPath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminAddExtendedNetworkProfessionalPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminAddExtendedNetworkProfessionalPost(params?: {
    username?: string;
    context?: HttpContext
    body?: UrbanPlannerProfessionalVm
  }
): Observable<void> {

    return this.apiV1AdminAddExtendedNetworkProfessionalPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdminUpdateExtendedNetworkProfessionalPost
   */
  static readonly ApiV1AdminUpdateExtendedNetworkProfessionalPostPath = '/api/v1/Admin/UpdateExtendedNetworkProfessional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdminUpdateExtendedNetworkProfessionalPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminUpdateExtendedNetworkProfessionalPost$Response(params?: {
    username?: string;
    context?: HttpContext
    body?: UrbanPlannerProfessionalVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiV1AdminUpdateExtendedNetworkProfessionalPostPath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdminUpdateExtendedNetworkProfessionalPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AdminUpdateExtendedNetworkProfessionalPost(params?: {
    username?: string;
    context?: HttpContext
    body?: UrbanPlannerProfessionalVm
  }
): Observable<void> {

    return this.apiV1AdminUpdateExtendedNetworkProfessionalPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
