<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <div class="heading-2 mt-1">STEP 1: PERSONAL INFORMATION</div>
    <div class="heading-4 mt-1">Contact Details</div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Legal Practice Council
                (LPC) number:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtlpc_Number" class="paragraph" name="lpc_Number"
                [(ngModel)]="form.lpc_Number" required #lpc_Number="ngModel"
                [ngClass]="{'invalid-input': lpc_Number.invalid, 'valid-input': lpc_Number.valid}">
        </div>
    </div>
    <div class="heading-5 mt-4">What is your physical/street address (domicilium citandi et executandi)?</div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                unit
                no:</label>
        </div>
        <div class="col-7">
            <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber" class="paragraph not-required"
                [(ngModel)]="form.addressDetails.unitNo">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName" class="paragraph">Estate/complex
                name:</label>
        </div>
        <div class="col-7">
            <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName" class="paragraph not-required"
                [(ngModel)]="form.addressDetails.complexName">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                no:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber" class="paragraph"
                [(ngModel)]="form.addressDetails.streetNo" required #streetNumber="ngModel"
                [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                Name:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName" class="paragraph"
                [(ngModel)]="form.addressDetails.streetName" required #streetName="ngModel"
                [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                [(ngModel)]="form.addressDetails.suburb" required #suburb="ngModel"
                [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                [(ngModel)]="form.addressDetails.city" #city="ngModel" required
                [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-10"><label class="paragraph"
                [ngClass]="{'mat-invalid-input': conveyancerYears.invalid, 'mat-valid-input': conveyancerYears.valid}">How
                many years have you been working as a conveyancer?</label>
        </div>
    </div>
    <div class="row">
    <div class="col-1 paragraph vertical-end">Years:</div>
    <div class="col-9">
        <input name="conveyancerYears" class="paragraph mat-input-section" type="number"
            [(ngModel)]="form.conveyancerYears" required #conveyancerYears="ngModel">
        </div>
    </div>
    <div class="row mat-input-section">
        <div class="row">
            <div class="col-8">
                <div class="heading-5 mt-4">
                    <span class="burnt-red">| </span>If you/your company serves on the panel of any financial
                    institution, please indicate below
                    which institutions are applicable:
                </div>
            </div>
        </div>
        <div class="col-12">
            <div *ngFor="let option of financialInstitutionsPanelCheckBoxOptions; let index = i">
                <label class="checkbox-container paragraph mt-1">
                    <span class="paragraph">
                        {{option.value}}<span [ngClass]="{'specify-text': form.financialInstituteOther.length == 0}"
                            *ngIf="financialInstitutionsPanelOtherCheck(option)"> – Please specify</span>
                    </span>
                    <input type="checkbox" [(ngModel)]="option.checked"
                        [name]="financialInstitutionsPanelCheckBoxOptions"
                        #financialInstitutionsPanelCheckBoxOptions="ngModel" />
                    <span class="checkmark"></span>
                </label>
                <div class="mt-2" *ngIf="financialInstitutionsPanelOtherCheck(option)">
                    <div class="row mt-2">
                        <div class="col-10">
                            <input name="financialInstituteOther" type="text"
                                class="paragraph mat-input-section mat-input-section-other"
                                [(ngModel)]="form.financialInstituteOther" required #financialInstituteOther="ngModel">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="submission-form">
        <div class="row text-center">
            <div class="col-10">
                <button id="btnSaveFinal" class="button heading-4" [disabled]="informationForm.invalid"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>