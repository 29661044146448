import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/User';
import { PropertiProSharedService } from '../properti-pro-shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly APIUrl = 'https://localhost:44302/api/v1/';
  constructor(private http: HttpClient, private authService: PropertiProSharedService) { }

  getbyId(id: string) {
    return this.http.get<User>(this.APIUrl + `User/profile/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    });
  }
}