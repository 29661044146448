import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { OfferToPurchasePriceVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-property-for-sale-form-purchase-price',
  templateUrl: './property-for-sale-form-purchase-price.component.html',
  styleUrls: ['./property-for-sale-form-purchase-price.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class PropertyForSaleFormPurchasePriceComponent implements OnInit {

  @Output() offerToPurchasePriceEvent = new EventEmitter<OfferToPurchasePriceVm>();
  @Output() openSideNavEvent = new EventEmitter();

  calculatedMortgageBondForPurchasePrice: number = 0;
  useMortgageBondOfPurchaserPrice: number = 0
  hasPermissionToShareDetails: number = 0;
  acceptDeclaration: boolean = false;

  form: OfferToPurchasePriceVm = {
    daysToObtainMortgage: null,
    daysToPayDeposit: null,
    depositPercentage: null,
    depositPrice: null,
    propertyOffer: null,
    propertyPurchaceMethod: 0,
    uncertainOfMortgagePayment: false,
    daysToPayBond: null,
    hasPermissionToShareDetails: false,
    mortgageBondForPurchasePrice: null,
    mortgageBondOther: ''
  }

  propertySaleTimeframeRadioOptions: KeyValuePair[] = [
    { key: 1, value: '5' },
    { key: 2, value: '10' },
    { key: 3, value: '15' },
    { key: 4, value: '30' },
    { key: 5, value: '45' },
    { key: 6, value: '60' },
    { key: 7, value: 'Any other, please specify:' },
  ]

  purchaseMethodRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'No bond required; I can provide bank guarantees' },
    { key: 2, value: 'I have a pre-qualified loan in place' },
    { key: 3, value: 'I wish to apply for a mortgage bond myself' },
    { key: 4, value: 'I need assistance from Property Matrix to apply for a mortgage bond' },
  ];

  intendToObtainMortgageBondRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'Yes, I agree to the amount as indicated above' },
    { key: 2, value: 'No, I rather wish to apply for in the amount of' },
    { key: 3, value: 'If uncertain, ' },
  ]

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes, I agree to the amount as indicated above' },
    { key: 2, value: 'No, I rather wish to apply for in the amount of' },
  ]

  constructor() { }

  ngOnInit(): void {
    
  }

  calculateMortgageBondForPurchasePrice() {
    let depositAmount = this.form.propertyOffer * (this.form.depositPercentage / 100)
    this.calculatedMortgageBondForPurchasePrice = this.form.propertyOffer - depositAmount;
  }

  submitForm(){
    if (this.useMortgageBondOfPurchaserPrice == 1) {
      this.form.mortgageBondForPurchasePrice = this.calculatedMortgageBondForPurchasePrice
    }
    this.form.daysToObtainMortgage = this.form.daysToObtainMortgage ?? 0;
    this.form.depositPrice = this.form.depositPrice ?? 0;
    this.form.daysToPayBond = this.form.daysToPayBond ?? 0;
    this.form.mortgageBondForPurchasePrice = this.form.mortgageBondForPurchasePrice ?? 0;
    this.form.hasPermissionToShareDetails = this.hasPermissionToShareDetails == 1;
    this.form.uncertainOfMortgagePayment = this.useMortgageBondOfPurchaserPrice == 2 || this.useMortgageBondOfPurchaserPrice == 3;
    this.offerToPurchasePriceEvent.emit(this.form)
  }

  preventDecimals(event){
    return event.charCode >= 48 && event.charCode <= 57
  }

  openSideNav(e: Event) {
    e.preventDefault();
    this.openSideNavEvent.emit();
  }

  focusOther() {
    setTimeout(() => {
      const input = document.getElementById('mortgageBondOther');
      if (input) {
        input.focus();
        GlobalMethods.scrollToId('mortgageBondOther')
      }
    }, 5);
  }

}
