import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planner-registration',
  templateUrl: './planner-registration.component.html',
  styleUrls: ['./planner-registration.component.scss']
})
export class PlannerRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
