<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <div class="heading-2 mt-1">STEP 1: PERSONAL AND COMPANY INFORMATION</div>
    <div class="heading-4 mt-1">Contact Details</div>
    <div class="heading-5 mt-4">What is your physical/street address (domicilium citandi et executandi)?</div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                unit
                no:</label>
        </div>
        <div class="col-7">
            <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber" class="paragraph not-required"
                [(ngModel)]="form.addressDetails.unitNo">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName" class="paragraph">Estate/complex
                name:</label>
        </div>
        <div class="col-7">
            <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName" class="paragraph not-required"
                [(ngModel)]="form.addressDetails.complexName">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                no:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber" class="paragraph"
                [(ngModel)]="form.addressDetails.streetNo" required #streetNumber="ngModel"
                [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                Name:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName" class="paragraph"
                [(ngModel)]="form.addressDetails.streetName" required #streetName="ngModel"
                [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                [(ngModel)]="form.addressDetails.suburb" required #suburb="ngModel"
                [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                [(ngModel)]="form.addressDetails.city" #city="ngModel" required
                [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-10"><label class="paragraph"
                [ngClass]="{'mat-invalid-input': yearsWorkingAsConveyancer.invalid, 'mat-valid-input': yearsWorkingAsConveyancer.valid}">How
                many years have you been working as a valuer?</label>
        </div>
    </div>
    <div class="row">
        <div class="col-1"></div>
        <div class="col-9">
            <input name="yearsWorkingAsConveyancer" class="paragraph mat-input-section" type="number"
                [(ngModel)]="form.yearsWorkingAsConveyancer" required #yearsWorkingAsConveyancer="ngModel">
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <div class="heading-5">Please provide your registration number with the SA Council for Property Valuers
                (SACPVP),
                as well as your registration type:</div>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <label class="paragraph">Registration Number:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" name="sacpvp_RegistrationNumber" class="paragraph"
                [(ngModel)]="form.sacpvp_RegistrationNumber" #sacpvp_RegistrationNumber="ngModel" required
                [ngClass]="{'invalid-input': sacpvp_RegistrationNumber.invalid, 'valid-input':sacpvp_RegistrationNumber.valid}">
        </div>
    </div>
    <div class="row">
        <mat-radio-group name="valuerTypeRadioOptions" [(ngModel)]="form.valuerType">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of valuerTypeRadioOptions"
                [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="form.valuerType > 0">
        <div *ngIf="form.valuerType == 2">
            <div class="row mt-2">
                <div class="col-8">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Please indicate the type and clusters of valuations you are
                        permitted to
                        perform without
                        restrictions, as per your SACPVP registration:
                    </div>
                </div>
                <div class="col-8">
                    <textarea name="clustersOfValutations" class="paragraph" rows="3"
                        [(ngModel)]="form.clustersOfValutations" #clustersOfValutations="ngModel"
                        placeholder="Three-storey walk-up apartments | shared office space | storage units"></textarea>
                </div>
            </div>
        </div>
        <div class="heading-5 mt-3">
            <span class="burnt-red">| </span>Which geographical areas do you mostly operate in? (Only indicate areas
            where you can do site visits for valuations on a daily basis)
        </div>
        <div class="row">
            <div class="col-12">
                <div *ngFor="let option of metroRegionsCheckBoxOptions; let index = i">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="paragraph">
                            {{option.value}}
                        </span>
                        <input type="checkbox" [(ngModel)]="option.checked" [name]="metroRegionsCheckBoxOptions"
                            #metroRegionsCheckBoxOptions="ngModel" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-10"><label class="paragraph"
                    [ngClass]="{'mat-invalid-input': yearsWorkingInValuationIndustry.invalid, 'mat-valid-input': yearsWorkingInValuationIndustry.valid}">How
                    many years have you been working in the valuation industry?</label></div>
            <div class="col-10">
                <input name="yearsWorkingInValuationIndustry" class="paragraph mat-input-section" type="number"
                    [(ngModel)]="form.yearsWorkingInValuationIndustry" required
                    #yearsWorkingInValuationIndustry="ngModel">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-8">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>For which types of properties do you have extensive valuation
                    experience and knowledge:
                </div>
            </div>
        </div>
        <div class="row mat-input-section">
            <div class="col-8">
                <div *ngFor="let option of propertyExperienceTypeCheckBoxOptions; let index = i">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="paragraph">
                            {{option.value}}<span
                                [ngClass]="{'specify-text': form.propertyExperienceTypeOther.length == 0}"
                                *ngIf="propertyExperienceTypeOtherCheck(option)"> – Please specify</span>
                        </span>
                        <input type="checkbox" [(ngModel)]="option.checked"
                            [name]="propertyExperienceTypeCheckBoxOptions"
                            #propertyExperienceTypeCheckBoxOptions="ngModel" />
                        <span class="checkmark"></span>
                    </label>
                    <div class="mt-2" *ngIf="propertyExperienceTypeOtherCheck(option)">
                        <div class="row mt-1">
                            <div class="col-10">
                                <input name="propertyExperienceTypeOther" class="paragraph mat-input-section"
                                    type="text" [(ngModel)]="form.propertyExperienceTypeOther" required
                                    #propertyExperienceTypeOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <div class="heading-5 mt-4">
                    <span class="burnt-red">| </span>If you/your company serves on the panel of any financial
                    institution,
                    please indicate below
                    which institutions are applicable:
                </div>
            </div>
        </div>
        <div class="row mat-input-section">
            <div class="col-8">
                <div *ngFor="let option of financialInstitutionsPanelCheckBoxOptions; let index = i">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="paragraph">
                            {{option.value}}<span
                                [ngClass]="{'specify-text': form.financialInstitutionsOther.length == 0}"
                                *ngIf="financialInstitutionsPanelOtherCheck(option)"> – Please specify</span>
                        </span>
                        <input type="checkbox" [(ngModel)]="option.checked"
                            [name]="financialInstitutionsPanelCheckBoxOptions"
                            #financialInstitutionsPanelCheckBoxOptions="ngModel" />
                        <span class="checkmark"></span>
                    </label>
                    <div class="mt-2" *ngIf="financialInstitutionsPanelOtherCheck(option)">
                        <div class="row mt-2">
                            <div class="col-10">
                                <input name="financialInstitutionsOther" class="paragraph mat-input-section" type="text"
                                    [(ngModel)]="form.financialInstitutionsOther" required
                                    #financialInstitutionsOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="submission-form">
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" class="button heading-4" [disabled]="informationForm.invalid"
                        (click)="submitForm()">SUBMIT</button>
                </div>
            </div>
        </section>
    </div>
</form>