import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-report-pdf',
  templateUrl: './report-pdf.component.html',
  styleUrls: ['./report-pdf.component.scss']
})
export class ReportPdfComponent implements OnInit {

  htmlReport = `<!DOCTYPE html>
  <html>
  
  <head>
      <meta charset="utf-8">
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet"
          integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous">
  </head>
  
  <body>
      <div class="container">
          <div class="wrapper">
              <div class="row mt-4">
                  <div class="col-12">
                      <h3 class="section-title">1. PROPERTY INFORMATION</h3>
                  </div>
              </div>
              <div class="row mt-4">
                  <div class="col-12">
                      <h6>STAND 35 MODDERFONTEIN AGRICULTURAL HOLDING</h6>
                  </div>
                  <div class="col-12 mt-3">
                      <div class="row">
                          <div class="col-6">
                              <div class="black-thin-underline">PROPERTY DETAILS</div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Property type
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Unit number
                                  </div>
                                  <div class="col-4">
                                      2
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Erf number
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Portion number
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Township
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Deeds erf extent
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Surveyor General erf extent
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                          </div>
                          <div class="col-6">
                              <p class="black-thin-underline">ADDRESS DETAILS</p>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Street number
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Street name
                                  </div>
                                  <div class="col-4">
                                      2
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Complex name
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Suburb name
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Sub-place name
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      City/Town
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Municipality
                                  </div>
                                  <div class="col-4">
                                      Erf
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-8 bold">
                                      Province
                                  </div>
                                  <div class="col-4">
                                      Gauteng
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="page-break"></div>
              <div class="row mt-4">
                  <div class="col-12">
                      LOCALITY MAP
                      <img src="https://propertymatrix.blob.core.windows.net/file-store/23322a0f-9183-4d0e-93d9-a81016caa437?sv=2018-03-28&sr=b&sig=9xZ%2BWVIEyxq7Nf5Z7ijmX81YlLa1naaJQBW1mAXcUi4%3D&se=2023-04-20T13%3A11%3A14Z&sp=r" id="localityMap">
                  </div>
              </div>
          </div>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe"
          crossorigin="anonymous"></script>
      <script>
      async function getImageFromDataUri(dataURI) {
          console.log('awe1')
          var blob = await (await fetch(dataURI)).blob();
          let objectURL = URL.createObjectURL(blob);
          return objectURL;
        }
        document.getElementById('localityMap').src = getImageFromDataUri("https://propertymatrix.blob.core.windows.net/file-store/23322a0f-9183-4d0e-93d9-a81016caa437?sv=2018-03-28&sr=b&sig=9xZ%2BWVIEyxq7Nf5Z7ijmX81YlLa1naaJQBW1mAXcUi4%3D&se=2023-04-20T13%3A11%3A14Z&sp=r")
          console.log('awe')
      </script>
      <style>
      .container {
        background-color: white !important;
    }
          .section-title {
              border-bottom: 1px solid #f2825e;
          }
  
          .container {
              text-align: center;
              width: 95vw;
          }
  
          .wrapper {
              text-align: start;
          }
  
          .black-thin-underline {
              border-bottom: 1px solid black;
              width: 95%;
          }
  
          .bold {
              font-weight: bold;
          }
  
          .page-break {
              page-break-after: always
          }
      </style>
  </body>
  
  </html>`

  sanitizedHtml = this.transform()

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.transform();
  }

  transform(){
    return this.sanitizer.bypassSecurityTrustHtml(this.htmlReport);
  }
}
