<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div id="top-page"></div>
    <div class="content content-padding">
        <div class="container">
            <!--Form section block-->
            <section class="mt-3">
                <div class="d-flex justify-content-between form-section-gap">
                    <div *ngFor="let header of formHeaders">
                        <button class="form-section form-section-button active-form-section"
                            [ngClass]="{ 'active-form-section': pageNumber >= header.key }"
                            [disabled]="pageNumber <= header.key"
                            (click)="selectPage(header.key)">
                            <h3 class="heading-3-light">{{header.value}}</h3>
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <div [hidden]="pageNumber != 1">
                   <app-planning-report *ngIf="reportDetails" [reportDetails]="reportDetails" (pageNumberEvent)="changePage($event)"></app-planning-report>
                </div>
                <div [hidden]="pageNumber != 2">
                    <app-addendum-one *ngIf="reportDetails" [reportDetails]="reportDetails" (pageNumberEvent)="changePage($event)"></app-addendum-one>
                </div>
                <div [hidden]="pageNumber != 3">
                    <app-addendum-two *ngIf="reportDetails" [reportDetails]="reportDetails" (pageNumberEvent)="changePage($event)"></app-addendum-two>
                </div>
                <div [hidden]="pageNumber != 4">
                    <app-addendum-three *ngIf="reportDetails" [reportDetails]="reportDetails" (pageNumberEvent)="changePage($event)"></app-addendum-three>
                </div>
                <div [hidden]="pageNumber != 5">
                    <app-valuation *ngIf="reportDetails" (submitReportEvent)="submitReport()" (saveReportEvent)="saveReport()" [reportDetails]="reportDetails"></app-valuation>
                </div>
            </section>
        </div>
    </div>
</main>