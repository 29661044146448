import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { CheckboxVm, UrbanPlannerDetailsVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-planner-register-form-information',
  templateUrl: './planner-register-form-information.component.html',
  styleUrls: ['./planner-register-form-information.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class PlannerRegisterFormInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() UrbanPlannerDetailsEvent = new EventEmitter<UrbanPlannerDetailsVm>();
  
  emailPattern: string = GlobalConstants.emailPattern;

  form: UrbanPlannerDetailsVm = {
    addressDetails: {
      streetName: '',
      streetNo: '',
      suburb: '',
      unitNo: '',
      complexName: '',
      city: '',
      postalCode: '',
    },
    adminContactDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false,
      landline: '',
      position: '',
    },
    townPlanningSupportStaffDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false,
      landline: '',
      position: '',
    },
    adminContactDetailsPosition: '',
    companyNameOperatedUnder: '',
    companyRegistrationNumber: '',
    companyVatNumber: '',
    graduationInstitution: '',
    plannerGraduationYear: '',
    sacplan_RegistrationNumber: '',
    sacplanRegistrationType: 0,
    statutoryPlanningExperienceYears: null,
    townPlanningSupportStaffPosition: '',
    municipalCouncilsMostlySubmittedTo: null,
    municipalCouncilsMostlySubmittedToOther: '',
    planningApplicationsExperienceAndKnowledge: null,
    planningApplicationsExperienceAndKnowledgeOther: '',
  }

  municipalCouncilsMostlySubmittedToOptions: CheckboxVm[] = [
    { id: 1, value: 'City of Tshwane (Pretoria)', checked: false },
    { id: 2, value: 'City of Johannesburg', checked: false },
    { id: 3, value: 'City of Ekurhuleni (East Rand)', checked: false },
    { id: 4, value: 'City of Cape Town', checked: false },
    { id: 5, value: 'City of eThekwini (Durban)', checked: false },
    { id: 6, value: 'Other', checked: false },
  ]

  planningApplicationsExperienceAndKnowledgeOptions: CheckboxVm[] = [
    { id: 1, value: 'Township establishment', checked: false },
    { id: 2, value: 'Fuel stations', checked: false },
    { id: 3, value: 'Retail stores/centres', checked: false },
    { id: 4, value: 'Environment-sensitive sites', checked: false },
    { id: 5, value: 'Other', checked: false },
  ]

  sacplanRegistrationTypes: KeyValuePair[] = [
    {key: 1, value: 'Professional planner'},
    {key: 2, value: 'Technical planner'},
    {key: 3, value: 'Candidate planner'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

  municipalOtherCheck(option: CheckboxVm): boolean {
    return option.id == 6 && option.checked;
  }

  planningOtherCheck(option: CheckboxVm): boolean {
    return option.id == 5 && option.checked;
  }

  submitForm(){
    this.form.planningApplicationsExperienceAndKnowledge = this.planningApplicationsExperienceAndKnowledgeOptions.filter(f => f.checked == true);
    this.form.municipalCouncilsMostlySubmittedTo = this.municipalCouncilsMostlySubmittedToOptions.filter(f => f.checked == true);;
    this.pageNumberEvent.emit(2);
    this.UrbanPlannerDetailsEvent.emit(this.form);
  }
  
}
