import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PropertiProSharedService } from '../properti-pro-shared.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authService: PropertiProSharedService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = sessionStorage.getItem('user');
    if (!user) {
      return next.handle(request);
    }
    if(!request.headers.has('Authorization')){
      const token = this.authService.getToken();
      if (!token) {
        return next.handle(request);
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
    
  }
}