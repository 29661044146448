import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { EstateAgentFormVm } from 'src/app/Services/propertypros/models';
import { EstateAgentService, UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-estate-agent-register-form',
  templateUrl: './estate-agent-register-form.component.html',
  styleUrls: ['./estate-agent-register-form.component.scss']
})
export class EstateAgentRegisterFormComponent implements OnInit {

  pageNumber: Number = 1;
  loading: boolean = false;
  user: any = null;


  form: EstateAgentFormVm = {
    estateAgentCompanyInformation: null,
    estateAgentPersonalInformation: null,
    paths: null,
    username: null
  }

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'PERSONAL INFORMATION' },
    { key: 2, value: 'COMPANY INFORMATION' },
    { key: 3, value: 'SUBMIT DOCUMENTS' },
    { key: 4, value: 'SIGN CONTRACT' },
  ];

  constructor(private router: Router, private estateAgentService: EstateAgentService, private sharedService: PropertiProSharedService, private userService: UserService) { }

  submitForm() {
    this.loading = true;
    const username: string = this.sharedService.getUsername();
    this.form.username = username;
    this.estateAgentService.apiV1EstateAgentCaptureEstateAgentFormPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {
              this.pageNumber = 3;
            });
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {
            this.pageNumber = 3;
          })
          this.loading = false;
        }
      })
  }

  ngOnInit(): void {
    this.addOrRemoveBackgroundImage();
    GlobalMethods.scrollToTop();
    sessionStorage.removeItem('originalUrl');
    this.user = sessionStorage.getItem('user');
    if (!this.user) {
      this.router.navigateByUrl('/login')
    } else {
      this.isNormalUser();
    }
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
    this.addOrRemoveBackgroundImage();
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  addOrRemoveBackgroundImage(){
    if(this.pageNumber == 4){
      document.getElementById('main').classList.add('background-image');
    }else{
      document.getElementById('main').classList.remove('background-image');
    }
  }

  assignEstateAgentPersonalInformation($event) {
    this.form.estateAgentPersonalInformation = $event;
  }

  assignEstateAgentCompanyInformation($event) {
    this.form.estateAgentCompanyInformation = $event;

  }

  assignFiles($event) {
    this.form.paths = $event;
    this.addOrRemoveBackgroundImage();
    this.submitForm();
  }

  isNormalUser() {
    const userModel = JSON.parse(JSON.parse(this.user));

    this.userService.apiV1UserIsNormalUserGet$Response({ email: userModel.userName })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an estate agent').then(result => {
              this.router.navigateByUrl('/home');
            })
          }
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'You have already been registered as an estate agent').then(result => {
            this.router.navigateByUrl('/home');
          })
          this.loading = false;
        }
      })
  }
}
