/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PropertyListingCustomListingVm } from '../models/property-listing-custom-listing-vm';
import { PropertyListingFilesEnum } from '../models/property-listing-files-enum';
import { PropertyListingReportTypeEnum } from '../models/property-listing-report-type-enum';
import { UploadDocumentDetailsVm } from '../models/upload-document-details-vm';
import { UserRolesEnum } from '../models/user-roles-enum';

@Injectable({
  providedIn: 'root',
})
export class PropertyListingService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1PropertyListingGetPlanningReportDetailsGet
   */
  static readonly ApiV1PropertyListingGetPlanningReportDetailsGetPath = '/api/v1/PropertyListing/GetPlanningReportDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetPlanningReportDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPlanningReportDetailsGet$Response(params?: {
    propertyId?: number;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetPlanningReportDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyId', params.propertyId, {});
      rb.query('custom', params.custom, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetPlanningReportDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPlanningReportDetailsGet(params?: {
    propertyId?: number;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetPlanningReportDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetPropertyListingsGet
   */
  static readonly ApiV1PropertyListingGetPropertyListingsGetPath = '/api/v1/PropertyListing/GetPropertyListings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetPropertyListingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingsGet$Response(params?: {
    username?: string;
    id?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetPropertyListingsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetPropertyListingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingsGet(params?: {
    username?: string;
    id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetPropertyListingsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetPropertyListingRiskScapeDataGet
   */
  static readonly ApiV1PropertyListingGetPropertyListingRiskScapeDataGetPath = '/api/v1/PropertyListing/GetPropertyListingRiskScapeData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetPropertyListingRiskScapeDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingRiskScapeDataGet$Response(params?: {
    propertyId?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetPropertyListingRiskScapeDataGetPath, 'get');
    if (params) {
      rb.query('propertyId', params.propertyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetPropertyListingRiskScapeDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetPropertyListingRiskScapeDataGet(params?: {
    propertyId?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetPropertyListingRiskScapeDataGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetAddressDetailsGet
   */
  static readonly ApiV1PropertyListingGetAddressDetailsGetPath = '/api/v1/PropertyListing/GetAddressDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetAddressDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAddressDetailsGet$Response(params?: {
    propertyId?: number;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetAddressDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyId', params.propertyId, {});
      rb.query('custom', params.custom, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetAddressDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAddressDetailsGet(params?: {
    propertyId?: number;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetAddressDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetAssignableUsersGet
   */
  static readonly ApiV1PropertyListingGetAssignableUsersGetPath = '/api/v1/PropertyListing/GetAssignableUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetAssignableUsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAssignableUsersGet$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetAssignableUsersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetAssignableUsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetAssignableUsersGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetAssignableUsersGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingAssignListingRoleGet
   */
  static readonly ApiV1PropertyListingAssignListingRoleGetPath = '/api/v1/PropertyListing/AssignListingRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingAssignListingRoleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingAssignListingRoleGet$Response(params?: {
    listingId?: number;
    userId?: string;
    roleType?: UserRolesEnum;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingAssignListingRoleGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
      rb.query('userId', params.userId, {});
      rb.query('roleType', params.roleType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingAssignListingRoleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingAssignListingRoleGet(params?: {
    listingId?: number;
    userId?: string;
    roleType?: UserRolesEnum;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingAssignListingRoleGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGenerateListingsGet
   */
  static readonly ApiV1PropertyListingGenerateListingsGetPath = '/api/v1/PropertyListing/GenerateListings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGenerateListingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGenerateListingsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGenerateListingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGenerateListingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGenerateListingsGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGenerateListingsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingSavePropertyListingDataPost
   */
  static readonly ApiV1PropertyListingSavePropertyListingDataPostPath = '/api/v1/PropertyListing/SavePropertyListingData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingSavePropertyListingDataPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingSavePropertyListingDataPost$Response(params?: {
    custom?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingSavePropertyListingDataPostPath, 'post');
    if (params) {
      rb.query('custom', params.custom, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingSavePropertyListingDataPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingSavePropertyListingDataPost(params?: {
    custom?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingSavePropertyListingDataPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingDeletePropertyListingFilePost
   */
  static readonly ApiV1PropertyListingDeletePropertyListingFilePostPath = '/api/v1/PropertyListing/DeletePropertyListingFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingDeletePropertyListingFilePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeletePropertyListingFilePost$Response(params?: {
    fileId?: string;
    type?: PropertyListingReportTypeEnum;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingDeletePropertyListingFilePostPath, 'post');
    if (params) {
      rb.query('fileId', params.fileId, {});
      rb.query('type', params.type, {});
      rb.query('custom', params.custom, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingDeletePropertyListingFilePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeletePropertyListingFilePost(params?: {
    fileId?: string;
    type?: PropertyListingReportTypeEnum;
    custom?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingDeletePropertyListingFilePost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetCustomMultipleListingGet
   */
  static readonly ApiV1PropertyListingGetCustomMultipleListingGetPath = '/api/v1/PropertyListing/GetCustomMultipleListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetCustomMultipleListingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetCustomMultipleListingGet$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetCustomMultipleListingGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetCustomMultipleListingGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetCustomMultipleListingGet(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetCustomMultipleListingGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingCreateCustomMultipleListingPost
   */
  static readonly ApiV1PropertyListingCreateCustomMultipleListingPostPath = '/api/v1/PropertyListing/CreateCustomMultipleListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingCreateCustomMultipleListingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingCreateCustomMultipleListingPost$Response(params?: {
    context?: HttpContext
    body?: PropertyListingCustomListingVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingCreateCustomMultipleListingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingCreateCustomMultipleListingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingCreateCustomMultipleListingPost(params?: {
    context?: HttpContext
    body?: PropertyListingCustomListingVm
  }
): Observable<void> {

    return this.apiV1PropertyListingCreateCustomMultipleListingPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingDeleteCustomListingPost
   */
  static readonly ApiV1PropertyListingDeleteCustomListingPostPath = '/api/v1/PropertyListing/DeleteCustomListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingDeleteCustomListingPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeleteCustomListingPost$Response(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingDeleteCustomListingPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingDeleteCustomListingPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDeleteCustomListingPost(params?: {
    id?: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingDeleteCustomListingPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingUpdateCustomMultipleListingPost
   */
  static readonly ApiV1PropertyListingUpdateCustomMultipleListingPostPath = '/api/v1/PropertyListing/UpdateCustomMultipleListing';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingUpdateCustomMultipleListingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingUpdateCustomMultipleListingPost$Response(params?: {
    context?: HttpContext
    body?: PropertyListingCustomListingVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingUpdateCustomMultipleListingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingUpdateCustomMultipleListingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingUpdateCustomMultipleListingPost(params?: {
    context?: HttpContext
    body?: PropertyListingCustomListingVm
  }
): Observable<void> {

    return this.apiV1PropertyListingUpdateCustomMultipleListingPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingDownloadPropertyReportGet
   */
  static readonly ApiV1PropertyListingDownloadPropertyReportGetPath = '/api/v1/PropertyListing/DownloadPropertyReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingDownloadPropertyReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDownloadPropertyReportGet$Response(params?: {
    id?: number;
    multipleReport?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingDownloadPropertyReportGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('multipleReport', params.multipleReport, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingDownloadPropertyReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingDownloadPropertyReportGet(params?: {
    id?: number;
    multipleReport?: boolean;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingDownloadPropertyReportGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingSaveFilesPost
   */
  static readonly ApiV1PropertyListingSaveFilesPostPath = '/api/v1/PropertyListing/SaveFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingSaveFilesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingSaveFilesPost$Response(params?: {
    custom?: boolean;
    type?: PropertyListingFilesEnum;
    context?: HttpContext
    body?: UploadDocumentDetailsVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingSaveFilesPostPath, 'post');
    if (params) {
      rb.query('custom', params.custom, {});
      rb.query('type', params.type, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingSaveFilesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1PropertyListingSaveFilesPost(params?: {
    custom?: boolean;
    type?: PropertyListingFilesEnum;
    context?: HttpContext
    body?: UploadDocumentDetailsVm
  }
): Observable<void> {

    return this.apiV1PropertyListingSaveFilesPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1PropertyListingGetFilesGet
   */
  static readonly ApiV1PropertyListingGetFilesGetPath = '/api/v1/PropertyListing/GetFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1PropertyListingGetFilesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetFilesGet$Response(params?: {
    propertyId?: number;
    custom?: boolean;
    type?: PropertyListingFilesEnum;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PropertyListingService.ApiV1PropertyListingGetFilesGetPath, 'get');
    if (params) {
      rb.query('propertyId', params.propertyId, {});
      rb.query('custom', params.custom, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1PropertyListingGetFilesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1PropertyListingGetFilesGet(params?: {
    propertyId?: number;
    custom?: boolean;
    type?: PropertyListingFilesEnum;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1PropertyListingGetFilesGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
