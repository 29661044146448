<mat-card class="mt-4 outlined-card">
    <div class="row">
        <div class="col-11">
            <div class="heading-2">Subscriptions</div>
        </div>
        <div class="col-1 edit-button-margin"><button mat-icon-button><mat-icon>edit</mat-icon></button></div>
    </div>
    <mat-card-content>
        <div>
        <div class="add-subscribe-block" *ngFor="let subscription of subscriptions; let i = index">
            <div class="add-block-text text-center paragraph-light">
                <span>SUBSCRIPTION</span>
                <br>
                <b>{{i + 1}}</b>
                <br>
                <span><button (click)="deleteSubscription(i)"
                        mat-icon-button><mat-icon>delete</mat-icon></button></span>
            </div>
        </div>
    </div>
    </mat-card-content>
</mat-card>