<!-- Background image -->
<img class="home-page-video w-100" src="/assets/images/home/Cover Image.png" alt="VideoLoop">
<!-- Background image -->
<div class="container-fluid">
  <!-- Bottom Navigation Menu -->
  <footer class="row mx-4">
    <div class="col-5">
      <span class="heading-2-light">DEVELOPMENT AND COMMERCIAL<br> <span class="mx-5">PROPERTY SPECIALISTS</span></span>
    </div>
    <div class="col-7">
      <ul class="navbar">
        <li class="navbot-item"><a class="nav-link heading-5-light" href="/register/choice"
            routerLink="/register/choice">REGISTER</a></li>
      </ul>
    </div>
  </footer> <!-- Bottom Navigation Menu -->
</div>