import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, OnDestroy, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-feasibility-studies',
  templateUrl: './feasibility-studies.component.html',
  styleUrls: ['./feasibility-studies.component.scss']
})
export class FeasibilityStudiesComponent implements AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(private _focusMonitor: FocusMonitor) { }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore1'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore2'));
  }

  newColorbtn1 = false;

  newColorbtn2 = false;

  newColorbtn3 = false;

  isMenuOpen = false;

  showLevel1 = false;

  showLevel2 = false;

  open = false;

  isShow = true;

  tabIndex = -1;

  toggleColorbtn1(index) {
    this.newColorbtn1 = !this.newColorbtn1;
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorbtn2(index) {
    this.newColorbtn2 = !this.newColorbtn2;
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    if (this.newColorbtn1 == true) {
      this.newColorbtn1 = false
    }
    if (this.newColorbtn2 == true) {
      this.newColorbtn2 = false
    }
  }
}