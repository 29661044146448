/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressVm } from '../models/address-vm';

@Injectable({
  providedIn: 'root',
})
export class MapsService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1MapsGetAddressGeocodePost
   */
  static readonly ApiV1MapsGetAddressGeocodePostPath = '/api/v1/Maps/GetAddressGeocode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressGeocodePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1MapsGetAddressGeocodePost$Response(params?: {
    context?: HttpContext
    body?: AddressVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressGeocodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressGeocodePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1MapsGetAddressGeocodePost(params?: {
    context?: HttpContext
    body?: AddressVm
  }
): Observable<void> {

    return this.apiV1MapsGetAddressGeocodePost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressImageGet
   */
  static readonly ApiV1MapsGetAddressImageGetPath = '/api/v1/Maps/GetAddressImage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressImageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressImageGet$Response(params?: {
    propertyKey?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressImageGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressImageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressImageGet(params?: {
    propertyKey?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetAddressImageGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetPropertyDetailsFromLatLonGet
   */
  static readonly ApiV1MapsGetPropertyDetailsFromLatLonGetPath = '/api/v1/Maps/GetPropertyDetailsFromLatLon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetPropertyDetailsFromLatLonGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsFromLatLonGet$Response(params?: {
    lat?: string;
    lon?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetPropertyDetailsFromLatLonGetPath, 'get');
    if (params) {
      rb.query('lat', params.lat, {});
      rb.query('lon', params.lon, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetPropertyDetailsFromLatLonGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsFromLatLonGet(params?: {
    lat?: string;
    lon?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetPropertyDetailsFromLatLonGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetAddressDetailsGet
   */
  static readonly ApiV1MapsGetAddressDetailsGetPath = '/api/v1/Maps/GetAddressDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetAddressDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressDetailsGet$Response(params?: {
    addressId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetAddressDetailsGetPath, 'get');
    if (params) {
      rb.query('addressId', params.addressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetAddressDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetAddressDetailsGet(params?: {
    addressId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetAddressDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetStructureExtentsGet
   */
  static readonly ApiV1MapsGetStructureExtentsGetPath = '/api/v1/Maps/GetStructureExtents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetStructureExtentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetStructureExtentsGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetStructureExtentsGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetStructureExtentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetStructureExtentsGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetStructureExtentsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetValuationGet
   */
  static readonly ApiV1MapsGetValuationGetPath = '/api/v1/Maps/GetValuation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetValuationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetValuationGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetValuationGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetValuationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetValuationGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetValuationGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsRiskscapeDemographicsVmGet
   */
  static readonly ApiV1MapsRiskscapeDemographicsVmGetPath = '/api/v1/Maps/RiskscapeDemographicsVM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsRiskscapeDemographicsVmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsRiskscapeDemographicsVmGet$Response(params?: {
    subplaceId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsRiskscapeDemographicsVmGetPath, 'get');
    if (params) {
      rb.query('subplaceId', params.subplaceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsRiskscapeDemographicsVmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsRiskscapeDemographicsVmGet(params?: {
    subplaceId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsRiskscapeDemographicsVmGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetDolomiteGet
   */
  static readonly ApiV1MapsGetDolomiteGetPath = '/api/v1/Maps/GetDolomite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetDolomiteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDolomiteGet$Response(params?: {
    subplaceId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetDolomiteGetPath, 'get');
    if (params) {
      rb.query('subplaceId', params.subplaceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetDolomiteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetDolomiteGet(params?: {
    subplaceId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetDolomiteGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetUnitsFromSchemeIdGet
   */
  static readonly ApiV1MapsGetUnitsFromSchemeIdGetPath = '/api/v1/Maps/GetUnitsFromSchemeId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetUnitsFromSchemeIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetUnitsFromSchemeIdGet$Response(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetUnitsFromSchemeIdGetPath, 'get');
    if (params) {
      rb.query('schemeId', params.schemeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetUnitsFromSchemeIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetUnitsFromSchemeIdGet(params?: {
    schemeId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetUnitsFromSchemeIdGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetSchemeDataGet
   */
  static readonly ApiV1MapsGetSchemeDataGetPath = '/api/v1/Maps/GetSchemeData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetSchemeDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetSchemeDataGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetSchemeDataGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetSchemeDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetSchemeDataGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetSchemeDataGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetPropertyDetailsGet
   */
  static readonly ApiV1MapsGetPropertyDetailsGetPath = '/api/v1/Maps/GetPropertyDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetPropertyDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsGet$Response(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetPropertyDetailsGetPath, 'get');
    if (params) {
      rb.query('propertyKey', params.propertyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetPropertyDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetPropertyDetailsGet(params?: {
    propertyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetPropertyDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1MapsGetBulkPropertyDataGet
   */
  static readonly ApiV1MapsGetBulkPropertyDataGetPath = '/api/v1/Maps/GetBulkPropertyData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MapsGetBulkPropertyDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetBulkPropertyDataGet$Response(params?: {
    subplaceId?: string;
    neighbourhoodId?: string;
    proprtyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MapsService.ApiV1MapsGetBulkPropertyDataGetPath, 'get');
    if (params) {
      rb.query('subplaceId', params.subplaceId, {});
      rb.query('neighbourhoodId', params.neighbourhoodId, {});
      rb.query('proprtyKey', params.proprtyKey, {});
      rb.query('unitNumber', params.unitNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1MapsGetBulkPropertyDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MapsGetBulkPropertyDataGet(params?: {
    subplaceId?: string;
    neighbourhoodId?: string;
    proprtyKey?: string;
    unitNumber?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1MapsGetBulkPropertyDataGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
