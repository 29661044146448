<main style="background-color: white">
  <div class="container-fluid">
    <section class="content">
      <div class="content-padding">
        <div class="container">
          <form id="registerForm" novalidate #registerForm="ngForm" class="submission-form">
            <div class="row">
              <div class="col-12">
                <h1 class="heading-1">
                  PLEASE PROVIDE THE FOLLOWING:
                </h1>
              </div>
              <div class="col-12 mt-4">
                <h4 class="heading-4">
                  Contact Details
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                  [(ngModel)]="form.personalDetails.name" required #name="ngModel"
                  [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                  [(ngModel)]="form.personalDetails.surname" required #surname="ngModel"
                  [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblLandline" for="txtLandline" class="paragraph">Landline:</label>
              </div>
              <div class="col-7">
                <input type="text" id="txtLandline" name="landline" class="paragraph not-required"
                  [(ngModel)]="form.personalDetails.landline">
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber" class="paragraph"
                  [(ngModel)]="form.personalDetails.phoneNumber" required #phoneNumber="ngModel" minlength="10"
                  [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                <small class="form-control-feedback"
                  *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                  *Mobile number must contain at least the 10 numbers
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalConfirmPhoneNumber" for="txtPersonalConfirmPhoneNumber" class="paragraph">Confirm
                  Mobile:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmPhoneNumber" name="confirmPhoneNumber"
                  class="paragraph" required #confirmPhoneNumber="ngModel"
                  [(ngModel)]="form.personalDetails.confirmPhoneNumber"
                  [ngClass]="{'invalid-input': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                  'valid-input': (phoneNumber.value == confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                  'confirm-valid': phoneNumber.value == confirmPhoneNumber.value}">
                  *Confirm Mobile does not match Mobile Number
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                  Address:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                  class="paragraph" [(ngModel)]="form.personalDetails.emailAddress" required #emailAddress="ngModel"
                  [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                  'valid-input': emailAddress.valid}">
                <small class="form-control-feedback"
                  *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                  *Must be a valid email address
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                  Email:</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                  class="paragraph" required #confirmEmail="ngModel" [pattern]="emailPattern"
                  [(ngModel)]="form.personalDetails.confirmEmail"
                  [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                  'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                  'confirm-valid': emailAddress.value == confirmEmail.value}">
                  *Confirm Email does not match Email Address
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <div class="heading-5">
                  <span class="burnt-red">| </span>Are you, as owner, a resident of the Republic of South
                  Africa?
                </div>
                <div>
                  <mat-radio-group name="radioSouthAfricanResident" [(ngModel)]="isRsaResident">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                      [value]="selectionValue.key">
                      {{selectionValue.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                  <span *ngIf="isRsaResident == 1">Identity
                    Number:</span>
                  <span *ngIf="isRsaResident == 2">Passport Number:</span>
                </label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number" class="paragraph"
                  [(ngModel)]="form.personalDetails.id_Number" required #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                  'valid-input': id_Number.valid}">
                <small class="form-control-feedback"
                  *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                  *Must must contain at least 9 characters
                </small>
              </div>
            </div>
            <p class="paragraph"><strong>Note: Please check spelling and digits carefully.</strong></p>
            <div class="row">
              <div class="col-12">
                <h4 class="heading-4 mt-3">
                  Log in details
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblUsername" for="txtUsername" class="paragraph">Username (Email Address):</label>
              </div>
              <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtUsername" name="username" class="paragraph"
                  [(ngModel)]="form.personalDetails.emailAddress" required #username="ngModel" disabled>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPassword" for="txtPassword" class="paragraph">Password:</label>
              </div>
              <div class="col-7">
                <input type="password" placeholder="Mandatory" id="txtPassword" name="password" class="paragraph"
                  [(ngModel)]="form.loginDetails.password" required #password="ngModel" [pattern]="passwordPattern"
                  [ngClass]="{'invalid-input': password.invalid, 'valid-input': password.valid}">
                <small class="form-control-feedback" *ngIf="password.errors && (password.dirty || password.touched)">
                  * Must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one
                  number and one special character
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label id="lblPasswordConfirm" for="txtPasswordConfirm" class="paragraph">Confirm Password:</label>
              </div>
              <div class="col-7">
                <input type="password" placeholder="Mandatory" id="txtPasswordConfirm" name="passwordConfirm"
                  class="paragraph" [(ngModel)]="form.loginDetails.passwordConfirm" required #passwordConfirm="ngModel"
                  [ngClass]="{'invalid-input': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                  'valid-input': (password.value == passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched)}">
                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                                  'confirm-valid': password.value == passwordConfirm.value}">
                  *Confirm Password does not match Password
                </small>
              </div>
            </div>
            <p class="paragraph">Once submitted, you will receive log-in details via email for future use or for
              instances where you
              cannot finalise a form/process and wish to resume
              at a later stage.</p>
            <div class="row">
              <div class="col-12">
                <label class="checkbox-container">
                  <span class="paragraph">I am aware that there is implied risk involved in providing my log-in details
                    and password to a third party to log in on my behalf. I understand
                    that such a person would have full access to all my personal information on 'My Profile'. I will not
                    hold Property Matrix SA (Pty) Ltd or any of it's
                    personnel or partners liable in any way for any claims, losses, or costs as a result of taking such
                    a risk.</span>
                  <input type="checkbox" [(ngModel)]="confirmConsent" name="confirmConsent" #coOonfirmConsent="ngModel"
                    id="cbConfirmConsent " data-toggle="toggle" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="row text-center mt-3">
              <div class="col-12">
                <button id="btnRegister" mat-button class="button heading-4 mt-2"
                  [disabled]="!(confirmConsent && registerForm.valid)" (click)="register()">
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</main>