import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { map, Observable } from 'rxjs';
import { ShareInOurDatabaseService } from 'src/app/Services/propertypros/services';
import { CheckboxVm, PropertyTypeMajorCategoryVm, SubscriptionVm } from 'src/app/Services/propertypros/models';
import { StepperVM } from '../../property-for-sale/property-for-sale-checkout/property-for-sale-checkout.component';
import { environment } from 'src/environments/environment';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-siod-subscribe',
  templateUrl: './siod-subscribe.component.html',
  styleUrls: ['./siod-subscribe.component.scss', './siod-subscribe.component-maps.scss']
})
export class SiodSubscribeComponent implements OnInit {
  subscriptions: SubscriptionVm[] = []
  showAddSubscription: boolean = false;

  private map: L.map = null;
  reRenderMap: boolean = false;
  mapRendered: boolean = false;
  containsSubscription: boolean = false;
  loading: boolean = false
  page: number = 1;
  bedBathParkingChecked: boolean = false;
  squareMeterChecked: boolean = false;
  metroActive: boolean = true;
  regionActive: boolean = false;
  suburbActive: boolean = false;
  polygons = [];
  suburbs = [];
  provincePolygons = [];
  southAfricaPolygon: any = {};
  activeSuburbId = '';
  activeRegionMarkers = [];
  propertyTypes: PropertyTypeMajorCategoryVm[] = [];
  regionPolygons: any = [];
  subPlacePolygons: any = [];
  activeRegionLayerName: string = null;

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() hideTopButtonsEvent = new EventEmitter<boolean>();

  subscription: SubscriptionVm = {
    sectorSelection: [],
    sellingPrice: [],
    landSize: [],
    bedrooms: [],
    bathrooms: [],
    parking: [],
    totalSize: [],
    propertyValues: [],
    showPlusIcon: true
  }

  sectorOptions: CheckboxVm[] = [
    { id: 1, value: 'Developed', checked: false },
    { id: 2, value: 'Developable', checked: false },
  ]

  priceOptions: CheckboxVm[] = [
    { id: 1, value: 'Up to R5m', checked: false },
    { id: 2, value: 'R5m to R10m', checked: false },
    { id: 3, value: 'R10m to R30m ', checked: false },
    { id: 4, value: 'R30m to R50m', checked: false },
    { id: 5, value: 'R50m to R80m', checked: false },
    { id: 6, value: 'R80m to R120m', checked: false },
    { id: 7, value: 'R120m to R200m', checked: false },
    { id: 8, value: 'R200m and above', checked: false },
  ]

  standSizeOptions: CheckboxVm[] = [
    { id: 1, value: '0 – 2 500m²', checked: false },
    { id: 2, value: '2 501m² - 5 000 m²', checked: false },
    { id: 3, value: '5 001m² - 1ha', checked: false },
    { id: 4, value: '1ha – 3ha', checked: false },
    { id: 5, value: '3ha – 6ha', checked: false },
    { id: 6, value: '6ha – 10ha', checked: false },
    { id: 7, value: '10ha – 20ha ', checked: false },
    { id: 8, value: '20ha – 40ha', checked: false },
    { id: 9, value: '40h – 100ha', checked: false },
    { id: 10, value: 'Above 100ha', checked: false },
  ]

  bedroomOptions: CheckboxVm[] = [
    { id: 1, value: '1', checked: false },
    { id: 2, value: '2', checked: false },
    { id: 3, value: '3 - 4', checked: false },
    { id: 4, value: '5 - 8', checked: false },
    { id: 5, value: '9 - 15', checked: false },
    { id: 6, value: '16+', checked: false },
  ]

  bathroomOptions: CheckboxVm[] = [
    { id: 1, value: '1', checked: false },
    { id: 2, value: '2', checked: false },
    { id: 3, value: '3 - 4', checked: false },
    { id: 4, value: '5 - 8', checked: false },
    { id: 5, value: '9 - 15', checked: false },
    { id: 6, value: '16+', checked: false },
  ]

  parkingOptions: CheckboxVm[] = [
    { id: 1, value: '1', checked: false },
    { id: 2, value: '2', checked: false },
    { id: 3, value: '3 - 6', checked: false },
    { id: 4, value: '7 - 12', checked: false },
    { id: 5, value: '13 - 19', checked: false },
    { id: 6, value: '20+', checked: false },
  ]

  bulkSizeOptions: CheckboxVm[] = [
    { id: 1, value: '0 – 50m²', checked: false },
    { id: 2, value: '51m² - 100m²', checked: false },
    { id: 3, value: '101m² - 200m²', checked: false },
    { id: 4, value: '201m² - 400m²', checked: false },
    { id: 5, value: '401m² - 700m²', checked: false },
    { id: 6, value: '701m² - 1 000m²', checked: false },
    { id: 7, value: '1 001m² - 2 000m²', checked: false },
    { id: 8, value: '2 001m² - 4 000m²', checked: false },
    { id: 9, value: '4 001m² - 8 000m²', checked: false },
    { id: 10, value: '8 001m² - 15 000m²', checked: false },
    { id: 11, value: 'Above 15 000m²', checked: false },
  ]

  cartSteps: StepperVM[] = [
    { key: 1, value: 'METRO', active: true },
    { key: 2, value: 'REGION', active: false },
    { key: 3, value: 'SUBURB', active: false },
  ]

  regionMarkers = [
    { id: 1, coordinates: [-23.5, 27], name: 'TSHWANE', isActive: true, icon: '../../../../assets/images/maps/icons/tshwane.png', provinceId: 3, normalIcon: null },
    { id: 2, coordinates: [-25.5, 24], name: 'JOHANNESBURG', isActive: true, icon: '../../../../assets/images/maps/icons/johannesburg.png', provinceId: 3, normalIcon: null },
    { id: 3, coordinates: [-25.8, 29], name: 'EKURUHLENI', isActive: true, icon: '../../../../assets/images/maps/icons/ekurhuleni.png', provinceId: 3, normalIcon: null },
    { id: 4, coordinates: [-32.8, 15.6], name: 'CAPE TOWN', isActive: true, icon: '../../../../assets/images/maps/icons/cape-town.png', provinceId: 9, normalIcon: null },
    { id: 5, coordinates: [-28.5, 31], name: 'ETHEKWINI', isActive: true, icon: '../../../../assets/images/maps/icons/ethekwini.png', provinceId: 4, normalIcon: null },
    { id: 6, coordinates: [-28.5, 23.5], name: 'MANGUANG', isActive: false, icon: '../../../../assets/images/maps/icons/manguang.png', provinceId: 2, normalIcon: null },
    { id: 7, coordinates: [-32.5, 28], name: 'BUFFALO CITY', isActive: false, icon: '../../../../assets/images/maps/icons/buffalo-city.png', provinceId: 1, normalIcon: null },
    { id: 8, coordinates: [-33.8, 24], name: 'NELSON MANDELA BAY', isActive: false, icon: '../../../../assets/images/maps/icons/mandela-bay.png', provinceId: 1, normalIcon: null },
    { id: 9, coordinates: [-34.5, 22], name: 'GARDEN ROUTE', isActive: false, icon: '../../../../assets/images/maps/icons/garden-route.png', provinceId: 9, normalIcon: null },
  ]

  constructor(private http: HttpClient, private ShareInOurDatabaseService: ShareInOurDatabaseService, private router: Router) { }

  ngOnInit(): void {
    for (let index = 0; index < 10; index++) {
      let model: SubscriptionVm = {
        sectorSelection: [],
        sellingPrice: [],
        landSize: [],
        bedrooms: [],
        bathrooms: [],
        parking: [],
        totalSize: [],
        propertyValues: [],
        showPlusIcon: true
      }
      this.subscriptions.push(model)
    }

    this.http.get(environment.APIUrl + ShareInOurDatabaseService.ApiV1ShareInOurDatabaseGetPropertyTypesGetPath).subscribe({
        next: (result: []) => {
          this.propertyTypes = result;
        }
        , error: (error) => {
          //GlobalMethods.tinyErrorAlert("Error", error)
        }
      });
  }

  deleteSubscription(index: number) {
    this.subscriptions.splice(index, 1);
    let model: SubscriptionVm = {
      sectorSelection: [],
      sellingPrice: [],
      landSize: [],
      bedrooms: [],
      bathrooms: [],
      parking: [],
      totalSize: [],
      propertyValues: [],
      showPlusIcon: true
    }
    this.subscriptions.push(model)
  }

  gotoAddSubscription(clickable: boolean) {
    if(!clickable){
      return;
    }
    this.showAddSubscription = true
    this.hideTopButtonsEvent.emit(true);
  }

  showPlusIcon(subscription: SubscriptionVm) {
    return subscription.sellingPrice.length == 0;
  }

  containsType(): boolean {
    let containsType: boolean = false;
    this.propertyTypes.forEach(mj => {
      mj.propertyTypeMinorCategories.forEach(mmj => {
        mmj.propertyTypeValues.forEach(v => {
          if (v.checked) {
            return containsType;
          }
        })
      })
    })
    return containsType;
  }

  changeToRegion() {
    this.map.setZoom(9)
  }

  developedClicked() {
    this.sectorOptions[0].checked = true;
    this.sectorOptions[1].checked = false;
  }
  developableClicked() {
    this.sectorOptions[1].checked = true;
    this.sectorOptions[0].checked = false;
  }

  sectorSubmit() {
    if (this.sectorOptions[1].checked || this.sectorOptions[0].checked) {
      this.page = 2;
      if (this.reRenderMap == true) {
        this.map.remove();
      }
      setTimeout(() => {
        this.initMap();
      }, 1);
    }
  }

  locationSubmit() {
    let containsMultipleMetros: boolean = this.activeRegionMarkers.filter(f => f.options.selected == true).length > 1;
    let containsSelectedRegions: boolean = this.regionPolygons.filter(f => f.options.selected == true).length > 0;
    let containsSelectedSubPlaces: boolean = this.subPlacePolygons.filter(f => f.options.selected == true).length > 0;
    this.goToPage(3);
  }

  typeSubmit() {
    if (this.containsType()) {
      this.setSizeCheckboxes();
      this.goToPage(4);
    }
  }

  priceSubmit() {
    if (this.priceOptions.filter(f => f.checked == true).length > 0) {
      this.goToPage(5);
    }
  }

  goToPage(pageNumber: number) {
    this.page = pageNumber
  }

  setSubmit() {
    if (this.sectorOptions[0].checked && this.bedBathParkingChecked) {
      if (this.bedroomOptions.filter(f => f.checked == true).length == 0
        || this.bathroomOptions.filter(f => f.checked == true).length == 0
        || this.parkingOptions.filter(f => f.checked == true).length == 0)
        return;
    }
    if (this.sectorOptions[0].checked && this.squareMeterChecked) {
      if (this.bulkSizeOptions.filter(f => f.checked == true).length == 0) {
        return;
      }
    }
    this.setSubscription();
    this.showAddSubscription = false;
    this.containsSubscription = true;
    this.hideTopButtonsEvent.emit(false);
  }

  moreThanOneMetroSelected() {
    let selectedRegionCount = this.activeRegionMarkers.filter(f => f.options.selected == true).length;
    return selectedRegionCount >= 2 || selectedRegionCount == 0
  }

  showNonRefinableNote() {
    let selectedRegionCount = this.activeRegionMarkers.filter(f => f.options.selected == true).length;
    return selectedRegionCount >= 2;
  }

  metroSelected() {
    return this.activeRegionMarkers.filter(f => f.options.selected == true).length > 0;
  }

  refineMetro() {
    let selectedMetro = this.activeRegionMarkers.find(f => f.options.selected == true);
    if (!selectedMetro) {
      return;
    }
    if (this.regionPolygons.length == 0) {
      setTimeout(() => {
        this.refineMetro();
      }, 1);
      return
    }
    this.activeRegionLayerName = selectedMetro.options.layerName;
    this.getSubPlaceData().subscribe({
      next: (result) => {
        for (let index = 0; index < result.features.length; index++) {
          let latlngs: any = result.features[index].geometry.coordinates;
          let reversedLatlngs = latlngs.map(function reverse(item) {
            return Array.isArray(item) && Array.isArray(item[0])
              ? item.map(reverse)
              : item.reverse();
          });

          let id = result.features[index].properties.OBJECTID
          let name = result.features[index].properties.REGION
          let layerName = result.features[index].properties.MN_NAME;
          let subPlaceName = result.features[index].properties
          let style = {
            color: 'grey',
            fillColor: 'transparent',
            opacity: 0.5,
            fillOpacity: 0,
            id: id,
            name: name,
            direction: "center",
            layerName: layerName,
            selected: false,
            subPlaceName: subPlaceName
          }
          let polygon = L.polygon(reversedLatlngs, style)
          polygon.on('click', function (e) {
            style = this.selectSubPlacePolygon(polygon.options.selected, style)
            polygon.setStyle(style)
          }.bind(this))
          this.subPlacePolygons.push(polygon);
        }
      },
      error: (error) => {
        console.log(error, 6)
      }
    });

    switch (selectedMetro.options.id) {
      case 1:
        this.map.setView([-25.7, 28.4], 10)
        break;
      case 2:
        this.map.setView([-26.2, 28], 10);
        break;
      case 3:
        this.map.setView([-26.15, 28.32], 10);
        break;
      case 4:
        this.map.setView([-33.87456279044508, 18.569492243509142], 10);
        break;
      case 5:
        this.map.setView([-29.88, 30.828446326249566], 10);
        break;
      default:
        break;
    }
  }

  renderRegionPolygons() {
    let activeLayer = this.activeRegionMarkers.find(f => f.options.selected == true).options.layerName;
    for (let index = 0; index < this.regionPolygons.length; index++) {
      let polygon = this.regionPolygons[index];
      if (polygon.options.layerName.trim().toUpperCase().includes(activeLayer.trim()) && this.map._zoom > 9 && this.map._zoom < 12) {
        polygon.addTo(this.map)
      } else {
        this.map.removeLayer(polygon)
      }
    }
  }

  renderSubPlacePolygons() {
    for (let index = 0; index < this.subPlacePolygons.length; index++) {
      let polygon = this.subPlacePolygons[index];
      if (polygon.options.layerName.trim().toUpperCase().includes(this.activeRegionLayerName.trim()) && this.map._zoom >= 12) {
        polygon.addTo(this.map)
      } else {
        this.map.removeLayer(polygon)
      }
    }
  }

  capitalizeWords(value) {
    if (value.includes(" ")) {
      let charArr = value.split(" ");
      for (let i = 0; i < charArr.length; i++) {
        if (charArr[i][0]) {
          charArr[i] = charArr[i][0].toUpperCase() + charArr[i].substr(1);
        }
      }
      return charArr.join(" ");
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  setSubscription() {
    this.subscription.sectorSelection = this.sectorOptions.filter(f => f.checked == true);
    this.subscription.landSize = this.standSizeOptions.filter(f => f.checked == true);
    this.subscription.bedrooms = this.bedroomOptions.filter(f => f.checked == true);
    this.subscription.bathrooms = this.bathroomOptions.filter(f => f.checked == true);
    this.subscription.parking = this.parkingOptions.filter(f => f.checked == true);
    this.subscription.totalSize = this.bulkSizeOptions.filter(f => f.checked == true);
    this.subscription.sellingPrice = this.priceOptions.filter(f => f.checked == true);

    for (let majorIndex = 0; majorIndex < this.propertyTypes.length; majorIndex++) {
      let major = this.propertyTypes[majorIndex];
      for (let minorIndex = 0; minorIndex < major.propertyTypeMinorCategories.length; minorIndex++) {
        let minor = major.propertyTypeMinorCategories[minorIndex];
        for (let valueIndex = 0; valueIndex < minor.propertyTypeValues.length; valueIndex++) {
          let value = minor.propertyTypeValues[valueIndex];
          if (value.checked == true) {
            this.subscription.propertyValues.push(value);
          }
        }
      }
    }
    this.propertyTypes.forEach(mj => {
      mj.propertyTypeMinorCategories.forEach(mmj => {
        mmj.propertyTypeValues.forEach(v => {
          if (v.checked == true) {
            this.subscription.propertyValues.push(v);
          }
        })
      })
    })

    let index = this.subscriptions.findIndex(f => f.showPlusIcon == true)
    this.subscriptions[index] = this.subscription
    this.subscription.showPlusIcon = false

    this.page = 1
    this.reRenderMap = true;
    this.resetAddSubscription();
    if (this.subscriptions.filter(f => f.showPlusIcon == true).length == 0 && this.subscriptions.length < 15) {
      this.subscriptions.push(this.subscription)
    }
    setTimeout(() => {
      GlobalMethods.scrollToTop();
    }, 10);
  }

  resetAddSubscription() {
    this.sectorOptions.forEach(f => f.checked = false);
    this.standSizeOptions.forEach(f => f.checked = false);
    this.bedroomOptions.forEach(f => f.checked = false);
    this.bathroomOptions.forEach(f => f.checked = false);
    this.parkingOptions.forEach(f => f.checked = false);
    this.bulkSizeOptions.forEach(f => f.checked = false);
    this.priceOptions.forEach(f => f.checked = false);
    this.subscription = {
      sectorSelection: [],
      sellingPrice: [],
      landSize: [],
      bedrooms: [],
      bathrooms: [],
      parking: [],
      totalSize: [],
      propertyValues: [],
      showPlusIcon: true
    }
    this.propertyTypes.forEach(mj => {
      mj.propertyTypeMinorCategories.forEach(mmj => {
        mmj.propertyTypeValues.forEach(v => {
          v.checked = false;
        })
      })
    })
    this.activeRegionLayerName = ''
    this.subPlacePolygons = []
    this.regionPolygons = []
    this.activeRegionMarkers = []
  }

  getRegionPolygons(): Observable<any> {
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha:Regions&maxFeatures=50&outputFormat=application/json`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  getSubplacesPolygons(): Observable<any> {
    let bounds = this.map.getBounds();
    let bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha:Subplaces&maxFeatures=50&outputFormat=application/json`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  setSizeCheckboxes() {
    this.propertyTypes.forEach(mj => {
      if (mj.name.trim() == 'RESIDENTIAL & LODGING') {
        mj.propertyTypeMinorCategories.forEach(mmj => {
          if (mmj.name == 'Single Residential' && mmj.propertyTypeValues.filter(f => f.checked == true).length > 0) {
            return this.bedBathParkingChecked = true;
          }
          if (mmj.name == 'Temporary Accommodation') {
            mmj.propertyTypeValues.forEach(v => {
              if (v.name == 'Guest & Short stay' && v.checked) {
                return this.bedBathParkingChecked = true;
              }
            })
          }
        })
      }
    })
    this.squareMeterChecked = true;
  }

  selectRegionPolygon(selected: boolean, style: any) {
    if (selected == true) {
      style.color = 'grey';
      style.fillColor = 'grey';
      style.fillOpacity = 0.1
      style.opacity = 0.5
      style.selected = false;
    } else {
      style.color = '#f2825e';
      style.fillColor = '#f2825e';
      style.fillOpacity = 0.5
      style.opacity = 0.7
      style.selected = true;
    }
    return style;
  }

  selectSubPlacePolygon(selected: boolean, style: any) {
    if (selected == true) {
      style.color = 'grey';
      style.opacity = 0.5
      style.fillColor = 'transparent';
      style.fillOpacity = 0
      style.selected = false;
    } else {
      style.color = '#f2825e';
      style.fillColor = '#f2825e';
      style.fillOpacity = 0.5
      style.opacity = 0.7
      style.selected = true;
    }
    return style;
  }

  setNextButtonStyles() {
    let nextButtonBlock = document.getElementById('nextButtonBlock');
    if (nextButtonBlock) {
      let zoomCondition = this.map._zoom > 9 && this.map._zoom < 12
      nextButtonBlock.style.display = zoomCondition ? "block" : "none";
    }
    let finalSubmitButtonBlock = document.getElementById('finalSubmitButtonBlock');
    if (finalSubmitButtonBlock) {
      let zoomCondition = this.map._zoom > 11 && this.map._zoom < 15
      finalSubmitButtonBlock.style.display = this.map._zoom > 11 && this.map._zoom < 15 ? "block" : "none";
    }
  }

  setZoomButtonStyles() {
    let zoomOutButton = document.getElementsByClassName('leaflet-control-zoom-out')[0] as HTMLElement;
    let zoomInButton = document.getElementsByClassName('leaflet-control-zoom-in')[0] as HTMLElement;
    let zoomButton = document.getElementsByClassName('leaflet-control-zoom')[0] as HTMLElement;
    if (zoomButton) {
      let zoomButtonsConditionRegion = this.map._zoom > 9 && this.map._zoom < 12;
      let zoomButtonsConditionSubPlace = this.map._zoom > 11 && this.map._zoom < 15;
      if (zoomButtonsConditionRegion == true) {
        let zoomOutCondition = this.map._zoom > 10
        let zoomInCondition = this.map._zoom < 11
        zoomOutButton.style.pointerEvents = zoomOutCondition ? "unset" : "none";
        zoomOutButton.style.color = zoomOutCondition ? "unset" : "#c6c6c6";

        zoomInButton.style.pointerEvents = zoomInCondition ? "unset" : "none";
        zoomInButton.style.color = zoomInCondition ? "unset" : "#c6c6c6";
      }
      if (zoomButtonsConditionSubPlace == true) {
        let zoomOutCondition = this.map._zoom > 12
        let zoomInCondition = this.map._zoom < 13
        zoomOutButton.style.pointerEvents = zoomOutCondition ? "unset" : "none";
        zoomOutButton.style.color = zoomOutCondition ? "unset" : "#c6c6c6";

        zoomInButton.style.pointerEvents = zoomInCondition ? "unset" : "none";
        zoomInButton.style.color = zoomInCondition ? "unset" : "#c6c6c6";
      }
      zoomButton.style.display = zoomButtonsConditionSubPlace || zoomButtonsConditionRegion ? "block" : "none";
    }
  }

  regionSelected() {
    return this.regionPolygons.filter(f => f.options.selected == true).length > 0
  }

  subPlaceSelected() {
    return this.subPlacePolygons.filter(f => f.options.selected == true).length > 0
  }

  zoomToSuburbLayer() {
    this.map.setZoom(12)
    this.map.closePopup()
  }

  showFinalSubmitButton() {
    return !(this.map._zoom > 11 && this.map._zoom < 15)
  }

  generateProvincePolygonStyle(hover: boolean, id: number): any {
    if (hover == true) {
      return { color: 'grey', fillColor: 'grey', opacity: 1, fillOpacity: 1, id: id }
    } else {
      return { color: '#181818', fillColor: '#181818', opacity: 1, fillOpacity: 1, id: id }
    }
  }

  getIconString(markerDetails: any, hover: boolean, active: boolean) {
    let borderStyle = hover && active ? 'border-bottom: 5px #f2825e solid;' : ''
    let launchSoonBlock = hover && !active ?
      `<div style="background-color: #727272;"><span style="color: black; font-family: MONTSERRAT; font-size: 14px">LAUNCHING SOON</span></div>`
      : '';
    let fontSize = active ? '18' : '15'
    let fontColour = active ? 'white' : '#5e5e5e'
    return `<div style="vertical-align: top; display: inline-block; text-align: center; width: unset !important;">
        <img src="${markerDetails.icon}" class="leaflet-zoom-animated leaflet-interactive" style="width: 60px; height: 60px;"/>
        <span style="font-family: MONTSERRAT; font-size: ${fontSize}px; font-weight: bold; color: ${fontColour}; display: block;white-space: nowrap;${borderStyle}">${markerDetails.name}</span>
      ${launchSoonBlock}
        </div>`
  }

  backButtonPress() {
    if (this.map._zoom >= 12) {
      this.map.setZoom(10)
    } else {
      let zoom = L.control.zoom();
      this.map.removeLayer(zoom)
      this.map.panTo([-29, 25])
      this.map.setZoom(6)
      this.map.closePopup();
    }
  }

  showBackButton() {
    return this.map._zoom >= 10;
  }

  mapReady() {
    return this.map
  }

  renderMarkerClusters(markerCluster: any) {
    this.map.on('zoom', function (e) {
      if (this._zoom >= 10) {
        this.addLayer(markerCluster)
      } else {
        this.removeLayer(markerCluster)
      }
    });
  }

  renderRegionMarkers() {
    let activeRegionMarkers = this.activeRegionMarkers;
    this.map.on('zoom', function (e) {
      for (let index = 0; index < activeRegionMarkers.length; index++) {
        let marker = activeRegionMarkers[index];
        if (this._zoom <= 7) {
          marker.addTo(this)
        } else {
          this.removeLayer(marker)
        }
      }
    });
  }

  renderProvincePolygons() {
    let provincePolygons = this.provincePolygons;
    let map = this.map;
    this.map.on('zoom', function (e) {
      for (let index = 0; index < provincePolygons.length; index++) {
        let polygon = provincePolygons[index];
        if (this._zoom <= 7) {
          polygon.addTo(map)
        } else {
          map.removeLayer(polygon)
        }
      }
    });
  }

  renderSouthAfricaPolygon() {
    let southAfricaPolygon = this.southAfricaPolygon
    this.map.on('zoom', function (e) {
      if (this._zoom <= 7) {
        southAfricaPolygon.addTo(this)
      } else {
        this.removeLayer(southAfricaPolygon);
      }
    });
  }

  getSouthAfricaPolygon(): Observable<any> {
    let bbox = '6.416015625000001,-40.84706035607122,44.9560546875,-16.97274101999902';
    let width = '628';
    let height = '877';
    let x = '377';
    let y = '303';
    let layer = 'Terisha:South_Africa'
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${layer}&STYLES&LAYERS=${layer}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  getProvinceLitePolygons(): Observable<any> {
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha:Provinces_lite&maxFeatures=50&outputFormat=application/json`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  getSubPlaceData(): Observable<any> {
    let metro = this.capitalizeWords(this.activeRegionLayerName.toLocaleLowerCase())
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha%3ASubplaces&maxFeatures=50&outputFormat=application/json&CQL_FILTER=WHERE%20MN_NAME%20LIKE%20%27%25${metro}%25%27`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  submitForm() {

  }

  initMap() {
    let mapElement = document.getElementById('pfsMap') as Element;
    if (mapElement) {
      mapElement.remove();
    }
    this.map = L.map('siodMap', {
      zoomControl: true,
      scrollWheelZoom: false,
      zoom: 6,
      zoomDelta: 0.5,
      zoomSnap: 0
    }).setView([-29, 25]);
    this.map.doubleClickZoom.disable();
    this.map.zoomControl.setPosition('bottomright');
    setTimeout(() => {
      let zoomButton = document.getElementsByClassName('leaflet-control-zoom')[0] as HTMLElement;
      if (zoomButton) {
        zoomButton.style.display = "none";
      }
    }, 5);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 50,
      minZoom: 1,
      transparent: false,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    const suburbsLayer = L.tileLayer.wms('https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?', {
      maxZoom: 14,
      minZoom: 13,
      layers: 'Terisha:Suburbs',
      transparent: true,
      format: 'image/png',
      crs: L.CRS.EPSG4326,
      opacity: 0.4,
    })
    suburbsLayer.addTo(this.map);

    const provinceLayer = L.tileLayer.wms('https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?', {
      maxZoom: 7,
      minZoom: 1,
      layers: 'Terisha:Provinces',
      transparent: false,
      format: 'image/png',
      crs: L.CRS.EPSG4326,
      opacity: 1,
    });
    provinceLayer.addTo(this.map);
    let map = this.map;

    this.getProvinceLitePolygons().subscribe({
      next: (result: any) => {
        for (let index = 0; index < result.features.length; index++) {
          let latlngs: any = result.features[index].geometry.coordinates;
          let reversedLatlngs = latlngs.map(function reverse(item) {
            return Array.isArray(item) && Array.isArray(item[0])
              ? item.map(reverse)
              : item.reverse();
          });
          let id = result.features[index].properties.AG_PROV_ID
          let normalStyle = this.generateProvincePolygonStyle(false, id);
          let polygon = L.polygon(reversedLatlngs, normalStyle)
          this.provincePolygons.push(polygon);
          polygon.addTo(this.map)
        }

        for (const element of this.regionMarkers) {
          let markerDetails = element;
          markerDetails.normalIcon = L.divIcon({
            iconSize: 'auto',
            html: this.getIconString(markerDetails, false, markerDetails.isActive),
            className: 'div-icon'
          });

          let textHoverIcon = L.divIcon({
            iconSize: 'auto',
            html: this.getIconString(markerDetails, true, markerDetails.isActive),
            className: 'div-icon'
          })
          let marker = L.marker(markerDetails.coordinates, {
            icon: markerDetails.normalIcon,
            selected: false,
            id: markerDetails.id,
            layerName: markerDetails.name
          })

          let polygon = this.provincePolygons.find(f => f.options.id == markerDetails.provinceId);
          let normalStyle = this.generateProvincePolygonStyle(false, polygon.options.id);
          let hoverStyle = this.generateProvincePolygonStyle(true, polygon.options.id)

          marker.on('mouseover', function (e) {
            setTimeout(() => {
              if (markerDetails.isActive == false) {
                e.target.setIcon(textHoverIcon)
              }
              polygon.setStyle(hoverStyle)
            }, 25);
          });

          marker.on('mouseout', function (e) {
            setTimeout(() => {
              if (markerDetails.isActive == false) {
                e.target.setIcon(markerDetails.normalIcon)
              }
              polygon.setStyle(normalStyle)
            }, 25);
          });

          marker.on('mouseup', function (e) {
            this.activeLayer = markerDetails.name;
            if (marker.options.selected == false) {
              marker.setIcon(textHoverIcon)
              marker.options.selected = true;
            } else {
              marker.setIcon(markerDetails.normalIcon)
              marker.options.selected = false;
            }
          }.bind(this));
          marker.addTo(this.map)
          this.activeRegionMarkers.push(marker)
        }
        this.renderProvincePolygons();
        this.renderRegionMarkers();
      },
      error: (error) => {
        console.log(error, 99)
      }
    })

    this.getSouthAfricaPolygon().subscribe({
      next: (result: any) => {
        let latlngs = result.features[0].geometry.coordinates;
        let reversedLatlngs = latlngs.map(function reverse(item) {
          return Array.isArray(item) && Array.isArray(item[0])
            ? item.map(reverse)
            : item.reverse();
        });
        this.southAfricaPolygon = L.polygon(reversedLatlngs, { color: '#b1b1b1', fillOpacity: 0, weight: 1 }).addTo(map);
        setTimeout(() => {
          this.renderSouthAfricaPolygon();
        }, 50);
      }
      , error: (err: any) => {
        console.log(err, 8)
      }
    });

    this.getRegionPolygons().subscribe({
      next: (result) => {
        for (const element of result.features) {
          if (element.geometry) {
            let latlngs: any = element.geometry.coordinates;
            let reversedLatlngs = latlngs.map(function reverse(item) {
              return Array.isArray(item) && Array.isArray(item[0])
                ? item.map(reverse)
                : item.reverse();
            });
            let id = element.properties.OBJECTID
            let name = element.properties.REGION
            let layerName = element.properties.layer;
            let style = {
              color: 'grey',
              fillColor: 'grey',
              opacity: 0.5,
              fillOpacity: 0.1,
              id: id,
              name: name,
              direction: "center",
              layerName: layerName,
              selected: false,
            }
            let polygon = L.polygon(reversedLatlngs, style);

            let lat = (polygon._bounds._northEast.lat + polygon._bounds._southWest.lat) / 2
            let lng = (polygon._bounds._northEast.lng + polygon._bounds._southWest.lng) / 2
            let marker = L.marker([lat, lng], {
              icon: L.divIcon({
                iconSize: 'auto',
                html: `<div class="heading-4" style="width: 100vw;text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
                1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;">${name}</div>`,
                className: 'div-icon'
              })
            })

            marker.on('mouseup', function (e) {
              style = this.selectRegionPolygon(polygon.options.selected, style)
              polygon.setStyle(style)
            }.bind(this))

            polygon.on('mouseover', function () {
              marker.addTo(this.map)
            }.bind(this))

            polygon.on('mouseout', function () {
              this.map.removeLayer(marker)
            }.bind(this))

            polygon.on('click', function (e) {
              style = this.selectRegionPolygon(polygon.options.selected, style)
              polygon.setStyle(style)
            }.bind(this))
            this.regionPolygons.push(polygon);

          }
        }
      }
    });

    this.map.on('zoom', function (e) {
      let backgroundColor = document.querySelector('.leaflet-pane .leaflet-overlay-pane svg') as HTMLElement;
      backgroundColor.style.background = this.map._zoom <= 7 ? "#262626" : "transparent"
      let submitButtonBlock = document.getElementById('submitButtonBlock');
      submitButtonBlock.style.display = this.map._zoom <= 7 ? "block" : "none";
      this.setZoomButtonStyles()
      this.setNextButtonStyles()
      this.renderRegionPolygons()
      this.renderSubPlacePolygons();
    }.bind(this));
    this.mapRendered = true;
  }
}

function getLayerData(http: HttpClient, bbox: string, width: string, height: string, queryLayers: string, layers: string, x: any, y: any): Observable<any> {
  let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${queryLayers}&STYLES&LAYERS=${layers}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
    .pipe(map(m => layerData = m))
  return layerData;
}