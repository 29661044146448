import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { CheckboxVm } from 'src/app/Services/propertypros/models';
import { PropertyListingService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-addendum-two',
  templateUrl: './addendum-two.component.html',
  styleUrls: ['./addendum-two.component.scss']
})
export class PropertyListingsAddendumTwoComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Input() reportDetails = null;
  customListing: boolean;

  fileUploader = new FileUploader();

  key: number = 1;
  counter: string[] = [
    "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
  ]

  propertySections: CheckboxVm[] = []

  sections: CheckboxVm[] = []
  demoSections: CheckboxVm[] = [
    { id: 1, value: 'SERVITUDES', checked: true },
    { id: 2, value: 'SG DIAGRAMS', checked: true }
  ]

  uploadBlocks: any[] = []

  constructor(private listingService: PropertyListingService, private sharedService: PropertiProSharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPropertyParams();
    for(let i = 0; i < this.reportDetails.planningReport.propertyInformation.length; i++){
      this.propertySections.push({ id: i + 1, value: this.reportDetails.propertyTitle[i], checked: true });
      this.uploadBlocks.push([]);
      for(let j = 0; j <= this.reportDetails?.addendumTwo?.showFiles[i].length; j++){
        this.uploadBlocks[i].push({ key: j + 1, value: '', file: null });
      }
    }
    for(let i = 0; i < this.reportDetails.planningReport.propertyInformation.length; i++){
      if(this.reportDetails?.addendumTwo?.propertyListingServitudes[i] == null){
        this.reportDetails?.addendumTwo?.propertyListingServitudes.push([{servitude: ''}]);
      }
    }
    this.assignFiles()
    for(let j = 0; j < this.demoSections.length; j++){
      let newSection: CheckboxVm = { id: this.key, value: this.demoSections[j].value, checked: true }
      this.key++;
      this.sections.push(newSection);
    }
  }
  
  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.customListing = params.customListing === 'true';
      }
    );
  }

  async deleteFileFromDb(index: any, propertyIndex) {
    let result = await this.sharedService.deletePropertyListingFile(this.uploadBlocks[propertyIndex][index].file.fileId, 3, this.customListing);
    if(result){
      this.uploadBlocks[propertyIndex].splice(index, 1);
    }
  }

  collapseOrExpandSection(section: CheckboxVm) {
    section.checked = !section.checked
  }

  referencesChecked = true;
  collapseOrExpandReferences() {
    this.referencesChecked = !this.referencesChecked
  }

  assignFiles() {
    for(let i = 0; i < this.uploadBlocks.length; i++){
      for (let j = 0; j < this.reportDetails?.addendumTwo?.showFiles[i].length; j++) {
        const file = this.reportDetails?.addendumTwo?.showFiles[i][j];
        if (file != undefined) {
          this.uploadBlocks[i][j] = { key: j + 1, value: '', file: { name: file.fileName, format: file.fileType, existingFile: true, fileId: file.fileId } }
        }
      }
    }
  }

  addNewEmptyFileSpot(i: any) {
    let key = this.uploadBlocks[i].slice(-1)[0].key + 1;
    const emptyBlock = { key: key, value: '', file: null }
    this.uploadBlocks[i].push(emptyBlock)
  }

  selectUpload(files, key, i) {
    this.fileUploader.specificFileBrowseHandler(files, key, i);
    this.updateShownBlocks(files[0], key, i);
    this.addNewEmptyFileSpot(i)
  }

  dropUpload($event, key, i) {
    this.fileUploader.specificOnFileDropped($event, key, i);
    this.updateShownBlocks($event[0], key, i);
    this.addNewEmptyFileSpot(i)
  }

  deleteFile(block, blockIndex, i) {
    this.uploadBlocks[i].splice(blockIndex, 1)
    let item = this.fileUploader.files.find(f => f.id == block.key);
    let fileIndex = this.fileUploader.files.indexOf(item);
    this.fileUploader.deleteFile(fileIndex);
  }

  updateShownBlocks(file, key, i) {
    let uploadBlock = this.uploadBlocks[i].find(f => f.key == key)
    uploadBlock.show = this.fileUploader.files.filter(f => f.id == key).length > 0;
    uploadBlock.file = file;
  }

  addServitude(index: number, propertyIndex: any) {
    this.reportDetails.addendumTwo.propertyListingServitudes[propertyIndex].push({ servitude: '', propertyNumber: propertyIndex });
    setTimeout(() => {
      document.getElementById(`servitude-${propertyIndex}-${index + 1}`)?.focus();
    }, 5);
  }

  deleteServitude(index: number, propertyIndex: any) {
    this.reportDetails.addendumTwo.propertyListingServitudes[propertyIndex].splice(index, 1);
  }

  goToAddendumThree() {
    this.reportDetails.addendumTwo.propertyListingServitudeFiles = this.fileUploader.files;
    this.pageNumberEvent.emit(4);
  }

  addRow(index: number, type: any, data: any): void {
    type.push(data);
    setTimeout(() => {
      document.getElementById(`${typeof(type)}-${index + 1}`)?.focus();
    }, 5);
  }

  deleteRow(index: number, type: any): void {
    type = type.splice(index, 1);
  }
}