import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from './models/User';
import { PropertiProSharedService } from './properti-pro-shared.service';
import { UserService } from './Services/user.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserRoles } from './common/global-constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  title = 'Property Pro Web';

  isLoggedIn = false;
  user: User;
  userRole: string;
  dropdown = false;
  subscription: Subscription;
  navbar: HTMLElement;
  navPos: number;
  adminActive: boolean = false;
  adminPageTitle: string;
  showImage: boolean = false;
  activeUserRole: any;
  activeNavMenuItems: any[] = [];


  constructor(private sharedService: PropertiProSharedService, private http: HttpClient, private userService: UserService, private router: Router, private pageTitle: Title) {
    
  }

  setTitle() {
    if(!this.pageTitle.getTitle()){
      this.pageTitle.setTitle('Property Matrix')
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.user = this.sharedService.getUserModel();
    this.isLoggedIn = this.sharedService.isLoggedIn();
    this.getAdminNavStatus();
    this.setAdminPageTitle();
  }

  enableAdminNav() {
    sessionStorage.setItem("adminNav", "true");
    this.adminActive = true;
  }

  disableAdminNav() {
    sessionStorage.setItem("adminNav", "false");
    this.adminActive = false;
  }

  getAdminNavStatus(): void {
    if (this.user) {
      let adminNavShow = sessionStorage.getItem("adminNav");
      this.adminActive = adminNavShow == "true" && this.user != null && this.user != undefined;
    }
  }

  setAdminPageTitle() {
    let user = this.sharedService.getUserModel();
    this.activeUserRole = user.userRole.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  async IsDropdownActive() {
    await new Promise(f => setTimeout(f, 100));
    if ((document.getElementById('PropertyInvestigations').classList.contains('active')) ||
      (document.getElementById('FeasibilityStudy').classList.contains('active')) ||
      (document.getElementById('PlanningApplication').classList.contains('active')) ||
      (document.getElementById('PropertyValuation').classList.contains('active'))) {
      this.dropdown = true;
    } else {
      this.dropdown = false;
    }
  }

  logout() {
    this.sharedService.logout();
  }
}