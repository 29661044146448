import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MetrosVm, UserListVm, UserRoleVm } from 'src/app/Services/propertypros/models';
import { UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  form: FormGroup;
  metros: MetrosVm[] = [];
  roles: UserRoleVm[] = [];
  user: UserListVm;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: [this.dialogData.userProfile.name, Validators.compose([Validators.required])],
      lastName: [this.dialogData.userProfile.surname, Validators.compose([Validators.required])],
      email: [this.dialogData.userProfile.email, Validators.compose([Validators.required])],
      role: [this.dialogData.userProfile.role, Validators.compose([Validators.required])],
      metro: [this.dialogData.userProfile.metro, Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.minLength(6),
        Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-])/)])],
    });
    this.loadMetros();
    this.loadRoles();
  }

  loadMetros() {
    this.userService.apiV1UserGetMetroGet$Json().subscribe(metros => {
      this.metros = metros;
    });
  }

  loadRoles() {
    this.userService.apiV1UserGetUserRolesGet$Json().subscribe(roles => {
      this.roles = roles;
    });
  }

  cancel(){
    this.dialog.closeAll();
  }

  saveChanges() {
     this.user = {
      id: this.dialogData.userProfile.id,
      name: this.form.value.firstName,
      surname: this.form.value.lastName,
      email: this.form.value.email,
      role: this.form.value.role,
      metro: this.form.value.metro,
      password: this.form.value.password
    };
    this.userService.apiV1UserUpdateUserProfilePut({ 
      body: this.user
    }).subscribe(() => {
      this.dialog.closeAll();
    });
  }
}
