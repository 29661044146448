import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminManageUsersDataSource } from './admin-manage-users.data.source';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, finalize, fromEvent, tap } from 'rxjs';
import { UserService } from 'src/app/Services/propertypros/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ApproveUserComponent } from './approve-user/approve-user.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { RemoveUserComponent } from './remove-user/remove-user.component';
import { UserRoleVm } from 'src/app/Services/propertypros/models';


@Component({
  selector: 'app-admin-manage-users',
  templateUrl: './admin-manage-users.component.html',
  styleUrls: ['./admin-manage-users.component.scss']
})
export class AdminManageUsersComponent implements OnInit, AfterViewInit {
  @ViewChild('bottomPaginator') paginator: MatPaginator;
  @ViewChild('input') input: ElementRef;
  form: FormGroup;
  roles: UserRoleVm[] = [];
  activeFilter: string = '';
  displayedColumns: string[] = ['profilePicture', 'name', 'email', 'metro', 'role', 'accountVerified', 'approved', 'endorsement', 'action'];
  dataSource: AdminManageUsersDataSource;
  private pageSub = new Subscription();
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  defaultPp = '../../../../assets/images/Placeholders/profile-picture-placeholder.jpg';
  constructor(
    private UserService: UserService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog

  ) {
    this.dataSource = new AdminManageUsersDataSource(UserService);
  }

  ngOnInit(): void {
    
    this.loadingSubject.next(true);
    this.form = this.formBuilder.group({
      searchUser: [''],
      filterRole: ['']
    });
  }

  ngAfterViewInit(): void {
    this.populateTable();
    this.loadRoles();
  }

  loadRoles() {
    this.UserService.apiV1UserGetUserRolesGet$Json().subscribe(roles => {
      this.roles = roles;
    });
  }

  toggleFilter(filterIndex: string) {
    if (this.activeFilter == null || this.activeFilter == undefined) {
      this.activeFilter = '';
    } else {
      this.activeFilter = filterIndex;
      this.form.patchValue({ filterRole: filterIndex });
    }
    this.populateTable();
  }

  populateTable() {
    this.loadingSubject.next(true);
    const filterValues = { ...this.form.value }
    this.dataSource.loadDataTable(filterValues.searchUser, filterValues.filterRole , this.paginator.pageIndex, this.paginator.pageSize);
    this.loadingSubject.next(true);
    this.pageSub = this.paginator.page
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.dataSource.loadDataTable(filterValues.searchUser, filterValues.filterRole, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        finalize(() => this.loadingSubject.next(true))
      ).subscribe();
  }

  searchUsers() {
    const filterValues = { ...this.form.value }
    this.loadingSubject.next(true);
    this.dataSource.loadDataTable(filterValues.searchUser, this.form.value.filterRole, this.paginator.pageIndex, this.paginator.pageSize);
  }

  addUser() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '500px',
      height: '80vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.populateTable();
    });
  }

  editUser(userProfile: any) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '500px',
      panelClass: 'dialog-container',
      data: { userProfile: userProfile }
    });
  }

  verifyUser(userProfile: any) {
    const dialogRef = this.dialog.open(VerifyUserComponent, {
      width: '500px',
      data: { userProfile: userProfile }
    })
    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  approveUser(userProfile: any) {
    const dialogRef = this.dialog.open(ApproveUserComponent, {
      width: '500px',
      height: 'auto',
      data: { userProfile: userProfile }
    })
    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  endorseUser(userProfile: any) {
    this.UserService.apiV1UserGetEndorsementGet({
      userId: userProfile.id,
      endorsement: !userProfile.endorsement
    })
      .subscribe(res => {
        this.populateTable();
      });
  }

  removeUser(userProfile: any) {
    const dialogRef = this.dialog.open(RemoveUserComponent, {
      width: '500px',
      data: { userProfile: userProfile }
    })
    dialogRef.afterClosed().subscribe(result => {
      this.populateTable();
    });
  }

  onDestroy() {
    this.pageSub.unsubscribe();
  }

  accountStateChange(userProfile: any) {
    if (userProfile.accountVerified == true) {
      this.verifyUser(userProfile);
    } else {
      this.verifyUser(userProfile);
    }
  }

}
