import { Component, ViewChildren, ElementRef, AfterViewInit, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { UrbanPlannerDetail } from 'src/app/models/registrationForms/urban-planner-detail';
import { Validators } from 'src/app/models/validation/validators';
import { RegisterUserVm } from 'src/app/Services/propertypros/models';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AuthenticateService } from 'src/app/Services/propertypros/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PlannerComponent {

  passwordPattern: string = GlobalConstants.passwordPattern;
  emailPattern: string = GlobalConstants.emailPattern;
  loading: boolean = false;

  form: RegisterUserVm = {
    personalDetails: {
      alternativeNumber: '',
      emailAddress: '',
      emailConfirmed: false,
      id_Number: '',
      name: '',
      phoneNumber: '',
      surname: '',
      rsa_Resident: false,
      phoneNumberConfirmed: false,
      landline: '',
      title: '',
      confirmEmail: '',
      confirmPhoneNumber: ''
    },
    loginDetails: {
      email: '',
      password: '',
      passwordConfirm: '',
    }
  }

  constructor(private sharedService: PropertiProSharedService, private router: Router, private authService: AuthenticateService) { }

  ngOnInit(): void {
    const user = this.sharedService.getUserModel();
    if (user) {
      this.router.navigateByUrl('/collaboration-agreement')
    }
  }

  ngAfterViewInit(): void { }

  register() {
    this.loading = true;
    this.authService.apiV1AuthenticateRegisterUrbanPlannerPost$Response({ body: this.form })
      .subscribe({
        next: (result) => {
          if (result.status == HttpStatusCode.Conflict) {
            GlobalMethods.tinyErrorAlert('Error', 'User already exists');
            return;
          }
          GlobalMethods.tinySuccessAlert('Welcome', 'Please login. After signing in, you will be redirected the urban planner info page.').then(result => {
            GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/collaboration-agreement', this.router)
          });
          this.loading = false;
        }
        , error: (error) => {
          GlobalMethods.tinyErrorAlert('Error', 'User already exists')
          this.loading = false;
        }
      })
  }
}


