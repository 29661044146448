/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { propertyprosConfiguration, propertyprosConfigurationParams } from './propertypros-configuration';

import { AdditionalServService } from './services/additional-serv.service';
import { AdminService } from './services/admin.service';
import { AuthenticateService } from './services/authenticate.service';
import { ContactUsService } from './services/contact-us.service';
import { ConveyancerService } from './services/conveyancer.service';
import { EstateAgentService } from './services/estate-agent.service';
import { FileService } from './services/file.service';
import { MapsService } from './services/maps.service';
import { PropertyForSaleService } from './services/property-for-sale.service';
import { PropertyListingService } from './services/property-listing.service';
import { SellingMyPropertyService } from './services/selling-my-property.service';
import { ShareInOurDatabaseService } from './services/share-in-our-database.service';
import { UrbanPlannerService } from './services/urban-planner.service';
import { UserService } from './services/user.service';
import { ValuerService } from './services/valuer.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdditionalServService,
    AdminService,
    AuthenticateService,
    ContactUsService,
    ConveyancerService,
    EstateAgentService,
    FileService,
    MapsService,
    PropertyForSaleService,
    PropertyListingService,
    SellingMyPropertyService,
    ShareInOurDatabaseService,
    UrbanPlannerService,
    UserService,
    ValuerService,
    propertyprosConfiguration
  ],
})
export class propertyprosApiModule {
  static forRoot(params: propertyprosConfigurationParams): ModuleWithProviders<propertyprosApiModule> {
    return {
      ngModule: propertyprosApiModule,
      providers: [
        {
          provide: propertyprosConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: propertyprosApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('propertyprosApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
