<main>
    <div class="container-fluid">
        <div class="container">
            <mat-card class="admin-top-grey-block">
                <div class="admin-top-grey-block-content">
                    <div class="heading-2 text-center">My Profile</div>
                    <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseOut()" id="my-profile-picture">
                        <img *ngIf="profilePicture" [src]='profilePicture' id="profile-image">
                        <input type="file" #fileDropRef id="fileDropRef" multiple accept=".png, .jpg, .jpeg"
                            (change)="selectUpload($event.target.files)" />
                    </div>
                    <div id="edit-my-profile-picture">Edit</div>
                </div>
            </mat-card>
            <div class="tab mt-5">
                <div *ngFor="let tabItem of tabList; let i = index">
                    <button [class]="{'active': tabItem.id == tab}" class="tablinks"
                        (click)="selectTab(tabItem.id)"><mat-icon>{{tabItem.icon}}</mat-icon></button>
                </div>
            </div>
            <div *ngIf="tab == 1">
                <app-admin-my-profile-personal-details></app-admin-my-profile-personal-details>
            </div>
            <div *ngIf="tab == 2">
                <app-admin-my-profile-work-details></app-admin-my-profile-work-details>
            </div>
            <div *ngIf="tab == 3">
                <app-admin-my-profile-documents></app-admin-my-profile-documents>
            </div>
            <div *ngIf="tab == 4">
                <app-admin-my-profile-siod></app-admin-my-profile-siod>
            </div>
            <div *ngIf="tab == 5">
                <app-admin-my-profile-favourites></app-admin-my-profile-favourites>
            </div>
            <div *ngIf="tab == 6">
                <app-admin-my-profile-billing></app-admin-my-profile-billing>
            </div>
            <div *ngIf="tab == 7">
                <app-admin-my-profile-extended-network></app-admin-my-profile-extended-network>
            </div>
        </div>
    </div>
</main>