import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { CheckboxVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-property-for-sale-offer-to-purchase',
  templateUrl: './property-for-sale-offer-to-purchase.component.html',
  styleUrls: ['./property-for-sale-offer-to-purchase.component.scss']
})
export class PropertyForSaleOfferToPurchaseComponent implements OnInit {

  confirmOne: boolean = false;
  confirmTwo: boolean = false;
  confirmThree: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  gotoForm() {
    sessionStorage.removeItem('offerType');
    sessionStorage.setItem('offerType', 'normalOffer');
    GlobalMethods.navigateToLoginWithRouteStored('originalUrl', '/propertyForSale/offerToPurchase/capture', this.router)
  }
}