<!--Delete Professional Confirmation-->
<section *ngIf="data.showDelete == true">
    <div class="heading-2">
        Are you sure you want to delete professional {{data.professional.personalDetails.name}}({{data.professional.profession}})?
    </div>
    <div class="row mt-4">
        <div class="col"></div>
        <div class="col-lg-4 col-6">
            <div class="row">
                <div class="col-6"><button mat-button (click)="onNoClick()">No</button></div>
                <div class="col-6"><button mat-button color="warn" (click)="deleteProfessional()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Add/Edit Professional-->
<section *ngIf="data.showDelete == false">
    <form id="professionalDialogForm" novalidate #professionalDialogForm="ngForm">
        <div class="submission-form">
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Profession(e.g. Architect):</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" class="paragraph" name="profession"
                        [(ngModel)]="data.professional.profession" required #profession="ngModel"
                        [ngClass]="{'invalid-input': profession.invalid, 'valid-input': profession.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph" name="name"
                        [(ngModel)]="data.professional.personalDetails.name" required #name="ngModel"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph" name="surname"
                        [(ngModel)]="data.professional.personalDetails.surname" required #surname="ngModel"
                        [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Company name:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" class="paragraph" name="companyName"
                        [(ngModel)]="data.professional.personalDetails.companyName" required #companyName="ngModel"
                        [ngClass]="{'invalid-input': companyName.invalid, 'valid-input': companyName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                        class="paragraph" [(ngModel)]="data.professional.personalDetails.phoneNumber" required
                        #phoneNumber="ngModel" minlength="10"
                        [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                        *Mobile number must contain at least the 10 numbers
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                        Address:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                        class="paragraph" [(ngModel)]="data.professional.personalDetails.emailAddress" required
                        #emailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                      'valid-input': emailAddress.valid}">
                    <small class="form-control-feedback"
                        *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label class="paragraph">Link to their website::</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" class="paragraph" name="linkToWebsite"
                        [(ngModel)]="data.professional.linkToWebsite" required #linkToWebsite="ngModel"
                        [ngClass]="{'invalid-input': linkToWebsite.invalid, 'valid-input': linkToWebsite.valid}">
                </div>
            </div>
            <div class="row text-center mt-3">
                <div class="col-12">
                    <button id="btnSaveCoOwner" mat-button [disabled]="professionalDialogForm.invalid" class="button heading-4 mt-2"
                    [mat-dialog-close]="data">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    </form>
</section>