import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MetrosVm, RegisterUserVm, UserRoleVm } from 'src/app/Services/propertypros/models';
import { AuthenticateService, UserService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  form: FormGroup;
  metros: MetrosVm[] = [];
  roles: UserRoleVm[] = [];
  user: RegisterUserVm[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public userService: UserService,
    public authenticateService: AuthenticateService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      profession: ['', Validators.compose([Validators.required])],
      idNumber: ['', Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      metro: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, 
        Validators.minLength(6), 
        Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-])/)])],
    });
    this.loadMetros();
    this.loadRoles();
  }

  loadMetros() {
    this.userService.apiV1UserGetMetroGet$Json().subscribe(metros => {
      this.metros = metros;
    });
  }

  loadRoles() {
    this.userService.apiV1UserGetUserRolesGet$Json().subscribe(roles => {
      this.roles = roles;
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  saveUser(){
    this.user = [{
      personalDetails: {
        name: this.form.value.firstName,
        surname: this.form.value.lastName,
        emailAddress: this.form.value.email,
        phoneNumber: this.form.value.phoneNumber,
        position: this.form.value.profession,
        id_Number: this.form.value.idNumber,
      },
      loginDetails: {
        email: this.form.value.email,
        password: this.form.value.password,
      }
    }];
    this.authenticateService.apiV1AuthenticateCreateNewUserPost({
      role: this.form.value.role.name,
      body: this.user[0]
    })
    .subscribe(user => {
      this.dialog.closeAll();
    });
  }
}