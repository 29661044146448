import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { CheckboxVm, EstateAgentPersonalInformationVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-estate-agent-personal-information',
  templateUrl: './estate-agent-personal-information.component.html',
  styleUrls: ['./estate-agent-personal-information.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class EstateAgentPersonalInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() EstateAgentPersonalInformationEvent = new EventEmitter<EstateAgentPersonalInformationVm>();

  emailPattern: string = GlobalConstants.emailPattern;

  form: EstateAgentPersonalInformationVm = {
    estateAgentFidelityFundStatus: 0,
    highestQualification: 0,
    registeredYears: null,
    otherResumeInformation: null,
    qualificationName: '',
    estateAgentFidelityFundStatusOther: '',
    metroRegions: null,
    specialisedSectors: null,
  }

  estateAgentStatusOptions: KeyValuePair[] = [
    { key: 1, value: 'Principal' },
    { key: 2, value: 'Full status agent' },
    { key: 3, value: 'Intern' }
  ];

  highestQualificationsOptions: KeyValuePair[] = [
    { key: 1, value: 'Matric (National Certificate)' },
    { key: 2, value: 'Higher certificate' },
    { key: 3, value: 'Diploma & advanced certificate' },
    { key: 4, value: 'Bachelor\'s degree & advanced diploma' },
    { key: 5, value: 'Honours degree & postgraduate diploma' },
    { key: 6, value: 'Master\'s degree' },
    { key: 7, value: 'Doctoral degree' },
  ];

  specialisedSectorsCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Residential', checked: false },
    { id: 2, value: 'Commercial', checked: false },
    { id: 3, value: 'Business - retail', checked: false },
    { id: 4, value: 'Business - offices', checked: false },
    { id: 5, value: 'Industrial', checked: false },
    { id: 6, value: 'Other', checked: false },
  ]

  metroRegionsCheckBoxOptions: CheckboxVm[] = [
    { id: 1, value: 'Tshwane', checked: false },
    { id: 2, value: 'Cape Town', checked: false },
    { id: 3, value: 'Joburg', checked: false },
    { id: 4, value: 'Ekurhuleni', checked: false },
    { id: 5, value: 'Durban', checked: false },
  ]

  constructor() { }

  submitForm(){
    this.form.metroRegions = this.metroRegionsCheckBoxOptions.filter(f => f.checked == true);
    this.form.specialisedSectors = this.specialisedSectorsCheckBoxOptions.filter(f => f.checked == true);
    this.pageNumberEvent.emit(2);
    this.EstateAgentPersonalInformationEvent.emit(this.form);
  }

  estateAgentFidelityFundStatusOtherCheck(option: CheckboxVm) : boolean{
    return option.id == 6 && option.checked;
  }

  ngOnInit(): void {
  }
  

}
