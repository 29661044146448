import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AddPropertyInvestigationVm, AddressVm, PersonalDetailsVm } from 'src/app/Services/propertypros/models';
import { AdditionalServService } from 'src/app/Services/propertypros/services';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-level2-investigations',
  templateUrl: './level2-investigations.component.html',
  styleUrls: ['../../../../css/2-modules/_forms.scss']
})
export class Level2InvestigationsComponent {

  emailPattern: String = GlobalConstants.emailPattern;

  isVatRegistered: number = 0;
  sameAddress: number = 0;

  loading: boolean = false;

  personalDetails: PersonalDetailsVm = {
    name: '',
    surname: '',
    landline: '',
    phoneNumber: '',
    alternativeNumber: '',
    emailAddress: '',
    confirmEmail: '',
    id_Number: ''
  }

  propertyDetails: AddressVm = {
    unitNo: '',
    complexName: '',
    streetNo: '',
    streetName: '',
    suburb: '',
    city: '',
    propertyDescription: '',
    currentLandUse: ''
  }

  enquirerAddress: AddressVm = {
    unitNo: '',
    complexName: '',
    streetNo: '',
    streetName: '',
    suburb: '',
    city: ''
  }

  queryBody: AddPropertyInvestigationVm = {
    personalDetails: this.personalDetails,
    propertyDetails: this.propertyDetails,
    enquirerType: 0,
    isVatRegistered: null,
    propertyInvestigationLevel: null,
    orgName: '',
    orgRegistrationNumber: '',
    orgVatNumber: '',
    orgLandline: '',
    orgPhoneNumber: '',
    orgEmail: '',
    orgConfirmEmail: '',
    sameAddress: null,
    enquirerAddress: this.enquirerAddress,
    ownerType: 0,
    investigationPurpose: ''
  }

  enquirerTypeSelection: KeyValuePair[] = [
    { key: 1, value: 'An entity/organisation' },
    { key: 2, value: 'Individual/natural person' }
  ]

  vatRegistrationSelection: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ]

  sameAddressOrgSelection: KeyValuePair[] = [
    { key: 1, value: 'Yes, that is also my business address' },
    { key: 2, value: 'No, I have a different business address' }
  ]

  sameAddressIndSelection: KeyValuePair[] = [
    { key: 1, value: 'Yes, that is also my personal address' },
    { key: 2, value: 'No, I reside on another property' }
  ]

  ownerTypes: KeyValuePair[] = [
    { key: 1, value: 'I am the owner of the property' },
    { key: 2, value: 'I consider buying the property' },
    { key: 3, value: 'I am an estate agent registered with Matrix' },
    { key: 4, value: 'I am an external estate agent' },
    { key: 5, value: 'None of the above' }
  ]

  constructor(private addService: AdditionalServService) { }

  emailConfirmed: boolean;
  checkValidation(email: NgModel, confirmEmail: NgModel) {
    this.emailConfirmed = GlobalMethods.checkValidation(email, confirmEmail);
  }

  submit({ value, valid }: { value: AddPropertyInvestigationVm, valid: boolean }) {
    this.loading = true
    this.queryBody.isVatRegistered = this.isVatRegistered == 1;
    this.queryBody.sameAddress = this.sameAddress == 1;
    this.queryBody.propertyInvestigationLevel = 2;
    if (valid && this.emailConfirmed) {
      this.addService.apiV1AdditionalServCapturePropertyInvestigationPost$Response({ body: this.queryBody }).pipe()
        .subscribe(Response => {
          this.loading = false
          GlobalMethods.tinySuccessAlert("Saved!", "Your details have been submitted!");
        },
          error => {
            this.loading = false
            GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.");
          }
        );
    } else {
      this.loading = false
      GlobalMethods.tinyErrorAlert("Error", "Please check that all mandatory fields are filled in.")
    }
  }
}