import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { AddressVm } from 'src/app/Services/propertypros/models';
import { AdminService } from 'src/app/Services/propertypros/services';
import { AdminMyProfileWorkDetailsEditDialogComponent } from '../../admin-my-profile-work-details/admin-my-profile-work-details-edit-dialog/admin-my-profile-work-details-edit-dialog.component';

@Component({
  selector: 'app-admin-my-profile-billing-edit-dialog',
  templateUrl: './admin-my-profile-billing-edit-dialog.component.html',
  styleUrls: ['./admin-my-profile-billing-edit-dialog.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AdminMyProfileBillingEditDialogComponent implements OnInit {

  uneditedData: any = {};

  constructor(public dialogRef: MatDialogRef<AdminMyProfileWorkDetailsEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminEditDialogModel, private adminService: AdminService, private sharedService: PropertiProSharedService) { }

  ngOnInit(): void {
    this.shallowCopyUneditedData();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  saveInfo() {
    let userName = this.sharedService.getUsername();
    this.adminService.apiV1AdminUpdateBillingDetailsPost$Response({ username: userName, body: this.data.data.value })
      .pipe(map(m => { return m })).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error updating data");
        }
      })
  }

  closeDialogWithNoChanges() {
    this.dialogRef.close(this.uneditedData.data);
  }

  shallowCopyUneditedData() {
    const jsonData = JSON.stringify(this.data);
    this.uneditedData = JSON.parse(jsonData);
  }
}
