/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangePasswordVm } from '../models/change-password-vm';
import { ForgotPasswordVm } from '../models/forgot-password-vm';
import { LoginVm } from '../models/login-vm';
import { RegisterUserVm } from '../models/register-user-vm';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AuthenticateLoginPost
   */
  static readonly ApiV1AuthenticateLoginPostPath = '/api/v1/Authenticate/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateLoginPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateLoginPost$Response(params?: {
    context?: HttpContext
    body?: LoginVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateLoginPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateLoginPost(params?: {
    context?: HttpContext
    body?: LoginVm
  }
): Observable<void> {

    return this.apiV1AuthenticateLoginPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateForgotPasswordPost
   */
  static readonly ApiV1AuthenticateForgotPasswordPostPath = '/api/v1/Authenticate/ForgotPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateForgotPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateForgotPasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ForgotPasswordVm
  }
): Observable<propertyprosStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateForgotPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateForgotPasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ForgotPasswordVm
  }
): Observable<string> {

    return this.apiV1AuthenticateForgotPasswordPost$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateForgotPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateForgotPasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ForgotPasswordVm
  }
): Observable<propertyprosStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateForgotPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateForgotPasswordPost$Json(params?: {
    context?: HttpContext
    body?: ForgotPasswordVm
  }
): Observable<string> {

    return this.apiV1AuthenticateForgotPasswordPost$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateChangePasswordPost
   */
  static readonly ApiV1AuthenticateChangePasswordPostPath = '/api/v1/Authenticate/ChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateChangePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateChangePasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordVm
  }
): Observable<propertyprosStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateChangePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateChangePasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ChangePasswordVm
  }
): Observable<string> {

    return this.apiV1AuthenticateChangePasswordPost$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateChangePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateChangePasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordVm
  }
): Observable<propertyprosStrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateChangePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateChangePasswordPost$Json(params?: {
    context?: HttpContext
    body?: ChangePasswordVm
  }
): Observable<string> {

    return this.apiV1AuthenticateChangePasswordPost$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateRegisterUserPost
   */
  static readonly ApiV1AuthenticateRegisterUserPostPath = '/api/v1/Authenticate/RegisterUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateRegisterUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterUserPost$Response(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateRegisterUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateRegisterUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterUserPost(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateRegisterUserPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateRegisterValuerPost
   */
  static readonly ApiV1AuthenticateRegisterValuerPostPath = '/api/v1/Authenticate/RegisterValuer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateRegisterValuerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterValuerPost$Response(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateRegisterValuerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateRegisterValuerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterValuerPost(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateRegisterValuerPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateRegisterUrbanPlannerPost
   */
  static readonly ApiV1AuthenticateRegisterUrbanPlannerPostPath = '/api/v1/Authenticate/RegisterUrbanPlanner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateRegisterUrbanPlannerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterUrbanPlannerPost$Response(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateRegisterUrbanPlannerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateRegisterUrbanPlannerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterUrbanPlannerPost(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateRegisterUrbanPlannerPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateRegisterEstateAgentPost
   */
  static readonly ApiV1AuthenticateRegisterEstateAgentPostPath = '/api/v1/Authenticate/RegisterEstateAgent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateRegisterEstateAgentPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterEstateAgentPost$Response(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateRegisterEstateAgentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateRegisterEstateAgentPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterEstateAgentPost(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateRegisterEstateAgentPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateRegisterConveyancerPost
   */
  static readonly ApiV1AuthenticateRegisterConveyancerPostPath = '/api/v1/Authenticate/RegisterConveyancer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateRegisterConveyancerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterConveyancerPost$Response(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateRegisterConveyancerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateRegisterConveyancerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateRegisterConveyancerPost(params?: {
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateRegisterConveyancerPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateGetUserDetailsGet
   */
  static readonly ApiV1AuthenticateGetUserDetailsGetPath = '/api/v1/Authenticate/GetUserDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateGetUserDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateGetUserDetailsGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateGetUserDetailsGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateGetUserDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateGetUserDetailsGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AuthenticateGetUserDetailsGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateCreateNewUserPost
   */
  static readonly ApiV1AuthenticateCreateNewUserPostPath = '/api/v1/Authenticate/CreateNewUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateCreateNewUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateCreateNewUserPost$Response(params?: {
    role?: string;
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateCreateNewUserPostPath, 'post');
    if (params) {
      rb.query('role', params.role, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateCreateNewUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1AuthenticateCreateNewUserPost(params?: {
    role?: string;
    context?: HttpContext
    body?: RegisterUserVm
  }
): Observable<void> {

    return this.apiV1AuthenticateCreateNewUserPost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateVerifyAccountPut
   */
  static readonly ApiV1AuthenticateVerifyAccountPutPath = '/api/v1/Authenticate/VerifyAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateVerifyAccountPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateVerifyAccountPut$Plain$Response(params?: {
    userId?: string;
    activeState?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateVerifyAccountPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('activeState', params.activeState, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as propertyprosStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateVerifyAccountPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateVerifyAccountPut$Plain(params?: {
    userId?: string;
    activeState?: boolean;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AuthenticateVerifyAccountPut$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateVerifyAccountPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateVerifyAccountPut$Json$Response(params?: {
    userId?: string;
    activeState?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateVerifyAccountPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('activeState', params.activeState, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as propertyprosStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateVerifyAccountPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateVerifyAccountPut$Json(params?: {
    userId?: string;
    activeState?: boolean;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AuthenticateVerifyAccountPut$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AuthenticateNetworkApprovalPut
   */
  static readonly ApiV1AuthenticateNetworkApprovalPutPath = '/api/v1/Authenticate/NetworkApproval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateNetworkApprovalPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateNetworkApprovalPut$Plain$Response(params?: {
    userId?: string;
    approvalState?: boolean;
    Sacplan?: boolean;
    townPlanningDegree?: boolean;
    relevantExperience?: boolean;
    idOrPassport?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateNetworkApprovalPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('approvalState', params.approvalState, {});
      rb.query('Sacplan', params.Sacplan, {});
      rb.query('townPlanningDegree', params.townPlanningDegree, {});
      rb.query('relevantExperience', params.relevantExperience, {});
      rb.query('idOrPassport', params.idOrPassport, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as propertyprosStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateNetworkApprovalPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateNetworkApprovalPut$Plain(params?: {
    userId?: string;
    approvalState?: boolean;
    Sacplan?: boolean;
    townPlanningDegree?: boolean;
    relevantExperience?: boolean;
    idOrPassport?: boolean;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AuthenticateNetworkApprovalPut$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AuthenticateNetworkApprovalPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateNetworkApprovalPut$Json$Response(params?: {
    userId?: string;
    approvalState?: boolean;
    Sacplan?: boolean;
    townPlanningDegree?: boolean;
    relevantExperience?: boolean;
    idOrPassport?: boolean;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticateService.ApiV1AuthenticateNetworkApprovalPutPath, 'put');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('approvalState', params.approvalState, {});
      rb.query('Sacplan', params.Sacplan, {});
      rb.query('townPlanningDegree', params.townPlanningDegree, {});
      rb.query('relevantExperience', params.relevantExperience, {});
      rb.query('idOrPassport', params.idOrPassport, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as propertyprosStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AuthenticateNetworkApprovalPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AuthenticateNetworkApprovalPut$Json(params?: {
    userId?: string;
    approvalState?: boolean;
    Sacplan?: boolean;
    townPlanningDegree?: boolean;
    relevantExperience?: boolean;
    idOrPassport?: boolean;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AuthenticateNetworkApprovalPut$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
