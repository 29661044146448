<form id="propertyDetailsForm" novalidate #propertyDetails="ngForm" class="submission-form">
    <!--Address-->
    <section class="submission-form">
        <div class="heading-2">STEP 2: PROPERTY DETAILS</div>
        <div class="heading-5">
            Physical/street address of the property to be sold:
        </div>
        <span class="paragraph"><strong>Physical/street address of the property to be sold:</strong></span>
        <form id="searchPropertyForm" novalidate #searchPropertyForm="ngForm" class="submission-form">
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                    unit
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                    class="paragraph not-required" [(ngModel)]="physicalAddress.unitNo">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                    class="paragraph">Estate/complex
                    name:</label>
            </div>
            <div class="col-7">
                <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                    class="paragraph not-required" [(ngModel)]="physicalAddress.complexName">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                    no:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                    class="paragraph" [(ngModel)]="physicalAddress.streetNo" required #streetNumber="ngModel"
                    [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                    Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                    class="paragraph" [(ngModel)]="physicalAddress.streetName" required #streetName="ngModel"
                    [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                    [(ngModel)]="physicalAddress.suburb" required #suburb="ngModel"
                    [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                    [(ngModel)]="physicalAddress.city" #city="ngModel" required
                    [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Postal code:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="postalCode" class="paragraph"
                    [(ngModel)]="physicalAddress.postalCode" #postalCode="ngModel" required
                    [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <button class="button small-button" [disabled]="loadingAddress || searchPropertyForm.invalid" (click)="getAddressGeoCode()">Show Address</button>
            </div>
        </div>
    </form>
        <div [hidden]="!addressGeocodeData && !loadingAddress">
            <div class="row map-section" *ngIf="loadingAddress">
                <div class="col-12">
                    <div class="spinner-container">
                        <div class="spinner-container-center">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center" [hidden]="loadingAddress">
                <div class="col-12">
                    <div class="map-container">
                        <div id="sypMap"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!loadingAddress && !loadingCadaster" class="mt-3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Is the location displayed on the map the correct address?
                </div>
                <mat-radio-group name="confirmCorrectAddress" [(ngModel)]="confirmCorrectAddress">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of confirmCorrectAddressOptionsRadio"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </section>
    <section *ngIf="confirmCorrectAddress > 0">
        <div class="row">
            <div class="col-12">
                <label class="checkbox-container">
                    <b class="paragraph">I wish to sell more than one property</b>
                    <input type="checkbox" [(ngModel)]="form.sellMoreThanOneProperty" name="sellMoreThanOneProperty"
                        #sellMoreThanOneProperty="ngModel" id="sellMoreThanOneProperty" data-toggle="toggle" />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div *ngIf="form.sellMoreThanOneProperty == true">
            <div class="row">
                <div class="col-12">
                    <strong class="paragraph">Please note:</strong>
                    <ul class="montserrat-bullets paragraph">
                        <li>
                            If you wish to sell properties at different locations, please fill in a separate
                            form for each property (as they will be advertised separately.)
                        </li>
                        <li>
                            Separate forms will also be required even if the properties (land parcels) are
                            adjacent to each other (creating one development opportunity.)
                        </li>
                        <li>
                            Separate properties (thus separate title deeds) will require separate forms
                        </li>
                        <li>
                            If you are logged in, the information you already provided will be auto filled.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="heading-5">
                    Property boundaries
                </div>
                <div class="col-12">
                    <span class="paragraph">It is important that the exact boundary of your property is
                        determined and confirmed.</span>
                </div>
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-12">
                <strong><u>There are three options:</u></strong>
            </div>
            <div class="col-12">
                <ol>
                    <li><strong>Get going immediately:</strong> If you reside at (or can visit) the property,
                        you make use of our mobile application (take a picture of the property from
                        the street and your property boundaries and details will be displayed on your mobile
                        phone), which you forward us; or</li>
                    <br />
                    <li><strong>I can wait two days:</strong> You provide the property description (erf,
                        holding or farm portion number), as indicated on your municipal account (except
                        for eThekwini), or on your title deed. Once your property is located; we forward you
                        an email for your confirmation. </li>
                    <br />
                    <li><strong>I can wait three days:</strong> You only provide us with the property address
                        (as filled in above) and our team will determine the full property description
                        and boundaries, and forward you an email for your confirmation. </li>
                </ol>
            </div>
        </div>
        <div class="heading-5">
            <span class="burnt-red">| </span>Which option do you prefer?
        </div>
        <div class="row">
            <mat-radio-group name="preferedOption" [(ngModel)]="form.preferedOption">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of preferedOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="form.preferedOption > 0" class="submission-form">
            <div class="heading-5">
                Click <a class="here" (click)="openSideNav(1)">
                    here
                </a> for easy-to-follow instructions.
            </div>

            <div class="heading-5">
                <span class="burnt-red">| </span>Please confirm:
            </div>
            <div class="row">
                <mat-radio-group name="agreeWithPropertyBoundry" [(ngModel)]="form.agreeWithPropertyBoundry">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of pleaseConfirmOptionsRadio"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="form.agreeWithPropertyBoundry > 0">
                <div *ngIf="form.pleaseConfirmOptionsRadio == 2">
                    <p class="paragraph">Thank you for letting us know, we will contact you to confirm that we
                        are
                        dealing with the correct property and boundary. If you have other sources
                        available – e.g., your municipal account (except for eThekwini) or a copy of your title
                        deed, please complete the following section:</p>
                </div>

                <div *ngIf="form.pleaseConfirmOptionsRadio == 2 || form.preferedOption == 2">
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Property Description:
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <textarea id="txtPropertyDescription" name="propertyDescription" class="paragraph" rows="3"
                                [(ngModel)]="form.propertyDescription" #propertyDescription="ngModel"></textarea>
                        </div>
                        <div class="col-12">
                            <strong class="paragraph">Examples: </strong>
                            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                                <li>Erf 386 Meyerton; </li>
                                <li>Holding 23 of Raslouw Agricultural Holdings</li>
                                <li>Portion 77 of the Farm Stoneybrooke 365-JR] </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="form.preferedOption == 3">
                    <div class="heading-5">
                        Please confirm:
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="checkbox-container">
                                <span class="paragraph">I can confirm that the address details provided above
                                    are
                                    correct – I have checked all numbers and spelling.
                                </span>
                                <input type="checkbox" [(ngModel)]="form.confirmCorrectAddressDetails"
                                    name="confirmCorrectAddressDetails" #confirmCorrectAddressDetails="ngModel"
                                    id="confirmCorrectAddressDetails" data-toggle="toggle" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <p class="paragraph mt-3">Thank you. You may keep an eye on your inbox. We will also notify
                            you
                            of our email via SMS.</p>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>What is the <span class="coral">current</span> main use of
                    this
                    property?
                </div>
                <div class="row">
                    <mat-radio-group name="mainPropertyUseOptionsRadio" [(ngModel)]="form.mainPropertyUse">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of mainPropertyUseOptionsRadio"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div *ngIf="form.mainPropertyUse == 6">
            <div class="row">
                <div class="col-7">
                    <textarea id="txtOtherMainPropertyUse" name="otherMainPropertyUse" class="paragraph" rows="3"
                        [(ngModel)]="form.mainPropertyUseOther" #otherMainPropertyUse="ngModel"></textarea>
                </div>
            </div>
            <p class="paragraph"><strong>Please note:</strong> Additional information or documents might be
                required if the property is specialized/unique in nature. If necessary, this will be
                requested by <strong>Property Matrix</strong> upon receipt of the initial information. </p>
        </div>
        <div *ngIf="form.mainPropertyUse > 0" class="submission-form">
            <div class="heading-5">
                <span class="burnt-red">| </span>What is the approximate age of the main building(s) on site?
            </div>
            <div class="row">
                <mat-radio-group name="approximateBuildingAge" [(ngModel)]="form.approximateBuildingAge">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of approximateBuildingAgeOptionsRadio" [value]="selectionValue.key">
                        <div *ngIf="selectionValue.key != 8">
                            {{selectionValue.value}}
                        </div>
                        <div *ngIf="selectionValue.key == 8">
                            Uncertain, but I estimate: <input style="width: 3rem" type="number"
                                [disabled]="form.approximateBuildingAge != 8"
                                [(ngModel)]="form.approximateBuildingAgeOther" name="approximateBuildingAgeOther"
                                #approximateBuildingAgeOther="ngModel" />
                            years
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="(form.approximateBuildingAge > 0 && form.approximateBuildingAge < 8) 
                || (form.approximateBuildingAge == 8 && form.approximateBuildingAgeOther > 0)">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Are there any other existing uses, buildings or permanent
                    fixtures that might influence the value of the property?
                </div>
                <div class="row">
                    <mat-radio-group name="hasValueAlteringInfluences" [(ngModel)]="valueAlteringInfluences">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNoOptionsRadio"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div *ngIf="valueAlteringInfluences > 0" class="submission-form">
            <div *ngIf="valueAlteringInfluences == 1">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Please describe:
                </div>
                <div class="row mt-1">
                    <div class="col-7">
                        <textarea id="txtValueAlteringInfluencesDescription" name="valueAlteringInfluencesDescription"
                            class="paragraph" rows="3" [(ngModel)]="form.valueAlteringInfluencesDescription"
                            #valueAlteringInfluencesDescription="ngModel"></textarea>
                    </div>
                </div>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>What is the current occupation status of all buildings/units on
                this property?
            </div>
            <div class="row">
                <mat-radio-group name="occupationStatus" [(ngModel)]="form.occupationStatus">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of occupationStatusOptionsRadio"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="form.occupationStatus > 0">
            <div *ngIf="form.occupationStatus == 3">
                <div class="heading-5">
                    <span class="burnt-red">| </span>How many buildings/units are tenanted?
                </div>
                <div class="row">
                    <mat-radio-group name="tenantedBuildingsAmount" [(ngModel)]="form.tenantedBuildingsAmount">
                        <mat-radio-button class="paragraph"
                            *ngFor="let selectionValue of tenantedBuildingsAmountOptionsRadio"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="form.tenantedBuildingsAmount == 2">
                    <p class="paragraph"><strong>Important:</strong>Information regarding the monthly rental
                        amount (including estate agent's commission but excluding VAT) for each building/unit
                        tenanted
                        is extremely important. Please have a rent roll in electronic format available when our
                        partnering agent visits your property.</p>
                </div>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>Are all the buildings/structures which are erected on the
                property done with duly approved building plans?
            </div>
            <strong class="paragraph">Important:</strong>
            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                <li>If all buildings/structures on the site are not indicated - hence the reason for no or
                    unapproved building plans - rather inform us now and please still
                    provide us with the building plans you do have</li>
                <li>The Matrix team will be keen to assist you in this matter – there are ways to structure a
                    deal without hiding any information from future buyers</li>
            </ul>

            <div class="row">
                <mat-radio-group name="allBuildingsDoneWithApprovedBuildingPlans"
                    [(ngModel)]="form.allBuildingsDoneWithApprovedBuildingPlans">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of allBuildingsDoneWithApprovedBuildingPlansOptionsRadio"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="form.allBuildingsDoneWithApprovedBuildingPlans > 0" class="submission-form">
            <div *ngIf="form.allBuildingsDoneWithApprovedBuildingPlans == 1">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Do you have copies available of the approved building
                    plans?
                </div>
                <div class="row">
                    <mat-radio-group name="hasCopiesOfApprovedBuildingPlans"
                        [(ngModel)]="form.hasCopiesOfApprovedBuildingPlans">
                        <mat-radio-button class="paragraph"
                            *ngFor="let selectionValue of hasCopiesOfApprovedBuildingPlansOptionsRadio"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div
            *ngIf="form.allBuildingsDoneWithApprovedBuildingPlans == 2 || form.allBuildingsDoneWithApprovedBuildingPlans == 3">
            <strong class="paragraph">Important:</strong>
            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                <li>Building plans aren't necessarily a deal-breaker but can certainly lead to quite a few
                    complex/legal matters if the property is sold and alterations
                    and/or additions have been carried out without municipal approval.</li>
                <li>Approved plans are also generally required by financial institutions should the
                    purchaser need to secure a loan.</li>
            </ul>
            <p class="paragraph"><strong>Property Matrix</strong> prides ourselves in a very fast and
                transparent
                system. We can therefore not afford a) the delays expected and b) any “skeleton in
                the closet” once we receive an offer on your property.</p>
            <strong class="paragraph">We therefore propose the following options:</strong>
            <div class="paragraph">
                <span>a) To advertise your property as not having approved building plans – <u>not</u>
                    recommended</span>
                <br>
                <span>b) To be pro-active and invest in the process to obtain approved building plans while
                    marketing your property - strongly recommended.</span>
            </div>
            <strong class="paragraph">We advise that:</strong>
            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                <li>You pay a minimal amount of R1 000 and <strong>Property Matrix</strong> appoints an
                    architectural technologist on our network to investigate the matter. </li>
                <li>Once the amount of work to acquire approved plans is determined, a quotation will be
                    provided. We are also in favour of getting this done, so we
                    will certainly not overcharge you!</li>
            </ul>
        </div>
        <div *ngIf="form.hasCopiesOfApprovedBuildingPlans > 0 || form.allBuildingsDoneWithApprovedBuildingPlans > 0">
            <div class="heading-5">
                <span class="grey">| </span>In your opinion, does the property have potential for improvements
                or a different use or type of development?
                plans?
            </div>
            <div class="row">
                <mat-radio-group name="hasImprovementPotential" [(ngModel)]="hasImprovementPotential">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNoOptionsRadio"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="hasImprovementPotential > 0" class="submission-form">
            <div *ngIf="hasImprovementPotential == 1">
                <div class="heading-5">
                    <span class="grey">| </span>Please describe any future potential of the property:
                    plans?
                </div>
                <div class="row">
                    <div class="col-7">
                        <textarea id="txtFuturePotential" name="futurePotential" class="paragraph" rows="3"
                            [(ngModel)]="form.futurePotential" #futurePotential="ngModel"></textarea>
                    </div>
                </div>
            </div>
            <div class="heading-4">
                Selling Price
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>What amount would you like to receive “in your pocket” for this
                property (thus excluding commission and excluding VAT)?
                plans?
            </div>
            <strong class="paragraph">Please note: </strong>
            <span class="paragraph">It is essential that your asking price is realistic, thus in line with the
                current market trends in your area.</span>
            <u class="paragraph">If uncertain:</u>
            <ul class="montserrat-bullets paragraph align-height paragraph no-indent-ul">
                <li><strong>Property Matrix</strong> can do a comprehensive value assessment to determine the
                    open market value of your property. This is inclusive and will not incur
                    any additional charges. Click <a class="here" (click)="openSideNav(2)">
                        here
                    </a> for details.</li>
                <li>You will also have the option to provide us (at your own expense) with a formal property
                    valuation, done by a SACPVP*-registered property valuer if
                    you are not in agreement with the value proposed. We also provide this service at a
                    competitive rate. Click <a class="here" (click)="openSideNav(3)">
                        here
                    </a> for details.</li>
            </ul>
            <div class="row">
                <mat-radio-group name="askingPriceChoice" [(ngModel)]="form.askingPriceChoice">
                    <mat-radio-button class="paragraph"
                        *ngFor="let selectionValue of askingPriceOptionsRadio; let i = index"
                        [value]="selectionValue.key" (click)="selectInput(i)">
                        <div *ngIf="selectionValue.key == 1">
                            {{selectionValue.value}}
                            <input value="" data-type="currency" [readonly]="form.askingPriceChoice != 1"
                                class="number-input" name="askingPrice" #askingPrice="ngModel"
                                [(ngModel)]="form.askingPrice" onClick="this.select();" id="askingPrice" />
                        </div>
                        <div *ngIf="selectionValue.key > 1">
                            {{selectionValue.value}}
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <section *ngIf="form.askingPriceChoice == 2 || (form.askingPriceChoice == 1 && form.askingPrice > 0)">
            <div class="row text-center">
                <div class="col-10">
                    <button id="btnSaveFinal" [disabled]="propertyDetails.invalid" class="button heading-4"
                        (click)="submitForm()">SUBMIT</button>
                </div>
            </div>
        </section>
    </section>
</form>