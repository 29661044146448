import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, OnDestroy, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-property-investigations',
  templateUrl: './property-investigations.component.html',
  styleUrls: ['./property-investigations.component.scss']
})
export class PropertyInvestigationsComponent implements AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private _focusMonitor: FocusMonitor) { }

  ngAfterViewInit(): void {
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore1'));
    this._focusMonitor.stopMonitoring(document.getElementById('btn'));
    this._focusMonitor.stopMonitoring(document.getElementById('LearnMore3'));
  }

  addOverflow = false;

  isMenuOpen = false;

  showLevel1 = false;

  showLevel2 = false;

  showLevel3 = false;

  open = false;

  tabIndex = -1;

  isShow = true;

  toggleColorbtn1(index) {
    this.open = true;
    this.isShow = false;
    this.showLevel1 = true;
    this.showLevel2 = false;
    this.showLevel3 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorbtn2(index) {
    this.open = true;
    this.isShow = false;
    this.showLevel2 = true;
    this.showLevel1 = false;
    this.showLevel3 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  toggleColorbtn3(index) {
    this.open = true;
    this.isShow = false;
    this.showLevel3 = true;
    this.showLevel2 = false;
    this.showLevel1 = false;
    this.addOverflow = true;
    this.sidenav.open();
    this.tabIndex = index;
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }


  close() {
    this.open = false;
    this.isShow = true;
    this.sidenav.close();
    this.addOverflow = false
  }
}
