<div class="edit-user-component col-12">
    <form [formGroup]="form">
        <mat-card-header>
            <mat-card-title>
                <h1>Edit User</h1>
            </mat-card-title>
            <mat-card-subtitle>
                <h3>Update user information</h3>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="input-spacer">
                <mat-input>
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="John" [formControl]="form.controls['firstName']">
                </mat-input>
                <small class="error-message" *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched">First Name is required</small>
            </div>
            <div class="input-spacer">
                <mat-input>
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="Doe" [formControl]="form.controls['lastName']">
                </mat-input>
                <small class="error-message" *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched">Last Name is required</small>
            </div>
            <div class="input-spacer">
                <mat-input>
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="johndoe@gmail.com" [formControl]="form.controls['email']">
                </mat-input>
                <small class="error-message" *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">First Name is required</small>
            </div>
            <div class="input-spacer">
                <mat-label class="dropdown-label">Role</mat-label>
                <mat-select class="dropdown-styling"  placeholder="Senior Planner" [formControl]="form.controls['role']">
                    <mat-option *ngFor="let role of roles" [value]="role">
                        {{role.name}}
                    </mat-option>
                </mat-select>
                <small class="error-message" *ngIf="form.controls['role'].hasError('required') && form.controls['role'].touched || form.controls['']">Role is required</small>
            </div>
            <div class="input-spacer">
                <mat-label class="dropdown-label">Metro</mat-label>
                <mat-select class="dropdown-styling"  placeholder="Select Metro" [formControl]="form.controls['metro']">
                    <mat-option *ngFor="let metro of metros" [value]="metro">
                        {{metro.name}}
                    </mat-option>
                </mat-select>
                <small class="error-message" *ngIf="form.controls['metro'].hasError('required') && form.controls['metro'].touched">Metro is required</small>
            </div>
            <div class="input-spacer">
                <mat-input>
                    <mat-label>Password (optional)</mat-label>
                    <input matInput placeholder="*******" [formControl]="form.controls['password']">
                </mat-input>
                <small class="error-message" *ngIf="form.controls['password'].hasError('minlength')">Passwords must be at least 6 characters.<br></small>
                <small class="error-message" *ngIf="form.controls['password'].hasError('pattern')">
                    Passwords must have at least 1 none Alphanumeric character.<br>
                    Passwords must have at least 1 digit.<br>
                    Passwords must have at least 1 Uppercase.
                </small>
            </div>
            <div class="col-12 buttons-container">
                <div class="buttons-spacing">
                    <button class="cancel-button" (click)="cancel()">
                        <span>Cancel</span>
                    </button>
                </div>
                <div class="buttons-spacing">
                    <button class="confirm-button" (click)="saveChanges()" [disabled]="!form.valid">
                        <span>Confirm</span>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </form>
</div>