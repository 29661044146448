<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <section class="mat-input-section">
        <div class="heading-2 mt-1">STEP 1: PERSONAL AND COMPANY INFORMATION</div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>The property to be sold is registered:
        </div>
            <mat-radio-group name="estateAgentStatus" [(ngModel)]="form.estateAgentFidelityFundStatus">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of estateAgentStatusOptions"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        <div *ngIf="form.estateAgentFidelityFundStatus > 0" class="submission-form">
            <div *ngIf="form.estateAgentFidelityFundStatus == 3" class="paragraph">
                <strong>Note</strong>: We regret to inform you that Property Matrix do not take any persons in who is not registered as full estate agents. If you, however, have any related/professional qualifications, and feel that you should be an exception, feel free to finalise this process and we will evaluate your qualification and experience.
            </div>
            <div *ngIf="form.estateAgentFidelityFundStatus == 1 || form.estateAgentFidelityFundStatus == 2" class="submission-form">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>Please indicate on the map below which metro, region(s) and suburbs
                    you know well and <span class="coral-text">can commit</span> to operate in.
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of metroRegionsCheckBoxOptions; let index = i">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}
                                </span>
                                <input type="checkbox" [(ngModel)]="option.checked" [name]="metroRegionsCheckBoxOptions"
                                    #metroRegionsCheckBoxOptions="ngModel" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="heading-5 mt-2">
                <span class="burnt-red">| </span>What is the highest qualification you have?
            </div>
            <div>
                <mat-radio-group name="highestQualification" [(ngModel)]="form.highestQualification">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of highestQualificationsOptions"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div *ngIf="form.highestQualification > 0">
            <div class="row">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': qualificationName.invalid, 'mat-valid-input': qualificationName.valid}">Please
                        specify the name of the certificate/diploma/degree</label></div>
                <div class="col-10">
                    <input name="qualificationName" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="form.qualificationName" required #qualificationName="ngModel">
                </div>
            </div>
            <div *ngIf="form.highestQualification != 1">
                <div class="heading-5 mt-2">
                    <span class="burnt-red">| </span>In which property sectors do you <span
                        class="coral">specialise</span>
                    (have extensive experience and knowledge)?
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of specialisedSectorsCheckBoxOptions; let index = i">
                            <label class="checkbox-container paragraph mt-1">
                                <span class="paragraph">
                                    {{option.value}}<span
                                        [ngClass]="{'specify-text': form.estateAgentFidelityFundStatusOther.length == 0}"
                                        *ngIf="estateAgentFidelityFundStatusOtherCheck(option)"> – Please specify</span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.checked"
                                    [name]="specialisedSectorsCheckBoxOptions"
                                    #specialisedSectorsCheckBoxOptions="ngModel" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="estateAgentFidelityFundStatusOtherCheck(option)">
                                <div class="row">
                                    <div class="col-10">
                                        <input name="estateAgentFidelityFundStatusOther" type="text" class="paragraph mat-input-section mat-input-section-other"
                                            [(ngModel)]="form.estateAgentFidelityFundStatusOther" required #estateAgentFidelityFundStatusOther="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': registeredYears.invalid, 'mat-valid-input': registeredYears.valid}">How
                        many years have you been operating as a <span class="coral">registered</span> Estate
                        Agent?</label></div>
                <div class="col-10">
                    <input name="registeredYears" class="paragraph mat-input-section" type="number"
                        [(ngModel)]="form.registeredYears" required #registeredYears="ngModel">
                </div>
            </div>
        </div>
        <div *ngIf="form.registeredYears != null || form.registeredYears > 0">
            <div class="heading-5 mt-3">
                <span class="charcoal">| </span>Anything else on your CV/resume, <span class="coral">related to the
                    property industry</span>, you wish to highlight?
            </div>
            <div class="row mt-2">
                <div class="col-10">
                    <textarea id="txtOtherResumeInformation" name="otherResumeInformation" class="paragraph" rows="3"
                        [(ngModel)]="form.otherResumeInformation" #otherResumeInformation="ngModel"></textarea>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="row text-center">
            <div class="col-10">
                <button id="btnSaveFinal" class="button heading-4" [disabled]="informationForm.invalid"
                    (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>