import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs';
import { UserRoles } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { CheckboxVm } from 'src/app/Services/propertypros/models';
import { AdminService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-admin-my-profile-work-details-edit-dialog',
  templateUrl: './admin-my-profile-work-details-edit-dialog.component.html',
  styleUrls: ['./admin-my-profile-work-details-edit-dialog.component.scss', '../../../../../../css/2-modules/_forms.scss']
})
export class AdminMyProfileWorkDetailsEditDialogComponent implements OnInit {

  uneditedData: any = {};
  userRole: string;
  userRoles = UserRoles;

  municipalCouncilsMostlySubmittedToOptions: CheckboxVm[] = [
    { id: 1, value: 'City of Tshwane (Pretoria)', checked: false },
    { id: 2, value: 'City of Johannesburg', checked: false },
    { id: 3, value: 'City of Ekurhuleni (East Rand)', checked: false },
    { id: 4, value: 'City of Cape Town', checked: false },
    { id: 5, value: 'City of eThekwini (Durban)', checked: false },
    { id: 6, value: 'Other', checked: false },
  ]

  planningApplicationsExperienceAndKnowledgeOptions: CheckboxVm[] = [
    { id: 1, value: 'Township establishment', checked: false },
    { id: 2, value: 'Fuel stations', checked: false },
    { id: 3, value: 'Retail stores/centres', checked: false },
    { id: 4, value: 'Environment-sensitive sites', checked: false },
    { id: 5, value: 'Other', checked: false },
  ]

  constructor(public dialogRef: MatDialogRef<AdminMyProfileWorkDetailsEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminEditDialogModel, private adminService: AdminService, private sharedService: PropertiProSharedService) { }

  ngOnInit(): void {
    this.getUserRole();
    this.assignCheckboxData();
    this.shallowCopyUneditedData();
  }

  assignCheckboxData(){
    if(this.data.editType == 1){
      if(this.userRole == UserRoles.urbanPlanner){
        for (let index = 0; index < this.municipalCouncilsMostlySubmittedToOptions.length; index++) {
          let element = this.municipalCouncilsMostlySubmittedToOptions[index];
          if(this.data.data.value.relevantCouncilIds.includes(element.id)){
            element.checked = true;
          }
        }
        for (let index = 0; index < this.planningApplicationsExperienceAndKnowledgeOptions.length; index++) {
          let element = this.planningApplicationsExperienceAndKnowledgeOptions[index];
          if(this.data.data.value.experienceIds.includes(element.id)){
            element.checked = true;
          }
        }
      }
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  otherCheckboxSelected(option: CheckboxVm, otherKey: number): boolean {
    return option.id == otherKey && option.checked;
  }

  closeDialogWithNoChanges(){
    this.dialogRef.close(this.uneditedData.data);
  }

  shallowCopyUneditedData(){
    const jsonData = JSON.stringify(this.data);
    this.uneditedData = JSON.parse(jsonData);
  }

  saveInfo(){
    let userName = this.sharedService.getUsername();
    switch (this.userRole) {
      case UserRoles.urbanPlanner:
        this.data.data.value.relevantCouncilIds = this.municipalCouncilsMostlySubmittedToOptions.filter(f => f.checked == true).map(m => m.id);
        this.data.data.value.experienceIds = this.planningApplicationsExperienceAndKnowledgeOptions.filter(f => f.checked == true).map(m => m.id);
        break;
    }
    this.adminService.apiV1AdminUpdateWorkDetailsPost$Response({ username: userName, typeId: this.data.editType, jsonData: JSON.stringify(this.data.data.value) })
      .pipe(map(m => { return m })).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error updating data");
        }
      })
  }

  getUserRole(){
    this.userRole = this.sharedService.getUserRole();
  }
}
