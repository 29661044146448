import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { CheckboxVm } from 'src/app/Services/propertypros/models';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class PropertyListingsValuationComponent implements OnInit {

  @Input() reportDetails = null;
  @Output() submitReportEvent = new EventEmitter();
  @Output() saveReportEvent = new EventEmitter();
  customListing: boolean;

  key: number = 1;
  counter: string[] = [
    "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
  ]

  propertySections: CheckboxVm[] = []

  sections: CheckboxVm[] = []
  demoSections: CheckboxVm[] = [
    { id: 1, value: 'SELLERS PRICE', checked: true },
    { id: 2, value: 'VALUATION ESTIMATION DETAILS', checked: true },
    { id: 3, value: 'DEEDS TRANSFER HISTORY', checked: true },
    { id: 4, value: 'PROPOSED SELLING PRICE', checked: true }
  ]

  confidenceLevelRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'High' },
    { key: 2, value: 'Medium-High' },
    { key: 3, value: 'Medium-Low' },
    { key: 4, value: 'Low' },
  ]

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPropertyParams();
    for(let i = 0; i < this.reportDetails.planningReport.propertyInformation.length; i++){
      this.propertySections.push({ id: i + 1, value: this.reportDetails.propertyTitle[i], checked: true });
    }
    for(let j = 0; j < this.demoSections.length; j++){
      let newSection: CheckboxVm = { id: this.key, value: this.demoSections[j].value, checked: true }
      this.key++;
      this.sections.push(newSection);
    }
  }
  
  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.customListing = params.customListing === 'true';
      }
    );
  }

  collapseOrExpandSection(section: CheckboxVm) {
    section.checked = !section.checked
  }

  submitReport() {
    this.submitReportEvent.emit();
  }

  saveReport() {
    this.saveReportEvent.emit();
  }
}
