/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { propertyprosBaseService } from '../propertypros-base-service';
import { propertyprosConfiguration } from '../propertypros-configuration';
import { propertyprosStrictHttpResponse } from '../propertypros-strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FileReturnModel } from '../models/file-return-model';

@Injectable({
  providedIn: 'root',
})
export class FileService extends propertyprosBaseService {
  constructor(
    config: propertyprosConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1FileUploadFilesPost
   */
  static readonly ApiV1FileUploadFilesPostPath = '/api/v1/File/UploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileUploadFilesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadFilesPost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<FileReturnModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileUploadFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<FileReturnModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileUploadFilesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadFilesPost$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<FileReturnModel>> {

    return this.apiV1FileUploadFilesPost$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<FileReturnModel>>) => r.body as Array<FileReturnModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileUploadFilesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadFilesPost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<FileReturnModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileUploadFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<FileReturnModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileUploadFilesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadFilesPost$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<FileReturnModel>> {

    return this.apiV1FileUploadFilesPost$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<FileReturnModel>>) => r.body as Array<FileReturnModel>)
    );
  }

  /**
   * Path part for operation apiV1FileAnonymousUploadFilesPost
   */
  static readonly ApiV1FileAnonymousUploadFilesPostPath = '/api/v1/File/AnonymousUploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileAnonymousUploadFilesPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileAnonymousUploadFilesPost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<FileReturnModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileAnonymousUploadFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<FileReturnModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileAnonymousUploadFilesPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileAnonymousUploadFilesPost$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<FileReturnModel>> {

    return this.apiV1FileAnonymousUploadFilesPost$Plain$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<FileReturnModel>>) => r.body as Array<FileReturnModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileAnonymousUploadFilesPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileAnonymousUploadFilesPost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<Array<FileReturnModel>>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileAnonymousUploadFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as propertyprosStrictHttpResponse<Array<FileReturnModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileAnonymousUploadFilesPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileAnonymousUploadFilesPost$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<FileReturnModel>> {

    return this.apiV1FileAnonymousUploadFilesPost$Json$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<Array<FileReturnModel>>) => r.body as Array<FileReturnModel>)
    );
  }

  /**
   * Path part for operation apiV1FileDownloadFileGet
   */
  static readonly ApiV1FileDownloadFileGetPath = '/api/v1/File/DownloadFile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileDownloadFileGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileDownloadFileGet$Response(params?: {
    fileId?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileDownloadFileGetPath, 'get');
    if (params) {
      rb.query('fileId', params.fileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileDownloadFileGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileDownloadFileGet(params?: {
    fileId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1FileDownloadFileGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1FileUploadProfilePicturePost
   */
  static readonly ApiV1FileUploadProfilePicturePostPath = '/api/v1/File/UploadProfilePicture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileUploadProfilePicturePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadProfilePicturePost$Response(params?: {
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileUploadProfilePicturePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileUploadProfilePicturePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileUploadProfilePicturePost(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1FileUploadProfilePicturePost$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1FileGetProfilePictureGet
   */
  static readonly ApiV1FileGetProfilePictureGetPath = '/api/v1/File/GetProfilePicture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1FileGetProfilePictureGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileGetProfilePictureGet$Response(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<propertyprosStrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FileService.ApiV1FileGetProfilePictureGetPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as propertyprosStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1FileGetProfilePictureGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1FileGetProfilePictureGet(params?: {
    username?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1FileGetProfilePictureGet$Response(params).pipe(
      map((r: propertyprosStrictHttpResponse<void>) => r.body as void)
    );
  }

}
