<mat-sidenav-container (backdropClick)="close()">
    <mat-sidenav #sidenav (keydown.escape)="close()" position="end">
        <section class="rotate-buttons" [hidden]="isShow">
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 0}"
                (click)="toggleColorbtn1(0)">LEVEL 1</button>
            <button class="sidenav-button heading-2" [ngClass]="{'open':open, 'active': tabIndex === 1}"
                (click)="toggleColorbtn2(1)">LEVEL 2</button>
        </section>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel1">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Level 1: Desktop Valuation:
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white" (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row paragraph-light">
                <div class="col-12">
                    <p>
                        This is a professional market value assessment provided in a memorandum format. No site visit
                        will be conducted in this instance. You will be required to complete a questionnaire and to
                        upload recent pictures of the interior and exterior of all buildings/stuctures on the site. Our
                        network of verified, market-leading valuers will add their expertise and professional input, but
                        the accuracy and thoroughness of the information you provide will be extremely important. If you
                        are limited in terms of time or technical skills (to take and upload pictures), we strongly
                        recommend that you rather request the Formal Valuation (Level 2).
                    </p>
                    There are various methods commonly used for determining the market value of real estate. These
                    methods of valuation comprise:
                    <ul class="montserrat-bullets">
                        <li>
                            Direct comparable sales approach
                        </li>
                        <li>
                            Cost approach
                        </li>
                        <li>
                            Income approach
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    FACTORS CONSIDERED DURING VALUE ASSESSMENT (same as Level 2)
                </div>
                <div class="col-12 paragraph-light">
                    <p>
                        Every property and every industry differ, but the <span class="heading-5-light">Property
                            Matrix</span> value assessment team and our professional valuers will generally investigate
                        the following factors to determine the open market value of your property:
                    </p>
                    <ul class="montserrat-bullets">
                        <li>
                            <span class="heading-5-light">Property price:</span> Previous
                            price(s) and date(s), as well as current
                            municipal value.
                        </li>
                        <li>
                            <span class="heading-5-light">Location:</span> Macro- and
                            micro- location, accessibility and exposure,
                            demographic trends, development in the are, surrounding properties and
                            amenities.
                        </li>
                        <li>
                            <span class="heading-5-light">Site Improvements:</span> Type
                            and quality of buildings on site,
                            building sizes, building grades, exterior and interior finishes and fittings,
                            age of buildings, replacement values,
                            parkings provision, site security, surrounding works.
                        </li>
                        <li>
                            <span class="heading-5-light">Building tenancy:</span> Owner
                            occupied or tenanted, lettability of
                            buildings, number and type of tenants, tenant spread and rating, lease tearms
                            and escalations, lease renewals or
                            tenant failures.
                        </li>
                        <li>
                            <span class="heading-5-light">Market conditions:</span> General
                            macro economy, market segment
                            conditions, supply and demand, rental rates and rental growth, expense rations,
                            vacancy periods, capitalisation rates.
                        </li>
                        <li>
                            <span class="heading-5-light">Comparative Market
                                Analysis:</span> Comparable sales in the nearby surrounds,
                            comparable sales in similar investors nodes, compaarable rentals in the nearby
                            surrounds, adjustments applicable,
                            saleability of subject property.
                        </li>
                        <li>
                            <span class="heading-5-light">Financials:</span> Actual gross
                            and net income, property expenses,
                            business profits, affordable rental calculations discounted cashflows of leases
                            (as and where applicalbe), etc.
                        </li>
                    </ul>
                </div>
                <div class="col-12">
                    <p class="paragraph-light">
                        The above list is by no means exhaustive and will be influenced by the property type
                        and valuation method
                        applied. However, please be assured that your property will be assessed and valued
                        in terms of a thorough
                        investigation and according to property specific parameters.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    DELIVERABLES AND TIMEFRAMES
                </div>
                <div class="col-12 paragraph-light">
                    <ul class="montserrat-bullets">
                        <li>
                            2-page memorandum in PDF format
                        </li>
                        <li>
                            Data collection by estate agent, verified and trained by Property Matrix
                        </li>
                        <li>
                            Professional valuer provides summary of findings and professional market value
                            assessment
                        </li>
                        <li>
                            Three to five working days for delivery
                        </li>
                        <li>
                            Fee structure as indicated in the table below. Note that this is only a general
                            indication; each request for a
                            valuation will be assessed individually and a property specific quotation will
                            be provided.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    FEE STRUCTURE (estimates only)
                </div>
                <div class="col-12">
                    <p class="paragraph-light">
                        All fees as indicated in the table below exclude VAT. Note that this is only a
                        general indication of fees; each
                        request for a valuation will be assessed individually and a property specific
                        quotation will be provided.
                    </p>
                </div>
                <div class="col-12 heading-5-light ">
                    Please note:
                </div>
                <div class="col-12">
                    <p class="paragraph-light">
                        If you wish to request a quotation for a formal valuation and the property is
                        already listed on the
                        <b>Property Matrix</b> website,
                        the fees will be reduced,
                        as Matrix has already done a planning investigation on this property as well as
                        a market value assessment.
                    </p>
                </div>
                <div class="row pb-3">
                    <div class="col-12">
                        <table>
                            <colgroup>
                                <col class="col-group-1">
                                <col class="col-group-2">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="heading-2-light text-center" colspan="2">
                                        DESKTOP
                                        VALUATION</th>
                                </tr>
                            </thead>
                            <tbody class="paragraph-light">
                                <tr class="grey-background paragraph">
                                    <td colspan="2">Vacant land</td>
                                </tr>
                                <tr>
                                    <td>Vacant land (single zoning)</td>
                                    <td>R2 000</td>
                                </tr>
                                <tr>
                                    <td>Vacant land with township rights</td>
                                    <td>R3 000</td>
                                </tr>
                                <tr class="grey-background paragraph">
                                    <td colspan="2">Land with built Improvements
                                        (residential/industrial/offices/flats/retail/other)</td>
                                </tr>
                                <tr>
                                    <td>Single land use; value less than R5-million</td>
                                    <td>R2 000</td>
                                </tr>
                                <tr>
                                    <td>Single land use; value between R5-million and
                                        R50-million</td>
                                    <td>R2 000</td>
                                </tr>
                                <tr>
                                    <td>Single land use; value between R20-million and
                                        R50-million</td>
                                    <td>R3 000</td>
                                </tr>
                                <tr>
                                    <td>Single land use; value above R50-million</td>
                                    <td>R4 000</td>
                                </tr>
                                <tr>
                                    <td>Multiple land uses; value less than R5-million</td>
                                    <td>R3 000</td>
                                </tr>
                                <tr>
                                    <td>Multiple land uses; value between R5-million and
                                        R20-million</td>
                                    <td>R3 000</td>
                                </tr>
                                <tr>
                                    <td>Multiple land uses; value between R20-million and
                                        R50-million</td>
                                    <td>R4 000</td>
                                </tr>
                                <tr>
                                    <td>Multiple land uses; value above R50-million</td>
                                    <td>R5 000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br />
            <div class="row text-center">
                <div class="col-12">
                    <button class="button-light heading-3"
                        [routerLink]="['/propertyValuation/propertyValuationRequest/']">REQUEST</button>
                </div>
            </div>
        </div>
        <div class="container-side sidenav-container container" [ngClass]="{'container-overflow' : addOverflow}"
            fxLayout="column" fxLayoutAlign="space-between start" *ngIf="showLevel2">
            <div class="row">
                <div class="col-11 heading-4-light py-3">
                    Level 2: Formal Valuation Report
                </div>
                <div class="col-1 btn-close-align pt-2">
                    <button type="button" class="btn-close btn-close-white " (click)="close()"
                        aria-label="Close"></button>
                </div>
            </div>
            <div class="row paragraph-light">
                <div class="col-12">
                    <p>
                        This is a detailed report and will also include a site visit by the property valuer.
                        The
                        report will contain full
                        details of market research and all relevant information and will be a more accurate
                        determination of the market
                        value of the property. This type of report can also be used for negotiation
                        purposes,
                        tax and audit purposes,
                        financing purposes, etc. The report also provides a replacement value of the
                        property
                        improvements for insurance purposes.
                    </p>
                </div>
                <div class="col-12">
                    There are various methods commonly used for determining the market value of real
                    estate. These methods of
                    valuation comprise:
                    <ul class="montserrat-bullets">
                        <li>
                            Direct comparable sales approach
                        </li>
                        <li>
                            Cost approach
                        </li>
                        <li>
                            Income approach
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    FACTORS CONSIDERED DURING VALUE ASSESSMENT (same as Level 1)
                </div>
                <div class="col-12 paragraph-light">
                    <p>
                        Every property and every industry differ, but the Property Matrix value assessment
                        team and our professional
                        valuers will generally investigate the following factors to determine the open
                        market value of your property:
                    </p>
                    <ul class="montserrat-bullets">
                        <li>
                            <span class="heading-5-light"> Property price:</span> Previous purchase price(s) and
                            date(s), as well as current
                            municipal value.
                        </li>
                        <li>
                            <span class="heading-5-light"> Location:</span> Macro- and micro- location, accessibility
                            and exposure, demographic
                            trends, developments in the
                            area, surrounding properties and amenities.
                        </li>
                        <li>
                            <span class="heading-5-light"> Site specifics:</span> Property size and shape, planning
                            parameters (zoning, density,
                            height, coverage, Floor Area
                            Ratio (FAR), building lines and parking requirements), servitudes and other
                            endorsements, availability of
                            services, environmental issues, residual land/available bulk, highest and best
                            use, development potential.
                        </li>
                        <li>
                            <span class="heading-5-light"> Site improvements:</span> Type and quality of buildings on
                            site, building sizes,
                            building grades, exterior and
                            interior finishes and fittings, age of buildings, replacement values, parking
                            provision, site security, surrounding
                            works.
                        </li>
                        <li>
                            <span class="heading-5-light"> Building tenancy:</span> Owner occupied or tenanted,
                            lettability of buildings, number
                            and type of tenants, tenant
                            spread and rating, lease terms and escalations, lease renewals or tenant
                            failures.
                        </li>
                        <li>
                            <span class="heading-5-light"> Market conditions:</span> General macro economy, market
                            segment conditions, supply and
                            demand, rental rates
                            and rental growth, expense ratios, vacancy rates, vacancy periods,
                            capitalisation rates.
                        </li>
                        <li>
                            <span class="heading-5-light"> Comparative Market Analysis:</span> Comparable sales in the
                            nearby surrounds,
                            comparable sales in similar
                            investors nodes, comparable rentals in the nearby surrounds, adjustments
                            applicable, saleability of subject
                            property.
                        </li>
                        <li>
                            <span class="heading-5-light"> Financials:</span> Actual gross and net income, property
                            expenses, business profits,
                            affordable rental calculations,
                            discounted cashflows of leases (as and where applicable), etc.
                        </li>
                    </ul>
                    <p>
                        The above list is by no means exhaustive and will be influenced by the property type
                        and valuation method
                        applied. However, please be assured that your property will be assessed and valued
                        in terms of a thorough
                        investigation and according to property specific parameters.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light">
                    DELIVERABLES AND TIMEFRAMES
                </div>
                <div class="col-12 paragraph-light">
                    <ul class="montserrat-bullets">
                        <li>
                            15 to 20-page report in PDF format
                        </li>
                        <li>
                            Includes site visit by professional valuer
                        </li>
                        <li>
                            Provides full detail of all market research and calculations
                        </li>
                        <li>
                            10 to 12 working days for delivery
                        </li>
                        <li>
                            Please note: This type of report will provide all the relevant information
                            required for a financing application
                            however most financial institutions / banks have their own templates and
                            requirements. We will be able
                            to transfer the information to the relevant template, however this will incur a
                            small additional fee and the
                            instruction will have to be issued through the specific bank.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 heading-5-light ">
                    FEE STRUCTURE (estimates only)
                </div>
                <div class="col-12">
                    <p class="paragraph-light">
                        All fees as indicated in the table below exclude VAT. Note that this is only a
                        general indication of fees; each
                        request for a valuation will be assessed individually and a property specific
                        quotation will be provided.
                    </p>
                </div>
                <div class="col-12 heading-5-light ">
                    Please note:
                </div>
                <div class="col-12">
                    <p class="paragraph-light">
                        If you wish to request a quotation for a formal valuation and the property is
                        already listed on the
                        <b>Property Matrix</b> website,
                        the fees will be reduced,
                        as Matrix has already done a planning investigation on this property as well as
                        a market value assessment.
                    </p>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-12">
                    <table>
                        <colgroup>
                            <col class="col-group-1">
                            <col class="col-group-2">
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="heading-2-light text-center" colspan="2">FORMAL
                                    VALUATION</th>
                            </tr>
                        </thead>
                        <tbody class="paragraph-light">
                            <tr class="grey-background paragraph">
                                <td colspan="2">Vacant land</td>
                            </tr>
                            <tr>
                                <td>Vacant land (single zoning)</td>
                                <td>R4 500</td>
                            </tr>
                            <tr>
                                <td>Vacant land with township rights</td>
                                <td>Depends on size of development</td>
                            </tr>
                            <tr class="grey-background paragraph">
                                <td colspan="2">Land with built Improvements
                                    (residential/industrial/offices/flats/retail/other)</td>
                            </tr>
                            <tr>
                                <td>Single land use; value less than R5-million</td>
                                <td>R3 500 to R5 000</td>
                            </tr>
                            <tr>
                                <td>Single land use; value between R5-million and
                                    R20-million</td>
                                <td>R5 500 to R8 500</td>
                            </tr>
                            <tr>
                                <td>Single land use; value between R20-million and
                                    R50-million</td>
                                <td>R9 500 to R12 500</td>
                            </tr>
                            <tr>
                                <td>Single land use; value above R50-million</td>
                                <td>R17 500 +</td>
                            </tr>
                            <tr>
                                <td>Multiple land uses; value less than R5-million</td>
                                <td>R5 000 to R6 500</td>
                            </tr>
                            <tr>
                                <td>Multiple land uses; value between R5-million and
                                    R20-million</td>
                                <td>R7 500 to R11 500</td>
                            </tr>
                            <tr>
                                <td>Multiple land uses; value between R20-million and
                                    R50-million</td>
                                <td>R11 500 to R15 500</td>
                            </tr>
                            <tr>
                                <td>Multiple land uses; value above R50-million</td>
                                <td>R22 500 +</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-12">
                    <button class="heading-3 button-light"
                        [routerLink]="['/propertyValuation/propertyValuationRequest/']">REQUEST</button>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <main class="background-image container-fluid">
        <section class="content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center heading-1">
                            VALUATION BY A REGISTERED PROFESSIONAL PROPERTY VALUER
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 paragraph text-center">
                        <p>
                            You might require an objective assessment to determine the 'open market value' of a certain
                            property to make clear financial decisions or to avoid unrealistic expectations, or for
                            several other reasons such as financing or auditing purposes.
                        </p>
                        <p>
                            The definition of 'market value' as laid down by the International Valuation Standards
                            Committee is:<br />
                            “The estimated amount for which a property should exchange on the date of valuation between
                            a willing buyer and a willing seller in an arms-length transaction after proper marketing
                            wherein the parties had each acted knowledgeably, prudently and without compulsion”.
                        </p>
                        <p>
                            For a valuation which adheres to legal and institutional requirements, you will require the
                            services of a professional valuer, registered with the South African Council for the
                            Property Valuers Profession (“SACPVP”). <b>Property Matrix</b> has a national panel of
                            selected professional valuers on board, who will be keen to investigate and value all types
                            of properties.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 heading-4 text-center pb-3">
                        WE OFFER TWO LEVELS OF PROPERTY VALUATIONS:
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="numbers-right-border">
                            <div class="heading-3 text-center">
                                LEVEL
                            </div>
                            <div class="numbers text-center">
                                1
                            </div>
                            <div class="align-height">
                                <div class="heading-5">Desktop valuation:
                                </div>
                                <ul class="montserrat-bullets paragraph">
                                    <li>
                                        2-page memorandum
                                    </li>
                                    <li>
                                        Excludes site visit – pictures & data (via
                                        questionnaire) provided by owner
                                    </li>
                                    <li>
                                        Professional valuer conducts market
                                        value assessment
                                    </li>
                                    <li>
                                        3 to 5 working days for delivery
                                    </li>
                                </ul>
                            </div>
                            <div class="text-center paragraph my-2">
                                R2 000 - R5 000
                            </div>
                            <div class="text-center">
                                <button class="button heading-3" id="LearnMore1" (click)="toggleColorbtn1(0)">LEARN
                                    MORE</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="text-center heading-3">
                            LEVEL
                        </div>
                        <div class="text-center numbers">
                            2
                        </div>
                        <div class="align-height">
                            <div class="heading-5">
                                Formal valuation report:
                            </div>
                            <ul class="montserrat-bullets paragraph">
                                <li>
                                    15 to 20-page report
                                </li>
                                <li>
                                    Includes site visit by professional valuer
                                </li>
                                <li>
                                    Full details of all market research and
                                    calculations
                                </li>
                                <li>
                                    10 to 12 working days for delivery
                                </li>
                            </ul>
                        </div>
                        <div class="text-center paragraph my-2">
                            R3 500 - R30 000
                        </div>
                        <div class="text-center">
                            <button class="button heading-3" id="btn" (click)="toggleColorbtn2(1)">LEARN
                                MORE</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </main>
</mat-sidenav-container>