import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { FileUploader } from 'src/app/models/file-uploader';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { CheckboxVm } from 'src/app/Services/propertypros/models';
import { Sections } from 'src/app/Services/sections';

@Component({
  selector: 'app-planning-report',
  templateUrl: './planning-report.component.html',
  styleUrls: ['./planning-report.component.scss']
})
export class PropertyListingsPlanningReportComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Input() reportDetails = null;
  customListing: boolean;

  onsiteFeatures: '';
  multiple: number[] = [1, 4];
  key: number = 1;
  index: number = 0;

  fileUploader = new FileUploader();

  aSections: Sections[] = [
    { id: 1, value: 'PROPERTY INFORMATION', checked: true, displayId:0 },
    { id: 2, value: 'LOCALITY MAP', checked: true, displayId:0 },
    { id: 3, value: 'EXISTING LAND USE', checked: true, displayId:0 },
    { id: 4, value: 'EXISTING LAND USE RIGHTS', checked: true, displayId:0 },
    { id: 5, value: 'SPATIAL DEVELOPMENT FRAMEWORK & POLICIES', checked: true, displayId:0 },
    { id: 6, value: 'ADDITIONAL POLICIES, PLANS, FIGURES', checked: true, displayId:0 },
    { id: 7, value: 'DEVELOPMENT POTENTIAL', checked: true, displayId:0 },
    { id: 8, value: 'IMPROVEMENT OPTIONS', checked: true, displayId:0 },
    { id: 9, value: 'A POSSIBLE DEVELOPMENT SCENARIO', checked: true, displayId:0 },
    { id: 10, value: 'QUANTIFIED EXTENT (A POSSIBLE DEVELOPMENT SCENARIO)', checked: true, displayId:0 },
  ]

  bSections: Sections[] = []
  baSections: Sections[] = [
    { id: 12, value: 'POTENTIAL CONSTRUCTION SCOPE - LAND PARCEL ONE', checked: true, parcel: 0, displayId:0 },
    { id: 12, value: 'POTENTIAL CONSTRUCTION SCOPE - LAND PARCEL TWO', checked: true, parcel: 1, displayId:0 },
    { id: 12, value: 'POTENTIAL CONSTRUCTION SCOPE - LAND PARCEL THREE', checked: true, parcel: 2, displayId:0 },
    { id: 12, value: 'POTENTIAL CONSTRUCTION SCOPE - LAND PARCEL FOUR', checked: true, parcel: 3, displayId:0 }
  ]

  cSections: Sections[] = [
    { id: 13, value: 'POTENTIAL CONSTRUCTION SCOPE', checked: true, displayId:0 },
    { id: 14, value: 'TITLE CONDITIONS ', checked: true, displayId:0 },
    { id: 15, value: 'REFERENCES ', checked: true, displayId:0 }
  ]

  sections: Sections[] = []

  counter: string[] = [
    "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
  ]

  canMakeAvailableOptionsCheckBox: CheckboxVm[] = [
    { id: 1, value: 'Figure 2: Locality of the site ', checked: false },
    { id: 2, value: 'Figure 3: Spatial Development Framework.', checked: false },
    { id: 3, value: 'Older buildings plans – not approved nor updated', checked: false },
    { id: 4, value: 'Other maps/plans', checked: false },
    { id: 5, value: 'Professional reports (done by engineers, environmental- or geotechnical specialists, etc.)', checked: false },
    { id: 6, value: 'Other/related documents', checked: false },
    { id: 7, value: 'Pictures of the property (recently taken - available in png or jpeg format) ', checked: false },
    { id: 8, value: 'None of the above', checked: false }
  ]

  parcelCountRadioOptions: KeyValuePair[] = [
    { key: 1, value: 'One' },
    { key: 2, value: 'Two' },
    { key: 3, value: 'Three' },
    { key: 4, value: '> Three' },
  ]

  uploadBlocks: any[] = [
    { key: 1, value: 'Figure 2: Locality of the site', file: null, picture: null, fileId: '' },
    { key: 2, value: 'SPATIAL DEVELOPMENT FRAMEWORK (SDF)', file: null, picture: null, fileId: '' },
    { key: 3, value: 'LEGEND', file: null, picture: null, fileId: '' },
    { 
      additional: [
        { key: 4, value: 'Additional Policies', file: null, picture: null, fileId: '' },
      ]
    },
  ]

  metricUnits: KeyValuePair[] = [
    { key: 1, value: 'Ha' },
    { key: 2, value: 'm2' },
  ]

  constructor(private sharedService: PropertiProSharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getPropertyParams();
    this.assignPictures();
    for (let i = 0; i < +this.reportDetails.planningReport.parcelCount; i++) {
      this.bSections.push({ id: 12, value: 'POTENTIAL CONSTRUCTION SCOPE - LAND PARCEL '+this.parcelCountRadioOptions[i].value.toString().toUpperCase(), checked: true, parcel: i });
    }
    if(+this.reportDetails.planningReport.parcelCount > this.reportDetails.planningReport.propozedZonings.length){
      for(let i = this.reportDetails.planningReport.propozedZonings.length; i < +this.reportDetails.planningReport.parcelCount; i++){
        this.reportDetails.planningReport.propozedZonings.push({ sizeParcel: "", zoningRights: "", landUses: "", additionalRights: "", density: "", coverage: "", height: "", far: "" });
      }
    }
    if(+this.reportDetails.planningReport.parcelCount > this.reportDetails.planningReport.potentialConstructionScopeParcels.length){
      for(let i = this.reportDetails.planningReport.potentialConstructionScopeParcels.length; i < +this.reportDetails.planningReport.parcelCount; i++){
        this.reportDetails.planningReport.potentialConstructionScopeParcels.push(
          {
            averageSizeMaxiumumUnits: '',
            maxMTwo: '',
            maxUnitsCalTotalProp: '',
            projectCoverage: '',
            potentialUsableSpace: '',
            projectDensityHa: '',
            projectFar: '',
            projectHeight: '',
            projectOpenSpacePerHectarUnusableSpace: '',
            projectParkingPerHundred: '',
            projectParkingPerUnit: '',
            projectVisitorsParkingPerUnit: '',
            proposedSizeOfProperty: '',
            sizeOfProperty: '',
            spacingMaxParkSpace: '',
            spacingParkingPerHundred: '',
            spacingParkingPerUnit: '',
            spacingVisitorsPerUnit: '',
            unusableSpace: '',
            maxMTwoFar: '',
          }
        );
      }
    }
    this.renderSections();
  }

  renderSections()
  {
    this.key = 1;
    this.index = 0;
    
    this.aSections.forEach(element => {
      element.index = 0;
      element.displayId = this.key;
      this.sections.push(element);
      if(this.customListing && this.multiple.filter(x => x === element.id).length > 0) {
        this.index++;
        this.insertNewSection(element);
      }
      this.key++;
    });
    if(!this.customListing){
      this.sections.push({ id: 11, value: 'CURRENT CONSTRUCTION SCOPE ', checked: true, displayId:this.key });
      this.key++;
    }
    this.index = 0;
    this.bSections.forEach(element => {
      element.displayId = this.key;
      this.sections.push(element);
      this.key++;
    });
    this.index = 0;
    this.cSections.forEach(element => {
      if(!this.customListing || element.id != 14){
        element.displayId = this.key;
        this.sections.push(element);
        this.key++;
      }
    });
    this.sections.sort((a: Sections, b: Sections) => {return +a.displayId - +b.displayId});
  }

  insertNewSection(section: Sections) {
    this.key++;
    const newSection: Sections = {
      checked: true,
      displayId: this.key,
      id: section.id,
      value: section.value,
      index: this.index
    }
    this.index = 0;
    this.sections.push(newSection);
  }

  getPropertyParams() {
    this.route.queryParams
      .subscribe(params => {
        this.customListing = params.customListing === 'true';
      }
    );
  }

  getSectionProperty(section: Sections): string {
    if(this.reportDetails.planningReport.propertyInformation.length > 1 && this.multiple.filter(x => x === section.id).length > 0) {
      const content = " - " + this.reportDetails.propertyTitle[section.index];
      return content;
    }
    else{
      return null;
    }
  }

  assignPictures() {
    for (let index = 0; index < this.uploadBlocks.length; index++) {
      let block = this.uploadBlocks[index];
      if(block.additional != null) {
        const file = this.reportDetails.planningReport?.showFiles?.filter(f => f.uploadTypeId == index + 1);
        if(file != undefined && file.length > 0){
          for(let j = 0; j < file.length - 1; j++){
            block.additional.push({ key: 4, value: 'Additional Policies', file: null, picture: null, fileId: '' });
          }
          for(let j = 0; j < file.length; j++){
            block.additional[j].picture = file[j]?.picture;
            block.additional[j].fileId = file[j]?.fileId;
  
            if(this.reportDetails.planningReport?.additionalPolicies[j] == null){
              this.reportDetails.planningReport?.additionalPolicies.push("");
            }
          }
        }
      } else {
        const file = this.reportDetails.planningReport?.showFiles?.find(f => f.uploadTypeId == index + 1);
        block.picture = file?.picture;
        block.fileId = file?.fileId;
      }
    }
  }

  async deleteFileFromDb(index: any, additionalIndex: any) {
    if(additionalIndex != undefined){
      this.reportDetails.planningReport.additionalPolicies = this.reportDetails.planningReport.additionalPolicies.splice(additionalIndex, 1);
      let result = await this.sharedService.deletePropertyListingFile(this.uploadBlocks[3].additional[additionalIndex].fileId, 1, this.customListing);
      if (result) {
        this.uploadBlocks[3].additional[additionalIndex].file = null;
        this.uploadBlocks[3].additional[additionalIndex].picture = null;
      }
    } else {
      let result = await this.sharedService.deletePropertyListingFile(this.uploadBlocks[index].fileId, 1, this.customListing);
      if (result) {
        this.uploadBlocks[index].file = null;
        this.uploadBlocks[index].picture = null;
      }
    }
  }

  potentialUsableSpaceHA() {
    return (this.reportDetails.planningReport.sizeOfPropertyExtent - this.reportDetails.planningReport.unusableSspaceExtent);
  }

  maximumUnitsCalculatedOnUsableSpace() {
    return this.potentialUsableSpaceHA() * 80
  }

  collapseOrExpandSection(section: CheckboxVm) {
    section.checked = !section.checked
  }

  selectUpload(files, id, i) {
    this.fileUploader.specificFileBrowseHandler(files, id);
    this.updateShownBlocks(files[0], id, i);
  }

  dropUpload($event, id) {
    this.fileUploader.specificOnFileDropped($event, id);
    this.updateShownBlocks($event[0], id, null);
  }

  deleteFile(block, blockIndex, isAdditional) {
    if(isAdditional){
      this.uploadBlocks[3].additional[blockIndex].file = null;
      let item = this.fileUploader.files.find(f => f.id == block.key);
      let fileIndex = this.fileUploader.files.indexOf(item);
      this.fileUploader.deleteFile(fileIndex);
    } else{
      this.uploadBlocks[blockIndex].file = null;
      let item = this.fileUploader.files.find(f => f.id == block.key);
      let fileIndex = this.fileUploader.files.indexOf(item);
      this.fileUploader.deleteFile(fileIndex);
    }
  }

  updateShownBlocks(file, id, i) {
    let uploadBlock = this.uploadBlocks[id - 1];
    if(i != null){
      uploadBlock.additional[i].show = this.fileUploader.files.filter(f => f.id == id).length > 0;
      uploadBlock.additional[i].file = file;
    } else {
      uploadBlock.show = this.fileUploader.files.filter(f => f.id == id).length > 0;
      uploadBlock.file = file;
    }
  }

  goToAddendumOne() {
    this.reportDetails.planningReport.files = this.fileUploader.files;
    this.pageNumberEvent.emit(2);
  }

  addRow(index: number, type: any, data: any, file: boolean): void {
    type.push(data);
    if(file){
      this.uploadBlocks[3].additional.push({ key: 4, value: 'Additional Policies', file: null, picture: null, fileId: '' });
    }
    setTimeout(() => {
      document.getElementById(`${typeof(type)}-${index + 1}`)?.focus();
    }, 5);
  }

  async deleteRow(index: number, type: any, file: boolean) {
    if(file){
      let resOne = await this.sharedService.deletePropertyListingFile(this.uploadBlocks[3].additional[index].fileId, 1, this.customListing);
      this.uploadBlocks[3].additional.splice(index, 1);
    }
    type = type.splice(index, 1);
  }

  parcelCountChange(index: number): void {
    this.sections = [];
    
    if(index != 4)
      this.bSections = this.baSections.slice(0, index - 4);
    else
      this.bSections = this.baSections;

    this.renderSections();

    if(index > this.reportDetails.planningReport.propozedZonings.length){
      for(let i = this.reportDetails.planningReport.propozedZonings.length; i < index; i++){
        this.reportDetails.planningReport.propozedZonings.push({ sizeParcel: "", zoningRights: "", landUses: "", additionalRights: "", density: "", coverage: "", height: "", far: "" });
        this.reportDetails.planningReport.potentialConstructionScopeParcels.push({averageSizeMaxiumumUnits: "", maxMTwo: "", maxUnitsCalTotalProp: "", projectCoverage: "", potentialUsableSpace: "", projectDensityHa: "", projectFar: "", projectHeight: "", projectOpenSpacePerHectarUnusableSpace: "", projectParkingPerHundred: "", projectParkingPerUnit: "", projectVisitorsParkingPerUnit: "", proposedSizeOfProperty: "", sizeOfProperty: "", spacingMaxParkSpace: "", spacingParkingPerHundred: "", spacingParkingPerUnit: "", spacingVisitorsPerUnit: "", unusableSpace: "", maxMTwoFar: ""});
      }
    } else {
      this.reportDetails.planningReport.propozedZonings = this.reportDetails.planningReport.propozedZonings.slice(0, index - this.reportDetails.planningReport.propozedZonings.length);
      this.reportDetails.planningReport.potentialConstructionScopeParcels = this.reportDetails.planningReport.potentialConstructionScopeParcels.slice(0, index - this.reportDetails.planningReport.potentialConstructionScopeParcels.length);
    }
  }
}
