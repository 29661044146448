<form id="detailsForm" novalidate #detailsForm="ngForm" class="submission-form">
    <section>
        <div class="heading-2 mt-1">STEP 1: PURCHASER DETAILS</div>
        <div class="heading-5 mt-2">
            <span class="burnt-red">| </span>In which entity would you like to purchase the property?
        </div>
        <mat-radio-group name="radioRegisteredTo" [(ngModel)]="form.propertyOwnershipType">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of propertyOwnershipType"
                [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="form.propertyOwnershipType == 1" class="submission-form">
            <div class="heading-5">
                <span class="burnt-red">| </span>Are you a resident of the Republic of South Africa?
            </div>
            <mat-radio-group name="radioSouthAfricanResident" [(ngModel)]="purchaserSAResident">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="purchaserSAResident > 0">
                <div class="row">
                    <div class="col-2">
                        <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                            <span *ngIf="purchaserSAResident == 1 || purchaserSAResident == 0">Identity
                                Number:</span>
                            <span *ngIf="purchaserSAResident == 2">Passport Number:</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                            class="paragraph" [(ngModel)]="form.personalDetails.id_Number" required #id_Number="ngModel"
                            minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                        'valid-input': id_Number.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                            *Must must contain at least 9 characters
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-radio-group name="radioMaritialStatus" [(ngModel)]="form.maritialStatus">
                            <mat-radio-button class="paragraph" *ngFor="let selectionValue of maritialStatuses"
                                [value]="selectionValue.key">
                                {{selectionValue.value}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div *ngIf="form.maritialStatus == 3" class="submission-form">
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpouseTitle" for="txtSpouseTitle" class="paragraph">Title:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpouseTitle" class="paragraph"
                            name="spouseTitle" [(ngModel)]="form.spouseDetails.title" required #spouseTitle="ngModel"
                            [ngClass]="{'invalid-input': spouseTitle.invalid, 'valid-input': spouseTitle.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpousePersonalName" for="txtSpousePersonalName" class="paragraph">Name:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalName" class="paragraph"
                            name="spouseName" [(ngModel)]="form.spouseDetails.name" required #spouseName="ngModel"
                            [ngClass]="{'invalid-input': spouseName.invalid, 'valid-input': spouseName.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpousePersonalSurname" for="txtSpousePersonalSurname"
                            class="paragraph">Surname:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalSurname" class="paragraph"
                            name="spouseSurname" [(ngModel)]="form.spouseDetails.surname" required
                            #spouseSurname="ngModel"
                            [ngClass]="{'invalid-input': spouseSurname.invalid, 'valid-input': spouseSurname.valid}">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpouseWorkPhone" for="txtSpouseWorkPhone" class="paragraph">Work
                            Phone:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" id="txtSpouseWorkPhone" name="spouseWorkPhone" class="paragraph not-required"
                            [(ngModel)]="form.spouseDetails.alternativeMobile">
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpousePersonalMobile" for="txtSpousePersonalMobile"
                            class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalMobile" name="spousePhoneNumber"
                            class="paragraph" [(ngModel)]="form.spouseDetails.phoneNumber" required
                            #spousePhoneNumber="ngModel" minlength="10"
                            [ngClass]="{'invalid-input': spousePhoneNumber.invalid, 'valid-input': spousePhoneNumber.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(spousePhoneNumber.errors && (spousePhoneNumber.dirty || spousePhoneNumber.touched)) && spousePhoneNumber.errors.minlength">
                            *Mobile number must contain at least the 10 numbers
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpousePersonalEmailAddress" for="txtSpousePersonalEmailaddress"
                            class="paragraph">Email
                            Address:</label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalEmailaddress"
                            name="spouseEmailAddress" class="paragraph" [(ngModel)]="form.spouseDetails.emailAddress"
                            required #spouseEmailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': spouseEmailAddress.invalid, 
                                                            'valid-input': spouseEmailAddress.valid}">
                        <small class="form-control-feedback"
                            *ngIf="spouseEmailAddress.errors && (spouseEmailAddress.dirty || spouseEmailAddress.touched)">
                            *Must be a valid email address
                        </small>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>Is your spouse a resident of the Republic
                    of South
                    Africa?
                </div>
                <mat-radio-group name="radioSpouseSouthAfricanResident" [(ngModel)]="spouseIsSouthAfricanResident">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
                <div class="row">
                    <div class="col-2">
                        <label id="lblSpousePersonalIdNumber" for="txtSpousePersonalIdNumber" class="paragraph">
                            <span
                                *ngIf="spouseIsSouthAfricanResident == 1 || spouseIsSouthAfricanResident == 0">Identity
                                Number:</span>
                            <span *ngIf="spouseIsSouthAfricanResident == 2">Passport Number:</span>
                        </label>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Mandatory" id="txtSpousePersonalIdNumber" name="spouseid_Number"
                            class="paragraph" [(ngModel)]="form.spouseDetails.id_Number" required
                            #spouseid_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': spouseid_Number.invalid, 
                                                                        'valid-input': spouseid_Number.valid}">
                        <small class="form-control-feedback"
                            *ngIf="(spouseid_Number.errors && (spouseid_Number.dirty || spouseid_Number.touched)) && spouseid_Number.errors.minlength">
                            *Must must contain at least 9 characters
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form.propertyOwnershipType == 2" class="submission-form">
            <div class="row">
                <div class="col-2">
                    <label id="lblLegalEntityName" for="txtLegalEntityName" class="paragraph">Entity full name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtLegalEntityName" class="paragraph"
                        name="legalEntityName" [(ngModel)]="form.legalEntityName" required #legalEntityName="ngModel"
                        [ngClass]="{'invalid-input': legalEntityName.invalid, 'valid-input': legalEntityName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblLegalEntityRegistrationNumber" for="txtLegalEntityRegistrationNumber"
                        class="paragraph">Entity registration nr.:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtLegalEntityRegistrationNumber" class="paragraph"
                        name="legalEntityRegistrationNumber" [(ngModel)]="form.legalEntityRegistrationNumber" required
                        #legalEntityRegistrationNumber="ngModel"
                        [ngClass]="{'invalid-input': legalEntityRegistrationNumber.invalid, 'valid-input': legalEntityRegistrationNumber.valid}">
                </div>
            </div>
            <div class="heading-5">
                <span class="burnt-red">| </span>Is the legal entity registered for VAT?
            </div>
            <mat-radio-group name="hasVatNumber" [(ngModel)]="hasVatNumber">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="hasVatNumber > 0">
                <div *ngIf="hasVatNumber == 1">
                    <div class="row mb-3">
                        <div class="col-2">
                            <label id="lblLegalEntityVATNumber" for="txtLegalEntityVATNumber" class="paragraph">VAT
                                number:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" id="txtLegalEntityVATNumber" name="legalEntityVATNumber"
                                class="paragraph" required placeholder="Mandatory"
                                [(ngModel)]="form.legalEntityVATNumber" #legalEntityVATNumber="ngModel"
                                [ngClass]="{'invalid-input': legalEntityRegistrationNumber.invalid, 'valid-input': legalEntityRegistrationNumber.valid}">
                        </div>
                    </div>
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>Is the entity owned by only one person/shareholder?
                </div>
                <mat-radio-group name="radioOnlyPropertyOwner" [(ngModel)]="moreThanOneOwner">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of multipleOwners"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="moreThanOneOwner > 0">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Are you the legal representative of the entity?
                </div>
                <mat-radio-group name="radioIsLegalRepresentative" [(ngModel)]="isLegalRepresentative">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="isLegalRepresentative == 1 && moreThanOneOwner == 1">
                <div class="heading-5">
                    <span class="burnt-red">| </span>Are you (as legal representative) a resident of the Republic of
                    South Africa?
                </div>
                <mat-radio-group name="radioSouthAfricanResident" [(ngModel)]="purchaserSAResident">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="purchaserSAResident > 0">
                    <div class="row mt-3">
                        <div class="col-2">
                            <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                                <span *ngIf="purchaserSAResident == 1 || purchaserSAResident == 0">Identity
                                    Number:</span>
                                <span *ngIf="purchaserSAResident == 2">Passport Number:</span>
                            </label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                                class="paragraph" [(ngModel)]="form.personalDetails.id_Number" required
                                #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                            'valid-input': id_Number.valid}">
                            <small class="form-control-feedback"
                                *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                                *Must must contain at least 9 characters
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isLegalRepresentative == 1 && moreThanOneOwner == 2">
                <div class="paragrah"><b>Important note:</b> A resolution, mandating you to act on behalf of all
                    members, as well as a copy of the company/entity registration document,
                    will be required. </div>
            </div>
            <div *ngIf="isLegalRepresentative == 2 && moreThanOneOwner == 2">
                <div class="paragrah"><b>Important note:</b> The person who is nominated as the representative of the
                    entity will need to sign the offer to purchase contract (to be generated).
                    A resolution, mandating him/her to act on behalf of all members, as well as a company/entity
                    registration document, will be required. </div>
            </div>
            <div *ngIf="isLegalRepresentative == 2 && moreThanOneOwner == 1">
                <span class="paragraph"><b><u>Please confirm</u></b></span>
                <div class="col-12">
                    <label class="checkbox-container paragraph mt-1">
                        <span class="heading-5">
                            I have consent from the legal representative of the entity to fill in this form and to
                            provide Property Matrix with his/her details.
                        </span>
                        <input type="checkbox" name="confirmLegalRepConcent" [(ngModel)]="confirmLegalRepConcent" />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div *ngIf="confirmLegalRepConcent" class="submission-form">
                    <div class="heading-5">Contact details of <span class="coral-text">legal representative</span>:
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph"
                                name="name" [(ngModel)]="form.personalDetails.name" required #name="ngModel"
                                [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalSurname" for="txtPersonalSurname" class="paragraph">Surname:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph"
                                name="surname" [(ngModel)]="form.personalDetails.surname" required #surname="ngModel"
                                [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblLandline" for="txtLandline" class="paragraph">Landline:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" id="txtLandline" name="landline" class="paragraph not-required"
                                [(ngModel)]="form.personalDetails.landline">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                                class="paragraph" [(ngModel)]="form.personalDetails.phoneNumber" required
                                #phoneNumber="ngModel" minlength="10"
                                [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                            <small class="form-control-feedback"
                                *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                                *Mobile number must contain at least the 10 numbers
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalConfirmPhoneNumber" for="txtPersonalConfirmPhoneNumber"
                                class="paragraph">Confirm
                                Mobile:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalConfirmPhoneNumber"
                                name="confirmPhoneNumber" class="paragraph" required #confirmPhoneNumber="ngModel"
                                [(ngModel)]="form.personalDetails.confirmPhoneNumber"
                                [ngClass]="{'invalid-input': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                'valid-input': (phoneNumber.value == confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched)}">
                            <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                'confirm-valid': phoneNumber.value == confirmPhoneNumber.value}">
                                *Confirm Mobile does not match Mobile Number
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress" class="paragraph">Email
                                Address:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress" name="emailAddress"
                                class="paragraph" [(ngModel)]="form.personalDetails.emailAddress" required
                                #emailAddress="ngModel" [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                'valid-input': emailAddress.valid}">
                            <small class="form-control-feedback"
                                *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                                *Must be a valid email address
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail" class="paragraph">Confirm
                                Email:</label>
                        </div>
                        <div class="col-6">
                            <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail" name="confirmEmail"
                                class="paragraph" required #confirmEmail="ngModel" [pattern]="emailPattern"
                                [(ngModel)]="form.personalDetails.confirmEmail"
                                [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                            <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                'confirm-valid': emailAddress.value == confirmEmail.value}">
                                *Confirm Email does not match Email Address
                            </small>
                        </div>
                    </div>
                    <div class="heading-5">
                        <span class="burnt-red">| </span>Are you (as legal representative) a resident of the Republic of
                        South Africa?
                    </div>
                    <mat-radio-group name="radioSouthAfricanResident" [(ngModel)]="purchaserSAResident">
                        <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNo"
                            [value]="selectionValue.key">
                            {{selectionValue.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="purchaserSAResident > 0">
                        <div class="row mt-3">
                            <div class="col-2">
                                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                                    <span *ngIf="purchaserSAResident == 1 || purchaserSAResident == 0">Representative ID
                                        number:</span>
                                    <span *ngIf="purchaserSAResident == 2">Representative
                                        passport number:
                                    </span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                                    class="paragraph" [(ngModel)]="form.personalDetails.id_Number" required
                                    #id_Number="ngModel" minlength="9" [ngClass]="{'invalid-input': id_Number.invalid, 
                                                                                'valid-input': id_Number.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                                    *Must must contain at least 9 characters
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="form.maritialStatus > 0 || confirmLegalRepConcent || (isLegalRepresentative > 0 && moreThanOneOwner > 0)"
            class="submission-form">
            <span class="paragraph"><strong>Physical/street address of the property to be sold:</strong></span>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyBuildingUnitNo" for="txtPropertyBuildingUnitNo" class="paragraph">Building/
                        unit
                        no:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPropertyBuildingUnitNo" name="buildingUnitNumber"
                        class="paragraph not-required" [(ngModel)]="form.address.unitNo">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyEstateComplexName" for="txtPropertyEstateComplexName"
                        class="paragraph">Estate/complex
                        name:</label>
                </div>
                <div class="col-6">
                    <input type="text" id="txtPropertyEstateComplexName" name="estateComplexName"
                        class="paragraph not-required" [(ngModel)]="form.address.complexName">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyStreetNo" for="txtPropertyStreetNo" class="paragraph">Street
                        no:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetNo" name="streetNumber"
                        class="paragraph" [(ngModel)]="form.address.streetNo" required #streetNumber="ngModel"
                        [ngClass]="{'invalid-input': streetNumber.invalid, 'valid-input': streetNumber.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyStreetName" for="txtPropertyStreetName" class="paragraph">Street
                        Name:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyStreetName" name="streetName"
                        class="paragraph" [(ngModel)]="form.address.streetName" required #streetName="ngModel"
                        [ngClass]="{'invalid-input': streetName.invalid, 'valid-input':streetName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertySuburb" for="txtPropertySuburb" class="paragraph">Suburb:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertySuburb" name="suburb" class="paragraph"
                        [(ngModel)]="form.address.suburb" required #suburb="ngModel"
                        [ngClass]="{'invalid-input': suburb.invalid, 'valid-input':suburb.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPropertyCity" for="txtPropertyCity" class="paragraph">City:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="city" class="paragraph"
                        [(ngModel)]="form.address.city" #city="ngModel" required
                        [ngClass]="{'invalid-input': city.invalid, 'valid-input':city.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label id="lblPostalCode" for="txtPostalCode" class="paragraph">Postal code:</label>
                </div>
                <div class="col-6">
                    <input type="text" placeholder="Mandatory" id="txtPropertyCity" name="postalCode" class="paragraph"
                        [(ngModel)]="form.address.postalCode" #postalCode="ngModel" required
                        [ngClass]="{'invalid-input': postalCode.invalid, 'valid-input':postalCode.valid}">
                </div>
            </div>
            <div class="row text-center">
                <div class="col-10">
                    <button id="btnSaveFinal" class="button heading-4"
                        [disabled]="detailsForm.invalid || (isLegalRepresentative == 2 && moreThanOneOwner == 1 && !confirmLegalRepConcent)"
                        (click)="submitForm()">SUBMIT</button>
                </div>
            </div>
        </div>
    </section>
</form>