<div class="row mt-3" *ngFor="let section of sections">
    <div class="cols-12 heading-2 mt-3 section-title">
        <div class="row">
            <div class="col-11">{{section.displayId}}. {{section.value}} {{getSectionProperty(section)}}</div>
            <div class="col-1">
                <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                    <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-2">
        <div class="row mt-4" *ngIf="section.id == 1" [hidden]="!section.checked">
            <div class="col-6">
                <span class="heading-5">PROPERTY DETAILS</span>
                <div class="paragraph">
                    <div class="row">
                        <div class="col-5">Property type</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].propertyType}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Unit number</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].unitNumber}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Erf number</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].erfNumber}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Portion number</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].portionNumber}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Township</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].township}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Deeds erf extent</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].deedsErfExtent}}</div>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="col-6">
                <span class="heading-5">ADDRESS DETAILS</span>
                <div class="paragraph">
                    <div class="row">
                        <div class="col-5">Street number</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].streetNumber}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Street name</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].streetName}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Complex name</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].complexName}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Suburb name</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].suburbName}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Subplace name</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].subPlaceName}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">City/Town</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].city}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Municipality</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].municipality}}</div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-5">Province</div>
                        <div class="col-7">{{reportDetails.planningReport.propertyInformation[section.index].province}}</div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 2" [hidden]="!section.checked">
        <div class="row mt-2">
            <div class="col-12 heading-3">
                <mat-icon class="location-icon">location_on</mat-icon>
                LOCALITY MAP
                <button mat-icon-button color="warn" *ngIf="uploadBlocks[0].picture" (click)="deleteFileFromDb(0)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="col-12 mt-2">
                <div class="upload-block map-upload" appDragAndDrop
                    (fileDropped)="dropUpload($event, uploadBlocks[0].key)" *ngIf="uploadBlocks[0].file == null">
                    <input type="file" class="map-upload" #fileDropRef id="fileDropRef" multiple
                        (change)="selectUpload($event.target.files, uploadBlocks[0].key)" />
                    <div class="row" *ngIf="uploadBlocks[0].picture">
                        <img class="map-upload" [src]='uploadBlocks[0].picture'>
                    </div>
                    <div class="row" *ngIf="!uploadBlocks[0].picture && uploadBlocks[0].file == null">
                        <div class="col-12">
                            <mat-icon class="file-icon">cloud_upload</mat-icon>
                        </div>
                        <div class="col-12 heading-3">DRAG & DROP</div>
                        <div class="col-12 heading-3">FILE HERE</div>
                        <div class="col-12 paragraph">or</div>
                        <div class="col-12"><button class="button small-button">CHOOSE FILE</button></div>
                    </div>
                </div>
                <div *ngIf="uploadBlocks[0].file != null" class="upload-block map-upload">
                    <div class="row paragraph">
                        <div class="col-12">
                            <mat-icon class="file-icon">insert_drive_file</mat-icon>
                        </div>
                        <h4 class="col-12 paragraph bold">
                            {{uploadBlocks[0].file.name}}
                        </h4>
                        <p class="col-12 heading-5">
                            {{ fileUploader.formatBytes(uploadBlocks[0].file.size) }}
                        </p>
                        <div class="col-12">
                            <app-progress [progress]="uploadBlocks[0].file?.progress"></app-progress>
                        </div>
                        <div class="delete col-12" (click)="deleteFile(uploadBlocks[0], 0, false)">
                            <mat-icon class="delete delete-icon">delete</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="paragraph text-end">
                    {{uploadBlocks[0].value}}
                </div>
            </div>
        </div>
        <div class="row mt-2 paragraph">
            <div class="col-12">
                Access to the site and if applicable: nearest bus routes and railway lines, are indicated in Addendum 1
                of this report.
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 3" [hidden]="!section.checked">
        <div class="paragraph" *ngIf="!customListing">
            <div class="row bold">
                <div class="col-5">Predominant land use*</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.predominantLandUse" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row bold">
                <div class="col-5">Additional land use(s)</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.additionalLandUses" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5">Surveyor General erf extent</div>
                <div class="col-7">{{reportDetails.planningReport.surveyorGeneralErfExtent}}m²</div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5">Gross floor area estimate</div>
                <div class="col-7">{{reportDetails.planningReport.grossFloorAreaEstimate}}m²</div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5">Building footprint estimate</div>
                <div class="col-7">{{reportDetails.planningReport.buildingFootprintEstimate}}m²</div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5">Coverage** estimate</div>
                <div class="col-7">{{reportDetails.planningReport.coverageEstimate ?? 0 * 100}}%</div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5">Height estimate: highest buiding</div>
                <div class="col-7">{{reportDetails.planningReport.heightEstimateHighestBuiding}} Storeys</div>
                <hr>
            </div>
        </div>
        <div class="row" *ngIf="customListing">
            <span class="heading-5">Description:</span>
            <div class="col-12">
                <div class="row">
                    <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.existingLandUseAndImprovementsDescription"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4 submission-form" *ngIf="section.id == 4" [hidden]="!section.checked">
        <div class="heading-5">
            Current planning particulars (legal rights) in terms of the
        </div>
        <div class="paragraph">
            <div class="row">
                <div class="col-5 vertical-center">Scheme name</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].schemeName" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Current zoning</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].currentZoning" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Additional rights</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].additionalRights" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Current planning application</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].currentPlanningApplication"
                        type="text" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Coverage* permitted</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].coveragePermitted" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Height permitted</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].heightPermitted" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Floor area ratio**</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].floorAreaRatio" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Density</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].density" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Minimum erf size</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].minimumErfSize" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Current permitted bulk</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].currentPermittedBulk" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Uses permitted on site</div>
                <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.existingZoaning[section.index].usesPermittedOnSite" type="text"
                        class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4 submission-form" *ngIf="section.id == 5" [hidden]="!section.checked">
        <div class="paragraph">
            <div class="row mt-2">
                <div class="col-8 heading-3">
                    {{uploadBlocks[1].value}} <button mat-icon-button color="warn" *ngIf="uploadBlocks[1].picture"
                        (click)="deleteFileFromDb(1)">
                        <mat-icon>delete</mat-icon>
                    </button></div>
                <div class="col-4 heading-3">
                    {{uploadBlocks[2].value}} <button mat-icon-button color="warn" *ngIf="uploadBlocks[2].picture"
                        (click)="deleteFileFromDb(2)">
                        <mat-icon>delete</mat-icon>
                    </button></div>
                <div class="col-8 mt-2">
                    <div class="upload-block map-upload" appDragAndDrop
                        (fileDropped)="dropUpload($event, uploadBlocks[1].key)" *ngIf="uploadBlocks[1].file == null">
                        <input type="file" class="map-upload" #fileDropRef id="fileDropRef"
                            (change)="selectUpload($event.target.files, uploadBlocks[1].key)" />
                        <div class="image-block" *ngIf="uploadBlocks[1].picture">
                            <img class="map-upload" [src]='uploadBlocks[1].picture'>
                        </div>
                        <div class="row" *ngIf="!uploadBlocks[1].picture && uploadBlocks[1].file == null">
                            <div class="col-12">
                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                            </div>
                            <div class="col-12 heading-3">DRAG & DROP</div>
                            <div class="col-12 heading-3">FILE HERE</div>
                            <div class="col-12 paragraph">or</div>
                            <div class="col-12"><button class="button small-button">CHOOSE FILE</button></div>
                        </div>
                    </div>
                    <div *ngIf="uploadBlocks[1].file != null" class="upload-block map-upload">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{uploadBlocks[1].file.name}}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(uploadBlocks[1].file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="uploadBlocks[1].file?.progress"></app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(uploadBlocks[1], 1, false)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph text-end">
                        {{uploadBlocks[1].value}}
                    </div>
                </div>
                <div class="col-4 mt-2">
                    <div class="upload-block map-upload" appDragAndDrop
                        (fileDropped)="dropUpload($event, uploadBlocks[2].key)" *ngIf="uploadBlocks[2].file == null">
                        <input type="file" class="map-upload" #fileDropRef id="fileDropRef"
                            (change)="selectUpload($event.target.files, uploadBlocks[2].key)" />
                        <div class="image-block" *ngIf="uploadBlocks[2].picture">
                            <img class="map-upload" [src]='uploadBlocks[2].picture'>
                        </div>
                        <div class="row" *ngIf="!uploadBlocks[2].picture && uploadBlocks[2].file == null">
                            <div class="col-12">
                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                            </div>
                            <div class="col-12 heading-3">DRAG & DROP</div>
                            <div class="col-12 heading-3">FILE HERE</div>
                            <div class="col-12 paragraph">or</div>
                            <div class="col-12"><button class="button small-button">CHOOSE FILE</button></div>
                        </div>
                    </div>
                    <div *ngIf="uploadBlocks[2].file != null" class="upload-block map-upload">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{uploadBlocks[2].file.name}}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(uploadBlocks[2].file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="uploadBlocks[2].file?.progress"></app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile(uploadBlocks[2], 2, false)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph text-end">
                        {{uploadBlocks[2].value}}
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <span class="heading-5">The Spatial Development Framework (SDF) is a policy that guides planning and future land development within the municipality.</span>
                <div class="col-12">
                    <div class="row">
                        <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.spatialDevelopmentFrameworkDescription"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4 submission-form" *ngIf="section.id == 6" [hidden]="!section.checked">
        <div class="paragraph" *ngFor="let item of uploadBlocks[3].additional; let i = index">
            <div class="row mt-2">
                <div class="col-8 heading-3">
                    {{item.value}} <button mat-icon-button color="warn" *ngIf="item.picture"
                        (click)="deleteFileFromDb(3, i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="col-12 mt-2">
                    <div class="upload-block map-upload" appDragAndDrop
                        (fileDropped)="dropUpload($event, item.key)" *ngIf="item.file == null">
                        <input  [id]="'additionalPolicies-' + i" type="file" class="map-upload" #fileDropRef id="fileDropRef"
                            (change)="selectUpload($event.target.files, item.key, i)" />
                        <div class="image-block" *ngIf="item.picture">
                            <img class="map-upload" [src]='item.picture'>
                        </div>
                        <div class="row" *ngIf="!item.picture && item.file == null">
                            <div class="col-12">
                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                            </div>
                            <div class="col-12 heading-3">DRAG & DROP</div>
                            <div class="col-12 heading-3">FILE HERE</div>
                            <div class="col-12 paragraph">or</div>
                            <div class="col-12"><button class="button small-button">CHOOSE FILE</button></div>
                        </div>
                    </div>
                    <div *ngIf="item.file != null" class="upload-block map-upload">
                        <div class="row paragraph">
                            <div class="col-12">
                                <mat-icon class="file-icon">insert_drive_file</mat-icon>
                            </div>
                            <h4 class="col-12 paragraph bold">
                                {{item.file.name}}
                            </h4>
                            <p class="col-12 heading-5">
                                {{ fileUploader.formatBytes(item.file.size) }}
                            </p>
                            <div class="col-12">
                                <app-progress [progress]="item.file?.progress"></app-progress>
                            </div>
                            <div class="delete col-12" (click)="deleteFile((item), i, true)">
                                <mat-icon class="delete delete-icon">delete</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph text-end">
                        {{item.value}}
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="row">
                        <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.additionalPolicies[i]"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-10"></div>
                <div class="col-1" *ngIf="i >= reportDetails.planningReport.additionalPolicies.length - 1 && i < 8">
                    <button (click)="addRow(i, reportDetails.planningReport.additionalPolicies, '', true)" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                </div>
                <div class="col-1" *ngIf="reportDetails.planningReport.additionalPolicies.length > 1">
                    <button (click)="deleteRow(i, reportDetails.planningReport.additionalPolicies, true)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4 submission-form" *ngIf="section.id == 7" [hidden]="!section.checked">
        <div class="paragraph">
            <div class="row">
                <div class="col-4 vertical-center bold">Metric unit</div>
                <div class="col-8">
                    <select class="w-100" [(ngModel)]="reportDetails.planningReport.metricUnit">
                        <option *ngFor="let item of metricUnits" [value]="item.key">{{item.value}}</option>
                    </select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-4 bold">Extent</div>
                        <div class="col-4 bold">Addendum No.</div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-4 vertical-center bold">Size of property</div>
                        <div class="col-4"><input
                                [(ngModel)]="reportDetails.planningReport.sizeOfPropertyExtent"
                                type="text" class="paragraph w-100 my-1"></div>
                        <div class="col-4"><input
                                [(ngModel)]="reportDetails.planningReport.sizeOfPropertyAddendumNo"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-4 vertical-center bold">Unusable space</div>
                        <div class="col-4"><input
                                [(ngModel)]="reportDetails.planningReport.unusableSspaceExtent"
                                type="text" class="paragraph w-100 my-1"></div>
                        <div class="col-4"><input
                                [(ngModel)]="reportDetails.planningReport.unusableSspaceAddendumNo"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-4 vertical-center bold">Potential usable space</div>
                        <div class="col-4">
                            <input
                                [(ngModel)]="reportDetails.planningReport.potentialUsableSpaceExtent"
                                type="text" class="paragraph w-100 my-1">
                        </div>
                        <div class="col-4">
                            <input
                                [(ngModel)]="reportDetails.planningReport.potentialUsableSpaceAddendumNo"
                                type="text" class="paragraph w-100 my-1">
                            </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 8" [hidden]="!section.checked">
        <div class="row" *ngIf="!customListing">
            <div class="paragraph" *ngFor="let improvement of reportDetails.planningReport.improvementOptions; let i = index">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 heading-5">Option {{ counter[i] }}</div>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Possible development/elevation</div>
                    <div class="col-7"><input [id]="'improvementOption-' + i" [(ngModel)]="improvement.possibleDevelopmentElevation" type="text"
                            class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Planning Application</div>
                    <div class="col-7"><input [(ngModel)]="improvement.planningApplication" type="text"
                            class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Time-frame (±)</div>
                    <div class="col-7"><input [(ngModel)]="improvement.timeFrame" type="text"
                            class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-10"></div>
                    <div class="col-1" *ngIf="i >= reportDetails.planningReport.improvementOptions.length - 1 && i < 8">
                        <button (click)="addRow(i, reportDetails.planningReport.improvementOptions, { possibleDevelopmentElevation: '', planningApplication: '', timeFrame: '' }, false)" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                    </div>
                    <div class="col-1" *ngIf="reportDetails.planningReport.improvementOptions.length > 1">
                        <button (click)="deleteRow(i, reportDetails.planningReport.improvementOptions, false)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="customListing">
            <div class="row mt-2">
                <span class="heading-5">From a planning perspective, the property appears to have the potential for any of the following:</span>
                <div class="col-12">
                    <div class="row">
                        <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.improvementOptionsDescription"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 9" [hidden]="!section.checked">
        <div class="row">
            <span class="heading-5">How many parcels do you reccomend?</span>
        </div>
        <div class="row">
            <div class="col-1">
                <mat-radio-group name="parcelCountRadioOptions" [(ngModel)]="reportDetails.planningReport.parcelCount">
                    <mat-radio-button class="paragraph" *ngFor="let selectionValue of parcelCountRadioOptions; let i = index"
                        (change)="parcelCountChange(i + 1)"
                        [value]="selectionValue.key">
                        {{selectionValue.value}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row mt-4">
            <span class="heading-5">According to our calculations, based on the interpretation of the relevant planning policies, the property appears to have the following development potential:</span>
            <div class="col-12">
                <div class="row">
                    <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.developmentPotential"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 10" [hidden]="!section.checked">
        <div class="row mt-2 paragraph" *ngIf="!customListing">
            <span class="heading-5">According to our calculations, based on the interpretation of the relevant planning policies, the following potential development is proposed:</span>
            <div class="col-12">
                <div class="row">
                    <input type="text" [(ngModel)]="reportDetails.planningReport.quantifiedExtentDescription" class="paragraph w-100 my-1">
                </div>
            </div>
        </div>
        <div class="paragraph" *ngFor="let item of reportDetails.planningReport.propozedZonings; let i = index">
            <div class="mt-4">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 heading-5">Proposed Zoning - Land Parcel {{ counter[i] }}</div>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed size of land parcel</div>
                    <div class="col-7"><input type="text" [(ngModel)]="item.sizeParcel" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed zoning/rights</div>
                    <div class="col-7"><input type="text" [(ngModel)]="item.zoningRights" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Land uses</div>
                    <div class="col-7"><input type="text" [(ngModel)]="item.landUses" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed additional rights</div>
                    <div class="col-7"><input  type="text" [(ngModel)]="item.additionalRights" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed density</div>
                    <div class="col-7"><input  type="text" [(ngModel)]="item.density" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed coverage</div>
                    <div class="col-7"><input  type="text" [(ngModel)]="item.coverage" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed Height</div>
                    <div class="col-7"><input  type="text" [(ngModel)]="item.height" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
                <div class="row">
                    <div class="col-5 vertical-center">Proposed FAR</div>
                    <div class="col-7"><input  type="text" [(ngModel)]="item.far" class="paragraph w-100 my-1"></div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 11 && !customListing" [hidden]="!section.checked">
        <div class="paragraph mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Project Development Controls</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Open space per hectare of usable space required</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.openSpacePerHectareUnusedSpace" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Density/ha</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.densityHa" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">F.A.R</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.far" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Height (storeys)</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.height" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Coverage</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.coverage" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per unit</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.projectParkingPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Visitors parking per unit</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.projectVisitorsPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per 100m²</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.projectParkingPerHundred" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Total parking requirement for maximum development</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.projectTotalParkingRequire" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Maximum Potential Development</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum units calculated on total property</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.maxUnits" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum m2 (based on FAR)</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.maxMTwoFAR" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum m2 (based on coverage & height)</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.maxMTwoCH" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Average size if maximum units are built</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.averageSizeMaxiumumUnits" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Spacing For Parking</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per unit</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.spacingParkingPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Visitors parking/unit</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.spacingVisitorsPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per 100m2  </div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.spacingParkingPerHundred" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Total parking requirement for development</div>
                <div class="col-7"><input  type="text" [(ngModel)]="reportDetails.planningReport.constructionScore.spacingTotalParkingRequire" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 12" [hidden]="!section.checked">
        <div class="paragraph mt-2">
            <div class="row">
                <div class="col-5 vertical-center">Proposed size of property</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].proposedSizeOfProperty" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
        <div class="paragraph mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Potential Land Space</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Size of property</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].sizeOfProperty" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Unusable space</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].unusableSpace" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Potential usable space</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].potentialUsableSpace" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
        <div class="paragraph mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Project Development Controls</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Open space per hectare of usable space required</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectOpenSpacePerHectarUnusableSpace" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Density/ha</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectDensityHa" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">F.A.R</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectFar" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Height (storeys)</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectHeight" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Coverage</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectCoverage" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per unit</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectParkingPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Visitors parking per unit</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectVisitorsParkingPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per 100m²</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].projectParkingPerHundred" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
        <div class="paragraph mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Maximum Potential Development</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum units calculated on total property</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].maxUnitsCalTotalProp" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum m2 (based on FAR)</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].maxMTwoFar" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum m2 (based on coverage & height)</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].maxMTwo" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Average size if maximum units are built</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].averageSizeMaxiumumUnits" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
        <div class="paragraph mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 heading-5">Spacing For Parking</div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per unit</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].spacingParkingPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Visitors parking/unit</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].spacingVisitorsPerUnit" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Parking: per 100m2</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].spacingParkingPerHundred" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Maximum parking spaces available on vacant land if fully developed</div>
                <div class="col-7"><input type="text" [(ngModel)]="reportDetails.planningReport.potentialConstructionScopeParcels[section.parcel].spacingMaxParkSpace" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 13" [hidden]="!section.checked">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <textarea rows="4" class="fs-14" [(ngModel)]="reportDetails.planningReport.potentialConstructionScope"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 14" [hidden]="!section.checked">
        <div class="row">
            <div class="col-1">
                <mat-checkbox class="paragraph" [(ngModel)]="reportDetails.planningReport.notSubjectToAnyRestrictiveConditions">The property is not subject to any restrictive conditions (refer to Addendum 4).</mat-checkbox>
                <mat-checkbox class="paragraph" [(ngModel)]="reportDetails.planningReport.subjectToRestrictiveConditions">The property is subject to restrictive conditions (refer to Addendum 4):</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-12"><input type="text" [(ngModel)]="reportDetails.planningReport.titleDeedConditions" placeholder="“Copy conditions form title deed”" class="paragraph w-100 my-1"></div>
        </div>
        <div class="row paragraph">
            <div class="col">
                The title deed's restrictive conditions supersedes any other rights and thus will have to be removed or the restrictive conditions be amended. A “removal of restrictive conditions” application need to be lodged to council to remove such conditions.
            </div>
        </div>
        <div class="row">
            <div class="col-1">
                <mat-checkbox class="paragraph" [(ngModel)]="reportDetails.planningReport.conveyancerReportRecommended">It is recommended that a deeds report be requested from a conveyancer.</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="cols-12 mt-4" *ngIf="section.id == 15" [hidden]="!section.checked">
        <div class="paragraph mt-2" *ngFor="let reference of reportDetails.planningReport.references; let i = index">
            <div class="row">
                <div class="col-5 vertical-center"> Name of the document, report, plan, diagram or map </div>
                <div class="col-7"><input type="text" [id]="'reference-' + i" [(ngModel)]="reference.name" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Company</div>
                <div class="col-7"><input type="text" [(ngModel)]="reference.company" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Professional</div>
                <div class="col-7"><input type="text" [(ngModel)]="reference.professional" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Mobile Number/Email:</div>
                <div class="col-7"><input type="text" [(ngModel)]="reference.contact" class="paragraph w-100 my-1"></div>
            </div>
            <div class="row">
                <div class="col-5 vertical-center">Website</div>
                <div class="col-7"><input type="text" [(ngModel)]="reference.website" class="paragraph w-100 my-1"></div>
                <hr>
            </div>
            <div class="row">
                <div class="col-10"></div>
                <div class="col-1" *ngIf="i >= reportDetails.planningReport.references.length - 1 && i < 8">
                    <button (click)="addRow(i, reportDetails.planningReport.references, { nameOfData: '', company: '', professional: '', contact: '', website: '' }, false)" class="mat-icon-button"><mat-icon>add</mat-icon></button>
                </div>
                <div class="col-1" *ngIf="reportDetails.planningReport.references.length > 1">
                    <button (click)="deleteRow(i, reportDetails.planningReport.references, false)" class="mat-icon-button"><mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="cols-12 mt-4 submission-form" *ngIf="section.id == 6" [hidden]="!section.checked">
        <div class="paragraph">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">DEVELOPMENT CONTROLS</div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Open space per hectare of usable space required</div>
                        <div class="col-7"><input
                                [value]="potentialUsableSpaceHA() * 0.1"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Density/ha</div>
                        <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.densityPerHectare"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">F.A.R</div>
                        <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.far" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Height (storeys)</div>
                        <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.heightInStoreys" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Coverage</div>
                        <div class="col-7"><input [(ngModel)]="reportDetails.planningReport.coverage" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">MAXIMUM POTENTIAL DEVELOPMENT WITH EXISTING RIGHT</div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Maximum units calculated on total property</div>
                        <div class="col-7"><input
                                [value]="reportDetails.planningReport.sizeOfPropertyExtent * 80" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Maximum m2 (based on FAR)</div>
                        <div class="col-7"><input [value]="potentialUsableSpaceSM()"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Maximum m2 (based on coverage & height)</div>
                        <div class="col-7"><input
                            [value]="(potentialUsableSpaceHA() * 0.5 * 2) * 10000"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Average size if maximum units are built</div>
                        <div class="col-7"><input
                                [value]="potentialUsableSpaceHA()" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">SPACE FOR PARKING WHEN IMPLEMENTING EXISTING MAX. RIGHTS & SPACE AVAILABLE
                        </div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Parking: per unit</div>
                        <div class="col-7"><input [value]="maximumUnitsCalculatedOnUsableSpace() * 2" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Visitors parking/unit</div>
                        <div class="col-7"><input [value]="maximumUnitsCalculatedOnUsableSpace() * 0.33"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Parking: per 100m2</div>
                        <div class="col-7"><input
                            [value]="(potentialUsableSpaceSM() / 100) * 4" type="text"
                                class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                    <div class="row">
                        <div class="col-5 vertical-center bold">Maximum parking spaces available on vacant land if fully
                            developed</div>
                        <div class="col-7"><input
                                [value]="0.5 * ((potentialUsableSpaceHA() - (potentialUsableSpaceHA() * 0.1)) * 10000) / 23"
                                type="text" class="paragraph w-100 my-1"></div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>  -->
</div>
<div class="text-center mt-5">
    <button (click)="goToAddendumOne()" class="button">Next</button>
</div>