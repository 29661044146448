import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';

@Component({
  selector: 'app-siod-confirm',
  templateUrl: './siod-confirm.component.html',
  styleUrls: ['./siod-confirm.component.scss']
})
export class SiodConfirmComponent implements OnInit {

  constructor(private router: Router, private sharedService: PropertiProSharedService) { }

  ngOnInit(): void {
  }

  approveSubscription(){
    sessionStorage.setItem('siodPageNumber', '3');
    this.router.navigateByUrl('/shareInOurDatabase/capture');
  }

}