import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { GlobalMethods } from 'src/app/common/global-methods';
import { PropertiProSharedService } from 'src/app/properti-pro-shared.service';
import { AdminService, FileService } from 'src/app/Services/propertypros/services';
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { AdminMyProfileDocumentsEditDialogComponent } from './admin-my-profile-documents-edit-dialog/admin-my-profile-documents-edit-dialog.component';
import { AdminEditDialogModel } from 'src/app/models/admin-edit-dialog-model';

@Component({
  selector: 'app-admin-my-profile-documents',
  templateUrl: './admin-my-profile-documents.component.html',
  styleUrls: ['./admin-my-profile-documents.component.scss']
})
export class AdminMyProfileDocumentsComponent implements OnInit {

  documentDetails: any[] = [
    { key: 1, value: null, title: 'Documents' },
  ];

  constructor(private adminService: AdminService, private sharedService: PropertiProSharedService, private fileService: FileService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getDocuments();
  }

  getDocuments() {
    let userName = this.sharedService.getUsername();

    this.adminService.apiV1AdminGetDocumentsGet$Response({ username: userName })
      .pipe(map(m => { return m })).subscribe({
        next: (result: any) => {
          let body = JSON.parse(result.body);
          console.log(body, 4)
          this.documentDetails[0].value = body;
        }
        , error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }

  editInfo(typeKey: number) {
    let data = this.documentDetails.find(f => f.key == typeKey);
    const dialogRef = this.dialog.open(AdminMyProfileDocumentsEditDialogComponent, {
      maxHeight: '80vh',
      data: { editType: typeKey, data: data } as AdminEditDialogModel,
    },);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDocuments();
      } 
    });
  }

  async downloadDocument(document: any) {
    this.fileService.apiV1FileDownloadFileGet$Response({ fileId: document.fileId })
      .pipe(map(m => { return m })).subscribe({
        next: async (result: any) => {
          let dataURI = result.body
          var blob = await (await fetch(dataURI)).blob();
          let filename = `${document.fileTypeName}${document.fileType}`;
          saveAs(blob, filename);
        }
        , error: () => {
          GlobalMethods.tinyErrorAlert("Error", "Error fetching data");
        }
      })
  }
}
