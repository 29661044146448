import { Component, OnInit } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';

@Component({
  selector: 'app-siod-form',
  templateUrl: './siod-form.component.html',
  styleUrls: ['./siod-form.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class SiodFormComponent implements OnInit {

  pageNumber: number = 1;
  hideTopButtonsFlag: boolean = false;

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'REGISTER' },
    { key: 2, value: 'SUBSCRIBE' },
    { key: 3, value: 'CONFIRM SUBSCRIPTION' },
    { key: 4, value: 'ADD SUBSCRIPTION' },
  ]

  constructor() { }

  ngOnInit(): void {
    let returnPageNumber = sessionStorage.getItem('siodPageNumber')
    if(returnPageNumber){
      this.pageNumber = parseInt(returnPageNumber);
      sessionStorage.removeItem('siodPageNumber')
    }
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
  }

  changePage($event) {
    this.pageNumber = $event;
    setTimeout(() => {
      GlobalMethods.scrollToTop();
    }, 100);
  }

  hideTopButtons($event: boolean){
    this.hideTopButtonsFlag = $event
  }
}