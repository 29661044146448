import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conveyancer-registration',
  templateUrl: './conveyancer-registration.component.html',
  styleUrls: ['./conveyancer-registration.component.scss']
})
export class ConveyancerRegistrationComponent {

  constructor() { }

}
