<div class="verify-user-component">
    <div>
        <mat-card-header>
            <mat-card-title>
                <h1>Verify User</h1>
            </mat-card-title>
            <mat-card-subtitle>
                <h3>Invite a new user to join the platform</h3>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="col-12 buttons-container">
                <div class="buttons-spacing">
                    <mat-button class="cancel-button" (click)="closeDialog()">
                        <span>Cancel</span>
                    </mat-button>
                </div>
                <div class="buttons-spacing">
                    <mat-button class="confirm-button" (click)="verifyUser()">
                        <span>Confirm</span>
                    </mat-button>
                </div>
            </div>
        </mat-card-content>
    </div>
</div>