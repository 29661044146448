<main class="background-image">
    <div class="container-fluid">
        <section class="content">
            <div class="content-padding">
                <div class="container">
                    <form id="registerForm" novalidate #registerForm="ngForm" class="submission-form">
                        <div class="row">
                            <div class="col-12">
                                <h1 class="heading-1">
                                    OPPORTUNITY FOR ESTATE AGENTS
                                </h1>
                            </div>
                            <div class="col-12 mt-4 paragraph">
                                <p>Welcome to this website, it is a privilege to have you on board. We trust that you
                                    will be rewarded beyond expectations when you make use of this
                                    platform to advertise your listed property.</p>
                                <span class="mt-2">
                                    To enable fast and transparent deals, every property advertised on this
                                    website:</span>
                                <ul class="montserrat-bullets m-1">
                                    <li>
                                        Is accompanied by a <span class="heading-5">Property Matrix</span>&nbsp;<a
                                            class="here" routerLink="/townPlanningApplication">level 2 town planning</a>
                                        report and
                                        supporting documents
                                    </li>
                                    <li>
                                        Is assessed by our value assessment team, with the support of our national
                                        professional valuer's network, to ensure that we advertise your listed
                                        property with a selling price which compares with the open market value of the
                                        property. Click <a class="here" routerLink="/propertyValuation">here</a> for
                                        details on our value assessments.
                                    </li>
                                    <li>
                                        Is presented (offered) to all buyers - according to our national network and
                                        database - who indicated an appetite for exactly what this property
                                        offers.
                                    </li>
                                </ul>
                                <p>Our extensive range and unique classification of property types and land uses are
                                    exceptional - not equalled by any other property website you have
                                    used before to market your listings. Whether it be properties relating to business,
                                    beauty, retail, culture, religion, education, animals, plants, vehicles,
                                    medical services, residential with home businesses… the list goes on.
                                    Your listed property will be advertised in the exact category it belongs to,
                                    enabling buyers (especially business owners, developers, and investors), to
                                    instantly find (or be notified of) exactly what they need.</p>
                            </div>
                            <div class="heading-2 text-center mt-3">HOW DO WE SERVE YOU AS AN ESTATE AGENT?</div>
                            <p class="paragraph mt-3">Once registered with <strong>Property Matrix</strong>, you will be
                                instantly connected to
                                a network of professional planners who are keen to assist you in obtaining
                                the information about the property you wish to advertise. We will check and verify the
                                information you've already gathered and will, in addition, add
                                our professional opinion regarding the current land use rights, as well as the potential
                                of the property: possible ways to develop/extend/'ennoble' it</p>
                            <div class="heading-2 text-center mt-3">OUR OFFER:</div>
                            <p class="paragraph mt-3">Neither the professional property investigation, the value
                                assessment, or the placing of
                                the advert on this ground-breaking national network will cost
                                you (or your agency) a cent. It will all be done at the planner's own risk and on a
                                cost-free basis. <strong>Property Matrix</strong> works with a wide network of estate
                                agents and agencies and is completely inclusive in this regard, there is no need for you
                                to leave your agency/franchise.</p>
                            <p class="paragraph">We trust that you are excited to be a part of this national network and
                                this new way of
                                marketing property. May this platform increase your future sales
                                tremendously. </p>
                            <p class="paragraph"><strong>For more info on the Property Matrix network, please provide
                                    your contact details
                                    below and we will provide you with further information on our
                                    commission structure, registration process and FREE training courses.</strong></p>
                        </div>
                        <div class="heading-4">Personal details</div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalName" for="txtPersonalName" class="paragraph">Name:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalName" class="paragraph"
                                    name="name" [(ngModel)]="form.personalDetails.name" required #name="ngModel"
                                    [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalSurname" for="txtPersonalSurname"
                                    class="paragraph">Surname:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalSurname" class="paragraph"
                                    name="surname" [(ngModel)]="form.personalDetails.surname" required
                                    #surname="ngModel"
                                    [ngClass]="{'invalid-input': surname.invalid, 'valid-input': surname.valid}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblLandline" for="txtLandline" class="paragraph">Landline:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="txtLandline" name="landline" class="paragraph not-required"
                                    [(ngModel)]="form.personalDetails.landline">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalMobile" for="txtPersonalMobile" class="paragraph">Mobile:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalMobile" name="phoneNumber"
                                    class="paragraph" [(ngModel)]="form.personalDetails.phoneNumber" required
                                    #phoneNumber="ngModel" minlength="10"
                                    [ngClass]="{'invalid-input': phoneNumber.invalid, 'valid-input': phoneNumber.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="(phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched)) && phoneNumber.errors.minlength">
                                    *Mobile number must contain at least the 10 numbers
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalConfirmPhoneNumber" for="txtPersonalConfirmPhoneNumber"
                                    class="paragraph">Confirm
                                    Mobile:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmPhoneNumber"
                                    name="confirmPhoneNumber" class="paragraph" required #confirmPhoneNumber="ngModel"
                                    [(ngModel)]="form.personalDetails.confirmPhoneNumber"
                                    [ngClass]="{'invalid-input': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                    'valid-input': (phoneNumber.value == confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (phoneNumber.value != confirmPhoneNumber.value) && (confirmPhoneNumber.dirty || confirmPhoneNumber.touched), 
                                    'confirm-valid': phoneNumber.value == confirmPhoneNumber.value}">
                                    *Confirm Mobile does not match Mobile Number
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalEmailAddress" for="txtPersonalEmailaddress"
                                    class="paragraph">Email
                                    Address:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalEmailaddress"
                                    name="emailAddress" class="paragraph"
                                    [(ngModel)]="form.personalDetails.emailAddress" required #emailAddress="ngModel"
                                    [pattern]="emailPattern" [ngClass]="{'invalid-input': emailAddress.invalid, 
                                                                    'valid-input': emailAddress.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                                    *Must be a valid email address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalConfirmEmail" for="txtPersonalConfirmEmail"
                                    class="paragraph">Confirm
                                    Email:</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalConfirmEmail"
                                    name="confirmEmail" class="paragraph" required #confirmEmail="ngModel"
                                    [pattern]="emailPattern" [(ngModel)]="form.personalDetails.confirmEmail"
                                    [ngClass]="{'invalid-input': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                    'valid-input': (emailAddress.value == confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (emailAddress.value != confirmEmail.value) && (confirmEmail.dirty || confirmEmail.touched), 
                                    'confirm-valid': emailAddress.value == confirmEmail.value}">
                                    *Confirm Email does not match Email Address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPersonalIdNumber" for="txtPersonalIdNumber" class="paragraph">
                                    Resident of RSA: ID nr
                                    <br>
                                    Non-resident: Passport nr.
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtPersonalIdNumber" name="id_Number"
                                    class="paragraph" [(ngModel)]="form.personalDetails.id_Number" required
                                    #id_Number="ngModel" minlength="9"
                                    [ngClass]="{'invalid-input': id_Number.invalid, 'valid-input': id_Number.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="(id_Number.errors && (id_Number.dirty || id_Number.touched)) && id_Number.errors.minlength">
                                    *Must must contain at least 9 characters
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblUsername" for="txtUsername" class="paragraph">Username(Email
                                    Address):</label>
                            </div>
                            <div class="col-6">
                                <input type="text" placeholder="Mandatory" id="txtUsername" name="username"
                                    class="paragraph" [(ngModel)]="form.loginDetails.email" required #username="ngModel"
                                    [pattern]="emailPattern"
                                    [ngClass]="{'invalid-input': username.invalid, 'valid-input': username.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="username.errors && (username.dirty || username.touched)">
                                    *Must be a valid email address
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPassword" for="txtPassword" class="paragraph">Password:</label>
                            </div>
                            <div class="col-6">
                                <input type="password" placeholder="Mandatory" id="txtPassword" name="password"
                                    class="paragraph" [(ngModel)]="form.loginDetails.password" required
                                    #password="ngModel" [pattern]="passwordPattern"
                                    [ngClass]="{'invalid-input': password.invalid, 'valid-input': password.valid}">
                                <small class="form-control-feedback"
                                    *ngIf="password.errors && (password.dirty || password.touched)">
                                    * Must contain minimum eight characters, at least one uppercase letter, one
                                    lowercase letter, one
                                    number and one special character
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <label id="lblPasswordConfirm" for="txtPasswordConfirm" class="paragraph">Confirm
                                    Password:</label>
                            </div>
                            <div class="col-6">
                                <input type="password" placeholder="Mandatory" id="txtPasswordConfirm"
                                    name="passwordConfirm" class="paragraph"
                                    [(ngModel)]="form.loginDetails.passwordConfirm" required #passwordConfirm="ngModel"
                                    [ngClass]="{'invalid-input': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                    'valid-input': (password.value == passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched)}">
                                <small class="form-control-feedback" [ngClass]="{'confirm-invalid': (password.value != passwordConfirm.value) && (passwordConfirm.dirty || passwordConfirm.touched), 
                                    'confirm-valid': password.value == passwordConfirm.value}">
                                    *Confirm Password does not match Password
                                </small>
                            </div>
                        </div>
                        <div class="row text-center mt-3">
                            <div class="col-12">
                                <button id="btnRegister" (click)="register()" [disabled]="registerForm.invalid"
                                    mat-button class="button heading-4 mt-2">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</main>