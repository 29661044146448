import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { GlobalMethods } from 'src/app/common/global-methods';
import { KeyValuePair } from 'src/app/common/globalModels/KeyValuePair';
import { OfferToPurchaseFormVm } from 'src/app/Services/propertypros/models';
import { PropertyForSaleService } from 'src/app/Services/propertypros/services';

@Component({
  selector: 'app-property-for-sale-offer-to-purchase-form',
  templateUrl: './property-for-sale-offer-to-purchase-form.component.html',
  styleUrls: ['./property-for-sale-offer-to-purchase-form.component.scss']
})
export class PropertyForSaleOfferToPurchaseFormComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  offerType: string = null
  pageNumber: Number = 1;
  loading: boolean = false;

  formHeaders: KeyValuePair[] = [
    { key: 1, value: 'PURCHASER DETAILS' },
    { key: 2, value: 'CONDITIONS' },
    { key: 3, value: 'PURCHASE PRICE' },
  ];

  form: OfferToPurchaseFormVm = {
    offerToPurchaseConditions: null,
    offerToPurchasePrice: null,
    offerToPurchasePurchaserDetails: null,
    username: ''
  }

  constructor(private router: Router, private propertyForSaleService: PropertyForSaleService) { }

  ngOnInit(): void {
    this.offerType = sessionStorage.getItem('offerType');
    if(!this.offerType){
      this.router.navigateByUrl('/home');
    }
    GlobalMethods.scrollToTop();
  }

  selectPage(pageNumber) {
    this.pageNumber = pageNumber;
  }

  changePage($event) {
    this.pageNumber = $event;
    if (this.pageNumber > 1) {
      GlobalMethods.scrollToTop();
    }
  }

  assignOfferToPurchasePurchaserDetails($event) {
    this.form.offerToPurchasePurchaserDetails = $event
  }

  assignOfferToPurchaseConditions($event) {
    this.form.offerToPurchaseConditions = $event
  }

  assignOfferToPurchasePrice($event) {
    this.form.offerToPurchasePrice = $event
    this.submitForm();
  }

  submitForm(){
    this.loading = true;
    this.propertyForSaleService.apiV1PropertyForSaleSaveOfferToPurchaseFormPost$Response({ body: this.form })
    .subscribe({
      next: (result) => {
        if (result.status == HttpStatusCode.Conflict) {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {
            this.loading = false;
            return;
          });
        }
        this.router.navigateByUrl('/propertyForSale/offerToPurchase/capture/finish')
        this.loading = false;
      }
      , error: (error) => {
        GlobalMethods.tinyErrorAlert('Error', 'Please ensure that all required fields are completed').then(result => {
        })
        this.loading = false;
      }
    })
    
  }

  openNav() {
    this.sidenav.open();
  }

  closeNav() {
    this.sidenav.close();
  }
}
