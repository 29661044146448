<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<form id="uploadDocumentsForm" novalidate #uploadDocuments="ngForm" class="submission-form">
    <div class="heading-2">STEP 4: UPLOAD DOCUMENTS</div>
    <div class="row">
        <div class="col-12">
            <strong class="paragraph">Please drag and drop copies of the following, in PDF format, to the indicated
                area.</strong>
            <ul class="montserrat-bullets paragraph">
                <li>
                    <u>Clear</u> copy of municipal rates account – both sides, all pages (not older than 3 months)
                </li>
                <li>
                    If the owner/representative is a resident of RSA, clear copy of ID
                </li>
                <li>
                    If the owner/representative is not a resident of RSA, clear copy of passport
                </li>
                <li>
                    If the owner is married in community, a copy of spouses ID
                </li>
                <li>
                    If the estate agent provided such, a copy of the commission claim form
                </li>
            </ul>
        </div>
    </div>
    <div class="block-wrapper text-center heading-5">
        <div *ngFor="let block of uploadBlocks; let i = index">
            <div class="grey-block" appDragAndDrop (fileDropped)="dropUpload($event, block.key)"
                *ngIf="block.file == null">
                <input type="file" #fileDropRef id="fileDropRef" multiple
                    (change)="selectUpload($event.target.files, block.key)" />
                <div class="row">
                    <div class="col-12">{{block.value}}</div>
                    <div class="col-12 paragraph">(Click or drop a file)</div>
                    <div class="col-12">
                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                    </div>
                </div>
            </div>
            <div *ngIf="block.file != null" class="grey-block">
                <div class="row paragraph">
                    <div class="col-12">
                        <mat-icon class="file-icon">insert_drive_file</mat-icon>
                    </div>
                    <h4 class="col-12 paragraph bold">
                        {{block.file.name}}
                    </h4>
                    <p class="col-12 heading-5">
                        {{ fileUploader.formatBytes(block.file.size) }}
                    </p>
                    <div class="col-12">
                        <app-progress [progress]="block.file?.progress"></app-progress>
                    </div>
                    <div class="delete col-12" (click)="deleteFile(block, i)">
                        <mat-icon class="delete delete-icon">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label class="checkbox-container">
        <strong class="paragraph">I have copies available, but I have trouble scanning and/or uploading it.</strong>
        <input type="checkbox" [(ngModel)]="form.troubleUploadingCopies" name="troubleUploadingCopies"
            #troubleUploadingCopies="ngModel" id="troubleUploadingCopies" data-toggle="toggle" />
        <span class="checkmark"></span>
    </label>
    <div class="heading-5">ADDITIONAL INFORMATION</div>
    <strong class="paragraph">Important note:</strong>
    <ul class="montserrat-bullets paragraph">
        <li>
            It will be beneficial to your investigation and the selling of your property if you provide us with as
            much information of the property as possible. Click
            <a class="here" (click)="openSideNav(4)">
                here
            </a> for examples of what we require.
        </li>
        <li>
            We kindly request that you will have all plans/documents/reports/pictures available in electronic format
            on the day of the site visit. If you do not
            have access to a quality scanner, we can recommend the services of PostNet – they scan all sizes at a
            reasonable price.
        </li>
    </ul>
    <div class="heading-5">
        <span class="burnt-red">| </span>Which of the following can you make available?
    </div>
    <div *ngFor="let option of canMakeAvailableOptionsCheckBox; let index = i"
        (click)="checkprofessionalReportsActive()" class="remove-row-gap">
        <label class="checkbox-container">
            <strong class="paragraph">{{option.value}}</strong>
            <input type="checkbox" [(ngModel)]="option.checked" [name]="canMakeAvailableOptions"
                #canMakeAvailableOptions="ngModel" />
            <span class="checkmark"></span>
        </label>
    </div>
    <div *ngIf="professionalReportsActive == true">
        <div class="paragraph submission-form">
            <strong><u>Important:</u></strong>
            <span><strong>Property Matrix</strong> respects the intellectual property of other related professionals. It
                is
                therefore essential that you have consent from that professional
                to a) share the report with Property Matrix, b) for the report to be made available on our website
                alongside
                your property advert, and c) that Property
                Matrix will charge an administrative fee for users to download the report. </span>
            <span>Our partnering agent will assist you with correspondence that can be forwarded to the relevant
                professional asking for their consent and offering this
                vehicle (our website) to also promote their company and services – free of charge.</span>
            <div class="heading-5">
                <span class="burnt-red">| </span>Please provide the following information of the professional report(s)
                you
                have available:
            </div>
            <div [hidden]="form.reports.length == 0" class="submission-form">
                <div class="heading-2">Reports</div>
                <table mat-table [dataSource]="form.reports" class="mat-elevation-z1" style="width: 45vw;">
                    <ng-container matColumnDef="report">
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let report; let i = index"> Report {{i + 1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="action1">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let report; let i = index">
                            <button mat-icon-button style="color: #fb8c00" (click)="openEditReportDialog(report, i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action2">
                        <th mat-header-cell *matHeaderCellDef> Delete </th>
                        <td mat-cell *matCellDef="let report; let i = index">
                            <button mat-icon-button color="warn" (click)="deleteReport(owner, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="button" (click)="openAddReportDialog()" id="addReportButton">
                    Add Report
                </button>
            </div>
        </div>
    </div>
    <section *ngIf="canMakeAvailableContainsChecked()">
        <div class="row text-center">
            <div class="col-10">
                <button id="documentSubmit" [disabled]="uploadDocuments.invalid" class="button heading-4" (click)="submitForm()">SUBMIT</button>
            </div>
        </div>
    </section>
</form>