<form id="contractDetailsForm" novalidate #contractDetails="ngForm" class="submission-form">
    <div class="heading-2">STEP 3: CONTRACT DETAILS</div>
    <span class="paragraph">Once an offer is received on your property, a draft offer to purchase contract will be
        drafted for your perusal. The following information will allow our
        system to streamline this process.</span>
    <div class="heading-3">Conveyancing</div>
    <div class="heading-5">
        <span class="burnt-red">| </span>Is the property governed by a homeowner's association, body corporate or any
        other association where a levy is payable?
    </div>
    <div class="row">
        <mat-radio-group name="propertyGoverenedByAssociation" [(ngModel)]="propertyGoverenedByAssociation">
            <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNoOptionsRadio"
                [value]="selectionValue.key">
                {{selectionValue.value}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="propertyGoverenedByAssociation == 1" class="submission-form">
        <div class="heading-5">
            <span class="burnt-red">| </span>Are your levies paid up to date?
        </div>
        <div class="row">
            <mat-radio-group name="leviesUpToDate" [(ngModel)]="leviesUpToDate">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of basicYesNoOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <span class="paragraph"><strong><u>Please note:</u></strong> Levies need to be settled to sell your property.
            The appointed conveyancer will get in touch to discuss and assist you with options. You
            may also refer to the municipal value as indicated on your rates and taxes account.</span>
    </div>
    <div *ngIf="leviesUpToDate == 2" class="submission-form">
        <div class="heading-5">
            <span class="burnt-red">| </span>Is the property governed by a homeowner's association, body corporate or
            any other association where a levy is payable?
        </div>
        <div class="row">
            <mat-radio-group name="useInHouseLegalTeam" [(ngModel)]="useInHouseLegalTeam">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of useInHouseLegalTeamOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="useInHouseLegalTeam == 1" class="submission-form">
            <span class="paragraph">Thank you for the opportunity to provide you with this service. Our conveyancing
                team will be in contact with you shortly.</span>
        </div>
        <!--conveyancing details-->
        <div *ngIf="useInHouseLegalTeam == 2" class="submission-form">
            <div class="row">
                <div class="col-3">
                    <label id="lblCompanyName" for="txtCompanyName" class="paragraph">Company name:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtCompanyName" class="paragraph" name="companyName"
                        [(ngModel)]="form.conveyancer.companyName" required #companyName="ngModel"
                        [ngClass]="{'invalid-input': companyName.invalid, 'valid-input': companyName.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblName" for="txtName" class="paragraph">Contact person:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtName" class="paragraph" name="name"
                        [(ngModel)]="form.conveyancer.personalDetails.name" required #name="ngModel"
                        [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblEmailAddress" for="txtEmailAddress" class="paragraph">Email Address:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtEmailAddress" class="paragraph"
                        name="emailAddress" [(ngModel)]="form.conveyancer.personalDetails.emailAddress" required
                        #emailAddress="ngModel" [pattern]="emailPattern"
                        [ngClass]="{'invalid-input': emailAddress.invalid, 'valid-input': emailAddress.valid}">
                    <small class="form-control-feedback"
                        *ngIf="emailAddress.errors && (emailAddress.dirty || emailAddress.touched)">
                        *Must be a valid email address
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblMobile" for="txtMobile" class="paragraph">Contact number - mobile:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtMobile" class="paragraph" name="mobile"
                        [(ngModel)]="form.conveyancer.personalDetails.mobile" required #mobile="ngModel" minlength="10"
                        [ngClass]="{'invalid-input': mobile.invalid, 'valid-input': mobile.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(mobile.errors && (mobile.dirty || mobile.touched)) && mobile.errors.minlength">
                        *Mobile number must contain at least the 10 numbers
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label id="lblAlternativeMobile" for="txtAlternativeMobile" class="paragraph">Contact number -
                        office:</label>
                </div>
                <div class="col-7">
                    <input type="text" placeholder="Mandatory" id="txtAlternativeMobile" class="paragraph"
                        name="alternativeMobile" [(ngModel)]="form.conveyancer.personalDetails.alternativeMobile" required
                        #alternativeMobile="ngModel" minlength="10"
                        [ngClass]="{'invalid-input': alternativeMobile.invalid, 'valid-input': alternativeMobile.valid}">
                    <small class="form-control-feedback"
                        *ngIf="(alternativeMobile.errors && (alternativeMobile.dirty || alternativeMobile.touched)) && alternativeMobile.errors.minlength">
                        *Mobile number must contain at least the 10 numbers
                    </small>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="propertyGoverenedByAssociation > 0" class="submission-form">
        <div class="heading-3">COMMISSION</div>
        <strong class="paragraph">Our commission structure is as follows:</strong>
        <div class="row mb-1">
            <div class="col-8">
                <div class="commision-table">
                    <table class="text-center paragraph">
                        <thead>
                            <tr>
                                <th>SELLING PRICE OF YOUR PROPERTY</th>
                                <th>% COMMISSION PAYABLE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableData">
                                <td>{{item.priceRange}}</td>
                                <td>{{item.percentage}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <span class="paragraph"><strong>Please note:</strong> The only income we generate is via the commission
            payable when your property is sold. We consider this as the remuneration for our
            initial professional input which was done on risk and free of charge.</span>
        <label class="checkbox-container">
            <strong class="paragraph">I accept the commission structure as set out above (calculated on the selling
                price of my property) and will not engage in any
                disputes regarding the commission payable.</strong>
            <input type="checkbox" [(ngModel)]="form.acceptCommisionStructure" name="troubleUploadingCopies"
                #acceptCommisionStructure="ngModel" id="acceptCommisionStructure" data-toggle="toggle" />
            <span class="checkmark"></span>
        </label>
    </div>
    <div *ngIf="form.acceptCommisionStructure == true" class="submission-form">
        <div class="heading-5">
            <span class="burnt-red">| </span>Were you introduced to Property Matrix by a partnering estate agent who
            recommended us to market this property?
        </div>
        <div class="row">
            <mat-radio-group name="recommendedByPartnerAgent" [(ngModel)]="recommendedByPartnerAgent">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of recommendedByPartnerAgentOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <span class="paragraph"><strong>Important:</strong> Property Matrix is unique in the sense that our partnering
            agents are trained to collect very specialised information.
            There will <u>always</u> be an agent involved and commission payable - thus no reason to hide any
            information if you were introduced
            to us by any of our agents - as it will have no effect on the amount you will pay towards commission.</span>
    </div>
    <div *ngIf="recommendedByPartnerAgent == 1" class="submission-form">
        <div class="heading-5">
            Details of <span class="coral">agent</span>:
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblAgentName" for="txtAgentName" class="paragraph">Agent Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAgentName" class="paragraph" name="agentName"
                    [(ngModel)]="form.agent.personalDetails.name" required #agentName="ngModel"
                    [ngClass]="{'invalid-input': agentName.invalid, 'valid-input': agentName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblAgentSurname" for="txtAgentSurname" class="paragraph">Agent surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAgentSurname" class="paragraph" name="agentSurname"
                    [(ngModel)]="form.agent.personalDetails.surname" required #agentSurname="ngModel"
                    [ngClass]="{'invalid-input': agentSurname.invalid, 'valid-input': agentSurname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblEstateAgency" for="txtEstateAgency" class="paragraph">Estate agency the agent
                    represents:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtEstateAgency" class="paragraph" name="estateAgency"
                    [(ngModel)]="form.agent.estateAgency" required #estateAgency="ngModel"
                    [ngClass]="{'invalid-input': estateAgency.invalid, 'valid-input': estateAgency.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblAgentEmailAddress" for="txtAgentEmailAddress" class="paragraph">Email Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAgentEmailAddress" class="paragraph"
                    name="agentEmailAddress" [(ngModel)]="form.agent.personalDetails.emailAddress" required #agentEmailAddress="ngModel"
                    [pattern]="emailPattern"
                    [ngClass]="{'invalid-input': agentEmailAddress.invalid, 'valid-input': agentEmailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="agentEmailAddress.errors && (agentEmailAddress.dirty || agentEmailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblAgentMobile" for="txtAgentMobile" class="paragraph">Agent mobile number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtAgentMobile" class="paragraph" name="agentMobile"
                    [(ngModel)]="form.agent.personalDetails.mobile" required #agentMobile="ngModel" minlength="10"
                    [ngClass]="{'invalid-input': agentMobile.invalid, 'valid-input': agentMobile.valid}">
                <small class="form-control-feedback"
                    *ngIf="(agentMobile.errors && (agentMobile.dirty || agentMobile.touched)) && agentMobile.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="checkbox-container">
                    <strong class="paragraph">I commit to sign the commission claim form if this was provided to me by
                        the above agent.</strong>
                    <input type="checkbox" [(ngModel)]="form.commitCommisionStructure" name="commitCommisionStructure"
                        #commitCommisionStructure="ngModel" id="commitCommisionStructure" data-toggle="toggle" />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
    <div *ngIf="recommendedByPartnerAgent > 0" class="submission-form">
        <div class="heading-5">
            <span class="burnt-red">| </span>Are you already connected to any urban planner who you prefer to work
            with?
        </div>
        <div class="row">
            <mat-radio-group name="connectedToUrbanPlanner" [(ngModel)]="connectedToUrbanPlanner">
                <mat-radio-button class="paragraph" *ngFor="let selectionValue of connectedToUrbanPlannerOptionsRadio"
                    [value]="selectionValue.key">
                    {{selectionValue.value}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div *ngIf="connectedToUrbanPlanner == 1" class="submission-form">
        <div class="heading-5">
            Details of <span class="coral">urban planner:</span>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblUrbanPlannerName" for="txtUrbanPlannerName" class="paragraph">Planner Name:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtUrbanPlannerName" class="paragraph"
                    name="urbanPlannerName" [(ngModel)]="form.urbanPlanner.personalDetails.name" required #urbanPlannerName="ngModel"
                    [ngClass]="{'invalid-input': urbanPlannerName.invalid, 'valid-input': urbanPlannerName.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblUrbanPlannerSurname" for="txtUrbanPlannerSurname" class="paragraph">Planner
                    surname:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtUrbanPlannerSurname" class="paragraph"
                    name="urbanPlannerSurname" [(ngModel)]="form.urbanPlanner.personalDetails.surname" required
                    #urbanPlannerSurname="ngModel"
                    [ngClass]="{'invalid-input': urbanPlannerSurname.invalid, 'valid-input': urbanPlannerSurname.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblPlannerLocation" for="txtPlannerLocation" class="paragraph">City/town where planner is
                    located:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtPlannerLocation" class="paragraph"
                    name="plannerLocation" [(ngModel)]="form.urbanPlanner.plannerLocation" required
                    #plannerLocation="ngModel"
                    [ngClass]="{'invalid-input': plannerLocation.invalid, 'valid-input': plannerLocation.valid}">
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblUrbanPlannerEmailAddress" for="txtUrbanPlannerEmailAddress" class="paragraph">Planner
                    Email Address:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtUrbanPlannerEmailAddress" class="paragraph"
                    name="urbanPlannerEmailAddress" [(ngModel)]="form.urbanPlanner.personalDetails.emailAddress" required
                    #urbanPlannerEmailAddress="ngModel" [pattern]="emailPattern"
                    [ngClass]="{'invalid-input': urbanPlannerEmailAddress.invalid, 'valid-input': urbanPlannerEmailAddress.valid}">
                <small class="form-control-feedback"
                    *ngIf="urbanPlannerEmailAddress.errors && (urbanPlannerEmailAddress.dirty || urbanPlannerEmailAddress.touched)">
                    *Must be a valid email address
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <label id="lblUrbanPlannerMobile" for="txtUrbanPlannerMobile" class="paragraph">Planner mobile
                    number:</label>
            </div>
            <div class="col-7">
                <input type="text" placeholder="Mandatory" id="txtUrbanPlannerMobile" class="paragraph"
                    name="urbanPlannerMobile" [(ngModel)]="form.urbanPlanner.personalDetails.mobile" required #urbanPlannerMobile="ngModel"
                    minlength="10"
                    [ngClass]="{'invalid-input': urbanPlannerMobile.invalid, 'valid-input': urbanPlannerMobile.valid}">
                <small class="form-control-feedback"
                    *ngIf="(urbanPlannerMobile.errors && (urbanPlannerMobile.dirty || urbanPlannerMobile.touched)) && urbanPlannerMobile.errors.minlength">
                    *Mobile number must contain at least the 10 numbers
                </small>
            </div>
        </div>
    </div>
    <div *ngIf="connectedToUrbanPlanner > 0" class="row text-center">
        <div class="col-10">
            <button id="btnSaveFinal" [disabled]="contractDetails.invalid" class="button heading-4" (click)="openCollaborationDialog()">SUBMIT</button>
        </div>
    </div>
</form>