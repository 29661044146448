import { Component, OnInit } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';

@Component({
  selector: 'app-estate-agent-registration',
  templateUrl: './estate-agent-registration.component.html',
  styleUrls: ['./estate-agent-registration.component.scss']
})
export class EstateAgentRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

}
