<div>
    <div class="heading-2">
        Add new multiple listing report
    </div>
    <div class="row mt-4">
        <div class="col-3">
            <label class="paragraph">Listing Name:</label>
        </div>
        <div class="col-7">
            <input type="text" placeholder="Mandatory" name="name" class="paragraph" [(ngModel)]="listingName" required
                #name="ngModel" [ngClass]="{'invalid-input': name.invalid, 'valid-input': name.valid}">
        </div>
    </div>
    <div class="heading-2 mt-4">
        Properties to link
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <input type="text" placeholder="Search" [(ngModel)]="search">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div *ngFor="let listing of searchListings(); let i = index">
                <div class="property-listing-block mt-3 px-1"
                    [ngClass]="{'added': isListingAdded(listing.propertyListingId)}">
                    <div class="row">
                        <div class="col-4">
                            <div class="property-cadaster-border">
                                <img class="w-100 property-cadaster-image" *ngIf="listing.image" [src]="listing.image">
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="heading-2">{{listing.addressTitle}}</div>
                                    <div class="heading-5">{{listing.completeAddress}}</div>
                                </div>
                                <div class="col-12 mt-1">
                                    <div class="row">
                                        <div class="col-4 heading-3">Date Received:</div>
                                        <div class="col-8 paragraph">{{listing.createdDate}}</div>
                                    </div>
                                </div>
                                <div class="col-12 mt-1">
                                    <div class="row">
                                        <div class="col-4 heading-3">Created by:</div>
                                        <div class="col-8 paragraph">{{listing.createdBy.firstName}}
                                            {{listing.createdBy.lastName}}</div>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <button *ngIf="!isListingAdded(listing.propertyListingId)"
                                        (click)="addListing(listing)" class="button">Add</button>
                                    <button (click)="removeListing(listing.propertyListingId)"
                                        *ngIf="isListingAdded(listing.propertyListingId, i)"
                                        mat-icon-button><mat-icon>delete</mat-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sticky-bottom" *ngIf="listingName && addedListings">
        <div class="row mt-4">
            <div class="col-12 text-end">
                <button [disabled]="listingName.length == 0 || addedListings.length == 0" (click)="postListing()"
                    class="button">Save</button>
            </div>
        </div>
    </div>
</div>