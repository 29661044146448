<div class="inner-block">
    <h1 class="heading-1 text-center mt-2">
        COLLABORATION
    </h1>
    <div class="m-4 mt-2">
        <div class="row">
            <div class="col-12">
                <strong class="paragraph">I confirm that I will commit my time and attention to:</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="montserrat-bullets paragraph">
                    <li>Agree on a date and time - within 5 working days once my boundaries are confirmed - for an agent
                        to
                        visit my property (if
                        not vacant land) and to have easy access to all buildings on the property.</li>
                    <li>Put effort in to have any additional information, reports, maps, building plans, tenant rolls,
                        etc.
                        available <u>in electronic format.</u></li>
                    <li>Allow the agent to take pictures (or a short video) of the property and interior and exterior of
                        all
                        buildings</li>
                    <li>Ensure that my property is marketing ready to make a good first impression. I will remove
                        clutter,
                        put personal items away,
                        open all curtains/blinds etc. If not done by myself, I will allow the agent to do so within
                        reasonable measures.</li>
                </ul>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button id="btnAgree" class="button heading-4" (click)="agree()">Agree</button>
            </div>
        </div>
    </div>
</div>