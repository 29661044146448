<main>
    <div class="main-container">
        <mat-card class="row sub-container">
            <div class="col">
                <div class="title-container">
                    <div class="title-icon link">
                        <mat-icon routerLink="../">arrow_back</mat-icon>
                    </div>
                    <div class="property-title">
                        {{listingAddress}}
                    </div>
                </div>
            </div>
            <div class="col" *ngFor="let item of headingTabItems">
                <div [class]="{'active-tab': tab == item.key}" class="tab-container" (click)="selectTab(item.key)">
                    <div class="tab-icon link">
                        <mat-icon>{{item.icon}}</mat-icon>
                    </div>
                    <div class="tab-title link">
                        {{item.value}}
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card class="row sub-container p-0" *ngIf="tab == 1">
            <div class="col" *appUserRole="[
                'HeadAdministrator',
                'HeadOfFinances',
                'AgentPortalAdministrator',
                'PlannerPortalAdministrator',
                'ConsultantAdministrator',
                'SeniorAgent',
                'SeniorPlanner',
                'HeadOfJuniorPlanners',
                'JuniorPlanner',
                'SeniorValuer',
                'NetworkAgent',
                'NetworkPlanner',
                'NetworkValuer']">
                <div class="phase-title">
                    <b>PHASE ONE</b>
                </div>
                <div class="phase-description">
                    The Draft Planning Report is "2023-11-21 RiskScape Data For Report" for the purpose of a Value Assessment.
                </div>
            </div>
            <app-admin-property-listings-workflow-tasks></app-admin-property-listings-workflow-tasks>
        </mat-card>
    </div>
</main>